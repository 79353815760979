import React, { useState } from "react";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { loadStripe } from "@stripe/stripe-js";

const AccountPlans = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const activePlanName = "Starter";
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

  const PlanData = [
    {
      id: 1,
      PlanName: "Starter",
      description: "Free Forever",
      monthlyPrice: 0,
      yearlyPrice: 0,
      Information: [
        "Document Upload Limit - 1",
        "Daily questions limit - 5 messages/day",
        "Chat with all PDFs - Yes",
        "Max file size - 5MB",
        "Excel Upload - Yes",
        "Chrome extension - No",
        "Customer support - Email",
      ],

      Button: "Free",
    },
    {
      id: 2,
      PlanName: "Pro Plan",
      description: !isMonthly ? "Billed Monthly" : "Billed Annually",
      monthlyPrice: 9.99,
      yearlyPrice: 99,
      Information: [
        "Document Upload Limit - Unlimited",
        "Daily questions limit - Unlimited",
        "Chat with all PDFs - Unlimited",
        `Max file size - ${!isMonthly ? "15 MB" : "50 MB"}`,
        "Excel Upload - Yes",
        "Video Scripts - Coming Soon",
        "Chrome extension - Coming Soon",
        "Google Drive Integration - coming soon",
        "Drop Box Integration - Coming Soon",
        "HubSpot Integration - coming soon",
        "GPT-4 (available as a paid add-on) - Yes",
        "Ability to Choose LLM Models - coming soon",
        "Business API access - coming soon",
        "Invite team members - coming soon",
        "Customer support - Premium Support",
      ],
      Button: "Upgrade To Pro",
    },
  ];

  const handlePaymentFrequencyChange = (isMonthly) => {
    setIsMonthly(isMonthly);
  };

  const handlePayment = async () => {
    const stripe = await stripePromise;
    const paymentFrequency = !isMonthly ? "Monthly" : "Yearly";
    // Call your backend to create a Checkout Session
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "api/v1/accounts/create_checkout/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          subscription_plan: "Paid",
          payment_frequency: paymentFrequency,
        }),
      }
    );

    const session = await response.json();

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.session_id,
    });

    if (result.error) {
      console.error(result.error.message);
      // Handle errors if needed
    }
  };

  return (
    <div>
      <h1 className="text-white text-[30px] font-bold ">Settings</h1>
      <div className="border border-white mt-2"></div>

      <div className="mt-4">
        <h2 className="text-xl font-bold text-white">Account plans</h2>
        <h3 className="text-gray-400">
          Pick an account plan that fits your workflow.
        </h3>
      </div>

      <div className="flex flex-col md:flex-row gap-10 mt-10">
        <div className="md:w-1/5 w-full ">
          <h2 className="text-xl font-bold text-white">Current plan</h2>
          <h3 className="text-gray-400 ">
            If you choose to downgrade at any point. Your subscription will be
            active until the end of your next billing cycle.
          </h3>

          <div className="flex justify-center items-center mt-6">
            <span className="mr-3 text-md font-medium text-gray-300">
              Monthly
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isMonthly}
                onChange={() => handlePaymentFrequencyChange(!isMonthly)}
                className="sr-only peer"
              />
              <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4  peer-focus:ring-purple-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
              <span className="ml-3 text-md font-medium text-gray-300">Yearly</span>
            </label>
          </div>
        </div>
        <div className="md:w-2/3 w-full  text-white flex flex-col gap-4">
          {PlanData.map((plan) => (
            <div
              className={`border border-gray-400 p-4 rounded-xl ${
                plan.PlanName === activePlanName
                  ? "border-4 border-green-500"
                  : ""
              }`}
              key={plan.id}
            >
              <div className="flex md:flex-row flex-col justify-between items-center">
                <div className="flex flex-row gap-4">
                  <h2 className="font-bold text-3xl">{plan.PlanName}</h2>
                  <div className="text-lg text-teal-400">
                    {isMonthly
                      ? `$${plan.yearlyPrice}`
                      : `$${plan.monthlyPrice}`}
                    <sub className="text-lg">
                      {isMonthly ? "/year " : "/month"}
                    </sub>
                  </div>
                </div>
                <div>
                  {plan.PlanName === activePlanName ? (
                    <IoCheckmarkDoneCircleSharp size={24} color="green" />
                  ) : (
                    <button  onClick={handlePayment}  className="p-4 border-4 text-xl font-semibold hover:border-purple-700 hover:text-purple-700 hover:bg-white hover:font-bold border-white rounded-full">
                      {plan.Button}
                    </button>
                  )}
                </div>
              </div>

              {plan.description && <p>{plan.description}</p>}
              {plan.Information && (
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "20px",
                    fontSize: "18px",
                  }}
                >
                  {plan.Information.map((info, index) => (
                    <li key={index}>{info}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountPlans;
