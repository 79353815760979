import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchExcelChat = createAsyncThunk(
  'excelChat/fetchExcelChat',
  async ({ excelKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return rejectWithValue('No authorization token found');
    }

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/qdrant-excelchat/${excelKey}/?token=${token}`;
    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = '';
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log('WebSocket connection established:', wsUrl);
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus('loading'));
        dispatch(updatePartialAnswer(''));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
    
        try {
            const data = JSON.parse(event.data);
            console.log("WebSocket full response:", data);
    
            
            if (data.error) {
                console.error("WebSocket Error:", data.error);
                dispatch(updateStatus("failed"));
                dispatch(updateFinalAnswer(`Error: ${data.error}`)); // Display error in UI
                socket.close();
                return;
            }
    
            if (data.chunk) {
                accumulatedAnswer += data.chunk;
                dispatch(updatePartialAnswer(accumulatedAnswer));
            }
    
            if (data.type === 'final') {
                botMessageId = data.data.bot_message_id;
                runId = data.data.run_id;
                generatedQuestions = data.data.generated_questions || ""; 
                const barChartData = data.data.charts?.bar_chart || null; // ✅ Extract the bar chart
                console.log("📊 Bar Chart Data:", barChartData); // ✅ Log the bar chart

                console.log("Generated Questions Received:", generatedQuestions);
    
                dispatch(updateGeneratedQuestions(generatedQuestions));
                dispatch(updateFinalAnswer(accumulatedAnswer));
                dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));
                dispatch(updateBarChart(barChartData));
                socket.close();
                resolve(accumulatedAnswer);
            }
        } catch (error) {
            console.error("Error parsing WebSocket message:", event.data, error);
        }
    };
    

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(rejectWithValue('WebSocket connection error'));
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
    });
  }
);




export const fetchExcelSummary = createAsyncThunk(
  "excelChat/fetchExcelSummary",
  async ({ excelKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No authorization token found");
      return rejectWithValue("No authorization token found");
    }

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/excel_summary/${excelKey}/?token=${token}`;
    console.log("WebSocket URL:", wsUrl);

    return new Promise((resolve, reject) => {
      const socket = new WebSocket(wsUrl);
      let accumulatedAnswer = "";
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log("✅ WebSocket connection established for summary.");
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
      };

      socket.onmessage = (event) => {
        console.log("📩 WebSocket message received:", event.data);

        try {
          const message = event.data.startsWith("data: ")
            ? event.data.slice(6)
            : event.data;
          const data = JSON.parse(message);

          if (!data) {
            console.error("⚠️ Received empty data from WebSocket!");
            return;
          }

          if (data.status === "connected") {
            console.log("✅ WebSocket connection confirmed.");
            return;
          }

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            console.log("📌 Accumulated Summary Chunk:", accumulatedAnswer);
            dispatch(updatePartialSummary(accumulatedAnswer));
          }

          if (data.type === "final") {
            console.log("🚀 WebSocket Sent Final Summary:", data.data?.summary);

            botMessageId = data.data?.bot_message_id;
            runId = data.data?.run_id;
            const summary = data.data?.summary || "";
            generatedQuestions = data.data?.example_questions || "";

            if (!summary) {
              console.warn("⚠️ No summary received from WebSocket!");
            }

            console.log("✅ Extracted Summary:", summary);
            console.log("🔎 Extracted Generated Questions:", generatedQuestions);

            dispatch(updateFinalSummary(summary));
            dispatch(updateGeneratedQuestions(generatedQuestions));

            dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));
            dispatch(updateStatus("succeeded"));

            socket.close();
            resolve(summary);
          }
        } catch (error) {
          console.error("❌ Error parsing WebSocket message:", event.data, error);
          reject(rejectWithValue("WebSocket message parsing error"));
        }
      };

      socket.onerror = (error) => {
        console.error("❌ WebSocket error:", error);
        socket.close();
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("❎ WebSocket connection closed.");
      };
    });
  }
);





const excelChatSlice = createSlice({
  name: 'excelChat',
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
     barChart: null,
    error: null,
    botMessageId: null, 
    runId: null,
    generatedQuestions: null,
    feedback: {},
    formattedTimestamp: "",
    userInitials: "",
  },
  initialState: {
    messages: [],
    partialAnswer: "",
    finalAnswer: "",
    partialSummary: "",
    finalSummary: "",
    status: "idle",
    summaryStatus: "idle",
    error: null,
    summaryError: null,
    botMessageId: null,
    runId: null,
    generatedQuestions: null,
    feedback: {},
    formattedTimestamp: "",
    userInitials: "",
  },
  reducers: {
    resetExcelChatState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null; 
      state.runId = null;
      state.generatedQuestions = null;
      state.feedback = {}; 
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBarChart: (state, action) => {
    state.barChart = action.payload;
},

    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId , generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions; 
    },
    updateFeedback: (state, action) => {
      const { messageId, feedbackType } = action.payload;
      state.feedback[messageId] = feedbackType;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updatePartialSummary: (state, action) => {
      state.partialSummary = action.payload;
    },
    updateFinalSummary: (state, action) => {
      console.log("✅ Final Summary Received in Redux:", action.payload);
      state.finalSummary = action.payload;
    },
    updateSummaryStatus: (state, action) => {
      state.summaryStatus = action.payload;
    },
    updateGeneratedQuestions: (state, action) => {
      state.generatedQuestions = action.payload; 
    },
  
    updateSummaryMetadata: (state, action) => {
      console.log("Updating Redux - Summary Metadata", action.payload);
    
      if (action.payload) {
        state.formattedTimestamp = action.payload.formattedTimestamp ? action.payload.formattedTimestamp : "N/A";
        state.userInitials = action.payload.userInitials ? action.payload.userInitials : "N/A";
      }
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExcelChat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExcelChat.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchExcelSummary.pending, (state) => {
        state.summaryStatus = "loading";
      })
      .addCase(fetchExcelSummary.fulfilled, (state) => {
        state.summaryStatus = "succeeded";
      })
      .addCase(fetchExcelSummary.rejected, (state, action) => {
        state.summaryError = action.payload;
        state.summaryStatus = "failed";
      })
      .addCase(fetchExcelChat.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});
export const {  resetExcelChatState, updatePartialAnswer, updatePartialSummary,updateBarChart,
  updateFinalSummary,
  updateSummaryStatus,
  updateGeneratedQuestions, updateFinalAnswer, updateBotMessageInfo, updateStatus,updateSummaryMetadata, } = excelChatSlice.actions;
export default excelChatSlice.reducer;