import React, { useState, useEffect } from "react";
import { FiLogOut, FiMenu } from "react-icons/fi";
import PdfHistoryDrawer from "./PdfHistoryDrawer";
import ProfileModal from "./ProfileModal";
import { BiUpload } from "react-icons/bi";
import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { FaArrowAltCircleLeft, FaYoutube } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CgDatabase } from "react-icons/cg";
import drawer from "../../Images/Frame.jpg";
import circle from "../../Images/Ellipse 1.jpg";
import SliderLogo from "../../Images/logo-sidebar.png";
import profile from "../../Images/profile.png";
import arrowUp from "../../Images/arrow_up.png";
import AIlogo from "../../Images/ai-01.png";
import axios from "axios";

import ContractLogo from "../../Images/contractLogo.png";
import InvoiceLogo from "../../Images/invoiceLogo.png";
import SpreadsheetLogo from "../../Images/spreadSheetLogo.png";

import AICopilotIcon from "../../Images/ai-copiot-icon.svg";
import FilledAICopilotIcon from '../../Images/ai-copilot-icon-filled.svg';
import DocumentsIcon from "../../Images/documents.svg";
import ContractsIcon from "../../Images/contracts-icon.svg";
import ContractsIconFilled from "../../Images/contracts-filled.svg";
import DocumentsIconfilled from "../../Images/documentsfilled.svg";
import TogglemenuClosed from "../../Images/togglemenu.svg";

const navItems = [
  {
    to: "/ai-Copilot",
    icon: <img src={AICopilotIcon} alt="AI logo" />,
    filledIcon: <img src={FilledAICopilotIcon} alt="AI logo active" />, // Ensure it's provided
    name: "AI Copilot",
    // button: (
    //   <button
    //     onClick={() => console.log("Button clicked!")}
    //     className="flex h-[19px] px-[6px] pt-[1px] pb-[3px] justify-center items-center rounded-[28px] border-[0.5px] border-[#C95EBE] text-center font-[Outfit] text-[12px] font-normal leading-normal tracking-[-0.48px] bg-gradient-to-r from-[#F5F4F3] via-[#C95EBE] to-[#C95EBE] bg-clip-text text-transparent cursor-pointer ml-[10px]"
    //   >
    //     coming soon
    //   </button>
    // ),
    // disableHover: true,
  },
  {
    to: "/uploadFile",
    icon: <img src={DocumentsIcon} alt="document logo" />,
    filledIcon: <img src={DocumentsIconfilled} alt="document logo active" />,
    name: "Documents",
  },
  {
    to: "/Contract",
    icon: <img src={ContractsIcon} alt="Contracts logo" />,
    filledIcon: <img src={ContractsIconFilled} alt="Contracts logo active" />,
    name: "Contracts",
  },
  {
    to: "/Invoices",
    icon: <img src="/invoice_unfilled-icon.svg" alt="Invoice logo" />,
    name: "Invoices",
    filledIcon: <img src="/invoice_filled-icon.svg" alt="Contracts logo active" />,

    // button: (
    //   <button
    //     onClick={() => console.log("Button clicked!")}
    //     className="flex h-[19px] px-[6px] pt-[1px] pb-[3px] justify-center items-center rounded-[28px] border-[0.5px] border-[#C95EBE] text-center font-[Outfit] text-[12px] font-normal leading-normal tracking-[-0.48px] bg-gradient-to-r from-[#F5F4F3] via-[#C95EBE] to-[#C95EBE] bg-clip-text text-transparent cursor-pointer ml-[10px]"
    //   >
    //     coming soon
    //   </button>
    // ),
    // disableHover: true,
  },

  { 
    to: "/Spreadsheet", //to: "/upload-excel",
    icon: <img src="/spreadsheets.svg" alt="Spreadsheet logo" />,
    name: "Spreadsheets",
    filledIcon: <img src="/spreadsheets_filled.svg" alt="Contracts logo active" />,

  },
];
const Menu = ({ isOpen, toggleMenu, setDisplayName }) => {
  const location = useLocation();
  const [isPdfHistoryDrawerOpen, setPdfHistoryDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("AI Copilot");
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    username: "",
  });
  const [isContractChat, setIsContractChat] = useState(false);
  const [isExcelChat, setIsExcelChat] = useState(false);
  const [isChatPage, setIsChatPage] = useState(false);
  const [isFolderPage, setIsFolderPage] = useState(false);
  const [isCopilotPage, setIsCopilotPage] = useState(false);
  const pathname = location.pathname.toLowerCase();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isInvoiceChat, setIsInvoiceChat] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
const [uploadedDocsCount, setUploadedDocsCount] = useState(0);
const [expiresOn, setExpiresOn] = useState("");
const [selectedPlan, setSelectedPlan] = useState("Grow"); // or "Start" based on logic
const [isTrialActive, setIsTrialActive] = useState(true);
const [popupType, setPopupType] = useState("payment");
const [isPaidYearlyPlan, setIsPaidYearlyPlan] = useState(false);
const [daysLeft, setDaysLeft] = useState(0);


  useEffect(() => {
    const token = localStorage.getItem("token");
  
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/accounts/get-subscription-status/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 200) {
        setSubscriptionStatus(response.data);
        const { uploaded_docs_count, expires_on , subscription_type, plan_type } = response.data;
        setUploadedDocsCount(uploaded_docs_count);
        setExpiresOn(expires_on);
  
        const isTrial = !(subscription_type === "paid_subs" && plan_type === "monthly");
        setIsTrialActive(isTrial);
  
        // 👇 Add this line
        setIsPaidYearlyPlan(subscription_type === "paid_subs" && plan_type === "yearly");

        if (expires_on) {
          const today = new Date();
          const expiryDate = new Date(expires_on);
          const diffTime = expiryDate.getTime() - today.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          setDaysLeft(diffDays > 0 ? diffDays : 0);
        }
      }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };
  
    fetchSubscriptionStatus();
  }, []);

  useEffect(() => {
    // const fullName =
    //   localStorage.getItem("fullName") || localStorage.getItem("full_name");
    const full_name = localStorage.getItem("full_name") || localStorage.getItem("fullName");

    // console.log("afaafa",full_name);
    const email = localStorage.getItem("email");
    const username = localStorage.getItem("username");

    setUser({
      full_name,
      email,
      username,
    });
    // Ensure setDisplayName exists before calling
    if (typeof setDisplayName === "function") {
      setDisplayName(full_name || username);
    }
  }, [setDisplayName]);

  const handleIconClick = (to) => {
    if (to) {
      navigate(to);
    }
  };
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/ai-copilot" || pathname === "/ai-copilot/") {
      setActiveItem("AI Copilot");
      return;
    }
    let currentItem = navItems.find((item) => {
      if (!item.to) return false;
      return pathname === item.to || pathname.startsWith(item.to);
    });
    if (pathname.startsWith("/folder")) {
      currentItem = navItems.find((item) => item.name === "Documents");
    }
    if (currentItem) {
      setActiveItem(currentItem.name);
    } else {
      setActiveItem(null);
    }
  }, [location]);

  useEffect(() => {
    const pathname = location.pathname.toLowerCase();
    setIsContractChat(pathname.includes("contractchat"));
    setIsChatPage(pathname.includes("/chat"));
    setIsInvoiceChat(pathname.includes("/invoices/invoice_chat"));
    setIsExcelChat(pathname.includes("/spreadsheets/excelchat")); 
    setIsFolderPage(pathname.includes("/folder"));
    setIsCopilotPage(pathname.includes("/ai-copilot"));
  }, [location]);
  const [isCompact, setIsCompact] = useState(false);

  const isRestrictedRoute = /\/(chat|Invoice_chat|folder|contractchat)/i.test(
    location.pathname.toLowerCase()
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);



  const handlePdfHistoryDrawerOpen = () => {
    setPdfHistoryDrawerOpen(true);
  };


  const displayName = `${user.full_name || user.username}`.trim();
  // console.log(displayName);

  // console.log("ksssk",displayName);
  let isClicked = false;

  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const navigate = useNavigate();
  const [clickedBox, setClickedBox] = useState(null);
  // const handleBoxClick = (index) => {
  //   setClickedBox(index === clickedBox ? null : index);
  // };
  const boxItems = [
    // { id: 1, label: "Account", icon: "./account-icon.svg" },
    // { id: 2, label: "Billing", icon: "./billing-icon.svg" },
    // { id: 3, label: "Teams", icon: "./teams-icon.svg" },
    {
      id: 3,
      label: "Settings",
      icon: "/account-icon.svg",
      style: {
        marginTop: "130px",
      },
    },
    {
      id: 4,
      label: "Logout",
      icon: "/logout-icon.svg",
      style: {
        marginTop: "130px",
      },
    },
  ];
  const logout = () => {
    localStorage.removeItem("full_name");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload(); // Reload to ensure a clean state
  };

  const handleLogout = () => {
    logout();
    navigate("/signin");
  };
  useEffect(() => {
    if (isOpen) {
      setIsPopupOpen(true);
    } else {
      setIsPopupOpen(false);
    }
  }, [isOpen]);

  const handleBoxClick = (index) => {
    if (boxItems[index].label === "Logout") {
      handleLogout();
    } else if (boxItems[index].label === "Settings") {
      navigate("/account");
    } else {
      setClickedBox(index);
    }
  };


  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  if (isChatPage) {
    return null;
  }
  if (location.pathname === "/test") {
    return null;
  }



  if (isContractChat) {
    return null;
  }
  if (isExcelChat) {
    return null;
  }


  if (isInvoiceChat) {
    return null;
  }

  const isFolderChat = location.pathname === "/Contract/FolderChat";

  if (isFolderChat) {
    return null;
  }

 
  

  return (
    <div className=" h-full md:h-screen  bg-[#2C1338]">
      <nav
        className={`bg-[#2C1338] transition-all ${isOpen ? "w-65" : "w-[80px]"
          } h-full  fixed z-50 top-0 left-0 flex flex-col justify-between`}
      >
        <div>
          <div
            className={`p-5 text-white flex items-center ${isOpen ? "justify-evenly" : "justify-center"
              }  `}
          >
            {isOpen ? (
              <div className="flex justify-between gap-6">
                <img src={SliderLogo} alt="logo" className="h-6 w-[131.77px]" />
                <button
                  onClick={toggleMenu}
                  className="h-[32px] w-[32px] bg-white rounded-full"
                >
                  <img src={drawer} className="p-[5px]" />
                </button>
              </div>
            ) : (
              <div className="flex flex-col gap-5 items-center justify-center">
                <button
                  onClick={toggleMenu}
                  className="h-[32px] w-[32px] bg-white rounded-full"
                >
                  <img src={TogglemenuClosed} className="p-[5px]" />
                </button>
                {isContractChat && (
                  <button className="bg-[#C95EBE] rounded-md p-1">
                    <img src="/chat-icon-nav.svg" alt="" />
                  </button>
                )}
                {isChatPage && (
                  <button className="bg-[#C95EBE] rounded-md p-1">
                    <img src="chat-icon-nav.svg" alt="" />
                  </button>
                )}
                {isFolderPage && (
                  <button className="bg-[#C95EBE] rounded-md p-1">
                    <img src="/chat-icon-nav.svg" alt="Folder Icon" />
                  </button>
                )}
                {isInvoiceChat && (
                  <button className="bg-[#C95EBE] rounded-md p-1">
                    <img src="/chat-icon-nav.svg" alt="Invoice Chat Icon" />
                  </button>
                )}
              </div>
            )}
          </div>

          {isOpen && <div className="h-[2px]  mb-4"></div>}

          {isOpen && (
            <ul className="text-white px-2">
              {navItems.map((item) => (
                <li
                  key={item.name}
                  className={`mb-3 cursor-pointer flex justify-start items-center h-10 w-[224px] ${isOpen && activeItem === item.name
                    ? "bg-[#A04A97] rounded-md"
                    : !item.disableHover
                      ? "hover:bg-[#633168] rounded-md "
                      : ""
                    } ${item.disableHover ? "pointer-events-none" : ""}`}
                >
                  {isOpen ? (
                    item.to ? (
                      <Link
                        to={item.to}
                        className="flex items-center w-full h-full pl-6"
                        onClick={() => !item.disableHover && handleItemClick(item.name)}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        <h1 className="text-[20px]">
                          {activeItem === item.name ? item.filledIcon : item.icon}
                        </h1>
                        <span className="ml-2">{item.name}</span>
                      </Link>
                    ) : (
                      <div className="flex items-center pl-6">
                        <h1 className="text-[20px]">
                          {activeItem === item.name ? item.filledIcon : item.icon}
                        </h1>
                        {isOpen && <span className="ml-2">{item.name}</span>}
                        {isOpen && item.button && item.button}
                      </div>
                    )
                  ) : null}
                </li>
              ))}
            </ul>


          )}
        </div>
        {!isOpen && (
          <ul
            className="text-white px-2 relative"
            style={{
              marginTop:  !isDropdownOpen ? "-316px" : "0",
            }}
          >
            {navItems
              .filter(
                (item) =>
                  !(isChatPage && ["AI Copilot", "Documents", "Contracts", "Invoices", "Spreadsheets"].includes(item.name))
              )
              .map((item, index) => (
                <li
                  key={item.name}
                  className={`mb-3 cursor-pointer flex justify-start items-center h-10 w-[224px] relative ${isOpen && activeItem === item.name
                    ? "bg-[#131113] rounded-md"
                    : ""
                    } ${item.disableHover
                      ? "hover:bg-transparent hover:text-inherit pointer-events-none"
                      : ""
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isOpen && item.to) {
                      navigate(item.to);
                    } else if (!item.disableHover) {
                      handleItemClick(item.name);
                    }
                  }}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  {!isOpen &&
                    ![
                      "/contract/folderchat",
                      "/chat",
                      "/invoices/invoice_chat",
                      "/contract/contractchat",
                    ].includes(location.pathname.toLowerCase()) ? (
                    item.to ? (
                      <Link to={item.to} className="flex items-center pl-6">
                        <h1 className="text-[20px]">
                          {activeItem === item.name ? item.filledIcon : item.icon}
                        </h1>
                      </Link>
                    ) : (
                      <div className="flex items-center pl-6">
                        <h1 className="text-[20px]">
                          {activeItem === item.name ? item.filledIcon : item.icon}
                        </h1>
                        {isOpen && item.button && item.button}
                      </div>
                    )
                  ) : null}

                  {!isOpen &&
                    hoveredItem === index &&
                    !/\/(chat|folder|invoice_chat|contractchat)/i.test(
                      location.pathname.toLowerCase()
                    ) && (
                      <div
                        className="absolute left-14 bg-[#FEF9F7] text-[#52525B] text-[14px] font-[Outfit] font-normal leading-none border border-[rgba(0,0,0,0.1)] rounded-md shadow-lg flex items-center justify-center whitespace-nowrap"
                        style={{
                          width: [
                            "Spreadsheets",
                          ].includes(item.name)
                            ? "100px"
                            : "90px",
                          height: "28px",
                          pointerEvents: "none",
                          cursor: "default",
                        }}
                      >
                        {["Spreadsheets"].includes(
                          item.name
                        ) ? (
                          <span
                            className="text-center"
                            style={{ pointerEvents: "auto", cursor: "none" }}
                          >
                            Coming Soon
                          </span>
                        ) : (
                          <span
                            className="text-center"
                            style={{ pointerEvents: "auto", cursor: "none" }}
                          >
                            {item.name}
                          </span>
                        )}
                      </div>
                    )}
                </li>
              ))}
          </ul>
        )}
        {/* {isPopupOpen && (
          <div
            className="w-[216px] h-[112px] flex-shrink-0 rounded-[12px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] p-3 flex flex-col justify-between text-[#2C1338] font-outfit ml-3 mb-8"
            style={{ background: 'linear-gradient(121.75deg, #F5F4F3 5.17%, #DAC9F4 93.14%)' }}
          >            <div className="flex justify-between items-center">
              <div className="flex items-center gap-1 -ml-[10px]">
                <img src="/nav-star-icon.svg" alt="" />
                <span className="font-semibold text-[13px] leading-normal text-black font-outfit">
                  Hurray!
                </span>
              </div>
              <span
                className="w-[20px] h-[20px] flex items-center justify-center flex-shrink-0 cursor-pointer text-black font-bold text-[14px] ml-[25px] -mt-[10px]"
                onClick={handleClose}
              >
                <img src="/nav-cross.svg" alt="" />
              </span>
            </div>

            <div style={{ marginTop: "8px" }}>
              <div className="font-semibold text-[13px] leading-normal -mt-[10px] ml-[13px] text-black font-outfit">
                You've Got 2 Free Months!
              </div>
              <div className="w-[176px] h-[76px] text-[rgba(23,23,23,0.80)] font-outfit text-[13px] font-normal leading-normal tracking-[-0.39px] mb-[10px] ml-[13px]">
                Celebrate with access to cutting-edge AI tools and powerful
                document insights.
              </div>
            </div>
          </div>
        )} */}
<div
  className="mx-3 my-4 p-4 text-[#2C1338] font-outfit text-sm flex flex-col justify-between transition-all duration-300"
  style={{
    width: "220px",
    height: "158px",
    flexShrink: 0,
    borderRadius: "12px",
    background: "linear-gradient(111deg, #F5EEFF 14.52%, #FDFDFD 99.31%)",
    boxShadow:
      "0px 18px 5px 0px rgba(255, 255, 255, 0.00), 0px 12px 5px 0px rgba(255, 255, 255, 0.01), 0px 7px 4px 0px rgba(255, 255, 255, 0.05), 0px 3px 3px 0px rgba(255, 255, 255, 0.09), 0px 1px 2px 0px rgba(255, 255, 255, 0.10)",
    visibility:
      isOpen &&
      isPopupOpen &&
      (
        subscriptionStatus?.subscription_type === "trial" ||
        subscriptionStatus?.subscription_type === "weekly_paid" ||
        (subscriptionStatus?.subscription_type === "paid_subs" &&
         subscriptionStatus?.plan_type === "monthly")
      )
    
        ? "visible"
        : "hidden",
    opacity:
      isOpen &&
      isPopupOpen &&
      (subscriptionStatus?.subscription_type === "trial" ||
        subscriptionStatus?.subscription_type === "weekly_paid")
  }}
>
    <div className="flex items-center mb-1">
  {!(!isTrialActive) && (
    <img
      src="/trial-icon-billing.svg"
      alt="Trial Icon"
      className="w-[16px] h-[16px] mr-2"
    />
  )}
  <div
    className="text-[#18101C] text-[13px] font-medium font-outfit"
    style={{
      letterSpacing: "0.13px",
      lineHeight: "normal",
    }}
  >
   {!isTrialActive
  ? "Maximize Your Potential with Grow Plan"
  : `Trial Active: ${daysLeft} Days Left`}

  </div>
</div>


{!isTrialActive ? (
  <div
    className="text-center font-outfit text-[13px] font-medium leading-none tracking-[0.13px]"
    style={{ color: "#73607B" }}
  >
    Save over{" "}
    <span
      style={{
        background: "linear-gradient(90deg, #F3904F 30.06%, #8B55DD 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      15% annually
    </span>{" "}
    compared to{" "}
    <span
      style={{
        background: "linear-gradient(90deg, #F3904F 30.06%, #8B55DD 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      Starter Plan!
    </span>
  </div>
) : (
  <div
    className="text-center font-outfit text-[13px] font-medium leading-none tracking-[0.13px]"
    style={{ color: "#73607B" }}
  >
    Upload up to{" "}
    <span
      style={{
        background: "linear-gradient(90deg, #F3904F 30.06%, #8B55DD 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      {subscriptionStatus?.upload_limit ?? 5} documents
    </span>{" "}
    and perform{" "}
    <span
      style={{
        background: "linear-gradient(90deg, #F3904F 30.06%, #8B55DD 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      {subscriptionStatus?.query_limit ?? 5} AI queries daily
    </span>
  </div>
)}


      <div className="mt-2 text-center">
        <div className="text-[12px] mb-1 text-[#2C1338]">Need more power?</div>
        <button
  onClick={() => navigate("/account", { state: { defaultTab: "billing" } })}
  className="inline-flex justify-center items-center gap-[10px] rounded-[6px] transition font-outfit text-[13px] font-medium leading-none tracking-[0.13px] text-center"
  style={{
    padding: "8px 20px",
    background: "linear-gradient(93deg, #6F52ED 0.64%, #845EC2 99.52%)",
    color: "#F5F4F3",
  }}
>
  Upgrade Now
</button>

    
      </div>
    </div>



        {isDropdownOpen && (
          <li
            className={`bg-[#2C1338] rounded-[6px] border border-[rgba(254,249,247,0.20)] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ${isOpen ? "w-[224px] ml-[7px]" : "w-[75px] ml-0"
              
              } text-white p-0 list-none`}
            onClick={toggleMenu}
          >
            <ul
              className={`list-none m-0 ${isOpen
                ? "p-[8px] flex flex-col gap-[4px] justify-start"
                : "p-[8px] flex flex-col gap-[12px] justify-center"
                }`}
            >
              {boxItems.map((item, index) => (
                <li
                  key={item.id}
                  onClick={() => handleBoxClick(index)}
                  className={`flex items-center ${isOpen
                    ? `w-[208px] justify-start gap-[8px]`
                    : `w-[54px] justify-center`
                    } h-[32px] rounded-[6px] p-0 cursor-pointer overflow-hidden transition-all ease-in-out duration-300 ${isOpen
                      ? clickedBox === index
                        ? "border-t-[0.5px] border-r-[0.5px] border-b-[3px] border-l-[0.5px] border-white bg-[#ffffff20] px-[12px] py-[6px]"
                        : "border-transparent bg-transparent px-[12px] py-[6px] hover:bg-[#633168] hover:text-white"
                      : "p-[4px] hover:bg-[#A04A97] hover:text-white"
                    }`}
                >
                  <img
                    src={item.icon}
                    alt={`${item.label} Icon`}
                    style={{
                      width: !isOpen ? "20px" : "16px",
                      height: !isOpen ? "20px" : "16px",
                    }}
                  />
                  {isOpen && <span>{item.label}</span>}
                  {!isOpen && (
                    <span style={{ display: "none" }}>{item.label}</span>
                  )}
                </li>
              ))}
            </ul>
          </li>
        )}



        <div className="pb-2 flex flex-col gap-4">
          {location.pathname.toLowerCase() !== "/chat" &&
            location.pathname.toLowerCase() !== "/invoice_chat" &&
            location.pathname.toLowerCase() !== "/contractchat" &&
            location.pathname.toLowerCase() !== "/Contract/FolderChat" && (
              <div className="flex justify-around items-center text-white">
                <div>
                  <img src={profile} alt="profile logo" />
                </div>
                {isOpen && (
                  <div>
                    <div className="text-base mt-2">{displayName}</div>
                    <div className="text-xs text-gray-400">{user.email}</div>
                  </div>
                )}

                <div>
                  <img
                    src={arrowUp}
                    alt="arrow-up"
                    className="cursor-pointer"
                    style={{
                      transform: isDropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s",
                    }}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  />
                </div>
              </div>
            )}
        </div>
      </nav>
    </div>
  );
};
export default Menu;
