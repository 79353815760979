import React, { useState, useEffect, useCallback,useRef } from "react";
import axios from "axios";
import "./uploadModal.css";
import { useNavigate } from "react-router-dom";

const UploadModal = ({ isOpen, onClose, onUploadSuccess, type, folderId ,file}) => {
  // const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [invoiceKey, setInvoiceKey] = useState(null);
  const [fileKey, setFileKey] = useState(null);
  const [progress, setProgress] = useState(0);
  let progressInterval = null;
  const token = localStorage.getItem("token");
  const [uploadController, setUploadController] = useState(null);
  const [messages, setMessages] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [isCanceled, setIsCanceled] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(file);
  const [currentMessage, setCurrentMessage] = useState("Waiting for updates...");

  useEffect(() => {
      if (file) {
          console.log("Received file in UploadModal:", file);
          setSelectedFile(file);
          handleFileUpload(file);
      }
  }, [file]); 


  useEffect(() => {
    if (isOpen && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [type, isOpen]);


  useEffect(() => {
    console.log("isUploaded state changed:", isUploaded);
  }, [isUploaded]);
  


  


  const fetchNewContractId = useCallback(async () => {
    if (!token) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/contract/contracts/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const highestId = Math.max(
          ...response.data.map((contract) => contract.id)
        );
        const newId = highestId + 1;
        return newId;
      } else {
        return 1;
      }
    } catch (error) {
      return null;
    }
  }, [token]);

  // useEffect(() => {
  //   return () => {
  //     if (socket.readyState === WebSocket.OPEN) {
  //       socket.close();
  //     }
  //   };
  // }, []);





  

  const navigate = useNavigate();
  const [contractKey, setContractKey] = useState(null);

  if (!isOpen) return null;

  const triggerFileInput = () => document.getElementById("fileInput").click();

  const handleViewDetails = () => {
    if (type === "Invoices") {
      if (!invoiceKey) {
        alert("Invoice key is missing. Please try again.");
        return;
      }
      navigate("/Invoices/Invoice_Details", {
        state: {
          invoiceKey,
          pdfFile: file,
          additionalInfo: "Invoice specific data",
        },
      });
    } else if (type === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey, pdfFile: file },
      });
    }
  };

  

  const startProgress = () => {
    progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 90) {
          return prevProgress + 1;
        } else {
          clearInterval(progressInterval);
          return prevProgress;
        }
      });
    }, 100);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    // setFile(selectedFile);
    setIsUploading(true);
    setIsUploaded(false);
    setProgress(0);
    setIsCanceled(false);
    handleFileUpload(selectedFile);
  };

  

  const handleFileUpload = async (selectedFile) => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Session expired, please log in.");
      onClose();
      return;
    }

    if (!selectedFile || (type === "underFolder" && !folderId)) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    let url = "";

    if (type === "contract") {
      url = `${process.env.REACT_APP_API_URL}api/v1/contract/qdrant-upload-contract/`;
    } else if (type === "underFolder") {
      url = `${process.env.REACT_APP_API_URL}api/v1/readpdf/upload-in-folder/${folderId}/`;
    } else if (type === "Spreadsheets") {
      url = `${process.env.REACT_APP_API_URL}api/v1/excel/qdrant-upload-excel/`;
    } else if (type === "Invoices") {
      url = `${process.env.REACT_APP_API_URL}api/v1/invoice/qdrant-upload-invoice/`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}api/v1/readpdf/qdrant-upload-async/`;
    }
    const controller = new AbortController();
    setUploadController(controller);
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
        signal: controller.signal,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
          setCurrentMessage(`Uploading... ${percentCompleted}%`);
        },
      });

      if (response.status === 200) {
        setProgress(100);
        setTimeout(() => {
            setIsUploaded(true); // Show success page
            setIsUploading(false);
            if (type === "Invoices") {
                setInvoiceKey(response.data.invoice_key);
            } else if (type === "contract") {
                setContractKey(response.data.contract_key);
            }
        }, 1000);
    } else {
        alert(response.data.msg || "File upload failed");
        setIsUploading(false);
    }
} catch (error) {
    alert(
        error.response?.data.msg || "An error occurred during file upload. Please try again."
    );
    setIsUploading(false);
}
};




    const handleClose = async () => {
      onClose();

      if (type === "contract") {
        navigate("/Contract");
      } else if (type === "underFolder") {
        navigate(`/folder/?folderId=${folderId}`);
      } else {
        navigate("/uploadFile");
      }
    };






  return (
    <div className="modal-overlay-details">
      <div className="modal-content-details">
        <button
          className="close-button"
          onClick={handleClose}
          style={{ marginRight: "30px", fontSize: "22px" }}
        >
          x
        </button>
         {/* <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".csv,.xlsx,.pdf"
            /> */}
        {isUploaded ? (
          <div>
            <div className="success-status">
              <div className="success-icon-upload">
                <img
                  src="/right-success.svg"
                  alt="Success Icon"
                  className="checkmark-icon"
                />
              </div>
              {type === "contract" ? (
                <h3 className="success-heading">
                  Contract Uploaded Successfully
                </h3>
              ) : type === "underFolder" ? (
                <h3 className="success-heading">
                  File Uploaded to Folder Successfully
                </h3>
              ) : type === "Invoices" ? (
                <h3 className="success-heading">
                  Invoice Uploaded Successfully
                </h3>
              ) : (
                <h3 className="success-heading">File Uploaded Successfully</h3>
              )}

              {type === "contract" ? (
                <p className="success-message-upload">
                  Your contract has been uploaded successfully
                </p>
              ) : type === "underFolder" ? (
                <p className="success-message">
                  Your file has been uploaded to the folder successfully
                </p>
              ) : type === "Invoices" ? (
                <p className="success-message">
                  Your invoice has been uploaded successfully
                </p>
              ) : (
                <p className="success-message">
                  Your File has been uploaded successfully
                </p>
              )}
              <div className="file-info">
                <span className="file-name-icon">
                  <img src="file-icon.svg" alt="" />
                </span>
                <span className="file-name">
                  {file ? file.name : "Uploaded_File.pdf"}
                </span>
              </div>
            </div>
            {type === "contract" && (
              <p className="extraction-message">
                Data has been extracted from{" "}
                <a href="#" className="extraction-link">
                  {file ? file.name : "Uploaded_File.pdf"}
                </a>
              </p>
            )}

            {type === "contract" || type === "Invoices" ? (
              <button
                className="view-details-button"
                onClick={handleViewDetails}
              >
                View Details
              </button>
            ) : type === "underFolder" ? (
              <button
                className="view-details-button-doc mt-6"
                onClick={handleClose}
              >
                Done
              </button>
            ) : (
              <button className="view-details-button-doc" onClick={handleClose}>
                Done
              </button>
            )}
          </div>
        ) :(
          <div className="Full-Box">
            {type === "contract" ? (
              <h3 className="Heading1">Upload Your Contract</h3>
            ) : type === "underFolder" ? (
              <h3 className="Heading1">Upload Your File to Folder</h3>
            ) : type === "Invoices" ? (
              <h3 className="Heading1">Upload Your Invoice</h3>
            ) : (
              <h3 className="Heading1">Upload Your Files</h3>
            )}

            {type === "contract" ? (
              <p className="Paragraph1">
                Upload your contract here and start extracting data
              </p>
            ) : type === "underFolder" ? (
              <p className="Paragraph1">
                Upload your file here and store it in a folder
              </p>
            ) : type === "Invoices" ? (
              <p className="Paragraph1">
                Upload your Invoice here and start extracting data
              </p>
            ) : (
              <p className="Paragraph1">
                Upload your file here and start extracting data
              </p>
            )}

            <div className="upload-box" style={type === "Spreadsheets" ? { marginLeft: "15rem" } : {}}>
              
              <img src="/uploading.svg" alt="" className="uploadingIcon" />
              <p className="status-text-upload">{currentMessage}</p>


              <p className="sub-status-text">
                {type === "contract"
                  ? "Your contract is being uploaded"
                  : type === "underFolder"
                    ? "Your file is being uploaded to the folder"
                    : type === "Invoices"
                      ? "Your invoice is being uploaded"
                      : "Your file is being uploaded"}
              </p>
              <p className="progress-label">Your Progress</p>
              <p className="progress-percentage-upload">
                {progress}% to complete
              </p>
              <div className="time-estimate">
                <span className="time-icon">⏱</span> 1min
              </div>
              <div className="progress-bar-cont">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progress}%` }}
                ></div>
                 
              </div>
            </div>

            <div className="file-info">
              <div className="file-info-container">
                <div
                  className="support"
                  style={{ position: "relative", left: "185px" }}
                >
                  Supported formats: PDF,XLSX, CSV
                </div>
              </div>
              <div className="file-info-container">
                <div
                  className="maximum"
                  style={{
                    position: "relative",
                    left: "-10px",
                  }}
                >
                  Maximum Size: 20MB
                </div>
              </div>
            </div>
          </div>
       
        )}
      </div>
    </div>
  );
};

export default UploadModal;