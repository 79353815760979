import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const sendFeedback = createAsyncThunk(
  "feedback/sendFeedback",
  async ({ messageId, runId, feedbackType, userFeedback = null }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No authorization token found");
    }

    const url =
      "https://wa-docusensabackend-2-staging-bjb3h4f3efeeancw.centralindia-01.azurewebsites.netapi/v1/contract/feedback/";
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };

   
    const requestBody = {
      message_id: messageId,
      run_id: runId,
      feedback_type: feedbackType,
      ...(feedbackType === "down" && { user_feedback: userFeedback }),
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const contentType = response.headers.get("Content-Type");
        if (contentType?.includes("application/json")) {
          const error = await response.json();
          return rejectWithValue(error.message || "Failed to send feedback");
        } else {
          const errorText = await response.text();
          return rejectWithValue(`Unexpected response: ${errorText}`);
        }
      }

      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "An error occurred while sending feedback");
    }
  }
);


const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    feedbackResult: null,
    status: "idle",
    error: null,
  },
  reducers: {
    resetFeedbackState: (state) => {
      state.feedbackResult = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.feedbackResult = action.payload.feedback_result;
        state.status = "succeeded";
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.error = action.payload || "Failed to send feedback";
        state.status = "failed";
      });
  },
});


export const { resetFeedbackState } = feedbackSlice.actions;
export default feedbackSlice.reducer;
