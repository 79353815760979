import React from "react";
import "./CancelSubscriptionPopup.css";
const CancelSubscriptionPopup = ({ onClose, onConfirm }) => {
  return (
    <div className="cancel-subs-overlay fixed inset-0 bg-black bg-opacity-40 z-50 flex items-center justify-center">
      <div className="cancel-subs-billing bg-white rounded-xl w-[440px] p-6 shadow-lg">
        <h2 className="cancel-subs-billing-title">
          Are You Sure You Want to Cancel?
        </h2>
        <p className="cancel-subs-billing-subs mt-2">
          This action cannot be undone.
        </p>
        <p className="cancel-subs-billing-substwo mt-4">
          By proceeding, you will end your current subscription and lose immediate access to all features.
        </p>
        <p className="cancel-subs-billing-substwo">
          You will be redirected to select a new plan if you wish to continue using our services.
        </p>
        <div className="flex justify-start gap-3 mt-5">
          <button
            onClick={onClose}
            className="cancel-subs-billing-button"
          >
            Keep Subscription
          </button>
          <button
            onClick={onConfirm}
            className="cancel-subs-billing-buttontwo"
          >
            Cancel Subscription
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionPopup;
