import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  fetchChatContract,
  resetChatContractState,
  fetchContractSummary,
  updatePartialAnswer,
  updateSummaryStatus,
} from "../../Features/chatContractSlice";
import { sendFeedback } from "../../Features/sendFeedback";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import "./contractChat.css";
import { format } from "date-fns";
const ContractChat = () => {
  const chatContainerRef = useRef(null);
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [typedQuestions, setTypedQuestions] = useState([]);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [shouldStopResponse, setShouldStopResponse] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [clientMessage, setClientMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [olderMessages, setOlderMessages] = useState([]);
  const [isFetchingOlderMessages, setIsFetchingOlderMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const [showEarlierMessagesPopup, setShowEarlierMessagesPopup] =
    useState(false);
  const [contractUrl, setContractUrl] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const finalAnswer = useSelector((state) => state.pdfChat?.finalAnswer || "");
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null);
  const [isThinking, setIsThinking] = useState(false);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const textareaRef = useRef(null);
  const [typingStatus, setTypingStatus] = useState(false);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const contractKey = new URLSearchParams(location.search).get("ckey") || "";
  const finalSummary = useSelector((state) => state.chatContract.finalSummary);

  const summaryWSStatus = useSelector((state) => state.chatContract.webSocketStatus);
const isSummaryActive = useSelector((state) => state.chatContract.isSummaryWebSocketActive);
const [isSummaryWebSocketActiveNow, setIsSummaryWebSocketActiveNow] = useState(false);



useEffect(() => {
  console.log("🧠 summaryWSStatus changed:", summaryWSStatus);
  if (summaryWSStatus === "connected") {
    setIsSummaryWebSocketActiveNow(true);
    console.log("🛑 Inputs should be DISABLED (WebSocket connected)");
  } else if (summaryWSStatus === "closed") {
    setIsSummaryWebSocketActiveNow(false);
    console.log("✅ Inputs should be ENABLED (WebSocket closed)");
  }
}, [summaryWSStatus]);


useEffect(() => {
  console.log("🚦 isSummaryWebSocketActiveNow:", isSummaryWebSocketActiveNow);
}, [isSummaryWebSocketActiveNow]);


  const partialAnswer = useSelector(
    (state) => state.chatContract.partialAnswer
  );
  const status = useSelector((state) => state.chatContract.status);
  const error = useSelector((state) => state.chatContract.error);
  const navigate = useNavigate();
  const { botMessageId = null, runId = null } = useSelector((state) => state.chatContract || {});

  const formattedTimestamp = useSelector((state) => state.chatContract.formattedTimestamp);
  const userInitials = useSelector((state) => state.chatContract.userInitials || "N/A");
  
 
  
  useEffect(() => {
    console.log("Formatted Timestamp Updated in Redux:", formattedTimestamp);
    console.log("User Initials Updated in Redux:", userInitials);
  }, [formattedTimestamp, userInitials]);
  
  const isSummaryWebSocketActive = useSelector(
  (state) => state.chatContract.isSummaryWebSocketActive
);


  const partialSummary = useSelector(
    (state) => state.chatContract?.partialSummary || ""
  );
  // const finalSummary = useSelector(
  //   (state) => state.chatContract?.finalSummary || ""
  // );
  const summaryStatus = useSelector(
    (state) => state.chatContract?.summaryStatus || "idle"
  );
  const isSummaryLoading = summaryStatus === "loading";

  const webSocketStatus = useSelector((state) => state.chatContract.webSocketStatus);

  useEffect(() => {
    if (contractKey) {
      dispatch(fetchContractSummary({ contractKey })); 
    }
  }, [contractKey, dispatch]);

  const generatedQuestions = useSelector((state) => state.chatContract.generatedQuestions);

  
  useEffect(() => {
    if (partialSummary) {
      setChatHistory((prev) => {
        const lastMessage = prev[0];
        if (
          lastMessage?.sender === "bot" &&
          lastMessage.text.startsWith("Summary")
        ) {
          lastMessage.text = partialSummary;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialSummary
          )
        ) {
          return [
            { id: Date.now(), sender: "bot", text: partialSummary },
           
          ];
        }
        return prev;
      });
    }
  }, [partialSummary]);
  
  useEffect(() => {
    if (finalSummary) {
      setChatHistory((prev) => {
        const lastMessage = prev[0]; 
        if (lastMessage?.sender === "bot") {
          lastMessage.text = finalSummary;
          return [...prev];
        }
        return [{ id: Date.now(), sender: "bot", text: finalSummary }, ...prev];
      });
      setIsSummaryCompleted(true); 
    }
  }, [finalSummary]);

  const handleGoToContracts = () => {
    navigate("/Contract"); 
  };

  useEffect(() => {
    setIsThinking(false);
    const fetchContractUrl = async () => {
      if (!contractKey) return;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/contract/view-contract/${contractKey}/`,

          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setIsThinking(false);
        if (response.status === 200 && response.data.contract_url) {
          setContractUrl(response.data.contract_url);
          setIsThinking(false);
        } else {
          console.error("Contract URL not found.");
        }
      } catch (error) {
        console.error("Error fetching contract URL:", error);
      }
    };

    fetchContractUrl();
  }, [contractKey]);
  const [isSummaryComplete, setIsSummaryComplete] = useState(false);

  

  useEffect(() => {
    const fetchSummary = async () => {
      if (!contractKey) return;

      try {
        
        setIsSummaryCompleted(false);

// ✅ Check if summary already exists in the chat
const summaryAlreadyExists = chatHistory.some(
  (msg) =>
    msg.sender === "bot" &&
    (msg.text?.startsWith("**Summary of the Document**") ||
     msg.text?.includes("Customer Information")) // you can tweak based on your actual content
);

if (!summaryAlreadyExists) {
  setChatHistory((prev) => {
    if (
      !prev.some(
        (msg) => msg.sender === "bot" && msg.text === "Summarizing..."
      )
    ) {
      return [
        { id: Date.now(), sender: "bot", text: "Summarizing..." },
        ...prev,
      ];
    }
    return prev;
  });
}


        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/contract/summary-and-questions/?contract_key=${contractKey}`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const { contract_details, chat_history } = response.data;

        const {
          summary,
          example_questions,
          pdf_url,
          run_id,
          bot_message_id,
        } = contract_details;

        if (summary) {
          setChatHistory((prev) => {
            const updatedHistory = prev
              .filter(
                (msg) =>
                  msg.sender !== "bot" ||
                  (msg.text !== "Summarizing..." &&
                    msg.text !==
                      "Failed to fetch summary. Please try again later.")
              )
              .map((msg) =>
                msg.sender === "bot" && msg.text === "Summarizing..."
                  ? { ...msg, text: summary }
                  : msg
              );

            
            if (
              updatedHistory.some(
                (msg) => msg.sender === "bot" && msg.text === summary
              )
            ) {
              return updatedHistory;
            }

            return [
              { id: Date.now(), sender: "bot", text: summary },
              ...updatedHistory,
            ];
          });
        }

        if (chat_history) {
        
          setChatHistory((prev) => {
            const existingMessageIds = new Set(prev.map((msg) => msg.id));
        
            const newMessages = chat_history
              .filter((item) => !existingMessageIds.has(item.message_id))
              .map((item) => ({
                id: item.message_id, // Ensure correct message ID is used
                sender: item.is_client ? "user" : "bot",
                text: item.message,
                timestamp: item.timestamp,
                runId: item.run_id || null, // Store runId per message
                messageId: item.message_id,// Ensure correct message ID is stored
              }));
        
            return [...prev, ...newMessages];
          });
        }
        setIsSummaryCompleted(true);

        if (example_questions) {
          const questions = example_questions.split(";").map((q) => q.trim());
          setTypedQuestions(questions.slice(0, 2));
          setShowDummyMessages(true);
        } else {
          console.warn("No example questions found in the API response");
        }
        


        } catch (error) {
          console.error("Error fetching summary and questions:", error);
          setChatHistory((prev) => {
            const hasValidData = prev.some(
              (msg) => msg.sender === "bot" && msg.text !== "Failed to fetch summary. Please try again later."
            );
        
            if (!hasValidData) {
              return [
                ...prev,
                {
                  id: Date.now(),
                  sender: "bot",
                  text: "Failed to fetch summary. Please try again later.",
                },
              ];
            }
        
            return prev;
          });
        } finally {
          setIsAwaitingResponse(false);
        }
      
    };

    fetchSummary();
  }, [contractKey]);

  const typeQuestion = null;

  useEffect(() => {
    if (generatedQuestions) {
      const questions = generatedQuestions.split(";").map((q) => q.trim());
      setTypedQuestions(questions.slice(0, 2));
      setShowDummyMessages(true);
    }
  }, [generatedQuestions]);
  

  const [placeholder, setPlaceholder] = useState("Ask me anything");

  const fetchOlderMessages = async () => {
    if (isFetchingOlderMessages || !hasMoreMessages) return;

    setIsFetchingOlderMessages(true);
    try {
      const token = localStorage.getItem("token");
      const offset = olderMessages.length;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/contract/chat-history/${contractKey}?offset=${offset}`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { chat_history: fetchedMessages, has_more } = response.data;
      setOlderMessages((prev) => [
        ...fetchedMessages.map((msg) => ({
          id: msg.message_id,
          sender: msg.is_client ? "user" : "bot",
          text: msg.message,
          timestamp: msg.timestamp,
        })),
        ...prev,
      ]);
      setHasMoreMessages(has_more);
    } catch (error) {
      console.error("Error fetching older messages:", error);
    } finally {
      setIsFetchingOlderMessages(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        chatContainerRef.current &&
        chatContainerRef.current.scrollTop === 0 &&
        !isFetchingOlderMessages
      ) {
        fetchOlderMessages();
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatContainerRef, isFetchingOlderMessages, olderMessages]);

  useEffect(() => {
    if (olderMessages.length > 0) {
      setChatHistory((prev) => [...olderMessages, ...prev]);
    }
  }, [olderMessages]);

  const handleSend = (message = clientMessage) => {
    if (isAwaitingResponse || isSummaryLoading) return;

    if (message.trim() && contractKey) {
      const userMessage = { id: Date.now(), sender: "user", text: message };

      setChatHistory((prev) => [
        ...prev,
        { ...userMessage, timestamp: new Date().toISOString() },
      ]);

      setChatHistory((prev) => [
        ...prev,
        {
          id: Date.now(),
          sender: "bot",
          text: "",
          final: false,
          timestamp: new Date().toISOString(),
        },
      ]);

      setIsThinking(true);
      setIsAwaitingResponse(true);

      dispatch(
        fetchChatContract({ contractKey, clientMessage: message })
      ).finally(() => {
        setIsAwaitingResponse(false);
        setIsThinking(false);
      });

      setClientMessage("");
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  useEffect(() => {
    if (isSummaryLoading) {
      
    } else if (status === "succeeded" || status === "failed") {
     
      setIsAwaitingResponse(false);
    }
  }, [status, isSummaryLoading]);

  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        }
        return prev;
      });
      setIsThinking(false);
    }
  }, [partialAnswer]);

  useEffect(() => {
    if (finalAnswer) {
        setChatHistory((prev) => {
            const lastMessage = prev[prev.length - 1];

            if (lastMessage?.sender === "bot" && !lastMessage.final) {
                lastMessage.text = finalAnswer;
                lastMessage.final = true;
                lastMessage.runId = runId;  // Ensure correct runId is used
                lastMessage.messageId = botMessageId;  // Ensure correct botMessageId is used

                return [...prev];
            } else if (!prev.some((msg) => msg.sender === "bot" && msg.text === finalAnswer)) {
                const newBotMessage = {
                    id: botMessageId,  // Ensure correct ID is used
                    sender: "bot",
                    text: finalAnswer,
                    final: true,
                    runId: runId,  // Store correct runId
                    messageId: botMessageId,  // Store correct messageId
                };

                return [...prev, newBotMessage];
            }

            return prev;
        });

        setIsAwaitingResponse(false);
        setIsThinking(false);
    }
}, [finalAnswer, generatedQuestions, runId, botMessageId]);


  useEffect(() => {
    if (finalAnswer) {
      setShowDummyMessages(false); // Hide only when final answer is received
    }
  }, [finalAnswer]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = textAreaHeight;
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight;
      }
    };
  }, [textAreaHeight]);

  const handleInputBackspace = () => {
    if (clientMessage.length > 0) {
      setClientMessage(clientMessage.slice(0, -1));
    }
  };

  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");

    formattedText = formattedText.replace(/\n/g, "<br/>");

    return `<p style="white-space: pre-wrap; word-wrap: break-word; overflow-wrap: break-word;">${formattedText}</p>`;
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      setTimeout(() => {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }, 100); // Small delay ensures it happens after re-render
    }
  };
  

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, finalAnswer, typedQuestions, showDummyMessages]);
  


  useEffect(() => {
    if (typedQuestions.length > 0) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [typedQuestions]);
  


  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isAtBottom = scrollHeight - clientHeight - scrollTop < 20;
  
      if (!isAtBottom) {
        setIsUserScrolling(true);
      } else {
        setIsUserScrolling(false);
      }
  
      if (scrollTop > 50) {
        setShowEarlierMessagesPopup(true);
  
        if (scrollHeight - clientHeight - scrollTop < 20) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      } else {
        setShowEarlierMessagesPopup(false);
        setIsScrolling(false);
      }
    
    }
  };

  let scrollTimeout;
  const handleScrollWithTimeout = () => {
    handleScroll();
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsUserScrolling(false), 1000);
  };
  
  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScrollWithTimeout);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScrollWithTimeout);
      }
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetChatContractState());
    };
  }, [dispatch]);
  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index);
      setClientMessage(dummyMessage.trim());
    }
  };

  const handleThumbsUpClick = (messageId) => {
    console.log("🔍 Checking chat history before thumbs up click...");
    console.log(chatHistory);
  
    const clickedMessage = chatHistory.find((msg) => msg.id === messageId);
  
    if (!clickedMessage) {
      console.error("❌ Clicked message not found in chat history.");
      return;
    }
  
    const correctBotMessageId = clickedMessage.messageId || botMessageId;
    const correctRunId = clickedMessage.runId || runId;
  
    if (!correctBotMessageId || !correctRunId) {
      console.error("⚠️ Run ID or Message ID is missing for this message:", clickedMessage);
      return;
    }
  
    console.log("📌 Using Message ID:", correctBotMessageId);
    console.log("📌 Using Run ID:", correctRunId);
  
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: true,
    }));
  
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
  
    setPopupVisible(true);
    setMessageIdToFeedback(correctBotMessageId);
    setRunIdToFeedback(correctRunId);
  
    console.log("📤 Submitting feedback with Message ID:", correctBotMessageId, "Run ID:", correctRunId);
    submitFeedback(correctBotMessageId, correctRunId, "up");
  };
  


  const handleThumbsDownClick = (messageId) => {
    console.log("🔍 Checking chat history before thumbs down click...");
    console.log(chatHistory);
  
    const clickedMessage = chatHistory.find((msg) => msg.id === messageId);
  
    if (!clickedMessage) {
      console.error("❌ Clicked message not found in chat history.");
      return;
    }
  
    const correctBotMessageId = clickedMessage.messageId || botMessageId;
    const correctRunId = clickedMessage.runId || runId;
  
    if (!correctBotMessageId || !correctRunId) {
      console.error("⚠️ Run ID or Message ID is missing for this message:", clickedMessage);
      return;
    }
  
    console.log("📌 Using Message ID:", correctBotMessageId);
    console.log("📌 Using Run ID:", correctRunId);
  
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: true,
    }));
  
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
  
    setShowThumbsDownPopup(true);
    setMessageIdToFeedback(correctBotMessageId);
    setRunIdToFeedback(correctRunId);
  };
  


  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  useEffect(() => {
    if (finalAnswer) {
      setThumbsUpClicked({});
    }
  }, [finalAnswer]);
  
  const submitFeedback = async (messageId, runId, feedbackType, userFeedback = "") => {
    console.log("Preparing to submit feedback...");
    console.log("Message ID:", messageId);
    console.log("Run ID:", runId);
    console.log("Feedback Type:", feedbackType);
    console.log("User Feedback:", userFeedback);
  
    if (!messageId || !runId || !feedbackType) {
      console.error("Missing required data for feedback submission.");
      return;
    }
  
    const payload = {
      message_id: messageId,
      run_id: runId,
      feedback_type: feedbackType,
      user_feedback: userFeedback.trim(),
    };
  
    console.log("Final Payload Sent to API:", payload);
  
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/contract/feedback/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  
  
  

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => {
      const updatedState = {
        ...prev,
        [id]: checked,
      };
      return updatedState;
    });
  };
  


  const handleTextareaChange = (e) => {
    setFeedbackMessage(e.target.value);

  };
  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
  
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n").trim(); 

    try {
      await submitFeedback(messageIdToFeedback, runIdToFeedback, "down", combinedFeedback);
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting thumbs-down feedback:", error);
    }
  };
  
  

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() &&
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setShowThumbsDownPopup(false);

    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });

    setFeedbackMessage("");
  };

  const inputField = document.querySelector(".custom-input-field-contract");
  const inputContainer = document.querySelector(".custom-input-container");

  const handleInput = () => {
    inputField.style.height = "auto";

    if (inputField.scrollHeight <= 88) {
      inputField.style.height = `${inputField.scrollHeight}px`;
      inputContainer.style.height = `${inputField.scrollHeight + 20}px`;
    } else {
      inputField.style.height = "88px";
      inputContainer.style.height = "88px";
    }
  };

  if (inputField) {
    inputField.addEventListener("input", handleInput);
  }

  useEffect(() => {
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
    }
  }, [chatHistory]);

  useEffect(() => {
    
    if (status === "loading") {
      setShowDummyMessages(false);
    } else if (status === "succeeded" || status === "failed") {
      setShowDummyMessages(true);
    }
  }, [status]);

  const streamText = (text, callback, delay = 30) => {
    let charIndex = 0;
    const interval = setInterval(() => {
      const chunk = text.slice(0, charIndex + 1);
      callback(chunk);

      charIndex += 1;
      if (charIndex >= text.length) {
        clearInterval(interval);
      }
    }, delay);
  };

  useEffect(() => {
    console.log("isSummaryCompleted:", isSummaryCompleted);
    console.log("finalAnswer:", finalAnswer);
  }, [isSummaryCompleted, finalAnswer]);
  

  // const formattedTimestamp = useSelector((state) => state.chatContract.formattedTimestamp);

  const groupMessagesByDate = (messages) => {
      const sortedMessages = [...messages].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
  
      const groupedMessages = {};
  
      sortedMessages.forEach((message) => {
          let label;
          try {
              const messageDate = message.timestamp ? new Date(message.timestamp) : null;
  
              if (!messageDate || isNaN(messageDate.getTime())) {
                  label = formattedTimestamp || "Summary"; 
              } else {
                  const today = new Date();
                  const yesterday = new Date(today);
                  yesterday.setDate(today.getDate() - 1);
  
                  if (messageDate.toDateString() === today.toDateString()) {
                      label = "Today";
                  } else if (messageDate.toDateString() === yesterday.toDateString()) {
                      label = "Yesterday";
                  } else {
                      label = format(messageDate, "dd MMM yyyy");
                  }
              }
          } catch (error) {
              console.error("Error processing message timestamp:", message, error);
              label = "Invalid Date";
          }
  
          if (!groupedMessages[label]) {
              groupedMessages[label] = [];
          }
          groupedMessages[label].push(message);
      });
  
      return groupedMessages;
  };
  

  const groupedChatHistory = groupMessagesByDate(chatHistory);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (clientMessage.trim() !== "") {
      setShowEarlierMessagesPopup(false);
    }
  }, [clientMessage]);


  useEffect(() => {
    // Reset summary status to idle on mount to prevent "Summarizing..." from persisting
    dispatch(updateSummaryStatus("idle"));
  }, [dispatch]);
  
useEffect(() => {
  return () => {
    dispatch(resetChatContractState());
  };
}, [dispatch]);
useEffect(() => {
  if (finalSummary) {
    setIsSummaryCompleted(true);
  }
}, [finalSummary]);
useEffect(() => {
  if (finalAnswer) {
    setIsAwaitingResponse(false);
    setIsThinking(false);
  }
}, [finalAnswer]);

  return (
    <div className="full-container-chat">
      <div className="-ml-16">
        <Header
          icon="/arrow-back.svg"
          name="Go to Contracts"
          onGoToContracts={handleGoToContracts}
        />
      </div>
      <div className="content-container">
        <div className="left-side">
        {showEarlierMessagesPopup && (
            <div
              className="fixed transform -translate-x-1/2 inline-flex items-center justify-center gap-1.5 px-4 py-1 text-sm font-outfit font-normal text-primary-500 border border-black/10 rounded-full bg-gradient-to-r from-[#F5F4F3] to-[#FCDFF9] shadow-custom "
              style={{
                top: `${55}px`,
                left: `${438}px`,
                color: "#C95EBE", 
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "normal",
                opacity: isScrolling ? "0.5" : "3",
              }}
            >
              <span>See earlier messages</span>
            </div>
          )}
           <div className="message-container " ref={chatContainerRef}>
            {Object.entries(groupedChatHistory)
              .sort(([dateA], [dateB]) => {
                if (dateA === "Today") return 1;
                if (dateB === "Today") return -1;
                return new Date(dateA) - new Date(dateB);
              })
              .map(([dateLabel, messages]) => (
                <div key={dateLabel}>
                  {/* Date Label */}
                  <div className="flex items-center justify-center my-4">
                    <div className="h-px w-[269px] bg-black/10"></div>
                    <div className="flex items-center justify-center gap-1 px-4 py-1.5 mx-3 rounded-full border border-black/10 shadow-md text-neutral-600 text-sm font-normal">
                      {dateLabel}
                    </div>
                    <div className="h-px w-[269px] bg-black/10"></div>
                  </div>

                  {/* Messages for the Date */}
                  {messages.map((message, index) => (
                    <div
                      key={message.id || index}
                      className={`mb-0 ${
                        message.sender === "user"
                          ? "w-full flex justify-end"
                          : "bot-message-container w-full flex items-start"
                      }`}
                    >
                      {/* Bot Messages */}
                      {message.sender === "bot" && (
                        <div className="bot-icon-container flex-shrink-0">
                          <img
                            src="/bot-icon.svg"
                            alt="Bot Icon"
                            className="bot-icon h-6 w-6 rounded-full mr-2"
                          />
                        </div>
                      )}
                      {message.sender === "bot" ? (
                        <div className="flex flex-col">
                          {(isThinking && index === chatHistory.length - 1) ||
                          message.text === "" ? (
                            <div className="thinking-indicator mb-7">
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          ) : (
                            <span
                              className="bot-message-content"
                              
                              dangerouslySetInnerHTML={{
                                __html: formatText(message.text),
                              }}
                            />
                          )}

{message.sender === "bot" && message.text && !isThinking && (isSummaryCompleted || finalAnswer) && (

                          <div className="reactions flex gap-2 text-gray-500 mt-2">
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => {
                                console.log("Thumbs up clicked for message:", message);
                                console.log("Message ID:", message.id, "Run ID:", message.runId);
                                handleThumbsUpClick(message.id, message.runId);
                              }}                            >
                              <img
                                src={
                                  thumbsUpClicked[message.id]
                                    ? "/thums-up-filled.svg"
                                    : "/thums-up.svg"
                                }
                                alt="Thumbs Up"
                              />
                              <span className="reaction-tooltip">
                                Thumbs Up
                              </span>
                            </span>
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => {
                                console.log("Thumbs down clicked for message:", message);
                                console.log("Message ID:", message.id, "Run ID:", message.runId);
                                handleThumbsDownClick(message.id, message.runId);
                              }}                            >
                              <img
                                src={
                                  thumbsDownClicked[message.id]
                                    ? "/thums-down-filled.svg"
                                    : "/thums-down.svg"
                                }
                                alt="Thumbs Down"
                              />
                              <span className="reaction-tooltip">
                                Thumbs Down
                              </span>
                            </span>
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() =>
                                handleCopy(message.id, message.text)
                              }
                            >
                              <img
                                src={
                                  copiedMessageId === message.id
                                    ? "/copy-clicked.svg"
                                    : "/copy.svg"
                                }
                                alt="Copy"
                              />
                              <span className="reaction-tooltip">Copy</span>
                            </span>
                          </div>
)}
                        </div>
                      ) : (
                        <div className="w-full flex justify-end items-start gap-4">
                        {/* User Message */}
                        <div className="user-message-bubble">
                          {message.text}
                        </div>
                        {/* User Icon */}
                        <div className="user-icon-container flex-shrink-0 self-end">
                        <div className="user-icon w-7 h-7 flex items-center justify-center rounded-full bg-white text-[#C95EBE] border border-[rgba(0,0,0,0.10)] text-[13px] font-outfit font-medium leading-none mr-3">
    {userInitials}
</div>

                        </div>
                      </div>
                      
                      )}
                    </div>
                  ))}
                </div>
              ))}

            {error && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}

            <div className="dummy-container flex flex-col gap-2 mr-20 mb-4">
              {showDummyMessages &&
                typedQuestions.map((question, index) => (
                  <div
                    key={index}
                    className={`dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center cursor-pointer ${
                      clickedIndex === index ? "opacity-50" : "opacity-100"
                    }`}
                    onClick={() =>
                      handleDummyMessageClick(question.trim(), index)
                    }
                  >
                    <span className="icon mr-2">
                      <img src="/star-dot.svg" alt="Star Icon" />
                    </span>
                    <p className="text-sm">{question.trim()}</p>
                  </div>
                ))}
            </div>
          </div>
        

          <div className="custom-input-wrapper"
           style={{
            marginLeft: "-35px",
            marginTop: "-35px",
            backgroundColor: "#FEF9F7",
          }}>
  <div className="input-container-holder">
    
    <textarea
    //  disabled={isSummaryWebSocketActiveNow && !isSummaryCompleted}
      placeholder={
        isSummaryWebSocketActiveNow && !isSummaryCompleted
          ? "Summarizing..."
          : isAwaitingResponse
          ? "Copilot is responding..."
          : "Ask me anything"
      }
      className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
      style={{
        paddingTop: "3px", // Adjust padding to reduce top space
        marginTop: "5px",
        lineHeight: "20px", // Use negative margin to bring text closer
      }}
      value={clientMessage}
      onChange={(e) => {
        console.log("✍️ Typing while summaryWebSocketActive:", isSummaryWebSocketActiveNow);
        setClientMessage(e.target.value);
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
          textareaRef.current.style.height = `${Math.min(
            e.target.scrollHeight,
            88
          )}px`;
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          if (!isAwaitingResponse && !isSummaryLoading && clientMessage.trim() &&  (!isSummaryWebSocketActiveNow || isSummaryCompleted)
          ) {
            handleSend(clientMessage.trim());
            setClientMessage("");
          }
        }
      }}
      ref={textareaRef}
    />

    
    <button
      className="send-button-holder"
      disabled={
    isAwaitingResponse ||
    isSummaryLoading ||
    !clientMessage.trim() ||
    (isSummaryWebSocketActiveNow && !isSummaryCompleted)
  }
  onClick={() => {
    console.log("📤 Send clicked | WS Active:", isSummaryWebSocketActiveNow);
    handleSend();
  }}
    >
     <img
  src={
    isSummaryLoading ||
    isAwaitingResponse ||
    (isSummaryWebSocketActiveNow && !isSummaryCompleted)
      ? "/loading-send-button.svg"
      : "/send.svg"
  }
  alt="Send"
/>

    </button>
  </div>
</div>


        </div>
        <div className="right-side">
          <iframe
            src={contractUrl}
            title="PDF Viewer"
            className="w-full h-full"
            allow="fullscreen"
          />
        </div>
      </div>

      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>

                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>

                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      value={feedbackMessage}
                      maxlength="250"
                      placeholder="Your feedback is much Appreciated and helps improve the AI!"
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>

                <button
                  className={`custom-button-thumsdown ${
                    isSubmitDisabled()
                      ? "disabled-button-thumsdown"
                      : "enabled-button-thumsdown"
                  }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractChat;
