import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Header } from "../../components/Header/Header";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import axios from "axios";
import "./UploadFilePage.css";
import SubscriptionPopup from "./SubscriptionPopup.jsx";
import { toast } from "react-toastify";
import StatusModal from "./StatusModal";
import UploadModal from "../Contract/UploadModal";
import CreateFolderModal from "../Contract/CreateFolderModal";
import FilterModal from "./FilterModal";
import chatSlice from "../../Features/chatSlice.js";
import "../Contract/contract_data.css";
import Spinner from "../Contract/Spinner.jsx";
const UploadFilePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    folder: true,
    normalDocument: true,
    contract: false,
    invoices: false,
  });
  const fileInputRef = useRef(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
  const actionMenuRef = useRef(null);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [successFolderName, setSuccessFolderName] = useState("");
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectedContractKeys, setSelectedContractKeys] = useState([]);
  const [selectedInvoiceKeys, setSelectedInvoiceKeys] = useState([]);
  const [file_ids, setFileId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  const [isRenameFolderModalOpen, setIsRenameFolderModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);

  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editContractId, setEditContractId] = useState(null);
  const [editedContractData, setEditedContractData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const editRowRef = useRef(null);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const pdf_key = localStorage.getItem("pdfKey");
  const tbodyRef = useRef(null);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [hoveredAction, setHoveredAction] = useState(null);
  const [clickedAction, setClickedAction] = useState(null);
  
  const [pdfToView, setPdfToView] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(0);
  const [folders, setFolders] = useState([]);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState("");

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);

  useEffect(() => {
    // Dynamically add the Chatling script
    const chatScript = document.createElement("script");
    chatScript.async = true;
    chatScript.type = "text/javascript";
    chatScript.src = "https://chatling.ai/js/embed.js";
    chatScript.setAttribute("data-id", "2287369244");
    chatScript.id = "chatling-embed-script";

    // Add page-specific configuration
    const chatlingConfigScript = document.createElement("script");
    chatlingConfigScript.type = "text/javascript";
    chatlingConfigScript.innerHTML = `
          window.chtlConfig = { 
            chatbotId: "2287369244",
            page: "UploadFilePage" // Add a specific page identifier
          };
        `;

    document.body.appendChild(chatlingConfigScript);
    document.body.appendChild(chatScript);

    // Cleanup the scripts on component unmount
    return () => {
      if (document.body.contains(chatlingConfigScript)) {
        document.body.removeChild(chatlingConfigScript);
      }
      if (document.body.contains(chatScript)) {
        document.body.removeChild(chatScript);
      }
    };
  }, []);

  const filteredContracts = useMemo(() => {
    if (!Array.isArray(files) || files.length === 0) return [];
    return files.filter((file) => {
      if (!file?.pdf_name) return false;
      return file.pdf_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [files, searchQuery]);

  const totalPages = useMemo(() => {
    const totalItems = folders.length + files.length;
    return Math.ceil(totalItems / rowsPerPage);
  }, [folders, files, rowsPerPage]);

  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };

    const [file, setFile] = useState(null);
  
        const handleFileChange = (event) => {
          const selectedFile = event.target.files[0];
          if (!selectedFile) return;
      
          console.log("File selected:", selectedFile); // Debug log
          setFile(selectedFile);  // Update file state
          setIsModalOpen(true);   // Open modal after file selection
      };

  const handleApplyFilters = (newFilters, files, folders) => {
    console.log("kjd", files[0].type);
    const filteredFiles = files
      .filter((file) => {
        if (newFilters.folder && file.isFolder) return true;
        if (newFilters.normalDocument && file.type === "normal_document")
          return true;
        if (newFilters.contract && file.type === "contract") return true;
        if (newFilters.invoice && file.type === "invoice") return true;
        return false;
      })
      .map((file) => ({
        ...file,
        file_type: file.document_type || "Unknown",
      }));

    const filteredFolders = folders.map((folder) => ({
      ...folder,
      file_type: "Folder",
    }));

    setFiles(filteredFiles);
    setFolders(filteredFolders);
  };

  console.log("ffffffff", files);

  const handleExport = async () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    if (selectedContracts.length === 0) {
      showPopup("Error", "Please select at least one document to export.");
      return;
    }
    if (!selectedContractKeys.length && !selectedInvoiceKeys.length) {
      alert("Please select at least one document to export.");
      return;
    }

    setExportInProgress(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/export-doc/`,
        {
          contract_keys: selectedContractKeys,
          invoice_keys: selectedInvoiceKeys,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "exported_documents.csv");
      document.body.appendChild(link);
      link.click();

      showPopup("Success", "Documents exported successfully.");
    } catch (error) {
      if (error.response) {
        const message =
          error.response.data.msg || "Failed to export documents.";
        showPopup("Error", message);
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    } finally {
      setExportInProgress(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      setIsActionMenuOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCancel = () => {
    setSelectedContracts([]);

    setIsModalOpen(false);
    setIsFilterModalOpen(false);
    setPopupVisible(false);

    setConfirmDeleteVisible(false);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (tbodyRef.current) {
      tbodyRef.current.scrollTop = 0;
    }
  };

  const handleBulkDeleteConfirmation = () => {
    if (selectedContracts.length === 0) {
      showPopup("Error", "No files or folders selected for bulk deletion.");
      return;
    }

    setPopupTitle("Confirm Bulk Deletion");
    setPopupMessage(
      "Are you sure you want to delete the selected items? This action cannot be undone."
    );
    setConfirmDeleteVisible(true);
  };

  const fetchFolders = async () => {
    if (!token) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/folders/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setFolders(response.data);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  const fetchContracts = async () => {
    if (!token) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/files-and-folders/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Fetched dataaaaaaaaaaa:", response.data);

      const { files, folders } = response.data;

      setFiles(files || []);
      setFolders(folders || []);
    } catch (error) {
      console.error("Error fetching files and folders:", error);
      if (
        error.response &&
        error.response.data?.message ===
          "No valid trial or active subscription. Redirecting to payment."
      ) {
        setShowSubscriptionPopup(true);
      } else {
        toast.error("Failed to load documents. Please try again later.", {
          autoClose: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const allItems = [...folders, ...files].map((item) => {
      const isFolder = !!item.documents;
      return {
        ...item,
        document_type: isFolder ? "folder" : item.document_type || "file",
      };
    });

    console.log("All Items after mapping:", allItems);

    if (selectedContracts.length === allItems.length) {
      setSelectedContracts([]);
      setSelectedContractKeys([]);
      setSelectedInvoiceKeys([]);
    } else {
      setSelectedContracts(allItems);
      setSelectedContractKeys(
        allItems
          .filter((item) => item.document_type === "contract")
          .map((item) => item.key)
      );
      setSelectedInvoiceKeys(
        allItems
          .filter((item) => item.document_type === "invoice")
          .map((item) => item.key)
      );
    }

    console.log(
      "Selected Contracts (Check Contract Type):",
      allItems.filter((item) => item.document_type === "contract")
    );
    console.log("Selected Contract Keys:", selectedContractKeys);
    console.log("Selected Invoice Keys:", selectedInvoiceKeys);
  };

  const filteredData = useMemo(() => {
    const normalizedQuery = searchQuery
      .trim()
      .replace(/\s+/g, " ")
      .toLowerCase();

    if (!normalizedQuery) return [...folders, ...files];

    return [...folders, ...files].filter((item) =>
      (item.name || "").toLowerCase().includes(normalizedQuery)
    );
  }, [folders, files, searchQuery]);

  const combinedFilesAndFolders = useMemo(() => {
    return [...folders, ...files]
      .map((item) => ({
        ...item,
        isFolder: !!item.documents,
        uploadedDate: new Date(item.uploaded_at || item.created_at),
      }))
      .sort((a, b) => b.uploadedDate - a.uploadedDate);
  }, [folders, files]);
  
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return combinedFilesAndFolders.slice(startIndex, endIndex);
  }, [combinedFilesAndFolders, currentPage, rowsPerPage]);
  
  



  console.log("paginate", paginatedData);

  const filteredPaginatedData = paginatedData;

  const handleCheckboxChange = (item) => {
    const isSelected = selectedContracts.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (isSelected) {
      setSelectedContracts(
        selectedContracts.filter((selectedItem) => selectedItem.id !== item.id)
      );

      // Remove the key from the respective keys list
      if (item.document_type === "contract") {
        setSelectedContractKeys(
          selectedContractKeys.filter((key) => key !== item.key)
        );
      } else if (item.document_type === "invoice") {
        setSelectedInvoiceKeys(
          selectedInvoiceKeys.filter((key) => key !== item.key)
        );
      }
    } else {
      setSelectedContracts([...selectedContracts, item]);

      // Add the key to the respective keys list
      if (item.document_type === "contract") {
        setSelectedContractKeys([...selectedContractKeys, item.key]);
      } else if (item.document_type === "invoice") {
        setSelectedInvoiceKeys([...selectedInvoiceKeys, item.key]);
      }
    }
  };

  useEffect(() => {
    if (!folders.length && !files.length) {
      fetchFolders();
      fetchContracts();
    }
  }, []);

  const handleUploadSuccess = () => {
    fetchContracts();
    fetchFolders();
    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();
  };

  const toggleActionMenu = (index) => {
    setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const CopyToFolderModal = ({
    isOpen,
    onClose,
    documents,
    folders,
    onCopy,
    onCreateFolder,
  }) => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedFolderName, setSelectedFolderName] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [creatingNewFolder, setCreatingNewFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSave = () => {
      if (!selectedFolder) {
        setErrorMessage("Please select a folder.");
        return;
      }
      if (!documents || documents.length === 0) {
        alert("No document selected for copying.");
        return;
      }

      const documentIds = documents.map((doc) => doc.id);
      onCopy(documentIds, selectedFolder);
      onClose();
    };

    const handleCreateFolder = async () => {
      if (!newFolderName.trim()) {
        setErrorMessage("Folder name cannot be empty.");
        return;
      }
      if (!documents || documents.length === 0) {
        alert("Please select at least one document.");
        return;
      }

      setErrorMessage(""); // Clear previous errors
      setLoading(true); // Show loading state if needed

      try {
        const documentIds = documents.map((doc) => doc.id);

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/readpdf/add-files/`,
          {
            file_ids: documentIds,
            folder_name: newFolderName.trim(),
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const newFolderId = response.data.folder_id; // Get folder ID from response

          setSelectedFolder(newFolderId);
          setSelectedFolderName(newFolderName);
          setNewFolderName("");
          setCreatingNewFolder(false);

          // Refresh folder list in the parent component (optional)
          if (onCreateFolder) {
            onCreateFolder({
              id: newFolderId,
              name: newFolderName,
            });
          }

          onClose(); // Close modal after success
        }
      } catch (error) {
        console.error("Error creating folder and copying files:", error);
        setErrorMessage("Failed to create folder and copy files.");
      } finally {
        setLoading(false);
      }
    };

    return isOpen ? (
      <div className="copy-modal-overlay">
        <div className="copy-modal">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-700">
              Copy to Folder
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 transition"
            >
              X
            </button>
          </div>
          <p>Copy into any folder and organize your documents</p>
          <h3>Choose Folder</h3>

          <div className="select-container">
            {/* Folder Selection Dropdown */}
            <div
              className="custom-select"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className="selected-option">
                {selectedFolder ? selectedFolderName : "Select a folder"}
              </div>
              <span className="select-icon">
                <img src="/copyToFolder-icon.svg" alt="Dropdown Icon" />
              </span>
            </div>

            {/* Show "Create New Folder" Input Box Between Dropdown & Folder List */}
            {creatingNewFolder && (
              <div className="new-folder-container flex mt-2 p-2 border border-gray-300 rounded-md">
                <input
                  type="text"
                  placeholder="Enter your folder name here"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  style={{
                    color: "rgba(82, 82, 91, 0.60)",
                    width: "200px",
                    fontFamily: "Outfit, sans-serif",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    padding: "12px",
                    border: "none",
                    background: "#FFF",
                  }}
                />
                <div className="folder-action-buttons flex ml-2">
                  <button
                    onClick={async () => {
                      await handleCreateFolder();
                      window.location.reload(); // Refresh the page
                    }}
                    className="save-button-copy"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setCreatingNewFolder(false)}
                    className="cancel-button-copy"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {/* Folder List (Remains Below Input Box) */}
            {dropdownOpen && (
              <div className="custom-dropdown-options">
                {folders.length > 0 ? (
                  folders.map((folder) => (
                    <div
                      key={folder.id}
                      className="custom-dropdown-option"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedFolder(folder.id);
                        setSelectedFolderName(folder.name);
                      }}
                    >
                      {folder.name}
                    </div>
                  ))
                ) : (
                  <div className="custom-dropdown-option no-folders">
                    No folders available
                  </div>
                )}
              </div>
            )}

            {/* "Create New Folder" Button */}
            {dropdownOpen && !creatingNewFolder && (
              <button
                className="create-new-folder-btn mt-3"
                onClick={() => {
                  setCreatingNewFolder(true);
                  setDropdownOpen(true);
                }}
              >
                <img
                  src="/folder-icon-copy.svg"
                  alt="Folder Icon"
                  className="folder-icon"
                />
                <span>Create New Folder</span>
              </button>
            )}
          </div>

          {errorMessage && (
            <p className="error-message text-red-500 mt-2">{errorMessage}</p>
          )}

          {selectedFolder && (
            <div className="copy-modal-actions flex justify-end mt-4">
              <button onClick={onClose} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleSave} className="save-button">
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    ) : null;
  };

  const RenameModal = ({ isOpen, onClose, document, onRename }) => {
    const [newName, setNewName] = useState(document?.pdf_name || "");

    useEffect(() => {
      if (document) setNewName(document.pdf_name);
    }, [document]);

    const handleSave = () => {
      if (newName.trim()) {
        onRename(document.id, newName.trim());
        onClose();
      }
    };

    return isOpen ? (
      <div className="rename-modal-overlay">
        <div className="rename-modal">
          <h2>Rename Document</h2>
          <p>Create a Custom Name for your Documents</p>
          <h3>Title</h3>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter new name"
          />
          <div className="rename-modal-actions">
            <button onClick={onClose}>Cancel</button>
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    ) : null;
  };

  const handleRename = async (documentId, newName) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/rename-doc/${documentId}/`,
        { new_name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === documentId
              ? { ...file, pdf_name: response.data.new_name }
              : file
          )
        );
        window.location.reload();

        showPopup("Success", "Document renamed successfully.");
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to rename document: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to rename document: No response received from the server."
        );
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleRenameFolder = async (folderId, newName) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/rename-folder/${folderId}/`,
        { new_name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder.id === folderId
              ? { ...folder, folder_name: response.data.folder_name }
              : folder
          )
        );

        showPopup("Success", "Folder renamed successfully.");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to rename folder: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to rename folder: No response received from the server."
        );
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleCopyToFolder = async (folderId, fileIds) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    // Flatten `fileIds` in case it's a nested array
    const flatFileIds = fileIds.flat();

    console.log(
      "Attempting to copy files:",
      flatFileIds,
      "to folder:",
      folderId
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/add-files/${folderId}/`,
        { file_ids: flatFileIds }, // Use flat list
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Copy successful:", response.data);

      if (response.status === 200) {
        showPopup("Success", "Documents copied successfully.");
      }
    } catch (error) {
      console.error("Copy to Folder Error:", error);

      if (error.response) {
        console.error("Server Response:", error.response.data);
        console.error("Status Code:", error.response.status);
        showPopup(
          "Error",
          `Failed to copy documents: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        showPopup(
          "Error",
          "No response received from the server. Please check your network."
        );
      } else {
        console.error("Unexpected Error:", error.message);
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleViewMore = (fileKey) => {
    if (!fileKey) {
      console.error("File key is missing!");
      alert("File key is not available.");
      return;
    }
    console.log("Navigating with contractKey:", fileKey);
    navigate("/Contract/contract-details", { state: { contractKey: fileKey } });
  };

  const enableEdit = (contract) => {
    if (contract) {
      setIsActionMenuOpen(false);
      setEditContractId(contract.id);
      setEditedContractData({ ...contract });
    } else {
      setEditContractId(null);
      setEditedContractData({});
    }
  };

  const handleEditChange = (field, value) => {
    setEditedContractData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const goToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleIncreasePage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handleDecreasePage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    fetchContracts();
    fetchFolders();
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  var intervalId;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const location = useLocation();

  const contractKey = location.state?.contractKey;

  useEffect(() => {
    if (contractKey) {
      console.log("Received contractKey:", contractKey);
    } else {
      console.error("No contractKey provided in navigation state.");
    }
  }, [contractKey]);

  const formatDateTimeWithRelative = (dateTimeString) => {
    if (!dateTimeString) return "N/A";

    const date = new Date(dateTimeString);
    const now = new Date();

    if (isNaN(date.getTime())) return "N/A";

    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    let relativeTime;
    if (diffInMinutes < 1) {
      relativeTime = "Just now";
    } else if (diffInMinutes < 60) {
      relativeTime = `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      relativeTime = `${diffInHours} hours ago`;
    } else {
      relativeTime = `${diffInDays} days ago`;
    }

    const absoluteDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return `${relativeTime} (${absoluteDate})`;
  };

  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];

      const fileType = ["application/pdf"];

      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const handleBulkDelete = async () => {
    if (!token) {
      showPopup("Error", "Session expired. Please log in again.");
      return;
    }

    if (selectedContracts.length === 0) {
      showPopup("Error", "No files or folders selected for deletion.");
      return;
    }

    try {
      const folderIds = selectedContracts
        .filter((item) => item.document_type === "folder")
        .map((item) => item.id);

      const documentIds = selectedContracts
        .filter((item) => item.document_type !== "folder")
        .map((item) => item.id);

      const payload = {
        folder_ids: folderIds,
        document_ids: documentIds,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/bulk-delete/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { deleted_folders, deleted_documents, failed_collections } =
          response.data;

        setFiles((prevFiles) =>
          prevFiles.filter((file) => !deleted_documents.includes(file.id))
        );
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => !deleted_folders.includes(folder.id))
        );

        setSelectedContracts([]);

        if (deleted_folders.length > 0 || deleted_documents.length > 0) {
          showPopup(
            "Success",
            `Deleted ${deleted_folders.length} folder(s) and ${deleted_documents.length} document(s).`
          );
        }

        if (failed_collections.length > 0) {
          const failureMessages = failed_collections
            .map(
              (failure) =>
                `ID: ${failure.id} (${failure.type}) - ${failure.error}`
            )
            .join("\n");
          showPopup("Some Deletions Failed", failureMessages);
        }
      } else {
        showPopup(
          "Error",
          "Failed to delete selected items. Please try again."
        );
      }
    } catch (error) {
      console.error("Error during bulk delete:", error);
      showPopup("Error", "An unexpected error occurred during deletion.");
    } finally {
      setConfirmDeleteVisible(false);
    }
  };

  useEffect(() => {
    const updateRowsPerPage = () => {
      const width = window.innerWidth;
  
      if (width >= 2400) {
        setRowsPerPage(7); // Extra large screens
      } else if (width >= 1920 && width < 2400) {
        setRowsPerPage(10);
      } else if (width >= 1536 && width < 1920) {
        setRowsPerPage(7); 
      } else if (width >= 1440 && width < 1536) {
        setRowsPerPage(6);
      } else if (width >= 1280 && width < 1440) {
        setRowsPerPage(6); 
      } else if (width >= 1024 && width < 1280) {
        setRowsPerPage(5); 
      } else if (width >= 768 && width < 1024) {
        setRowsPerPage(4); // Tablets
      } else {
        setRowsPerPage(3); // Mobile devices
      }
    };
  
    updateRowsPerPage(); // Set initial rows per page
    window.addEventListener("resize", updateRowsPerPage);
  
    return () => window.removeEventListener("resize", updateRowsPerPage);
  }, []);
  
  



  const handleResponse = (res, file) => {
    if (!res || !res.data) {
      console.error("Response or response data is undefined");
      return setStatus("Failed to get a valid response from the server");
    }

    if (res.data.pdf_key) {
      console.log("PDF key received:", res.data.pdf_key);
      localStorage.setItem("pdfKey", res.data.pdf_key);
    }

    switch (res.data.status) {
      case "STARTING":
        console.log("STARTING: PDF key received:", res.data.pdf_key);

        return setStatus("Uploading Pdf ..");
      case "LOADING":
        return setStatus("Loading Pdf ...");
      case "ANALYZING":
        return setStatus("Analysing Pdf ...");
      case "SUMMARIZING":
        return setStatus("Summarizing Pdf ...");
      case "PENDING":
        return setStatus("Maybe Asynchronous task failed...");
      case "FAILED":
        return setStatus("Failed due to some issues, please try again!");
      case "Completed":
        clearInterval(intervalId);
        setStatus("Upload successful!");

        return location.pathname === "/test"
          ? navigate("/test/c", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            })
          : navigate("/chat", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            });
      default:
        console.error(`Unknown status from server ${res.data.status}`);
        throw new Error(`Unknown status from server ${res.data.status}`);
    }
  };

  const handleDelete = (pdfId) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    setPopupTitle("Confirm Deletion");
    setPopupMessage(
      "Are you sure you want to delete this PDF? This action cannot be undone."
    );

    setContractToDelete(pdfId);
  };

  const handleEditSave = async (contractId) => {
    if (!editedContractData.contract_name) {
      showPopup("Error", "Contract name is required.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/contract/edit-contract-name/`,
        {
          contract_key: editedContractData?.key,
          new_contract_name: editedContractData?.contract_name,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFiles((prevFiles) =>
          prevFiles.map((filee) =>
            filee.id === filee ? editedContractData : filee
          )
        );
        setEditContractId(null);
        showPopup("Success", "Contract updated successfully.");
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to update contract: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to update contract: No response received from the server."
        );
      } else {
        showPopup("Error", "Failed to update contract. Please try again.");
      }
    }
  };

  const confirmDelete = async () => {
    if (!contractToDelete || !token) {
      showPopup("Error", "No PDF selected for deletion.");
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/pdfs/${contractToDelete}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== contractToDelete)
        );
        showPopup("Success", "PDF deleted successfully.");
      } else {
        showPopup("Error", "Failed to delete the PDF. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
      showPopup("Error", "Failed to delete PDF. Please try again.");
    } finally {
      setConfirmDeleteVisible(false);
      setContractToDelete(null);
    }
  };

  useEffect(() => {
    if (editContractId) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContractId]);

  const upload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      console.log("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let file = null;
    handleFile(e).then((res) => {
      file = res;
    });

    try {
      const token = localStorage.getItem("token");

      setLoading(true);
      setStatus("Uploading...");
      await axios
        .post(
          process.env.REACT_APP_BASE_URL +
            `api/v1/readpdf/qdrant-upload-async/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("API Response:", res);
        });
    } catch (err) {
      setLoading(false);
      setStatus("Error during file upload");
      if (
        err.response &&
        err.response.data &&
        err.response.data.msg === "Unsupported file format"
      ) {
        toast.warn("Unsupported file format", { autoClose: 2000 });
      } else {
        console.error("Error during file upload:", err);
        toast.error("An error occurred during file upload", {
          autoClose: 2000,
        });
      }
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/delete-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => folder.id !== folderId)
        );
        setIsDeleteFolderModalOpen(false);
        showPopup("Success", "Folder deleted successfully.");
      } else {
        showPopup("Error", "Failed to delete folder. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      showPopup("Error", "Failed to delete folder. Please try again.");
    }
  };

  console.log("object", selectedContracts);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <>
      {loading && <Spinner />}
      <div className="document-full-container">
        <div className="documents-header">
          <Header name="Documents" icon={""} />
        </div>

        <div className="document-contract-table-container">
          <div className="document-contract-table-header">
            <div className="document-search-filter-container">
            <div
  className="document-search-box"
  style={{
    borderRadius: "20px",
    border: isSearchFocused
      ? "1px solid rgba(187, 66, 174, 0.36)"
      : "1px solid var(--black_stroke, rgba(0, 0, 0, 0.10))",
    background: "#FFF",
    boxShadow: isSearchFocused
      ? "0px 11px 3px 0px rgba(0, 0, 0, 0.00), 0px 7px 3px 0px rgba(0, 0, 0, 0.01), 0px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)"
      : "none",
    transition: "all 0.3s ease-in-out",
  }}
>
  <img src="search.svg" alt="" />
  <input
    type="text"
    placeholder="Search Documents Here"
    className="document-input-space"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    onFocus={() => setIsSearchFocused(true)}
    onBlur={() => setIsSearchFocused(false)}
  />
</div>
              <button
  className={`filter-button ${isFilterApplied ? "filter-applieds" : ""}`}
  onClick={toggleFilterModal}
>
  <img
    src={isFilterApplied ? "/Filter_CTA.png" : "/filter.svg"}
    alt="Filter Icon"
  />
</button>

            </div>

            {selectedContracts.length > 0 ? (
              <div className="document-button-cat">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (selectedContracts.length > 0) {
                      setCurrentDocument(selectedContracts); // Set all selected documents
                      setIsCopyModalOpen(true);
                    } else {
                      alert("Please select a document first.");
                    }
                  }}
                  disabled={exportInProgress || selectedContracts.length === 0}
                >
                  <img src="./copyToFolder.svg" alt="Export Icon" />
                  {exportInProgress ? "Copying....." : "Copy to folder"}
                </button>

                {selectedContracts.some(
                  (item) => item.document_type === "contract"
                ) && (
                  <button
                    onClick={handleExport}
                    disabled={exportInProgress}
                    className="document-export-button"
                  >
                    <img src="/export-icon-blue.svg" alt="Export Icon" />
                    {exportInProgress ? "Exporting..." : "Export"}
                  </button>
                )}

                <button
                  onClick={handleBulkDeleteConfirmation}
                  disabled={selectedContracts.length === 0}
                  className="document-delete-button"
                >
                  <img src="/delete-icon-red.svg" alt="Delete Icon" />
                  Delete
                </button>

                <button
                  onClick={handleCancel}
                  className="document-cancel-button"
                >
                  <img src="/cancel_icon.svg" alt="Cancel Icon" />
                </button>
              </div>
            ) : (
              <div className="document-upload-section">
                <button
                  className="document-upload-button1"
                  onClick={() => setIsCreateFolderModalOpen(true)}
                >
                  <img src="/upload-doc-create.svg" alt="Upload Icon" /> Create
                  Folder
                </button>
<>
<div className="upload-container-invoice">
                    <button
    className="document-upload-button2"
    onMouseEnter={() => {
      console.log("Mouse entered - Tooltip should appear");
      setIsTooltipVisible(true);
    }}
    onMouseLeave={() => {
      console.log("Mouse left - Tooltip should disappear");
      setIsTooltipVisible(false);
    }}
    onClick={() => fileInputRef.current.click()}
  >
     <img src="/upload-icon-doc.svg" alt="Upload Icon" /> Upload
  </button>

  {isTooltipVisible && (


<div 
  className={`upload-tooltip-invoice absolute top-[88px] left-[93%] transform -translate-x-1/2 
              w-[178px] h-[92.637px] flex-shrink-0 bg-contain bg-no-repeat 
              p-[27px] px-[20px] font-outfit text-[16px] font-normal leading-[8px] text-[#171717] 
              whitespace-nowrap z-[1000] flex opacity-${isTooltipVisible ? '100' : '0'} 
              ${isTooltipVisible ? 'visible' : 'invisible'} transition-opacity duration-200 ease-in-out`}
  style={{ backgroundImage: "url('/path-to-background.svg')" }}  
>
  <p className="text-[#171717] font-outfit text-[13px] font-normal leading-none w-[99px] h-[27px] flex-shrink-0">
    Supported formats:<br />Pdf, xlsx, csv, xls <br />Maximum Size : 25MB
  </p>
</div>




  )}
                
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".csv,.xlsx,.pdf"
                  />
                </div>
              

                 <input
               type="file"
               ref={fileInputRef}
               style={{ display: "none" }}
               onChange={handleFileChange}
               accept=".csv,.xlsx,.pdf"
             />
                </>
              </div>
            )}
          </div>

          <div className="container">
            <table className="contract-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="document-custom-checkbox"
                      onChange={handleSelectAll}
                      checked={
                        selectedContracts.length ===
                          folders.length + files.length &&
                        folders.length + files.length > 0
                      }
                    />
                  </th>
                  <th>File Name</th>
                  <th>File Type</th>
                  <th>Size</th>
                  <th>Uploaded Date</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody ref={tbodyRef}>
                {loading ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <Spinner />
                    </td>
                  </tr>
                ) : filteredPaginatedData.length > 0 ? (
                  filteredPaginatedData.map((item, index) => (
                    <tr
                      key={`${
                        item.isFolder ? item.doc_type : item.document_type
                      }-${index}`}
                      onClick={(e) => {
                        if (
                          e.target.type !== "checkbox" &&
                          !e.target.classList.contains("action-button")
                        ) {
                          e.stopPropagation();
                          if (item.documents) {
                            const pdfUrl = item.documents[0]?.file_url;
                            const pdfKey = item.documents[0]?.key;
                            navigate(`/folder/?folderId=${item.id}`, {
                              state: {
                                folderId: item.id,
                                folderName: item.name,
                              },
                            });
                          } else {
                            navigate("/chat", {
                              state: { pdfUrl: item.pdf_url, pdfKey: item.key },
                            });
                          }
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="h-[52px] align-middle">
                        <input
                          type="checkbox"
                          className="document-custom-checkbox"
                          checked={selectedContracts.some(
                            (selectedItem) => selectedItem.id === item.id
                          )}
                          onChange={() =>
                            handleCheckboxChange({
                              ...item,
                              document_type: item.isFolder
                                ? item.doc_type
                                : item.document_type,
                            })
                          }
                        />
                      </td>
                      <td
  className="file-name-cell"
  style={{
    maxWidth: "370px",
    whiteSpace: "normal", // Allows text to wrap
    wordBreak: "break-word", // Ensures long words wrap properly
    overflow: "hidden",
    padding: "10px",
    textAlign: "left",
  }}
>
                        {item.documents ? (
                          <div
                            className="file-icon-and-name"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/folder/?folderId=${item.id}`, {
                                state: {
                                  folderId: item.id,
                                  folderName: item.name,
                                },
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="/folder-icon.svg"
                              alt="Folder Icon"
                              className="file-icon"
                            />
                            <div className="file-details">
                              <div className="file-name">
                                {item.name || "N/A"}
                              </div>
                              <div className="file-type">
                                {item.doc_type || item.file_type || "N/A"}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="file-icon-and-name">
                            <img
                              src="/pdf.svg"
                              alt="PDF Icon"
                              className="file-icon"
                            />
                            <div className="file-details">
                              <div className="file-name">
                                {item.name || "N/A"}
                              </div>
                              {item.file_type || "Pdf"}
                            </div>
                          </div>
                        )}
                      </td>
                      <td style={{ color: "#B793ED" }}>
                        {item.documents ? "Folder" : item?.document_type}
                      </td>
                      <td>
                        {item.documents
                          ? item.folder_size || item.size || "N/A"
                          : item.document_size || item.size || "N/A"}
                      </td>
                      <td>
                        <div className="text-left  leading-5">
                          <span className="">{item.time_ago || "N/A"}</span>
                          <br />
                          <span className="">
                            {item.created_at || item.uploaded_at
                              ? new Date(
                                  item.created_at || item.uploaded_at
                                ).toLocaleDateString()
                              : new Date().toLocaleDateString()}
                          </span>
                        </div>
                      </td>

                      <td className="chat-button-cell">
                        <div
                          className="chat-button-doc"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.documents) {
                              navigate("/Contract/FolderChat", {
                                state: {
                                  folderId: item.id,
                                  folderName: item.name || "N/A",
                                  pdfList: item.documents.map((doc) => ({
                                    pdfKey: doc.key || doc.id, // Ensure pdfKey is set
                                    pdfUrl:
                                      doc.pdf_url ||
                                      doc.file_url ||
                                      doc.pdfUrl ||
                                      "",
                                    name: doc.name,
                                  })),
                                },
                              });
                            } else {
                              navigate("/chat", {
                                state: {
                                  pdfUrl: item.pdf_url || item.url,
                                  pdfKey: item.key,
                                },
                              });
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="/chat-icon.svg"
                            alt="Chat Icon"
                            className="chat-icon"
                          />
                          {/* <span>Chat</span> */}
                        </div>
                      </td>
                      <td>
                        <img
                          src="./action.svg"
                          alt="Actions"
                          width="20"
                          height="20"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleActionMenu(index);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        {isActionMenuOpen === index && (
                          <div
                            className={`document-action-menu ${
                              item.documents ? "folder-options" : ""
                            }`}
                            ref={actionMenuRef}
                          >
                            {item.documents ? (
                              <>
                               <div
  className="document-action-item"
  onMouseEnter={() => setHoveredAction("rename-folder")}
  onMouseLeave={() => setHoveredAction(null)}
  onMouseDown={() => setClickedAction("rename-folder")}
  onMouseUp={() => setClickedAction(null)}
  onClick={(e) => {
    e.stopPropagation();
    setCurrentFolder(item);
    setIsRenameFolderModalOpen(true);
  }}
  style={{
    color: clickedAction === "rename-folder" ? "#C95EBE" : hoveredAction === "rename-folder" ? "#C95EBE" : "",
    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
    borderRadius: "8px",
    border: clickedAction === "rename-folder" || hoveredAction === "rename-folder" ? "none" : "none",
    background: clickedAction === "rename-folder" || hoveredAction === "rename-folder" ? "#FAF9F6" : "transparent",
    padding: "10px",
    cursor: "pointer",
  }}
>
  <img
    src={clickedAction === "rename-folder" || hoveredAction === "rename-folder" ? "./edit-active-contract.svg" : "./edit.svg"}
    alt="Edit Icon"
    className="document-action-icon"
  />
  <p>Rename Folder</p>
</div>

<div
  className="document-action-item"
  onMouseEnter={() => setHoveredAction("delete-folder")}
  onMouseLeave={() => setHoveredAction(null)}
  onMouseDown={() => setClickedAction("delete-folder")}
  onMouseUp={() => setClickedAction(null)}
  onClick={(e) => {
    e.stopPropagation();
    setFolderToDelete(item.id);
    setIsDeleteFolderModalOpen(true);
  }}
  style={{
    color: clickedAction === "delete-folder" ? "#E74C3C" : hoveredAction === "delete-folder" ? "#E74C3C" : "",
    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
    borderRadius: "8px",
    border: clickedAction === "delete-folder" || hoveredAction === "delete-folder" ? "none" : "none",
    background: clickedAction === "delete-folder" || hoveredAction === "delete-folder" ? "#FFF5F5" : "transparent",
    padding: "10px",
    cursor: "pointer",
  }}
>
  <img
    src={clickedAction === "./delete-active-contract.svg" || hoveredAction === "delete-folder" ? "./delete-active-contract.svg" : "./delete.svg"}
    alt="Delete Icon"
    className="document-action-icon"
  />
  <p>Delete Folder</p>
</div>

                              </>
                            ) : (
                              <>
                                <div
                                  className="document-action-item"
                                  onMouseEnter={() => setHoveredAction("copy")}
                                  onMouseLeave={() => setHoveredAction(null)}
                                  onMouseDown={() => setClickedAction("copy")}
                                  onMouseUp={() => setClickedAction(null)}
                                  onClick={(e) => {
                                    
                                    e.stopPropagation();

                                    // If multiple documents are selected, copy them all
                                    if (selectedContracts.length > 0) {
                                      setCurrentDocument(selectedContracts); // Set multiple selected documents
                                    } else {
                                      setCurrentDocument([item]); // Set only the clicked document
                                    }

                                    setIsCopyModalOpen(true);
                                  }}
                                  style={{
                                    color: clickedAction === "copy" ? "#C95EBE" : hoveredAction === "copy" ? "#C95EBE" : "",
                                    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
                                    borderRadius: "8px",
                                    border: clickedAction === "copy" || hoveredAction === "copy" ? "" : "",
                                    background: clickedAction === "copy" || hoveredAction === "copy" ? "#ECF6FD" : "transparent",
                                    padding: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
    src={clickedAction === "copy" || hoveredAction === "copy" ? "./folder-copy-active.svg" : "./folder-copy.svg"}
    alt="Copy to Folder Icon"
                                    className="document-action-icon"
                                  />
                                  <p>Copy to Folder</p>
                                </div>
                                <div
  className="document-action-item"
  onMouseEnter={() => setHoveredAction("rename")}
  onMouseLeave={() => setHoveredAction(null)}
  onMouseDown={() => setClickedAction("rename")}
  onMouseUp={() => setClickedAction(null)}
  onClick={(e) => {
    e.stopPropagation();
    setCurrentDocument(item);
    setIsRenameModalOpen(true);
  }}
  style={{
    color: clickedAction === "rename" ? "#C95EBE" : hoveredAction === "rename" ? "#C95EBE" : "",
    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
    borderRadius: "8px",
    border: clickedAction === "rename" || hoveredAction === "rename" ? "none" : "none",
    background: clickedAction === "rename" || hoveredAction === "rename" ? "#EAF4FB" : "transparent",
    padding: "10px",
    cursor: "pointer",
  }}
>
  <img
    src={clickedAction === "rename" || hoveredAction === "rename" ? "./edit-active-contract.svg" : "./edit.svg"}
    alt="Edit Icon"
    className="document-action-icon"
  />
  <p>Rename</p>
</div>

<div
  className="document-action-item"
  onMouseEnter={() => setHoveredAction("delete")}
  onMouseLeave={() => setHoveredAction(null)}
  onMouseDown={() => setClickedAction("delete")}
  onMouseUp={() => setClickedAction(null)}
  onClick={(e) => {
    e.stopPropagation();
    handleDelete(item.id);
  }}
  style={{
    color: clickedAction === "delete" ? "#E74C3C" : hoveredAction === "delete" ? "#E74C3C" : "#000",
    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
    borderRadius: "8px",
    border: clickedAction === "delete" || hoveredAction === "delete" ? "none" : "none",
    background: clickedAction === "delete" || hoveredAction === "delete" ? "#FDEDEC" : "transparent",
    padding: "10px",
    cursor: "pointer",
  }}
>
  <img
    src={clickedAction === "delete" || hoveredAction === "delete" ? "./delete-active-contract.svg" : "./delete.svg"}
    alt="Delete Icon"
    className="document-action-icon"
  />
  <p>Delete</p>
</div>

                              </>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no-contracts-icon">
                    <img src="/no-contracts.svg" alt="No Data Available" />
                    <p className="no-contracts-para">
                      Looks like your table is empty!
                    </p>
                  </div>
                )}
              </tbody>
            </table>
            <div className="pagination" style={{ bottom: "10px" }}>
              <div className="document-pagination-dropdown">
                <select
                  value={currentPage}
                  onChange={(e) => handlePageChange(Number(e.target.value))}
                  className="document-page-selector"
                >
                  {Array.from({ length: totalPages }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
              <p className="document-contentDown">of {totalPages} pages</p>
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                style={{
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                }}
              >
                <img
                  src="decrease.svg"
                  alt="Previous Page"
                  style={{ height: "15px", width: "15px" }}
                />
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{
                  cursor:
                    currentPage === totalPages ? "not-allowed" : "pointer",
                }}
              >
                <img
                  src="increase.svg"
                  alt="Next Page"
                  style={{ height: "15px", width: "15px" }}
                />
              </button>
            </div>
          </div>
        </div>

        {popupVisible && (
          <div className="document-popup-overlay">
            <div className="document-popup-box">
              <h2>{popupTitle}</h2>
              <p>{popupMessage}</p>
              <button
                onClick={closePopup}
                className="document-popup-close-button"
              >
                Close
              </button>
            </div>
          </div>
        )}

        {confirmDeleteVisible && (
          <div className="document-popup-overlay">
            <div className="document-popup-box-delete">
              {" "}
              <h2>Confirm Deletion</h2>
              <p>
                Are you sure you want to delete the selected files and folders?
                This action cannot be undone.
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={handleBulkDelete}
                  className="document-popup-close-delete"
                >
                  Yes, Delete
                </button>

                <button
                  onClick={() => setConfirmDeleteVisible(false)}
                  className="document-popup-close-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {contractToDelete && (
          <div className="document-popup-overlay">
            <div className="document-popup-box-delete">
              <h2>Confirm Deletion</h2>
              <p>
                Are you sure you want to delete this PDF? This action cannot be
                undone.
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={confirmDelete}
                  className="document-popup-close-delete"
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => setContractToDelete(null)}
                  className="document-popup-close-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <UploadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onUploadSuccess={handleUploadSuccess}
          type={"document"}
          file={file}
        />
        <RenameModal
          isOpen={isRenameModalOpen}
          onClose={() => setIsRenameModalOpen(false)}
          document={currentDocument}
          onRename={(documentId, newName) => handleRename(documentId, newName)}
        />

        <CopyToFolderModal
          isOpen={isCopyModalOpen}
          onClose={() => setIsCopyModalOpen(false)}
          documents={currentDocument}
          folders={folders}
          onCopy={(documentId, folderId) =>
            handleCopyToFolder(folderId, [documentId])
          }
          onCreateFolder={fetchFolders}
        />


<FilterModal
  isOpen={isFilterModalOpen}
  onClose={() => setIsFilterModalOpen(false)}
  onApply={(filteredFiles, filteredFolders) => {
    setFiles(filteredFiles);
    setFolders(filteredFolders);
    setIsFilterApplied(true); // ✅ Mark filters as applied
  }}
  setFiles={setFiles}
  setFolders={setFolders}
  setIsFilterApplied={setIsFilterApplied} // ✅ Ensure filter status updates
/>



        <CreateFolderModal
          isOpen={isCreateFolderModalOpen}
          onClose={() => setIsCreateFolderModalOpen(false)}
          onCreateSuccess={fetchFolders}
        />
        <RenameModal
          isOpen={isRenameFolderModalOpen}
          onClose={() => setIsRenameFolderModalOpen(false)}
          document={currentFolder}
          onRename={handleRenameFolder}
        />

        <button
          onClick={handleBulkDelete}
          disabled={selectedContracts.length === 0}
        >
          <img src="/delete-icon-red.svg" alt="Delete Icon" />
        </button>

        {isSuccessPopupVisible && (
          <div className="success-popup-overlay">
            <div className="success-popup-box">
              <div className="stacked-icons-container">
                <img
                  src="/folder-copy-tick.svg"
                  alt="Tick"
                  className="icon tick-icon"
                />
                <img
                  src="/folder-copy-circle.svg"
                  alt="Circle"
                  className="icon circle-icon"
                />
                <img
                  src="/folder-copied-circle.svg"
                  alt="Copied"
                  className="icon copied-icon"
                />
              </div>

              <h2 className="folder-uploaded-success">
                Folder Successfully Copied
              </h2>
              <p className="folder-folderName">
                Your documents have been copied to {successFolderName}.
              </p>
              <div className="copy-folder-last">
                <button
                  onClick={() => navigate(`/folder/?folderId=${file_ids}`)}
                  className="copy-folder-view"
                >
                  View Folder
                </button>

                <button
                  onClick={() => setIsSuccessPopupVisible(false)}
                  className="copy-folder-done"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        )}

        {isDeleteFolderModalOpen && (
          <div className="document-popup-overlay">
            <div className="document-popup-box-delete">
              <h2>Confirm Folder Deletion</h2>
              <p>
                Are you sure you want to delete this folder? This action cannot
                be undone.
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={() => handleDeleteFolder(folderToDelete)}
                  className="document-popup-close-delete"
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => setIsDeleteFolderModalOpen(false)}
                  className="document-popup-close-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {showSubscriptionPopup && (
  <SubscriptionPopup onClose={() => setShowSubscriptionPopup(false)} />
)}

      </div>
    </>
  );
};

export default UploadFilePage;
