import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../Features/chatSlice";
import chatContractReducer from "../Features/chatContractSlice";
import pdfChatReducer from "../Features/pdfChatSlice";
import invoiceChatReducer from "../Features/invoiceChatSlice";
import excelChatReducer from "../Features/excelChatSlice";
import feedbackReducer from "../Features/sendFeedback";
import copilotChatReducer from "../Features/CopilotChatSlice";

const store = configureStore({
  reducer: {
    chat: chatReducer,
    chatContract: chatContractReducer,
    pdfChat: pdfChatReducer,
    excelChat: excelChatReducer,
    invoiceChat: invoiceChatReducer,
    feedback: feedbackReducer,
    copilotChat: copilotChatReducer,
  },
});

export default store;
