import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import UploadDocumentModal from "../Contract/UploadDocumentModal"; 
import FilterModalForFolder from "./FilterModalForFolder";
import "./FolderContent.css";
import UploadModal from "./UploadModal"; 
import "./contract_data.css";

const FolderContents = () => {
  const location = useLocation();
  const [isPopupVisible, setIsPopupVisible] = useState(false); 
  const { folderName } = useParams();
  const [files, setFiles] = useState([]); 
  const [folders, setFolders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [renameInput, setRenameInput] = useState("");
  const [deletingDocId, setDeletingDocId] = useState(null);
  const navigate = useNavigate();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
const fileInputRef = useRef(null);
  const [isRenameFolderModalOpen, setIsRenameFolderModalOpen] = useState(false);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [renameFolderInput, setRenameFolderInput] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showBulkActions, setShowBulkActions] = useState(false); 
  const [activeActionMenuFolder, setActiveActionMenuFolder] = useState(null);
  const dropdownRefs = useRef([]); 
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [hoveredAction, setHoveredAction] = useState(null);
  const [clickedAction, setClickedAction] = useState(null);
  
  const { pdfUrl, pdfKey } = location.state || {};
  const token = localStorage.getItem("token");
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false); 
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null); 

  const folderId = new URLSearchParams(location.search).get("folderId");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 
  

  const actionMenuRef = useRef(null);
  const totalPages = Math.ceil((files.documents?.length || 0) / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const showPopup = (type, message) => {
    alert(`${type}: ${message}`); 
  };

  const toggleFolderActionMenu = (folder) => {
    setActiveActionMenuFolder((prevFolder) =>
      prevFolder === folder ? null : folder
    );
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const [filters, setFilters] = useState({
    folder: true,
    normalDocument: true,
    contract: false,
    invoices: false,
  });

  const handleCancel = () => {

    setIsModalOpen(false);
    setIsFilterModalOpen(false);
  };

  const handleCheckboxChange = (fileId) => {
    setSelectedFiles((prevSelected) => {
      const updatedSelected = prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId) 
        : [...prevSelected, fileId]; 

      setShowBulkActions(updatedSelected.length > 0);

      return updatedSelected;
    });
  };

  const handleSelectAll = () => {
    if (!files.documents || files.documents.length === 0) {
      setSelectedFiles([]);
      setShowBulkActions(false); 
      return;
    }
  
    if (selectedFiles.length === files.documents.length) {
      setSelectedFiles([]); 
      setShowBulkActions(false); 
    } else {
      const allFileIds = files.documents.map((file) => file.id);
      setSelectedFiles(allFileIds);
      setShowBulkActions(true);
    }
  };
  
  const [file, setFile] = useState(null);

      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;
    
        console.log("File selected:", selectedFile); // Debug log
        setFile(selectedFile);  // Update file state
        setIsModalOpen(true);   // Open modal after file selection
    };
  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const filteredDocuments = files.documents
    ? files.documents.filter(
        (file) => file.name.toLowerCase().includes(searchQuery.toLowerCase()) 
      )
    : [];

  const RenameModal = ({ isOpen, onClose, onRename, fileName }) => {
    const [newName, setNewName] = useState(fileName);

    const handleSubmit = () => {
      if (newName.trim() !== "") {
        onRename(newName);
        onClose();
      }
    };

    if (!isOpen) return null;

    return (
      <div className="rename-modal-overlay">
        <div className="rename-modal">
          <h2>Rename Document</h2>
          <p>Create a Custom Name for your Documents</p>
          <h3>Title</h3>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter new name"
          />
          <div className="rename-modal-actions">
            <button onClick={onClose}>Cancel</button>
            <button onClick={handleSubmit}>Save</button>
          </div>
        </div>
      </div>
    );
  };

  const DeleteModal = ({ isOpen, onClose, onDelete, fileName }) => {
    if (!isOpen) return null;

    return (
      <div className="document-popup-overlay">
        <div className="document-popup-box-delete">
          <h2>Confirm Deletion</h2>
          <p under-folder-delete-popup>
            Are you sure you want to delete this PDF? This action cannot be
            undone.
          </p>
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              onClick={onDelete}
              className="document-popup-close-delete"
            >
              Yes, Delete
            </button>
            <button
              onClick={() => onClose(null)} 
              className="document-popup-close-button ml-[-22px]"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleRenameFolder = async (newName) => {
    if (!newName) {
      showPopup("Error", "Folder name is required.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/rename-folder/${currentFolder.id}/`,
        { new_name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder.id === currentFolder.id
              ? { ...folder, name: newName }
              : folder
          )
        );
        setIsRenameFolderModalOpen(false); 
        showPopup("Success", "Folder renamed successfully.");
      }
    } catch (error) {
      console.error("Error renaming folder:", error);
      showPopup("Error", "Failed to rename folder. Please try again.");
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/delete-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => folder.id !== folderId)
        );
        setIsDeleteFolderModalOpen(false); 
        showPopup("Success", "Folder deleted successfully.");
      } else {
        showPopup("Error", "Failed to delete folder. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      showPopup("Error", "Failed to delete folder. Please try again.");
    }
  };
  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleFolderActionClick = (action, folder) => {
    setCurrentFolder(folder); 
    if (action === "rename") {
      setRenameFolderInput(folder.name); 
      setIsRenameFolderModalOpen(true); 
    } else if (action === "delete") {
      setIsDeleteFolderModalOpen(true); 
    }
  };

  const fetchFiles = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/files-in-the-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedDocuments = response.data.documents.map((doc) => ({
          ...doc,
          pdf_url: doc.url || "", 
        }));
        setFiles({ ...response.data, documents: updatedDocuments });
        setFolders({ folder_name: response.data.folder_name });
      } else {
        console.log("Failed to fetch folder files.");
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [folderId]);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionMenuIndex !== null &&
        dropdownRefs.current[actionMenuIndex] &&
        !dropdownRefs.current[actionMenuIndex].contains(event.target)
      ) {
        setActionMenuIndex(null);
      }
    };
    

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleImageClick = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const [actionMenuIndex, setActionMenuIndex] = useState(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionMenuIndex !== null &&
        dropdownRefs.current[actionMenuIndex] &&
        !dropdownRefs.current[actionMenuIndex].contains(event.target)
      ) {
        setActionMenuIndex(null);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionMenuIndex]); 
  const toggleActionMenu = (index) => {
    setActionMenuIndex(actionMenuIndex === index ? null : index);
  };
  
  useEffect(() => {
    if (files.documents?.length >= 5) {
      setIsPopupVisible(true);
    }
  }, [files.documents]);
  
  const paginatedDocuments = filteredDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log("page", filteredDocuments)

  if (loading) return <p>Loading folder contents...</p>;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleBulkDelete = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected for deletion");
      return;
    }
  
    try {
      setLoading(true); 
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/bulk-delete-from-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          data: { document_ids: selectedFiles }, 
        }
      );
  
      if (response.status === 200) {
        const { removed_documents, not_found_documents } = response.data;
        const updatedDocuments = files.documents.filter(
          (doc) => !removed_documents.some((removed) => removed.document_id === doc.id)
        );
        setFiles((prevFiles) => ({ ...prevFiles, documents: updatedDocuments }));
        const updatedSelectedFiles = selectedFiles.filter(
          (id) => !removed_documents.some((removed) => removed.document_id === id)
        );
        setSelectedFiles(updatedSelectedFiles); 
  
        if (updatedSelectedFiles.length === 0) {
          setShowBulkActions(false); 
        }
        if (not_found_documents.length > 0) {
          console.error(`Some documents could not be removed:`, not_found_documents);
        }
      } else {
        console.error("Failed to delete documents. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting documents:", error);
    } finally {
      setLoading(false); 
    }
  };
  
  
  
  const handleBulkExport = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected for export");
      return;
    }
  
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
  
      const selectedFileObjects = files.documents.filter((file) =>
        selectedFiles.includes(file.id)
      );
  
      const contractKeys = selectedFileObjects
        .filter((file) => file.type === "contract")
        .map((file) => file.key);
  
      if (contractKeys.length === 0) {
        alert("Export is only available for contracts. Please select at least one contract.");
        return;
      }
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/export-doc/`,
        { contract_keys: contractKeys },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
  
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported_documents.csv";
      link.click();
  
      
      setSelectedFiles([]);
      setShowBulkActions(false);
    } catch (error) {
      console.error("Error exporting files:", error.response?.data || error.message);
      alert("An error occurred while exporting files. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  

  const handleUploadSuccess = () => {
    if (files.documents?.length >= 5) {
      setIsPopupVisible(true); 
      return; 
    }
  
    fetchFiles(); 
    handleCloseModal();
  };
  

  const handleRename = async (newName) => {
    if (!currentFile) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/rename-doc/${currentFile.id}/`,
        {
          new_name: newName,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        fetchFiles();
        setIsRenameModalOpen(false);
      } else {
        console.error("Failed to rename document.");
      }
    } catch (error) {
      console.error("Error renaming document:", error);
    }
  };

  const handleDelete = async () => {
    if (!currentFile) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/remove-doc-from-folder/${folderId}/${currentFile.id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchFiles(); 
        setIsDeleteModalOpen(false);
      } else {
        console.error("Failed to delete document.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };


  return (
    <div className="Contents-Full-Container">
      <div>
        <Header icon={"/arrow-back.svg"} name="Go to Documents" />
      </div>
      <div className="folder-content-info">
        <div className="main-content">
          <img
            src="/folder-icon-folder.svg"
            alt="Folder Icon"
            className="folder-icon"
          />
          <h2 className="folder-name-underfolder">
            {folders.folder_name || "N/A"}
          </h2>
          
        </div>

        <div className="action-buttons-content">
          {selectedFiles.length === 0 && (
            <>
              <button
                className="ask-copilot-button-content"
                onClick={() => {
                  if (files.documents) {
                    navigate("/Contract/FolderChat", {
                      state: {
                        folderId: folderId,
                        folderName: folders.folder_name || "N/A",
                        pdfList: files.documents.map((doc) => ({
                          pdfKey: doc.id,
                          pdfUrl: doc.url,
                          name: doc.name,
                        })), 
                      },
                    });
                  }
                }}
              >
                <img src="/askaicontent.svg" alt="Ask Copilot" />
                Chat With Folder
              </button>
              <div className="upload-container-invoice">
                    <button
    className="upload-button-content"
    onMouseEnter={() => {
      console.log("Mouse entered - Tooltip should appear");
      setIsTooltipVisible(true);
    }}
    onMouseLeave={() => {
      console.log("Mouse left - Tooltip should disappear");
      setIsTooltipVisible(false);
    }}
    onClick={() => fileInputRef.current.click()}
    disabled={files.documents?.length > 5}
    >
      <img src="/upload_white.svg" alt="Upload" />
      Upload
  </button>

  {isTooltipVisible && (


<div 
  className={`upload-tooltip-invoice absolute top-[138px] left-[93%] transform -translate-x-1/2 
              w-[178px] h-[92.637px] flex-shrink-0 bg-contain bg-no-repeat 
              p-[27px] px-[20px] font-outfit text-[16px] font-normal leading-[8px] text-[#171717] 
              whitespace-nowrap z-[1000] flex opacity-${isTooltipVisible ? '100' : '0'} 
              ${isTooltipVisible ? 'visible' : 'invisible'} transition-opacity duration-200 ease-in-out`}
  style={{ backgroundImage: "url('/path-to-background.svg')" }}  
>
  <p className="text-[#171717] font-outfit text-[13px] font-normal leading-none w-[99px] h-[27px] flex-shrink-0">
    Supported formats:<br />Pdf, xlsx, csv, xls <br />Maximum Size : 25MB
  </p>
</div>




  )}
                
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".csv,.xlsx,.pdf"
                  />
                </div>
             
              <input
               type="file"
               ref={fileInputRef}
               style={{ display: "none" }}
               onChange={handleFileChange}
               accept=".csv,.xlsx,.pdf"
             />
            </>
          )}
        </div>
      </div>

      <div className="document-search-filter-container">
      <div
  className="document-search-box"
  style={{
    borderRadius: "20px",
    border: isSearchFocused
      ? "1px solid rgba(187, 66, 174, 0.36)"
      : "1px solid var(--black_stroke, rgba(0, 0, 0, 0.10))",
    background: "#FFF",
    boxShadow: isSearchFocused
      ? "0px 11px 3px 0px rgba(0, 0, 0, 0.00), 0px 7px 3px 0px rgba(0, 0, 0, 0.01), 0px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)"
      : "none",
    transition: "all 0.3s ease-in-out",
  }}
>
  <img src="search.svg" alt="" />
  <input
    type="text"
    placeholder="Search Documents Here"
    className="document-input-space"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    onFocus={() => setIsSearchFocused(true)}
    onBlur={() => setIsSearchFocused(false)}
  />
</div>
        <button
  className={`filter-button ${isFilterApplied ? "filter-applieds" : ""}`}
  onClick={toggleFilterModal}
>
  <img
    src={isFilterApplied ? "/Filter_CTA.png" : "/filter.svg"}
    alt="Filter Icon"
  />
</button>


        {showBulkActions && (
          <div className="bulk-action-bar">
           <button
  onClick={handleBulkExport}
  disabled={
    selectedFiles.length === 0 ||
    !files.documents.some(
      (file) => selectedFiles.includes(file.id) && file.type === "contract"
    )
  }
  className="bulk-action-button export-button"
>
  <img
    src="/export-icon-blue.svg"
    alt="Export"
    className="button-icon"
  />
  <span className="export-spna">Export</span>
</button>

            <button
              onClick={handleBulkDelete}
              disabled={selectedFiles.length === 0}
              className="bulk-action-button delete-button"
            >
              <img
                src="/delete-icon-red.svg"
                alt="Delete"
                className="button-icon"
              />
              <span className="delete-spna">Delete</span>
            </button>

            <button
              onClick={() => {
                setSelectedFiles([]);
                setShowBulkActions(false); 
              }}
              className="bulk-action-button cancel-button"
            >
             
              <span>X</span>
            </button>
          </div>
        )}
      </div>

      <div className="container">
  <table className="contract-table">
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            className="document-custom-checkbox"
            checked={files.documents?.length > 0 && selectedFiles?.length === files?.documents?.length} 
            onChange={handleSelectAll} 
            disabled={!files.documents?.length} 
          />
        </th>
        <th>File Name</th>
        <th>File Type</th>
        <th>Size</th>
        <th>Uploaded Date</th>
        <th></th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {files.documents && files.documents.length > 0 ? (
        paginatedDocuments.map((file, index) => (
          <tr key={file.id} className="h-16">
            <td>
              <input
                type="checkbox"
                className="document-custom-checkbox"
                checked={selectedFiles.includes(file.id)} 
                onChange={() => handleCheckboxChange(file.id)} 
              />
            </td>
            <td className="file-name-cell">
              <div className="file-icon-and-name">
                <img src="/pdf.svg" alt="PDF Icon" className="file-icon" />
                <div className="file-details">
                  <div className="file-name">{file.name}</div>
                  <div className="file-type">{file.file_type}</div>
                </div>
              </div>
            </td>
            <td>{file.type}</td>
            <td>{file.size}</td>
            <td>
              <span>{file.time_ago}</span>
              <br />
              <span>{file.uploaded_at}</span>
            </td>
            <td className="chat-button-cell">
              <div
                className="chat-button-doc-underfolder"
                onClick={() => {
                  navigate("/chat", {
                    state: { pdfUrl: file.pdf_url, pdfKey: file.key },
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/chat-icon.svg"
                  alt="Chat Icon"
                  className="chat-icon"
                />
                
              </div>
            </td>
            <td>
              <img
                src="/action.svg"
                alt="Actions"
                width="20"
                height="20"
                style={{ cursor: "pointer" }}
                onClick={() => toggleActionMenu(index)} 
              />
              {actionMenuIndex === index && (
                <div
                ref={(el) => (dropdownRefs.current[index] = el)}
                className="document-action-menu-underfolder"
              >            
                 <div
  className="document-action-item"
  onMouseEnter={() => setHoveredAction("rename")}
  onMouseLeave={() => setHoveredAction(null)}
  onMouseDown={() => setClickedAction("rename")}
  onMouseUp={() => setClickedAction(null)}
  onClick={() => {
    setCurrentFile(file); 
    setRenameInput(file.name);
    setIsRenameModalOpen(true);
  }}
  style={{
    color: clickedAction === "rename" ? "#C95EBE" : hoveredAction === "rename" ? "#C95EBE" : "",
    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
    borderRadius: "8px",
    border: clickedAction === "rename" || hoveredAction === "rename" ? "none" : "none",
    background: clickedAction === "rename" || hoveredAction === "rename" ? "#FDF6EC" : "transparent",
    padding: "10px",
    cursor: "pointer",
  }}
>
  <img
    src={clickedAction === "rename" || hoveredAction === "rename" ? "/edit-active-contract.svg" : "/edit.svg"}
    alt="Edit Icon"
    className="document-action-icon"
  />
  <p>Rename</p>
</div>


<div
  className="document-action-item"
  onMouseEnter={() => setHoveredAction("delete")}
  onMouseLeave={() => setHoveredAction(null)}
  onMouseDown={() => setClickedAction("delete")}
  onMouseUp={() => setClickedAction(null)}
  onClick={() => {
    setCurrentFile(file);
    setIsDeleteModalOpen(true);
  }}
  style={{
    color: clickedAction === "delete" ? "#E74C3C" : hoveredAction === "delete" ? "#E74C3C" : "",
    transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
    borderRadius: "8px",
    border: clickedAction === "delete" || hoveredAction === "delete" ? "none" : "none",
    background: clickedAction === "delete" || hoveredAction === "delete" ? "#FDEDEC" : "transparent",
    padding: "10px",
    cursor: "pointer",
  }}
>
  <img
    src={clickedAction === "delete" || hoveredAction === "delete" ? "/delete-active-contract.svg" : "/delete.svg"}
    alt="Delete Icon"
    className="document-action-icon"
  />
  <p>Delete</p>
</div>

                </div>
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
        <td colSpan="7" className="no-documents-message-cell">
          <div className="no-documents-container flex flex-col items-center justify-center mr-2rem">
            <img
              src="/no-contracts.svg"
              alt="No Documents Found"
              className="no-documents-icon mb-4"
            />
            <p className="no-documents-message text-center text-gray-500">
              Looks like your Table is empty!
            </p>
          </div>
        </td>
      </tr>
      
      )}
    </tbody>
    
  </table>

</div>
{isPopupVisible && (
  <div className="popup-container-underfolder">
    <div className="popup-content-folder">
      <h2>Oops! Maximum Limit Reached</h2>
      <p>This folder allows a maximum of 5 documents. To upload more, please delete an existing document.</p>
      <button
        className="popup-close-button-folder"
        onClick={() => setIsPopupVisible(false)} 
      >
        Got It
      </button>
    </div>
  </div>
)}



      {loading && <div className="loading-indicator">Deleting documents...</div>}


    <FilterModalForFolder
  isOpen={isFilterModalOpen}
  onClose={() => setIsFilterModalOpen(false)}
  onApply={(filteredFiles, filteredFolders) => {
    setFiles(filteredFiles);
    setFolders(filteredFolders);
    setIsFilterApplied(true); // ✅ Mark filter as applied
  }}
  setFiles={setFiles}
  setFolders={setFolders}
  setIsFilterApplied={setIsFilterApplied} // ✅ Ensure filter status updates
  folderId={folderId}
  src="folder"
/>


<UploadModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUploadSuccess={handleUploadSuccess}
        folderId={folderId} 
        type={"underFolder"} 
        file={file}
      />

      <RenameModal
        isOpen={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
        onRename={handleRename}
        fileName={renameInput}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        fileName={currentFile ? currentFile.name : ""}
      />

      <RenameModal
        isOpen={isRenameFolderModalOpen}
        onClose={() => setIsRenameFolderModalOpen(false)}
        onRename={handleRenameFolder}
        fileName={renameFolderInput}
      />

      <DeleteModal
        isOpen={isDeleteFolderModalOpen}
        onClose={() => setIsDeleteFolderModalOpen(false)}
        onDelete={() => handleDeleteFolder(currentFolder?.id)}
        fileName={currentFolder ? currentFolder.name : ""}
      />
    </div>
  );
};

export default FolderContents;
