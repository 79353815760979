import { googleLogin } from "../services/login";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";

function GoogleSignInButton(props) {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  /*global google*/
  let client;

  const handleGoogleLogin = async (response) => {
    console.log(response, "response");

    try {
      // Call the googleLogin function to get user data (like email)
      const res = await googleLogin(response.code, hostname);

      // Assuming the email is returned in the response (adjust as necessary)
      const email = res.data?.email;
      const username = res.data?.username;
      if (email) {
        localStorage.setItem("email", email); // Save email to localStorage
      }
      if (username) {
        localStorage.setItem("username", username); // Save email to localStorage
      }

      // Continue with login success actions
      props.setVerified(true);
      props.loginMessage();
      navigate("/test");
    } catch (error) {
      console.error("Google login failed", error);
    }
  };

  if (typeof google !== "undefined") {
    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      callback: handleGoogleLogin,
    });
  } else {
    async function wait_for_1_sec() {
      await delay(1000);
      client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        callback: handleGoogleLogin,
      });
    }
    wait_for_1_sec();
  }

  // const hostname = window.location.hostname;
  // const navigate = useNavigate();
  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // /*global google*/
  // let client;
  // if (typeof google != "undefined") {
  //   client = google.accounts.oauth2.initCodeClient({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     scope:
  //       "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  //     callback: async (response) => {
  //       console.log(response, "response")

  //       await googleLogin(response.code, hostname).then((res) => {
  //         props.setVerified(true);
  //         props.loginMessage();
  //         navigate("/uploadFile")
  //       });
  //     },
  //   });
  // } else {
  //   async function wait_for_1_sec() {
  //     await delay(1000);
  //     client = google.accounts.oauth2.initCodeClient({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope:
  //         "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",

  //       callback: async (response) => {
  //         console.log(response, "response")
  //         await googleLogin(response.code, hostname).then((res) => {
  //           props.setVerified(true);
  //           props.loginMessage();
  //           navigate("/uploadFile")
  //         });
  //       },
  //     });
  //   }
  //   wait_for_1_sec();
  // }

  return (
    <button
      id="google-login-btn"
      onClick={() => client.requestCode()}
      className="googlebtn flex items-center justify-center w-full max-w-sm h-[44px] rounded-lg text-white font-semibold text-sm px-4"
      style={{
        display: "flex",
        width: "355px",
        height: "44px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px",
        
      }}
    >

     <img
    src="/googleicon.svg"
    alt="Google icon"
    className="w-6 h-6 md:w-7 md:h-7 mr-12"
  />
  
      
      <div
        className="googleText"
        style={{
          color: "var(--Neutral-1, #3F3F46)",
          
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "normal",
          marginLeft: "-40px",
        }}
      >
        <span>Continue with Google</span>
      </div>
    </button>
  );
}
export default GoogleSignInButton;
