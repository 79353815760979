import React, { useState, useEffect, useRef } from "react";
import "./ChatHistory.css";
import { useNavigate } from "react-router-dom";
const ChatHistory = ({
  isOpen,
  chatHistory,
  setChatHistory,
  onSelectChat,
  onNewChat,
  isLoadingChatHistory,
  activeChatId,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [optionsOpenChatId, setOptionsOpenChatId] = useState(null);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  const navigate = useNavigate();
  // Token is now retrieved from localStorage
  const [token, setToken] = useState(localStorage.getItem("token"));

  // Refs to detect clicks outside the dropdown or edit input
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the options menu if click occurs outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOptionsOpenChatId(null);
      }
      // If editing is active and click is outside the input, commit the change
      if (editingChatId && inputRef.current && !inputRef.current.contains(event.target)) {
        console.log("Click outside detected; committing rename");
        commitRename();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [editingChatId, editingTitle]);

  // Do not render if sidebar is not open
  if (!isOpen) return null;

  // 1. Filter chats by search query
  const filteredChats = (chatHistory || []).filter((chat) =>
    (chat.title || "").toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedChats = [...filteredChats].sort(
    (a, b) => new Date(b.last_message) - new Date(a.last_message)
  );
  // 2. Group chats by date range
  const groupChatsByDate = (chats) => {
    const today = [];
    const yesterday = [];
    const last7Days = [];
    const older = [];
    const now = new Date();

    chats.forEach((chat) => {
      const chatDate = new Date(chat.last_message);
      const diffInTime = now - chatDate;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

      if (diffInDays === 0) {
        today.push(chat);
      } else if (diffInDays === 1) {
        yesterday.push(chat);
      } else if (diffInDays <= 7) {
        last7Days.push(chat);
      } else {
        older.push(chat);
      }
    });

    return { today, yesterday, last7Days, older };
  };

  const groupedChats = groupChatsByDate(sortedChats);

  // 3. Toggle the dropdown menu
  const handleOptionsClick = (e, chatId) => {
    e.stopPropagation();
    setOptionsOpenChatId((prev) => (prev === chatId ? null : chatId));
  };

  // 4. Start editing a chat title (inline)
  const startEditChatTitle = (e, chat) => {
    e.stopPropagation();
    console.log(`Starting edit for chat ${chat.chat_id} with current title: ${chat.title}`);
    setEditingChatId(chat.chat_id);
    setEditingTitle(chat.title);
    // Optionally, close the options menu
    setOptionsOpenChatId(null);
  };

  // 5. Commit the new title (called on Enter or onBlur)
  const commitRename = async () => {
    if (!editingChatId || isSaving) return;
    if (!editingTitle.trim()) {
      console.log("New title is empty; canceling edit");
      setEditingChatId(null);
      return;
    }

    setIsSaving(true);
    const chatId = editingChatId;
    const newTitle = editingTitle.trim();
    // Remove trailing slash if any
    const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}api/v1/copilot/rename-session/`;
    console.log(`Attempting to rename chat ${chatId} to: "${newTitle}"`);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          new_title: newTitle,
        }),
      });

      // Log raw response for debugging
      const text = await response.text();
      console.log("Raw response:", text);
      const data = JSON.parse(text);
      console.log("Parsed JSON response:", data);

      if (response.ok) {
        setChatHistory((prevChats) =>
          prevChats.map((chat) =>
            chat.chat_id === chatId ? { ...chat, title: newTitle } : chat
          )
        );
        console.log(`Chat ${chatId} successfully renamed to "${newTitle}"`);
      } else {
        console.error("Rename failed:", data.msg || "Failed to rename chat.");
      }
    } catch (error) {
      console.error("Error during renaming:", error);
    } finally {
      setEditingChatId(null);
      setIsSaving(false);
    }
  };

  // 6. Delete a chat from the history
  // const handleDeleteChat = async (e, chatId) => {
  //   e.stopPropagation();
  //   console.log(`Attempting to delete chat ${chatId}`);

  //   const confirmDelete = window.confirm("Are you sure you want to delete this chat?");
  //   if (!confirmDelete) {
  //     console.log("Delete canceled by user.");
  //     return;
  //   }

  //   const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}api/v1/copilot/delete-session/`;

  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem("token")}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ chat_id: chatId }),
  //     });

  //     const text = await response.text();
  //     console.log("Raw delete response:", text);
  //     const data = JSON.parse(text);
  //     console.log("Parsed delete response:", data);

  //     if (response.ok) {
  //       setChatHistory((prevChats) => prevChats.filter((chat) => chat.chat_id !== chatId));
  //       console.log(`Chat ${chatId} deleted successfully`);
  //     } else {
  //       console.error("Delete failed:", data.detail || "Failed to delete chat.");
  //     }
  //   } catch (error) {
  //     console.error("Error during deletion:", error);
  //   }
  // };

  const handleDeleteChat = (e, chat) => {
    e.stopPropagation();
    console.log(`Attempting to delete chat ${chat}`);
    setChatToDelete(chat);
  };

  const confirmDeleteChat = async () => {
    if (!chatToDelete) return;

    const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}api/v1/copilot/delete-session/`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ chat_id: chatToDelete.chat_id }),
      });

      const text = await response.text();
      console.log("Raw delete response:", text);
      const data = JSON.parse(text);
      console.log("Parsed delete response:", data);

      if (response.ok) {
        setChatHistory((prevChats) =>
          prevChats.filter((chat) => chat.chat_id !== chatToDelete.chat_id)
        );
        console.log(`Chat ${chatToDelete.chat_id} deleted successfully`);
        if (chatToDelete.chat_id === activeChatId) {
                  onSelectChat(null, []); // clear active chat session
                  onNewChat();

                }
      } else {
        console.error("Delete failed:", data.detail || "Failed to delete chat.");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    } finally {
      // Reset after completion
      setChatToDelete(null);
    }
  };


  // 7. Fetch a particular chat history when a chat item is clicked.
  const handleChatItemClick = async (chatId) => {
    console.log("Chat title clicked, chatId:", chatId);
    const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}api/v1/copilot/get-chat-sessions/${chatId}/`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("Fetched chat session data:", data);
      // Pass the fetched chat history to the parent component (or handle as needed)
      onSelectChat(chatId, data.chat_history);

    } catch (error) {
      console.error("Error fetching chat session:", error);
    }
  };



  // Render a single chat item (handles inline editing)
  const renderChatItem = (chat) => {
    const isEditing = editingChatId === chat.chat_id;
    return (
      <li
        key={chat.chat_id}
        onClick={isEditing ? undefined : () => handleChatItemClick(chat.chat_id)}
        className="chat-item"
      >
        {isEditing ? (
          <input
            ref={inputRef}
            className="chat-item-title editing"
            value={(editingTitle || "").replace(/"/g, "")}
            onChange={(e) => setEditingTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log("Enter pressed; committing rename");
                commitRename();
              } else if (e.key === "Escape") {
                console.log("Escape pressed; canceling edit");
                setEditingChatId(null);
              }
            }}
            // Commit rename on blur (clicking elsewhere)
            onBlur={commitRename}
            autoFocus
          />
        ) : (
          <span className="chat-item-title" title={chat.title || ""}>
            {(chat.title || "").replace(/"/g, "")}
          </span>
        )}

        <img
          src="/options-icon.svg"
          alt="options"
          className="options-icon"
          onClick={(e) => handleOptionsClick(e, chat.chat_id)}
        />
        {optionsOpenChatId === chat.chat_id && (
          <div className="chat-options-menu" ref={dropdownRef}>
            <button onClick={(e) => startEditChatTitle(e, chat)}>
              <img src="rename.svg" alt="rename icon" />
              <span>Rename</span>
            </button>
            <button onClick={(e) => handleDeleteChat(e, chat)}>
              <img src="delete-chat.svg" alt="delete icon" />
              <span>Delete</span>
            </button>
          </div>
        )}
      </li>
    );
  };


  return (
    <div className="chat-historyDiv z-40">
      {/* Header (Search Bar) */}
      <div className="chat-history-header">
        <div className="ChatHistorySearchBox">
          <img src="/search.svg" alt="search icon" />
          <input
            type="text"
            placeholder="Search"
            className="ChatHistorySearchBox-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      {/* <div className="chat-history-body ChatHistory-EmptyContent">
          <h1>No chat history yet!</h1>
          <p className="ml-6">Start a conversation by choosing a prompt or typing a question.</p>
          <img src="ChatHistory-bot-icon.svg" alt="bot icon" className="m-4" />
        </div> */}


      {/* // --- MIDDLE SCROLLABLE PART (Chat List) --- */}
      {isLoadingChatHistory ? (
        <div className="chat-history-body ChatHistory-Loading">
          <div role="status">
            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-pink-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
          </div>


        </div>
      ) : chatHistory.length === 0 ? (
        <div className="chat-history-body ChatHistory-EmptyContent">
          <h1>No chat history yet!</h1>
          <p>Start a conversation by choosing a prompt or typing a question.</p>
          <img src="ChatHistory-bot-icon.svg" alt="bot icon" />
        </div>
      ) : filteredChats.length === 0 ? (
        <div className="chat-history-body ChatHistory-NoResults">
          <h1>No results found</h1>
          <img src="ChatHistory-no-results.svg" alt="No results found icon" />
        </div>
      ) : (
        <div className="chat-history-body">
          {groupedChats.today.length > 0 && (
            <>
              <h2 className="day-heading">Today</h2>
              <ul className="chat-list">
                {groupedChats.today.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
          {groupedChats.yesterday.length > 0 && (
            <>
              <h2 className="day-heading">Yesterday</h2>
              <ul className="chat-list">
                {groupedChats.yesterday.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
          {groupedChats.last7Days.length > 0 && (
            <>
              <h2 className="day-heading">Previous 7 Days</h2>
              <ul className="chat-list">
                {groupedChats.last7Days.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
          {groupedChats.older.length > 0 && (
            <>
              <h2 className="day-heading">Previous 30 Days</h2>
              <ul className="chat-list">
                {groupedChats.older.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
        </div>
      )}



      {/* Footer (New Chat Button) */}
      <div className="chat-history-footer">
        <button className="start-newChat-button" onClick={onNewChat}>
          <img src="/newChat-history-icon.svg" alt="New Chat Icon" />
          <span>New Chat</span>
        </button>
      </div>


      {chatToDelete && (
        <div className="confirm-popup-overlay" style={{ fontFamily: "Outfit" }}>
          <div className="confirm-popup">
            <div className="confirm-delete-text flex flex-col gap-5">
              <strong>Delete chat?</strong>
              <p>This will delete <strong>{chatToDelete.title.replace(/"/g, "")}</strong> from Chat History. You can't undo this action.</p>
            </div>
            <div className="w-full text-right">
              <div className="popup-buttons  ">
                <button className="cancel-btn" onClick={() => setChatToDelete(null)}>Cancel</button>
                <button className="delete-btn" onClick={confirmDeleteChat}>Delete</button>
              </div>
            </div>

          </div>
        </div>
      )}

    </div>
  );
};

export default ChatHistory;
