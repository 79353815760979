    import React, { useState, useEffect, useRef } from "react";
    import { Header } from "../../components/Header/Header";
    import "./accountPage.css";
    import { useNavigate,useLocation } from "react-router-dom";
    import axios from "axios";
    import PlanComparisonPopup from "./PlanComparisonPopup";
  import CancelSubscriptionPopup from "./CancelSubscriptionPopup";
    const AccountPage = () => {
      const [fullName, setFullName] = useState("");
      const [email, setEmail] = useState("");
      const [isEditing, setIsEditing] = useState(false);
      const [isPopupVisible, setIsPopupVisible] = useState(false);
      const popupRef = useRef(null);
      const navigate = useNavigate();
      const [isClicked, setIsClicked] = useState(false);
      const [tempFullName, setTempFullName] = useState("");
      const [subscriptionType, setSubscriptionType] = useState("");
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [uploadedDocsCount, setUploadedDocsCount] = useState(0);
  const [expiresOn, setExpiresOn] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("Grow"); 
  const [isTrialActive, setIsTrialActive] = useState(true);
  const [popupType, setPopupType] = useState("payment");
  const [isPaidYearlyPlan, setIsPaidYearlyPlan] = useState(false);
  const [isYearlyTrial, setIsYearlyTrial] = useState(false);
  const location = useLocation();
  const initialTab = location.state?.defaultTab || "account";
  const [activeTab, setActiveTab] = useState(initialTab);
  const previousSubscriptionType = useRef("");
  const previousPlanType = useRef("");
  const [isActivating, setIsActivating] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);


  const [currencySymbol, setCurrencySymbol] = useState("$"); // Default to USD
  const [countryCode, setCountryCode] = useState(""); // Optional
  const [userIP, setUserIP] = useState("");

  useEffect(() => {
    fetchPlans(); // Fetch currency info when the component mounts
  }, []);


  useEffect(() => {
    if (location.state?.defaultTab) {
      window.history.replaceState({}, document.title); 
    }
  }, []);


      useEffect(() => {
        const storedFullName = localStorage.getItem("fullName") || "John Doe";
        const storedEmail = localStorage.getItem("email") || "example@gmail.com";

        setFullName(storedFullName);
        setEmail(storedEmail);
      }, []);

      const handleFullNameChange = (e) => {
        setFullName(e.target.value);
        localStorage.setItem("fullName", e.target.value);
      };

      const handleEmailChange = (e) => {
        setEmail(e.target.value);
        localStorage.setItem("email", e.target.value);
      };
      const handleUpload = () => {
        alert("Upload option selected");
        setIsPopupVisible(false); 
      };

      const handleDelete = () => {
        alert("Delete option selected");
        setIsPopupVisible(false);
      };
      const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
      };
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (inputRef.current && !inputRef.current.contains(event.target)) {
            handleSave();
            setIsEditing(false);
          }
        };
      
        document.addEventListener("mousedown", handleClickOutside);
      
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [tempFullName]);

      const [upgradeStartDate, setUpgradeStartDate] = useState(null);
  const [upgradeEndDate, setUpgradeEndDate] = useState(null);

      const handleOpenPopup = (planTitle, type = "payment") => {
        setSelectedPlan(planTitle);
        setPopupType(type);

      const isTrial = subscriptionType === "trial" && planType === "trial";
  setIsYearlyTrial(isTrial && quotaType === "yearly");
  const start = new Date();
  const end = new Date(start);

  // Add 1 month or 1 year depending on plan
  if (planTitle === "Grow") {
    if (isYearlyTrial || (!isTrialActive && selectedPlan === "Grow")) {
      end.setFullYear(end.getFullYear() + 1);
    } else {
      end.setMonth(end.getMonth() + 1);
    }
  }
  setUpgradeStartDate(start);
  setUpgradeEndDate(end);
        setIsPopupOpen(true);
      };
      
      const formatPopupDate = (dateObj) => {
        if (!dateObj) return "";
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
      };
      
      
      const handleClosePopup = () => {
        setIsPopupOpen(false);
        setIsProcessingPayment(false);
      };
      
      const trialDocsLimit = 5;
      const paidDocsLimit = 70;
      const totalDocs = isTrialActive ? trialDocsLimit : paidDocsLimit;
      
      const docsUsedPercentage = (uploadedDocsCount / totalDocs) * 100;
      
      const totalTrialDays = 14;
      const getDaysLeft = () => {
        if (!expiresOn) return 0;
        const today = new Date();
        const expiry = new Date(expiresOn);
      
        // Clear hours/minutes for accurate full-day difference
        today.setHours(0, 0, 0, 0);
        expiry.setHours(0, 0, 0, 0);
      
        const diffTime = expiry.getTime() - today.getTime();
        const dayDiff = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        return Math.max(dayDiff, 0);
      };
      
      
      
      const trialDaysLeft = getDaysLeft();
      const trialUsedPercentage = ((totalTrialDays - trialDaysLeft) / totalTrialDays) * 100;
      


      const updateFullName = async (nameToUpdate) => {
        if (!nameToUpdate || !nameToUpdate.trim()) {
          console.log("⚠ No valid name provided. API not called.");
          return;
        }
      
        console.log("🚀 updateFullName called with:", nameToUpdate);
      
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("⚠ No token found. User not authenticated.");
          alert("User is not authenticated. Please log in.");
          return;
        }
      
        console.log("✅ Making API call to update full name...");
        try {
          const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}api/v1/accounts/update-full-name/`,
            { full_name: nameToUpdate }, // Ensure correct name is passed
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
      
          if (response.status === 200) {
            console.log("🎯 Success! API responded with:", response.data);
            setFullName(response.data.full_name);
            localStorage.setItem("fullName", response.data.full_name);
          } else {
            console.log("❌ API response error:", response);
            alert("Failed to update name.");
          }
        } catch (error) {
          console.error("🚨 Error updating name:", error);
          alert("An error occurred while updating the name. Please try again.");
        }
      };

      const [planType, setPlanType] = useState("");
  const [quotaType, setQuotaType] = useState("");
      
      const fetchSubscriptionStatus = async () => {
        const token = localStorage.getItem("token");
        if (!token) return;
      
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/v1/accounts/get-subscription-status/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
      
          if (response.status === 200) {
            const { uploaded_docs_count, expires_on , subscription_type, plan_type, quota } = response.data;
            setSubscriptionType(subscription_type);
            setPlanType(plan_type);
            setQuotaType(quota);
            setUploadedDocsCount(uploaded_docs_count);
            setExpiresOn(expires_on);
            previousSubscriptionType.current = subscription_type;
            previousPlanType.current = plan_type;
            const isTrial = !(subscription_type === "paid_subs" && plan_type === "monthly");
            setIsTrialActive(isTrial);
      
            // 👇 Add this line
            setIsPaidYearlyPlan(subscription_type === "paid_subs" && plan_type === "yearly");
            const isActualTrial = subscription_type === "trial" && plan_type === "trial";
            setIsYearlyTrial(isActualTrial && quota === "yearly");
          }
        } catch (error) {
          console.error("Failed to fetch subscription status:", error);
        }
      };
      
      
      const loadRazorpayScript = () => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          document.body.appendChild(script);
        });
      };

      
      const initializeRazorpay = async (subscriptionId) => {
        const isScriptLoaded = await loadRazorpayScript();
        if (!isScriptLoaded) {
          alert("Razorpay SDK failed to load.");
          return;
        }
      
        const userName = localStorage.getItem("fullName") || "User";
        const userEmail = localStorage.getItem("email") || "user@example.com";
        const userContact = localStorage.getItem("user_contact") || "0000000000";
      
        const options = {
          key: "rzp_test_SbFHwwSZNNf7SE", // Replace with your live key for production
          subscription_id: subscriptionId,
          name: "DocuSensa",
          description: `${selectedPlan} Plan Subscription`,
          image: "/DocuSensaLogo.png",
          callback_url: window.location.origin + "/account", // Redirect after payment
          prefill: {
            name: userName,
            email: userEmail,
            contact: userContact,
          },
          theme: { color: "#C95EBE" },
          handler: async function (response) {
            console.log("Payment Success", response);
            setIsProcessingPayment(false); 
            setIsPopupOpen(false);
            setIsActivating(true);
          
            let retryCount = 0;
            const maxRetries = 50; // poll for ~30 seconds
            const delay = 9000; // poll every 3 seconds
          
            const token = localStorage.getItem("token");
          
            const pollingMessage = document.createElement("div");
            pollingMessage.innerText = "Please wait, your payment is being processed. Your new features will be available shortly...";
            pollingMessage.className = "payment-processing-message";
            setIsActivating(true);
            const pollSubscription = async () => {
              try {
                const result = await axios.get(
                  `${process.env.REACT_APP_API_URL}api/v1/accounts/get-subscription-status/`,
                  {
                    headers: {
                      Authorization: `Token ${token}`,
                    },
                  }
                );
            
                const data = result.data;
            
                const isNowPaid = data.subscription_type === "paid_subs";
                const isYearly = data.plan_type === "yearly";
                const isMonthly = data.plan_type === "monthly";
            
                const prevType = previousSubscriptionType.current;
  const prevPlan = previousPlanType.current;

  const upgradedToMonthly =
    prevType === "trial" && selectedPlan === "Grow" && isNowPaid && isMonthly;

  const upgradedToYearlyFromTrial =
    prevType === "trial" && selectedPlan === "Grow" && isNowPaid && isYearly;

  const upgradedToYearlyFromMonthly =
    prevType === "paid_subs" && prevPlan === "monthly" && selectedPlan === "Grow" && isNowPaid && isYearly;

  const expectedUpgrade = upgradedToMonthly || upgradedToYearlyFromTrial || upgradedToYearlyFromMonthly;
  console.log("Upgrade path:", {
    prevType,
    prevPlan,
    newType: data.subscription_type,
    newPlan: data.plan_type,
    upgradedToMonthly,
    upgradedToYearlyFromTrial,
    upgradedToYearlyFromMonthly,
  });

            
                if (result.status === 200 && isNowPaid && expectedUpgrade) {
                  setIsActivating(false);
  setShowGetStarted(true);

                } else {
                  retryCount++;
                  if (retryCount < maxRetries) {
                    setTimeout(pollSubscription, delay);
                  } else {
                    pollingMessage.innerText =
                      "Still processing... please refresh the page after a few moments.";
                  }
                }
              } catch (err) {
                console.error("Polling error:", err);
                retryCount++;
                if (retryCount < maxRetries) {
                  setTimeout(pollSubscription, delay);
                } else {
                  pollingMessage.innerText =
                    "Still processing... please refresh the page after a few moments.";
                }
              }
            };
            
          
            fetchSubscriptionStatus(); // Refresh immediately before polling
            setTimeout(() => {
              pollSubscription();
            }, 30000); 
          },
          
          modal: {
            escape: false,
            ondismiss: function () {
              console.log("User closed Razorpay.");
              setIsPopupOpen(false);
            },
          },
        };
      
        const rzp = new window.Razorpay(options);
        rzp.open();
      };

      const fetchPlans = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/v1/accounts/get-plans/`
          );
          if (response.status === 200) {
            const { ip_address, currency, country_code } = response.data;
            setUserIP(ip_address);
            setCountryCode(country_code);
      
            if (currency === "INR") {
              setCurrencySymbol("₹");
            } else {
              setCurrencySymbol("$");
            }
          }
        } catch (error) {
          console.error("Error fetching plans:", error);
        }
      };
      

      const handleUpgrade = async () => {
        const token = localStorage.getItem("token");
      
        if (!token || !selectedPlan) {
          console.log("⚠️ Missing token or selected plan.");
          return;
        }
      
        try {
          const isTrialToYearly = isYearlyTrial && selectedPlan === "Grow";
          const isMonthlyPaidToYearly = !isTrialActive && selectedPlan === "Grow";
      
          const endpoint = isMonthlyPaidToYearly
            ? `${process.env.REACT_APP_API_URL}api/v1/accounts/plan-upgrade-subscription/`
            : `${process.env.REACT_APP_API_URL}api/v1/accounts/trial-upgrade-subscription/`;
          setIsProcessingPayment(true);
          const response = await axios.post(
            endpoint,
            { plan_type: selectedPlan === "Grow" ? "yearly" : "monthly" },
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
      
          console.log("🟢 Initial upgrade response:", response.data);
      
          // Case 1: Got subscription_id immediately
          if (response.data.subscription_id) {
           
            console.log("✅ Subscription ID ready:", response.data.subscription_id);
            
            initializeRazorpay(response.data.subscription_id);
            return;
          }
      
          // Case 2: Got local_id — start polling
          if (response.data.local_id) {
            const localId = response.data.local_id;
            
      
            const maxRetries = 30;
            const delay = 8000; // 3 seconds
            let attempts = 0;
      
            const pollForSubscriptionId = async () => {
              try {
                console.log(`🔁 Polling attempt ${attempts + 1} for local_id: ${localId}`);
                const result = await axios.get(
                  `${process.env.REACT_APP_API_URL}api/v1/accounts/get-subscription-status/?local_id=${localId}`,
                  {
                    headers: {
                      Authorization: `Token ${token}`,
                    },
                  }
                );
      
                console.log("📦 Polling response:", result.data);
      
                if (result.data.subscription_id) {
                  console.log("🎉 Subscription ID found after polling:", result.data.subscription_id);
                  initializeRazorpay(result.data.subscription_id);
                } else {
                  attempts++;
                  if (attempts < maxRetries) {
                    setTimeout(pollForSubscriptionId, delay);
                  } else {
                    console.log("⏰ Max polling attempts reached. No subscription_id yet.");
                  }
                }
              } catch (error) {
                console.error("❌ Polling error:", error);
                attempts++;
                if (attempts < maxRetries) {
                  setTimeout(pollForSubscriptionId, delay);
                } else {
                  console.log("⛔ Max retries hit with errors.");
                }
              }
            };
      
            pollForSubscriptionId();
          }
        } catch (error) {
          console.error("🚨 Error in handleUpgrade:", error);
        }
      };
      
      
      

      
      


      
      
      
      const handleSave = () => {
        if (!tempFullName.trim()) {
          alert("Full Name cannot be empty.");
          return;
        }
      
        setFullName(tempFullName); // Set full name only when save is clicked
        localStorage.setItem("fullName", tempFullName);
      
        updateFullName(tempFullName); // Pass tempFullName explicitly
        setIsEditing(false);
      };
      
      
      

      const handleResetPassword = () => {
        navigate("/request-password-reset"); 
      };
      const spanRef = useRef(null);
  
      

    const handleDeleteAccount = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("User is not authenticated. Please log in.");
        return;
      }

      const confirmed = window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      );
      if (!confirmed) return;

      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}api/v1/accounts/delete-account/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Check if response status is 200
        if (response.status === 200) {
          alert(response.data.message); // Show success message
          localStorage.clear(); // Clear user data from localStorage
          navigate("/signin"); // Redirect to sign-in page
        } else {
          alert("Failed to delete account. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("An error occurred while trying to delete your account.");
      }
    };

    const handleLogout = () => {
        localStorage.removeItem("full_name");
        localStorage.removeItem("email");
        localStorage.removeItem("username");
        localStorage.removeItem("token");
        localStorage.clear();
        sessionStorage.clear();
        navigate("/signin"); 
        window.location.reload(); 
      };
      
      const inputRef = useRef(null);
      const formatDate = (isoDate) => {
        if (!isoDate) return "";
        const date = new Date(isoDate);
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      };
      
      const formattedExpiryDate = formatDate(expiresOn);
      
    
      const [isDeleteMode, setIsDeleteMode] = useState(false);
      
      useEffect(() => {
        if (activeTab === "billing") {
          fetchSubscriptionStatus();
        }
      }, [activeTab]);

      const [showCancelPopup, setShowCancelPopup] = useState(false);


      const handleCancelSubscription = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("User not authenticated. Please log in.");
          return;
        }
      
      
      
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/accounts/cancel-subscription/`,
            {},
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
      
          if (response.status === 200) {
          
            navigate("/test"); // Navigate to /test page
          } else {
            alert("Cancellation failed. Please try again.");
          }
        } catch (error) {
          console.error("Error cancelling subscription:", error);
          alert("An error occurred while cancelling your subscription.");
        }
      };
      
      // useEffect(() => {
      //   const handleClickOutside = (event) => {
      //     if (
      //       (inputRef.current && inputRef.current.contains(event.target)) || 
      //       (spanRef.current && spanRef.current.contains(event.target)) // Ignore clicks on the span
      //     ) {
      //       return;
      //     }
        
      //     console.log("Clicked outside. Exiting edit mode...");
      //     if (isEditing) {
      //       setIsEditing(false);
      //     }
      //     // Exit edit mode but DO NOT call API
      //   };
      
      //   document.addEventListener("mousedown", handleClickOutside);
      
      //   return () => {
      //     document.removeEventListener("mousedown", handleClickOutside);
      //   };
      // }, [isEditing]);
      


      const handleEditClick = () => {
        setIsEditing(true);
        setTempFullName(fullName);
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 100);
      };
      
      const formatPopupPrice = (usd, inr) => {
        return currencySymbol === "₹" ? `₹${inr}` : `$${usd}`;
      };
      

      const handleDeleteAccountClick = () => {
        setIsDeleteMode(true);
        setActiveTab("delete");
      };
      
      return (
        <div>
          <div className="">
            <Header name="Account" icon={""} />
          </div>
          <div className="account-box-area-profile flex">
          <div className="flex flex-col items-center">
      {/* Right Side Border */}

      {/* Sidebar Options */}
      <div className="mt-6 flex flex-col space-y-4">
      
      <button
              className={`account-sidebar-option ${activeTab === "account" ? "" : "inactive"}`}
              onClick={() => {
                setActiveTab("account");
                setIsDeleteMode(false); // Reset delete mode when switching back
              }}
            >
              Account
            </button>
            <button
    className={`account-sidebar-option ${activeTab === "billing" ? "" : "inactive"}`}
    onClick={() => {
      setActiveTab("billing");
      setIsDeleteMode(false); 
      fetchSubscriptionStatus();
    }}
  >
    Billing
  </button>

            <p
    className={`delete-sidebar-option ${activeTab === "delete" ? "active" : ""}`}
    onClick={handleDeleteAccountClick}
  >
    Delete Account
  </p>

      </div>
    </div>
    <div className="sidebar-divider-account"></div>
    {activeTab === "account" && !isDeleteMode ? (


              <div>
            <h2 className="personal-info-account">Personal Information’s</h2>
            {/* <div className="flex gap-8">
              <div className="circle-account-profile cursor-pointer">
                <span className="account-name-profile">
                  {fullName ? fullName[0] : "U"}
                </span>

                <div className="edit-icon-account" onClick={togglePopup}>
      <img src="/edit-icon-account.svg" alt="Edit Icon" />
    </div>


                {isPopupVisible && (
                  <div className="popup-container">
                    <div className="popup-option" onClick={handleUpload}>
                      <img
                        src="/upload-icon.svg"
                        alt="Upload Icon"
                        className="popup-icon"
                      />
                      <span>Upload</span>
                    </div>
                    <div className="popup-option" onClick={handleDelete}>
                      <img
                        src="/delete.svg"
                        alt="Delete Icon"
                        className="popup-icon"
                      />
                      <span>Delete</span>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className="flex">
            <div className="flex flex-col">
                <label htmlFor="fullName" className="Full-Name-Account">
                  Full Name
                </label>
                <div className="relative">
                  <input
                    id="fullName"
                    type="text"
                    value={fullName}
                    onChange={handleFullNameChange}
                    className="namehere-account h-12 px-5 py-3 rounded-lg border border-gray-200 bg-white font-outfit text-[16px] font-bold text-gray-600"
                    disabled={!isEditing}
                  />
                  <img
                    src={isEditing ? "/edit-icon-account.svg" : "/edit-icon-account.svg"}
                    alt="Edit"
                    className="absolute right-3 top-6 w-4 h-4 cursor-pointer"
                    onClick={() => (isEditing ? updateFullName() : setIsEditing(true))}
                  />
                </div>
              </div>

              <div className="flex flex-col email-container">
                <label htmlFor="email" className="Full-Name-Account">
                  Email ID
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="namehere-account"
                />
              </div>
            </div> */}
            <div className="account-profile-border-box">
            <div className="circle-account-profile cursor-pointer">
                <span className="account-name-profile">
                  {fullName ? fullName[0] : "U"}
                </span>

                {/* <div className="edit-icon-account" onClick={togglePopup}>
      <img src="/edit-icon-account.svg" alt="Edit Icon" />
      
    </div> */}

    </div>
    <div className="profile-text-container">
            <h2 className="full-name-text">{fullName}</h2>
            <p className="email-text">{email}</p>
          </div>
            </div>
            <div className="account-profile-section">
          {/* Header Row (Labels) */}
          <div className="account-profile-header">
            <span className="account-profile-label">Full Name</span>
            <span className="account-profile-label-email">Email Address</span>
            <span className="account-profile-label"></span> 
          </div>

          <div className="account-profile-data">
    {isEditing ? (
      <>
        {/* Editable Input Field */}
        <input
          type="text"
          className="account-profile-value-fullname"
          value={tempFullName}
          onChange={(e) => setTempFullName(e.target.value)} // Update tempFullName
          ref={inputRef}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateFullName();
            }
          }}
        />
  {/* <button
    type="button"
    className="edit-icon-container"
    onClick={(event) => {
      event.preventDefault(); // Prevents unintended behavior
      event.stopPropagation(); // Stops parent elements from blocking click
      console.log("🖱 Save button onClick triggered!");
      handleSave();
      updateFullName();
    }}
    onMouseDown={(event) => event.stopPropagation()} // Prevents mousedown from closing editing mode
  >
    Save
  </button> */}





      </>
    ) : (
      <>
        {/* Display Full Name */}
        <span ref={spanRef} className="account-profile-value-fullname">
          {fullName}
        </span>

        {/* Edit Button (Only Show if Not Editing) */}
        {!isEditing && (
    <div className="edit-icon-container" onClick={handleEditClick}>
      <span className="edit-text">Edit</span>
      <img
        src="/edit-icon-account.svg"
        alt="Edit Icon"
        className="edit-icon-account-fullname"
      />
    </div>
  )}

      </>
    )}
        <span className="account-profile-value">{email}</span>

  </div>



        </div>
        <div className="account-profile-section-another">
        <div>
              <h2 className="account-password-reset-heading">Reset Password</h2>

              <p className="account-password-reset-heading2">Easily reset your password</p>
            </div>
            <button className="account-button-profile-reset" onClick={handleResetPassword}>Reset password</button>
        </div>
        
          

          {/* <div className="flex">
            <div>
              <h2 className="personal-info-account-password">
                Delete Your Account
              </h2>
              <p className="personal-info-account-secure">
                Permanently delete your account
              </p>
            </div>
            <button className="account-button-profile-delete-account" onClick={handleDeleteAccount}>
              Delete Account
            </button>
          </div> */}
          <div>
          <img
                        src="/logout-icon-account.svg"
                        alt="Delete Icon"
                        className="logout-icon-account"
                      />
            <p className="logout-account-btton cursor-pointer" onClick={handleLogout}>Logout</p>
          </div>
          </div>
          ) : activeTab === "billing" ? (
            <div className="billing-wrapper">
              {!isPaidYearlyPlan && (
            <div className="billing-upgrade-banner">
              <div className="billing-upgrade-left">
                <h3 className="billing-upgrade-title">
    {isTrialActive ? "Maximize Your Potential! 🚀" : "Ready for Even More? 🚀"}
  </h3>

  <p className="billing-upgrade-subtitle">
    {isTrialActive ? (
      "Your document limit is almost full! Get unlimited uploads, AI-powered search, and premium features by upgrading"
    ) : (
      <>
        Upgrade to the Grow Plan for Unlimited Access and <br /> Advanced Features!
      </>
    )}
  </p>


              </div>
              <button
    className="billing-upgrade-btn"
    onClick={() =>
      handleOpenPopup("Grow", isTrialActive ? "payment" : "info")
    }
  >
    {isTrialActive ? "Upgrade Now" : "Learn More"}{" "}
    <span className="billing-upgrade-arrow">
      <img src="/billing-upgrade-arrow.svg" alt="arrow" />
    </span>
  </button>


  {isTrialActive ? (
    <div className="billing-upgrade-image">
      <img src="/Illustration-1.svg" alt="Upgrade illustration" />
    </div>
  ) : (
    <div className="mt-4">
      <img src="/Illustration-2.svg" alt="Post-trial illustration" />
    </div>
  )}

            </div>
              )}
          <div className="mt-2"><h className="billing-plan-box-title">Selected Plan </h></div>
            <div className="billing-plan-box">
              <div className="billing-plan-header">
              <h4 className="billing-plan-title">
    {isYearlyTrial
      ? "Grow Plan (Trial)"
      : isPaidYearlyPlan
      ? "Grow Plan"
      : isTrialActive
      ? "Starter Plan (Trial)"
      : "Starter Plan"}
  </h4>

                <div className="billing-trial-days-left">
    <img src="/billing-hourglass.svg" alt="hourglass icon" className="billing-trial-icon" />
    {trialDaysLeft} days left
  </div>
              </div>
          
              <div className="billing-plan-details">
              <div className="billing-price">
    {isYearlyTrial
      ? currencySymbol === "₹" ? "₹17,032.00" : "$199.00"
      : isPaidYearlyPlan
      ? currencySymbol === "₹" ? "₹17,032.00" : "$199.00"
      : isTrialActive
      ? currencySymbol === "₹" ? "₹3,338.00" : "$39.99"
      : currencySymbol === "₹" ? "₹3,338.00" : "$39.00"}
    <span className="billing-per-month">
      {isYearlyTrial
        ? "/year after trial"
        : isPaidYearlyPlan
        ? "/year"
        : isTrialActive
        ? "/month after trial"
        : "/month"}
    </span>
  </div>



                <div className="billing-feature-columns">
      <ul className="billing-feature-list">
      <li>
    Upload Limit:{" "}
    <strong>
      <span className="billing-trial-note-highlights">
        {isPaidYearlyPlan
          ? "Unlimited Document Uploads"
          : isTrialActive
          ? "5 documents"
          : "70 documents"}
      </span>
    </strong>
  </li>

      
  {isPaidYearlyPlan ? (
    <li>Billed Yearly</li>
  ) : isTrialActive ? (
    <li>
      Trial Duration:{" "}
      <strong>
        <span className="billing-trial-note-highlights">14 days</span>
      </strong>{" "}
      <span className="billing-trial-note">
        (Auto billing starts on <strong>{formattedExpiryDate}</strong>)
      </span>
    </li>
  ) : (
    <li>
      Billed Monthly
      <span className="billing-trial-note">
        (Next billing on <strong>{formattedExpiryDate}</strong>)
      </span>
    </li>
  )}

  <li>
    {isPaidYearlyPlan || isYearlyTrial
      ? "Chat with 10 documents at a time"
      : isTrialActive
      ? "With AI copilot and AI search on knowledgebase"
      : "Chat with 5 documents at a time"}
  </li>


      </ul>
      <ul className="billing-feature-list">
      <li>AI Queries: <strong><span className="billing-trial-note-highlights">Unlimited</span></strong></li>

      <li>
    Maximum File Size:{" "}
    <strong>
      <span className="billing-trial-note-highlights">
      {isPaidYearlyPlan || isYearlyTrial ? "50MB per document" : "25MB per document"}

      </span>
    </strong>
  </li>
      
  <li>
    {isPaidYearlyPlan || !isTrialActive
      ? "Advanced Data Extraction"
      : "With Advanced Data Extraction"}
  </li>

      </ul>
    </div>
    {!isPaidYearlyPlan && (
    <div className="billing-bottom-cta">
    <a href="#" className="billing-upgrade-link">
      Need more? <strong onClick={() =>
      handleOpenPopup("Grow", isTrialActive ? "payment" : "info")
    }>Upgrade your plan</strong>
    </a>
    <button className="billing-cancel-btn"  onClick={() => setShowCancelPopup(true)}>Cancel Subscription</button>
  </div>
    )}

  {isPaidYearlyPlan && (
    <p className="billing-next-billing-text ml-8" style={{ marginTop: "12px" }}>
      Next billing on <strong>{formattedExpiryDate}</strong>
    </p>
  )}

              </div>
            </div>
            {!isPaidYearlyPlan && (
    <>
            <div className="mt-4"> <h className="billing-usage-box-headings">Usage</h></div>

            {isTrialActive ? (
            <div className="billing-usage-box mt-2" style={{ height: "120px" }}>
    <div className="billing-usage-grid">
      {/* Left Side: Progress Bars */}
      <div className="billing-usage-left">
      <div className="billing-usage-row">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <span>Uploaded Documents</span>
      <span className="date-billing">{uploadedDocsCount} / {totalDocs}</span>
      </div>
      <div className="billing-usage-bar">
        <div className="billing-usage-fill" style={{ width: `${docsUsedPercentage}%` }}></div>
      </div>
    </div>

    <div className="billing-usage-row">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
    <span>Trial Ends In</span>
    <span className="date-billing" style={{ marginLeft: "auto" }}>
      {trialDaysLeft} / {totalTrialDays} days
    </span>
    </div>
    <div className="billing-usage-bar">
      <div className="billing-usage-fill" style={{ width: `${trialUsedPercentage}%` }}></div>
    </div>
  </div>

      </div>

      {/* Right Side: Trial + Warning */}
      <div className="billing-usage-right">
    
        <p className="billing-limit-warning">You're close to your limit. Upgrade to avoid interruptions</p>
      </div>
    </div>
  </div>
  ) : (
    <div className="billing-usage-box mt-2" style={{ height: "120px" }}>
    <div className="billing-usage-grid-month">
      {/* Left Side: Progress Bars */}
      <div className="billing-usage-left">
      <div className="billing-usage-row">
      <span>Uploaded Documents</span>
      <span className="date-billing-month">{uploadedDocsCount} / {totalDocs}</span>
      <div className="billing-usage-bar-month">
        <div className="billing-usage-fill" style={{ width: `${docsUsedPercentage}%` }}></div>
      </div>
    </div>

    
      </div>

      {/* Right Side: Trial + Warning */}
      <div className="billing-usage-right">
    
        <p className="billing-limit-warning-month">You're close to your limit. Upgrade to avoid interruptions</p>
      </div>
    </div>
  </div>
  )}
  </>
  )}




          </div>
          
          
            ) : (
              <div className="delete-account-container">
                    <img
                        src="/delete-account-icon.svg"
                        alt="Delete Icon"
                        className="delete-account-icon"
                      />
                      <h className="delete-account-icon-header">Are you sure you Want to delete your Account Permanently</h>
                      <p className="delete-account-icon-para">by doing this your Account will be Deleted permanently and you will not be able to recover your Account anymore</p>
                      <div className="delete-account-buttons">
    <button className="account-button-profile-delete-account" onClick={handleDeleteAccount}>
      Delete Account
    </button>
    <button
    className="account-button-profile-cancel"
    onClick={() => {
      setIsDeleteMode(false);  // Exit delete mode
      setActiveTab("account"); // Switch sidebar tab back to Account
    }}
  >
    Cancel
  </button>

  </div>

            </div>
          )}
          </div>
          
  {activeTab === "billing" && isPaidYearlyPlan && (
    
    <div className="enterprise-plan-card">
    <p className="coming-soon-label">Coming Soon For You</p>
    <div className="enterprise-card-box">
      <h2 className="enterprise-title">Enterprise</h2>
      <p className="enterprise-subtitle">Ideal for Organisations</p>
      <hr className="enterprise-divider" />

      <div className="enterprise-features">
        <div className="enterprise-includes mt-2">
          <span className="feature-tag">
            <img src="/check-icon-billing-star.svg" alt="sparkle" />
            What's Included
          </span>
        </div>

        <ul className="enterprise-feature-list">
          <li>
            <img src="/doc-icon-billing.svg" alt="doc" className="w-[20px] h-[20px]"/>
            Includes All features of Grow Plan.
          </li>
          <li className="ml-[30px]">
            <img src="/lock-icon-billing.svg" alt="lock" className="w-[20px] h-[20px]"/>
            SSO and SAML
          </li>
          <li className="ml-[-40px]">
            <img src="/user-icon-billing.svg" alt="user"className="w-[20px] h-[20px]" />
          <span> Dedicated Customer Success Manager</span>
          </li>
          <li className="integration-row">
            <img src="/integrations-icon-billing.svg" alt="integrations" className="w-[20px] h-[20px]"/>
          <span className="pl-1"> Integrations with{" "}</span>
            <span className="integration-links pl-4 ">
              SFDC, Hubspot, Oracle NetSuite, QuickBooks, Pandadoc, Docusign
            </span>
          </li>
        </ul>

        <button className="contact-sales-btn">
          Contact Sales
          <img src="/call-new-icon.svg" alt="phone" />
        </button>
      </div>
    </div>
  </div>

  )}
          {isPopupOpen && (
    popupType === "payment" ? (
    <div className="custom-popup-overlay">
      <div className="popup-content" style={{ width: "484px" }}>
        <span className="close-btn" onClick={handleClosePopup}>
          &times;
        </span>

        <h2 className="custom-popup-title-billing">
          {selectedPlan === "Start"
            ? "Start Your 14-Day Free Trial Today!"
            : selectedPlan === "Grow"
            ? "Confirm Your Plan Upgrade"
            : "Get Started with Your Plan"}
        </h2>

        <p className="custom-popup-subtext-billing">
          {selectedPlan === "Grow"
            ? "You're upgrading to the full version of your plan. Enjoy all premium features!"
            : "Review your selection and start enjoying premium features right away"}
        </p>

        
        <div className="popup-plan-details"style={{ width: "434px" }}>
          <div className="popup-plan-header">
            <span className="popup-free">Unlock Full Benefits</span>
            <span className="popup-free-monthly">Billed monthly</span>
          </div>
          <div>
            <div className="popup-plan-info">
              <div className="popup-plan-column ml-4">
                <p className="popup-title">Plan</p>
                <p className="popup-value">{!isTrialActive ? "Grow Plan" : "Start Plan"}</p>
              </div>
              <div className="popup-plan-column"style={{ marginLeft: "94px" }}>
                <p className="popup-title">Price</p>
                <p className="popup-value">
    {isYearlyTrial
      ? formatPopupPrice("199.00", "17,032.00")
      : !isTrialActive
      ? formatPopupPrice("199.00", "17,032.00")
      : formatPopupPrice("39.00", "3,338.00")}
    <sub>{isYearlyTrial ? "/year" : !isTrialActive ? "/year" : "/month"}</sub>
  </p>


              </div>
              <div className="popup-plan-column ml-4">
                <p className="popup-title">Start Date</p>
                <p className="popup-value">{formatPopupDate(upgradeStartDate)}</p>
              </div>
              <div className="popup-plan-column"style={{ marginLeft: "94px" }}>
                <p className="popup-title">End Date</p>
                <p className="popup-value">{formatPopupDate(upgradeEndDate)}</p>
              </div>
            </div>
            <p className="popup-extra-text ml-2 flex items-center">
    What's included
    <div className="tooltip-wrapper relative group ml-1">
      <img
        src="/check-icon-billing.svg"
        alt="check icon"
        className="w-4 h-4 cursor-pointer"
      />
      <div className="tooltip-popup absolute hidden group-hover:flex flex-col gap-2 top-[-109px] left-[-10px] w-[224px] h-[108px] bg-white p-2 rounded-xl shadow-md z-50">
      <div className="flex items-start gap-2">
    <img src="/check-icon-billing-tick.svg" className="w-7 h-7" />
    <p className="check-icon-billing-para">
      {isTrialActive ? "Upload 70 documents/month" : "Unlimited Document Uploads"}
    </p>
  </div>
  <div className="flex items-start gap-2">
    <img src="/check-icon-billing-tick.svg" className="w-7 h-7" />
    <p className="check-icon-billing-para">
      Unlimited AI Queries
    </p>
  </div>
  <div className="flex items-start gap-2">
    <img src="/check-icon-billing-tick.svg" className="w-7 h-7" />
    <p className="check-icon-billing-para">
      {isTrialActive ? "Maximum file size: 25MB" : "Maximum file size: 50MB"}
    </p>
  </div>

      </div>
    </div>
  </p>

          </div>
        </div>

        <p className="popup-terms">
          By clicking below, you agree to our{" "}
          <strong>Terms of Service</strong> and{" "}
          <strong>Privacy Policy</strong>.
        </p>
        <div className="popup-button" onClick={handleUpgrade}>
        <p className="popup-button-payment">
  {isProcessingPayment
    ? "Please wait ⏳ Redirecting to Razorpay..."
    : "Confirm Upgrade"}
</p>

          <p>Review Plan Details, in case they want to double-check the benefits or cost</p>
        </div>

        <p className="popup-payment-note">
          You’ll be securely redirected to Razorpay to complete your card
          setup.
        </p>
      </div>
    </div>
  ) : (
    <PlanComparisonPopup onClose={handleClosePopup}   currencySymbol={currencySymbol} onUpgradeClick={handleOpenPopup} />

  )
  )}

  {showCancelPopup && (
    <CancelSubscriptionPopup
      onClose={() => setShowCancelPopup(false)}
      onConfirm={handleCancelSubscription}
    />
  )}

  {/* ACTIVATION IN PROGRESS POPUP */}
  {isActivating && (
    <div className="activation-overlay">
       <img src="/leftside-celebration.svg" alt="celebration left"  style={{
    position: "absolute",
    top: "50%",
    left: "32rem",
    transform: "translateY(-50%)",
   
    zIndex: 1,
  }} />

{/* Right side image */}
<img src="/right-side-celebration.svg" alt="celebration right"  style={{
   position: "absolute",
   top: "50%",
   left: "49rem",
   transform: "translateY(-50%)",
  
   zIndex: 1,
  }} />
      <div className="activation-popup">
        <img src="/celebrate-icon.svg" alt="confetti" className="confetti-icon" />
       
        <h2>Welcome on board!</h2>
        <p>
          ✨ We’re setting up your premium features...
          <br />
          Hang tight, this will take just a moment!
        </p>
        <button disabled className="activating-btn">Activating your account...</button>
      </div>
    </div>
  )}

  {/* ACTIVATION COMPLETE POPUP showGetStarted && */}
  {showGetStarted && (
    <div className="activation-overlay">
       <img src="/leftside-celebration.svg" alt="celebration left"  style={{
    position: "absolute",
    top: "50%",
    left: "32rem",
    transform: "translateY(-50%)",
   
    zIndex: 1,
  }} />

{/* Right side image */}
<img src="/right-side-celebration.svg" alt="celebration right"  style={{
   position: "absolute",
   top: "50%",
   left: "49rem",
   transform: "translateY(-50%)",
  
   zIndex: 1,
  }} />
      <div className="activation-popup">
        <img src="/celebrate-icon.svg" alt="confetti" className="confetti-icon" />
        <h2>You're all set!</h2>
        <p>
          Ready to unlock the full potential of your documents?
          <br />
          
        </p>
        <button
          className="activating-btn"
          onClick={() => {
            setShowGetStarted(false);
            window.location.href = "/ai-Copilot";
          }}
        >
          Get Started Now
        </button>
      </div>
    </div>
  )}


        </div>

      );
    };

    export default AccountPage;
