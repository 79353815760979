import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { SiMicrosoftexcel } from "react-icons/si";
import StatusModal from "../../UploadFile/StatusModal";
import DocuSensaLogo from "../../../Images/DocuSensaLogo.png";

const UploadExcel = () => {

  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];
      const fileType = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];

      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(selectedFile);  // Changed to readAsArrayBuffer
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const upload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    // console.log("formData", formData);
    // console.log("e", e.target.files[0]);
    let file = null;
    handleFile(e)
      .then((res) => {
        file = res;
        // file now contains the ArrayBuffer from handleFile
      })
      .catch((error) => {
        console.error("Error reading file:", error);
        return;
      });

    try {
      setLoading(true);
      setStatus("Uploading...")
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + "api/v1/excel/upload-excel/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // console.log("res", res.data);
          localStorage.setItem("excelkey", res.data.excel_key);
          // const data = {
          //   summary: res.data.data.summary,
          //   examples: res.data.data.examples,
          // };
          navigate("/excel-chat", {
            state: {
              excelfile: e.target.files[0],
              summary: res.data.data.summary,
              questions: res.data.data.examples,
            },
          });
        });
      setLoading(false);
    } catch (err) {
      if (
        err.response.data.error ==
        "File extension “pdf” is not allowed. Allowed extensions are: xlsx, csv."
      ) {
        toast.warn("Only excel files are allowed", {
          autoClose: 2000,
        });
      }
      // console.log("err", err);
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-900 h-screen w-full ">
      {/* {loading && (
        <div className="fixed top-0 left-0 w-full h-full z-50 overflow-auto bg-black bg-opacity-70 flex justify-center items-center">
          <div className="loader w-16 h-16 border-t-4 border-b-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      )} */}
      <div className="w-10/12 m-auto pt-16">
        <div className="flex justify-center">
          <img src={DocuSensaLogo} alt="logo" className="h-12 md:h-24 " />
        </div>
        <div className="mt-2 flex md:flex-row flex-col text-center items-center mb-8">
          <h1 className="w-full font-extrabold  text-4xl  md:text-8xl text-white">
            Upload Excel File
          </h1>
        </div>

        <div className=" border border-white"></div>

        <div className="mt-4">
          <div className="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-80 border-2 border-dashed rounded-lg cursor-pointer bg-gray-800  border-white  bg-clip-bg bg-gradient-to-r from-purple-700 to-red-400 hover:from-red-400 hover:to-purple-700"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <SiMicrosoftexcel color="White" fontSize={200} />
                <p className="mt-2 mb-2 text-lg text-white">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-sm text-white">Supported excel</p>
              </div>
              <input
                onChange={upload}
                //   onClick={openModal}
                id="dropzone-file"
                type="file"
                className="hidden"
              />
            </label>
          </div>
        </div>
        {loading  && (
          <StatusModal
            closeModal={closeModal}
            status={status}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default UploadExcel;
