import React, { useState, useEffect } from "react";
import "./filterModal";
import axios from "axios";
import "./FilterModalForFolder.css"

const FilterModalForFolder = ({ isOpen, onClose, onApply, setFiles, folderId, src ,setIsFilterApplied}) => {
  const [token, setToken] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    normalDocument: false,
    contract: false,
    invoice: false,
  });
  const [fileType, setFileType] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [rangeDate, setRangeDate] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const [activeTab, setActiveTab] = useState("FileType");

  const handleApply = async () => {
    try {
      const params = {
        file_type: fileType.length > 0 ? fileType.join(",") : undefined,
        ...(rangeDate
          ? { date_range: rangeDate }
          : {
              from_date: fromDate || undefined,
              to_date: toDate || undefined,
            }),
      };

      console.log("Filters being applied:", params);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/filter-files-in-folder/${folderId}/`,
        {
          params,
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFileType([]);
        setFromDate("");
        setToDate("");
        setRangeDate("");
        src === "folder"
          ? setFiles({
              documents: response.data.files.map((file) => ({
                ...file,
                key: file.key || "",
                pdf_url: file.url || "", 
              })),
            })
          : setFiles(
              response.data.files.map((file) => ({
                ...file,
                key: file.key || "", 
              }))
            );
            setIsFilterApplied(true);
      }
      
    } catch (err) {
      console.log("Error while applying filter:", err);
    }
    onClose();
  };

  const handleCheckboxChange = (type) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev, [type]: !prev[type] };

      setFileType(() => {
        const fileTypeMapping = {
          normalDocument: "normal_document",
          contract: "contract",
          invoice: "invoice",
        };

        const selectedFileTypes = Object.keys(updatedFilters)
          .filter((key) => updatedFilters[key])
          .map((key) => fileTypeMapping[key]);

        return selectedFileTypes;
      });

      return updatedFilters;
    });
  };

  const clearAllFilters = async () => {
    try {
      // Reset filters
      setSelectedFilters({
        normalDocument: false,
        contract: false,
        invoice: false,
      });
      setFileType([]);
      setFromDate("");
      setToDate("");
      setRangeDate("");
  
      // Fetch all files in the folder without any filters
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/readpdf/files-in-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // Update the table data
      if (response.status === 200) {
        setFiles({
          documents: response.data.files.map((file) => ({
            ...file,
            key: file.key || "",
            pdf_url: file.url || "",
          })),
        });

        setIsFilterApplied(false);
      } else {
        console.error("Failed to fetch all files.");
      }
    } catch (error) {
      console.error("Error fetching all files:", error);
    } finally {
      // Close the modal
      onClose();
    }
  };
  
  

  const isDatePickerDisabled = rangeDate !== "";

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay-doc">
      <div className="filter-modal-doc-underfolder">
      <button className="close-button-folder-underfolder" onClick={onClose}>&times;</button>
        <h2>Filter By</h2>
        <div className="Display">
          <div className="Box-rec">
            <button
              className={`Box-doc ${activeTab === "FileType" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("FileType")}
            >
              <img src="./File-type.svg" alt="" />
              File Type
            </button>
          </div>

          {activeTab === "FileType" && (
            <div className="filter-options">
              <div className="Header-doc-filter-folder">
                <p>Select the type of files to include in the results</p>
              </div>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFilters.normalDocument}
                  onChange={() => handleCheckboxChange("normalDocument")}
                  className="chekcbox-doc-filter"
                />
                Normal Document
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFilters.contract}
                  onChange={() => handleCheckboxChange("contract")}
                  className="chekcbox-doc-filter"
                />
                Contract
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFilters.invoice}
                  onChange={() => handleCheckboxChange("invoice")}
                  className="chekcbox-doc-filter"
                />
                Invoice
              </label>
            </div>
          )}
        </div>

        <div className="filter-actions-folder">
          <button onClick={clearAllFilters} className="clear-all-filter">
            Clear All
          </button>
          <button onClick={handleApply} className="apply-filter">
            Apply filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModalForFolder;
