import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './uploadDocument.css';         // Make sure this file includes all your .AI-CP-* classes
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';

const UploadModal = ({ isOpen, onClose, onUploadSuccess, type, folderId, onSummarizeDocument }) => {
  // ----------------- State Declarations ----------------- //
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);

  // Document Type & Key Handling
  const [docType, setDocType] = useState('');
  const [contractKey, setContractKey] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);

  // state to track drag-over status
  const [isDragOver, setIsDragOver] = useState(false);

  // WebSocket status message (shown during upload)
  const [wsStatusMessage, setWsStatusMessage] = useState("Waiting for updates...");

  // For manual fallback progress
  const progressInterval = useRef(null);

  // For axios cancel token
  const uploadCancelSourceRef = useRef(null);

  // React Router Hooks
  const navigate = useNavigate();
  const location = useLocation();
  // If needed, you can retrieve extra data from location.state:
  const { invoiceKey, extractedData } = location.state || {};

  // Token from Local Storage
  const token = localStorage.getItem('token');

  // ----------------- WebSocket Connection ----------------- //
  useEffect(() => {
    // Create a new WebSocket for real-time progress
    const uploadSocket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/ws/upload-status/`
    );

    uploadSocket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    uploadSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Received WebSocket message:", data);

      // If the message contains a pdf_key, update contractKey
      if (data.pdf_key) {
        setContractKey(data.pdf_key);
      }

      if (data.type === "progress" || data.type === "completed") {
        setProgress(data.progress);
        setWsStatusMessage(data.message);
      } else if (data.type === "error") {
        console.error("Upload error via WebSocket:", data.message);
        setWsStatusMessage(`Error: ${data.message}`);
        setIsUploading(false);
      }
    };

    uploadSocket.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    // Cleanup on unmount
    return () => {
      if (uploadSocket.readyState === WebSocket.OPEN) {
        uploadSocket.close();
      }
    };
  }, []);

  // If modal is not open, don't render anything
  if (!isOpen) return null;

  // ----------------- Fallback Manual Progress (if WebSocket doesn't update) ----------------- //
  const startProgress = () => {
    progressInterval.current = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 90) {
          return prevProgress + 1;
        } else {
          clearInterval(progressInterval.current);
          return prevProgress;
        }
      });
    }, 100);
  };

  // ----------------- File Upload Logic ----------------- //
  const triggerFileInput = () => document.getElementById('fileInput').click();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsUploading(true);
    setIsUploaded(false);
    setProgress(0);

    // Start fallback manual progress
    startProgress();

    // Proceed with actual file upload
    handleFileUpload(selectedFile);
  };

  const handleFileUpload = async (selectedFile) => {
    if (!token) {
      alert("Session expired, please log in.");
      onClose();
      return;
    }

    // Basic validation
    if (!selectedFile || (type === 'underFolder' && !folderId)) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    // Construct the correct upload URL depending on your 'type'
    let url = '';
    if (type === 'document') {
      url = `${process.env.REACT_APP_API_URL}api/v1/copilot/qdrant-upload-doc/`;
    } else if (type === 'underFolder') {
      url = `${process.env.REACT_APP_API_URL}api/v1/readpdf/upload-in-folder/${folderId}/`;
    } else {
      // Default or fallback URL if needed
      url = `${process.env.REACT_APP_API_URL}api/v1/readpdf/upload-in-folder/${folderId}/`;
    }

    // Create a cancel token source and store in ref
    uploadCancelSourceRef.current = axios.CancelToken.source();

    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`,
        },
        cancelToken: uploadCancelSourceRef.current.token,
        onUploadProgress: (progressEvent) => {
          // This is fallback progress via Axios
          const percentCompleted = Math.min(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
            10
          );
          setProgress(percentCompleted);
        },
      });

      if (response.status === 200) {
        // Clear fallback interval
        clearInterval(progressInterval.current);

        // Simulate finishing to 100%
        setTimeout(() => {
          setProgress(100);
          setTimeout(() => {
            setIsUploaded(true);
            setIsUploading(false);

            // Update docType, keys, etc.
            setDocType(response.data.doc_type);
            // In this case, the pdf_key might already be set from the WebSocket.
            // If not, you can still update it from the response:
            if (!contractKey && response.data.pdf_key) {
              setContractKey(response.data.pdf_key);
            }
            setContractUrl(response.data.file_url);

            // Optionally store URL in localStorage if you want
            localStorage.setItem('contractUrl', response.data.file_url);
          }, 1000);
        }, 500);
      } else {
        alert(response.data.msg || "File upload failed");
        setIsUploading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Upload cancelled:", error.message);
      } else {
        console.error("Upload error:", error);
        alert(
          error.response?.data.msg ||
          "An error occurred during file upload. Please try again."
        );
      }
      setIsUploading(false);
    }
  };

  // ----------------- Navigation & Close Handlers ----------------- //
  const handleClose = async () => {
    // If an upload is in progress, cancel it and call the cancel API
    if (isUploading) {
      if (uploadCancelSourceRef.current) {
        // Cancel the ongoing axios request
        uploadCancelSourceRef.current.cancel("Upload cancelled by user.");
      }

      // If a pdf key is available (i.e., received from the WebSocket), call the cancel-upload API.
      if (contractKey) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/readpdf/cancel-upload/`,
            { pdf_key: contractKey },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Cancel upload API error:", error);
        }
      }
      setIsUploading(false);
      toast.success("Document Upload Cancelled");
    }

    // Proceed with closing and navigation
    onClose();
    if (type === 'document') {
      navigate('/ai-copilot');
    } else if (type === 'underFolder') {
      navigate(`/folder/?folderId=${folderId}`);
    } else {
      navigate('/uploadFile');
    }
  };
  const handleSummarizeDocument = () => {
    localStorage.removeItem("copilotChat_messages");
    if (!contractKey || !contractUrl) {
      toast.error("Document information is missing. Please try again.");
      return;
    }
    console.log("Triggering Summarize Document callback...", contractKey);

    // Instead of navigate(...), call the parent's callback
    if (typeof onSummarizeDocument === "function") {
      onSummarizeDocument({
        contractKey,
        contractUrl,
        docType,
      });
    }
  };
  const handleViewDetails = () => {
    if (docType === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey, pdfFile: file }
      });
    } else if (docType === "invoice") {
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: contractKey || "defaultInvoiceKey" }
      });
    } else {
      console.warn("Unsupported document type:", docType);
    }
  };

  // ----------------- Render ----------------- //
  // For your dashed-line <hr /> style in "uploaded" state:
  const hrStyle = {
    border: '1px dashed rgba(139, 85, 221, 1)',
    marginTop: '-20px',
    width: '100%',
  };

  return (
    <div className="AI-CP-modal-overlay-details">
      <div className="modal-content-details">
        {/* Close Button */}
        <button
          className="close-button"
          onClick={handleClose}
          style={{ marginRight: '30px', fontSize: '22px' }}
        >
          x
        </button>

        {/* ------------- If Uploaded ------------- */}
        {isUploaded ? (
          <div className='uploadedMainContainer'>
            <div className="AIsuccess-status">
              <div className="success-icon-upload">
                <img
                  src="/right-success.svg"
                  alt="Success Icon"
                  className="checkmark-icon"
                />
              </div>
              {/* Heading */}
              {type === 'document' ? (
                <h3 className="success-heading">Great! Your document is ready!</h3>
              ) : type === 'underFolder' ? (
                <h3 className="success-heading">File Uploaded to Folder Successfully</h3>
              ) : (
                <h3 className="success-heading">File Uploaded Successfully</h3>
              )}

              {/* Subheading */}
              {type === 'document' ? (
                <p className="AI-CP-success-message-upload">
                  Your document has been uploaded successfully! Choose your next action below.
                </p>
              ) : type === 'underFolder' ? (
                <p className="success-message">
                  Your file has been uploaded to the folder successfully
                </p>
              ) : (
                <p className="success-message">
                  Your File has been uploaded successfully
                </p>
              )}
            </div>

            {/* Dashed line */}
            <hr style={hrStyle} />

            <div className="uploadedBottomContent">
              {/* If 'document' type, show AI actions */}
              {type === 'document' && (
                <div className="AI-CP-extraction-container">
                  <img
                    src="/bot-icon.svg"
                    alt="bot Icon"
                    className="extractionBot-icon"
                  />
                  <div className="AI-CP-extraction-message AI-CP-left-arrow">
                    {/* If docType is recognized */}
                    {docType === "contract" || docType === "invoice" ? (
                      <>
                        <p>
                          We've detected this as a{" "}
                          {docType === "contract" ? "Contract" : "Invoice"} document.
                          What do you want to do next?
                        </p>
                        <p>Would you like to extract key clauses, or should I summarize it for you?</p>
                      </>
                    ) : docType === "normal_document" ? (
                      <>
                        <p>
                          We've detected this as a Normal document. What do you want to do next?
                        </p>
                        <p>Would you like me to summarize it for you?</p>
                      </>
                    ) : docType === "spreadsheet" ? (
                      <>
                        <p>
                          We've detected this as a Spreadsheet. What do you want to do next?
                        </p>
                        <p>You can analyze data, generate charts, or extract insights effortlessly.</p>
                      </>
                    ) : (
                      <p>Document type not recognized. Please choose an action.</p>
                    )}
                  </div>
                </div>
              )}
              {/* {type === 'document' && ( */}
                <div className="AI-CP-button-container">
                  {docType === 'normal_document' && (
                    <button
                      className="AI-CP-summarizeDoc-button"
                      onClick={handleSummarizeDocument}
                    >
                      <img src="/copilot-icon.svg" alt="bot Icon" />
                      Summarize Document
                    </button>
                  )}

                  {(docType === 'contract' || docType === 'invoice') && (
                    <>
                      <button
                        className="AI-CP-summarizeDoc-button"
                        onClick={handleSummarizeDocument}
                      >
                        <img src="/copilot-icon.svg" alt="bot Icon" />
                        Summarize Document
                      </button>
                      <button
                        className="extract-details-button"
                        onClick={handleViewDetails}
                      >
                        <img src="/extract-data.svg" alt="extract-detail Icon" />
                        Extract Key Data
                      </button>
                    </>
                  )}

                  {docType === 'spreadsheet' && (
                    <button
                      className="spreadsheet-button"
                    // onClick={handleSpreadsheetFunction}
                    >
                      <img src="/spreadsheet-icon2.svg" alt="Spreadsheet Icon" />
                      Analyze
                    </button>
                  )}
                </div>
              {/* )} */}


            </div>
          </div>
        ) : /* ------------- If Uploading ------------- */ isUploading ? (
          <div className="AI-CP-Full-Box">
            {type === "document" ? (
              <h3 className="AI-CP-Heading1 ml-20">Upload Your Document</h3>
            ) : type === "underFolder" ? (
              <h3 className="AI-CP-Heading1">Upload Your File to Folder</h3>
            ) : (
              <h3 className="AI-CP-Heading1">Upload Your Files</h3>
            )}

            {type === "document" ? (
              <p className="AI-CP-Paragraph1 ml-2 mt-1">
                Upload your document here and start extracting data
              </p>
            ) : type === "underFolder" ? (
              <p className="AI-CP-Paragraph1">
                Upload your file here and store it in a folder
              </p>
            ) : (
              <p className="AI-CP-Paragraph1">
                Upload your file here and start extracting data
              </p>
            )}

            <div className="AI-CP-uploading-box">
              <div className="AI-CP-uploadingIconContainer mb-2">
                <img src="/uploadingDoc.svg" alt="" className="AI-CP-uploadingIcon" />
                <img src="/uploadingDoc2.svg" alt="" className="AI-CP-uploadingIcon2" />
              </div>

              {/* Real-time progress message from WebSocket or fallback text */}
              <p className="AI-CP-status-text-upload mb-8">
                {wsStatusMessage.length > 0
                  ? wsStatusMessage
                  : "Uploading your document... Sit tight while we process your file!"}
              </p>

              <div className="progessText">
                <p className="AI-CP-progress-percentage-upload">{progress}% to complete</p>
                <div className="AI-CP-time-estimate">
                  <span className="time-icon">⏱</span> 1min
                </div>
              </div>

              <div className="AI-CP-progress-bar-cont">
                <div
                  className="AI-CP-progress-bar-fill"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>

            <div className="AI-CP-file-info">
              <div className="AI-CP-AI-CP-file-info-container">
                <div
                  className="AI-CP-support"
                  style={{ position: "relative", left: "12rem" }}
                >
                  Supported formats: Pdf
                </div>
              </div>
              <div className="AI-CP-file-info-container">
                <div
                  className="AI-CP-maximum"
                  style={{ position: "relative", left: "-60px" }}
                >
                  Maximum Size: 20MB
                </div>
              </div>
            </div>
          </div>
        ) : (
          /* ------------- Initial State (No Upload Yet) ------------- */
          <div className="AI-CP-Full-Box">
            {type === 'document' ? (
              <h3 className="AI-CP-Heading1">Let's Get Started with Your Document!</h3>
            ) : type === 'underFolder' ? (
              <h3 className="AI-CP-Heading1">Upload Your File to Folder</h3>
            ) : (
              <h3 className="AI-CP-Heading1">Upload Your Files</h3>
            )}

            {type === 'document' ? (
              <p className="AI-CP-Paragraph1">
                Upload your document here and start extracting data
              </p>
            ) : type === 'underFolder' ? (
              <p className="AI-CP-Paragraph1">
                Upload your file here and store it in a folder
              </p>
            ) : (
              <p className="AI-CP-Paragraph1">
                Upload your file here and start extracting data
              </p>
            )}

            <div
              className={`AI-CP-upload-box ${isDragOver ? "drag-over" : ""}`}
              onClick={triggerFileInput}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragOver(true);
              }}
              onDragLeave={() => setIsDragOver(false)}
              onDrop={(e) => {
                e.preventDefault();
                setIsDragOver(false);
                const droppedFile = e.dataTransfer.files[0];
                handleFileChange({ target: { files: [droppedFile] } });
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf"
              />
              <div className="AI-CP-upload-icon">
                <img src="/uploadBig.svg" alt="Upload Icon" className="AI-CP-upload-big-icon" />
                <img src="/uploadSmall.svg" alt="Overlay Icon" className="AI-CP-upload-small" />
              </div>
              <p className="AI-CP-main-text">
                {isDragOver ? "Drop your file here" : "Drag and Drop Files Here or "}
                <span className="choose-file">Choose File</span>
              </p>
            </div>

            <div className="AI-CP-file-info">
              <div className="AI-CP-file-info-container">
                <div
                  className="support"
                  style={{ position: 'relative', left: '12rem' }}
                >
                  Supported formats: Pdf
                </div>
              </div>
              <div className="AI-CP-file-info-container">
                <div
                  className="maximum"
                  style={{ position: 'relative', left: '-60px' }}
                >
                  Maximum Size: 20MB
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadModal;
