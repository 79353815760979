import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPdfChat = createAsyncThunk(
  'pdfChat/fetchPdfChat',
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return rejectWithValue('No authorization token found');
    }

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/qdrant-chat/${pdfKey}/?token=${token}`;
    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = '';
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus('loading'));
        dispatch(updatePartialAnswer(''));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);

        try {
          const data = JSON.parse(event.data);
          console.log("WebSocket full response:", data);

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.type === 'final') {
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            generatedQuestions = data.data.generated_questions || ""; 
          console.log("kkkkk",generatedQuestions)
          
            dispatch(updateGeneratedQuestions(generatedQuestions)); // Update the state with the summary
          
            dispatch(updateFinalAnswer(accumulatedAnswer));
            dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));
            socket.close();
            resolve(accumulatedAnswer);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(rejectWithValue('WebSocket connection error'));
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
    });
  }
);




export const fetchDocumentSummary = createAsyncThunk(
  "chat/fetchDocumentSummary",
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No authorization token found");
      return rejectWithValue("No authorization token found");
    }

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/pdf-summary-and-chats/${pdfKey}/?token=${token}`;
    console.log("WebSocket URL:", wsUrl);

    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log("WebSocket connection established.");
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);


        try {
          const message = event.data.startsWith("data: ")
            ? event.data.slice(6)
            : event.data;
          const data = JSON.parse(message);
          console.log("Parsed WebSocket message data:", data);

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            console.log("Accumulated Answer:", accumulatedAnswer);
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.pdf_details) {
            if (data.pdf_details.example_questions) {
                generatedQuestions = data.pdf_details.example_questions;
                console.log("Generated Questions received:", generatedQuestions);
                dispatch(updateGeneratedQuestions(generatedQuestions)); 
                dispatch(updateSummaryStatus("succeeded"));

            }

            // Extract and combine user initials
            const firstChar = data.pdf_details.first_char || "";
            const lastChar = data.pdf_details.last_char || "";
            const userInitials = (firstChar + lastChar).trim().toUpperCase() || "N/A";

            console.log("User Initials:", userInitials);
            dispatch(updateUserInitials(userInitials)); 
        }


          if (data.type === "final") {
            console.log("🚀 WebSocket Sent Final Summary:", data.data?.summary);
            
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            const summary = data.data.summary || "";
            generatedQuestions = data.data.example_questions || ""; 
            
            console.log("Summary received:", summary);
            console.log("Generated Questions received:", generatedQuestions);

            // Extract and format timestamp
            let formattedTimestamp = "N/A";
            if (data.data.summary_generated_at) {
              try {
                formattedTimestamp = new Date(data.data.summary_generated_at).toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                });
              } catch (error) {
                console.error("Error parsing summary_generated_at:", error);
              }
            }

            // // Extract and combine user initials
            // const firstChar = data.data.first_char || "";
            // const lastChar = data.data.last_char || "";
            // const userInitials = (firstChar + lastChar).trim().toUpperCase() || "N/A";

            // console.log("Formatted Timestamp:", formattedTimestamp);
            // console.log("User Initials:", userInitials);

            // Dispatching Redux updates
            console.log("Final summary dispatching:", summary);

            dispatch(updateFinalSummary(summary));
            dispatch(updateGeneratedQuestions(generatedQuestions)); 
          //  dispatch(updateSummaryMetadata({ formattedTimestamp, userInitials }));
            dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));

            // dispatch(updateStatus("succeeded"));
            if (generatedQuestions) {
              console.log("✅ Summary generation complete and questions received");
              dispatch(updateSummaryStatus("completed"));
          }
            socket.close();
            resolve(summary);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed. Final Summary should have been received.");
      };
    });
  }
);




const pdfChatSlice = createSlice({
  name: 'pdfChat',
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
    error: null,
    botMessageId: null, 
    runId: null,
    generatedQuestions: null,
    feedback: {},
    formattedTimestamp: "",
    userInitials: "",
    summaryStatus: "idle",
  },
  initialState: {
    messages: [],
    partialAnswer: "",
    finalAnswer: "",
    partialSummary: "",
    finalSummary: "",
    status: "idle",
    summaryStatus: "idle",
    error: null,
    summaryError: null,
    botMessageId: null,
    runId: null,
    generatedQuestions: null,
    feedback: {},
    formattedTimestamp: "",
    userInitials: "",
  },
  reducers: {
    resetPdfChatState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null; 
      state.runId = null;
      state.generatedQuestions = null;
      state.feedback = {}; 
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId , generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions; 
    },
    updateFeedback: (state, action) => {
      const { messageId, feedbackType } = action.payload;
      state.feedback[messageId] = feedbackType;
    },
    updateUserInitials: (state, action) => { // <-- Define this action
      state.userInitials = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updatePartialSummary: (state, action) => {
      state.partialSummary = action.payload;
    },
    updateFinalSummary: (state, action) => {
      state.finalSummary = action.payload;
    },
    updateSummaryStatus: (state, action) => {
      console.log("Updating summary status:", action.payload);
      state.summaryStatus = action.payload;
    },
    updateGeneratedQuestions: (state, action) => {
      state.generatedQuestions = action.payload; 
    },
  
    updateSummaryMetadata: (state, action) => {
      console.log("Updating Redux - Summary Metadata", action.payload);
    
      if (action.payload) {
        state.formattedTimestamp = action.payload.formattedTimestamp ? action.payload.formattedTimestamp : "N/A";
        state.userInitials = action.payload.userInitials ? action.payload.userInitials : "N/A";
      }
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPdfChat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPdfChat.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchDocumentSummary.pending, (state) => {
        state.summaryStatus = "loading";
      })
      .addCase(fetchDocumentSummary.fulfilled, (state) => {
        state.summaryStatus = "succeeded";
      })
      .addCase(fetchDocumentSummary.rejected, (state, action) => {
        state.summaryError = action.payload;
        state.summaryStatus = "failed";
      })
      .addCase(fetchPdfChat.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});
export const { resetPdfChatState, updatePartialAnswer, updatePartialSummary,updateUserInitials,
  updateFinalSummary,
  updateSummaryStatus,
  updateGeneratedQuestions, updateFinalAnswer, updateBotMessageInfo, updateStatus,updateSummaryMetadata, } = pdfChatSlice.actions;
export default pdfChatSlice.reducer;