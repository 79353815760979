import React from "react";

const SpreadsheetViewer = ({ fileUrl }) => {
  // Extract file extension
  const fileExtension = fileUrl.split(".").pop().toLowerCase();

  return (
    <div className="flex flex-col md:flex-row gap-4 w-full h-full">
      {/* Google Docs Viewer (for CSV files) */}
      {fileExtension === "csv" && (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`}
          title="CSV Viewer"
           className="w-full h-full"x
              allow="fullscreen"
        />
      )}

      {/* Office Viewer (for Excel files) */}
      {["xls", "xlsx", "docx", "pptx"].includes(fileExtension) && (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
          title="Office Viewer"
         className="w-full h-full"x
              allow="fullscreen"
        />
      )}
    </div>
  );
};

export default SpreadsheetViewer;
