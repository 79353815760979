// components/PlanComparisonPopup.js
import React from "react";
// import singleUser from "../Images/single-user.png";
// import multiUser from "../Images/group.png";
import "./PlanComparisonPopup.css"; // Optional: or reuse your pricing.css





const PlanComparisonPopup = ({ onClose, onUpgradeClick ,currencySymbol = "$" }) => {
  const cardInfo = [
    {
      offer: "Your Current plan",
      title: "Start",
      planDes: "Monthly Plan - Flexible & Affordable",
      price: "$39.00/month",
      originalPrice: 60.99,
      discountedPrice: 39.0,
      user: "1 user",
    //   userImg: singleUser,
    points: [
        {
          icon: "/features-icon-one-pay.svg",
          text: (
            <>
              <span>Document Upload Limit:</span>
              <br />
              Upto 70 documents per month
            </>
          ),
        },
        {
          icon: "/features-icon-two-pay.svg",
          text: "Daily Questions Limit: Unlimited",
        },
        {
          icon: "/features-icon-three-pay.svg",
          text: "Maximum File Size: 25 MB",
        },
        {
          icon: "/features-icon-four-pay.svg",
          text: "AI Co-Pilot with AI Search on Knowledgebase",
        },
        { icon: "/features-icon-five-pay.svg", text: "Chat with Excel: Yes" },
        {
          icon: "/features-icon-six-pay.svg",
          text: (
            <>
              <span>Multi-Document Chat: 
             </span>
              <br />
              Chat with up to 5 documents simultaneously
            </>
          ),
        },
        {
          icon: "/features-icon-eight-pay.svg",
          text: (
            <>
              <span>Advanced Data Extraction:
           
             </span>
              <br />
              OCR, contracts, and invoices
            </>
          ),
        },

        { icon: "/features-icon-nine-pay.svg", text: "AI RFP Writer" },
        {
          icon: "/features-icon-ten-pay.svg",
          text: "Custom Fields to Extract: Yes",
        },
        {
          icon: "/features-icon-eleven-pay.svg",
          text: "Support Level: Standard support included",
        },
        {
          icon: "/features-icon-twelve-pay.svg",
          text: (
            <>
              <span>Free Trial: 
            
           
             </span>
              <br />
              14 days (5 documents overall, 5 AI queries per day)
            </>
          ),
        },

        {
          icon: "/features-icon-seven-pay.svg",
          text: (
            <>
              <span className="coming-soon-text">Coming Soon:</span>
              <br />
              Includes Google Drive and OneDrive integrations
            </>
          ),
        }
        
      ],
    },
    {
      offer: "Limited time Offer",
      title: "Grow",
      planDes: "Yearly Plan - Saves more",
      price: "$199.00/year",
      user: "Multiple Users",
      originalPrice: 299.99,
      discountedPrice: 199.0,
      button: { text: "Upgrade Now" },
    //   userImg: multiUser,
    points: [
        {
          icon: "/features-icon-one-pay.svg",
          text: (
            <>
              <span>Document Upload Limit:</span>
              <br />
              Unlimited Document uploads
            </>
          ),
        },
        {
          icon: "/features-icon-two-pay.svg",
          text: "Daily Questions Limit: Unlimited",
        },
        {
          icon: "/features-icon-three-pay.svg",
          text: "Maximum File Size: 50 MB",
        },
        {
          icon: "/features-icon-four-pay.svg",
          text: "AI Co-Pilot with AI Search on Knowledgebase",
        },
        { icon: "/features-icon-five-pay.svg", text: "Chat with Excel: Yes" },
        {
          icon: "/features-icon-six-pay.svg",
          text: (
            <>
              <span>Multi-Document Chat: 
             </span>
              <br />
              Chat with up to 5 documents simultaneously
            </>
          ),
        },
        {
          icon: "/features-icon-eight-pay.svg",
          text: (
            <>
              <span>Advanced Data Extraction:
           
             </span>
              <br />
              OCR, contracts, and invoices
            </>
          ),
        },
        { icon: "/features-icon-nine-pay.svg", text: "AI RFP Writer" },
        {
          icon: "/features-icon-ten-pay.svg",
          text: "Custom Fields to Extract: Yes",
        },
        {
          icon: "/features-icon-eleven-pay.svg",
          text: "Support Level: Standard support included",
        },
        {
          icon: "/features-icon-twelve-pay.svg",
          text: (
            <>
              <span>Free Trial: 
            
           
             </span>
              <br />
              14 days (5 documents overall, 5 AI queries per day)
            </>
          ),
        },

        {
          icon: "/features-icon-seven-pay.svg",
          text: (
            <>
              <span className="coming-soon-text">Coming Soon:</span>
              <br />
              Includes Google Drive and OneDrive integrations
            </>
          ),
        }
      ],
    },
  ];

  const formatPrice = (usd, inr) => {
    return currencySymbol === "₹" ? `₹${inr}` : `$${usd}`;
  };

  return (
    <div className="custom-popup-overlay-month">
      <div className="popup-content-month">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2 className="custom-popup-title-month">Ready for more? Upgrade to Grow.</h2>
        <p className="custom-popup-subtext mt-2">Enjoy powerful features, increased limits, and better value with our Grow Plan.</p>

        <div className="price-card-container w-full h-full flex md:flex-row flex-col items-center justify-center gap-6 pt-8 relative">
          {cardInfo.map((card, index) => (
            <div
              key={index}
              
              className={`card flex flex-col bg-white p-6 transition-all duration-300`}
              style={{
                width: "21.56rem",
                minHeight: card.title === "Start" ? "26rem" : "30.44rem",
                borderRadius: "1rem",
                border: "1px solid rgba(0, 0, 0, 0.10)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              {/* Offer Badge */}
              <div
  className="offers-pay-terms"
  style={{
    backgroundColor:
      card.offer === "Your Current plan" ? "#8B55DD" : "", // light blue vs light pink (example)
    color:
      card.offer === "Your Current plan" ? "#F5F4F3" : "", // blue vs pink text (example)
    
  }}
>
  <span className="offers-text-pay">{card.offer}</span>
</div>


              {/* Title & Description */}
              <div className="text-center">
                <h3 className="card-header text-lg font-bold text-[#18101C]">
                  {card.title}
                </h3>
                <p className="plan-des">{card.planDes}</p>
                {/* <p className="card-sub-header text-base text-gray-600 mt-2">{card.subHeader}</p> */}
              </div>

              {/* Price */}
              {/* Pricing Section */}
              <div className="card-price text-center font-semibold text-[#18101C] text-2xl flex items-center justify-center gap-2">
                {card.originalPrice !== null ? (
                  <>
                    {/* Strikethrough Original Price */}
                    <span className="text-[#C95EBE] text-lg line-through pay-original-price">
  {formatPrice(card.originalPrice, card.title === "Grow" ? "25,675.30" : "5,219.96")}
</span>

<span className="text-black pay-discount-price">
  {formatPrice(card.discountedPrice, card.title === "Grow" ? "17,032.00" : "3,338.00")}
</span>
<sub className="pay-discount-price-des">
  {card.title === "Grow" ? "/year" : "/month"}
</sub>
                  </>
                ) : (
                  <span className="comming-sson-pay">/Coming Soon</span>
                )}
              </div>

              <div
                style={{
                  background:
                    "linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%)",
                  width: "299px",
                  height: "1px",
                  // margin: "16px auto",
                  marginLeft: "15px",
                }}
              ></div>

              <div className="flex flex-col gap-2 ml-4">
                {/* Heading with Icon */}
                <div className="flex items-center included-pay">
                  <img
                    src="/stars-pay.svg"
                    alt="star icon"
                    className="w-5 h-5 mr-2"
                  />
                  <h4 className="card-include ">What's Included</h4>
                </div>

                <div
                  className="points-container mt-0"
                  style={{
                    maxHeight: card.title === "Enterprise" ? "222px" : "240px", // 240px for first two, 222px for third
                    overflowY: "auto", // Ensure scrolling applies for all if needed
                  }}
                >
                  {card.points.map((point, index) => (
                    <div
                      key={index}
                      className="flex items-start text-gray-800 text-sm"
                    >
                      <img
                        src={point.icon}
                        alt="feature icon"
                        className="w-5 h-5 mr-3 mt-0"
                      />
                      <div className="des-features-pay">{point.text}</div>
                    </div>
                  ))}
                </div>
              </div>

              {card.title === "Grow" && (
  <div className="mt-6">
    <button
      className="amount-button-billing card-btn w-full py-3 rounded-lg font-semibold text-lg transition duration-300 flex items-center justify-center gap-3"
      onClick={() => onUpgradeClick("Grow", "payment")} // Call parent method
    >
      <span className="flex items-center">
        {card.button?.text || "Upgrade"}
        <span className="mt-1 ml-1">
          <img src="/pay-icon-trail-white.svg" alt="icon" />
        </span>
      </span>
    </button>
  </div>
)}



            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanComparisonPopup;
