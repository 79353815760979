import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
  } from "react";
  import { FaSearch } from "react-icons/fa";
  import { useNavigate } from "react-router-dom";
  import { Header } from "../../components/Header/Header";
  import UploadModal from "../Contract/UploadModal";
  import FilterModal from "../Contract/filterModal";
  import bell from "../../Images/ai-bell.png";
  import search from "../../Images/search-big.jpg";
  import axios from "axios";
  import "../Spreadsheets/Spreadsheets_data.css";
  import Spinner from "../Contract/Spinner";
  
  const Spreadsheets_data = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBulkDelete, setIsBulkDelete] = useState(false);
    const [exportInProgress, setExportInProgress] = useState(false);
    const [spreadsheets, setSpreadsheets] = useState([]); // New state for Excel filtering
    const [token, setToken] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [editContractId, setEditContractId] = useState(null);
    const [editedContractData, setEditedContractData] = useState({});
    const [loading, setLoading] = useState(false);
    const editRowRef = useRef(null);
    const [selectedinvoices, setSelectedinvoices] = useState([]);
    const [selectedContractKeys, setSelectedContractKeys] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMessage, setPopupMessage] = useState("");
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [contractToDelete, setContractToDelete] = useState(null);
    const actionMenuRef = useRef(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
const fileInputRef = useRef(null);
    const [filterApplied, setFilterApplied] = useState(false);
    const navigate = useNavigate();

    const [hoveredAction, setHoveredAction] = useState(null);
    const [clickedAction, setClickedAction] = useState(null);
    
    const handleFilterApply = (filteredSpreadsheets) => {
  setSpreadsheets(filteredSpreadsheets);
  setFilterApplied(true);
  toggleFilterModal();
};

  
    useEffect(() => {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        setToken(storedToken);
      } else {
        console.error("Token is missing from localStorage");
      }
    }, []);
  
    const toggleModal = () => {
      if (!token) {
        showPopup("Session Expired", "Session expired. Please log in again.");
        return;
      }
      setIsModalOpen((prev) => !prev);
    };
  
    const handleExport = async () => {
      if (!token) {
        showPopup("Session Expired", "Session expired. Please log in again.");
        return;
      }
    
      if (!selectedinvoices || selectedinvoices.length === 0) {
        showPopup("Error", "Please select at least one invoice to export.");
        return;
      }
    
      setExportInProgress(true);
    
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/invoice/export-invoice/`,
          { invoice_keys: selectedContractKeys },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
            responseType: "blob", 
          }
        );
    
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoices_export.csv"); 
        document.body.appendChild(link);
        link.click(); 
        document.body.removeChild(link);
    
        showPopup("Success", "Invoices exported successfully.");
      } catch (error) {
        if (error.response) {
          const message = error.response.data?.msg || "Failed to export invoices.";
          showPopup("Error", message);
        } else {
          showPopup("Error", "An unexpected error occurred. Please try again.");
        }
      } finally {
        setExportInProgress(false);
      }
    };
    
      const [file, setFile] = useState(null);
    
          const handleFileChange = (event) => {
            const selectedFile = event.target.files[0];
            if (!selectedFile) return;
        
            console.log("File selected:", selectedFile); // Debug log
            setFile(selectedFile);  // Update file state
            setIsModalOpen(true);   // Open modal after file selection
        };
  
    const handleCancel = () => {
      setSelectedinvoices([]);
  
      setIsModalOpen(false);
      setIsFilterModalOpen(false);
      setPopupVisible(false);
  
      setConfirmDeleteVisible(false);
    };
  
    const fetchSpreadsheets = useCallback(async () => {
      if (!token) return;
      setLoading(true);
  
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/excel/excels/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        console.log("response", response.data);
        setSpreadsheets(response.data);
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setLoading(false);
      }
    }, [token]);
  
    const tableBodyRef = useRef(null);
  
    const scrollToTop = () => {
      if (tableBodyRef.current) {
        tableBodyRef.current.scrollTop = 0;
      }
    };
  
    const handleSelectAll = () => {
      if (selectedinvoices.length === spreadsheets.length) {
        setSelectedinvoices([]);
        setSelectedContractKeys([]);
      } else {
        setSelectedinvoices(spreadsheets.map((contract) => contract.id));
        setSelectedContractKeys(spreadsheets.map((contract) => contract.key));
      }
    };
    
    const filteredSpreadsheets = useMemo(() => {
      const query = searchQuery?.toLowerCase() || ""; 
      return spreadsheets.filter((spreadsheet) => {
        const spreadsheetName = spreadsheet?.excel_name?.toLowerCase() || ""; 
        return spreadsheetName.includes(query);
      });
    }, [spreadsheets, searchQuery]);
    
    
    
    
    
  
    const indexOfLastContract = currentPage * rowsPerPage;
    const indexOfFirstContract = indexOfLastContract - rowsPerPage;
    const currentinvoices = useMemo(() => {
      const indexOfLastContract = currentPage * rowsPerPage;
      const indexOfFirstContract = indexOfLastContract - rowsPerPage;
      return filteredSpreadsheets.slice(indexOfFirstContract, indexOfLastContract);
    }, [filteredSpreadsheets, currentPage, rowsPerPage]);
  
    const totalPages = useMemo(
      () => Math.ceil(filteredSpreadsheets.length / rowsPerPage),
      [filteredSpreadsheets, rowsPerPage]
    );
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(Number(event.target.value));
      setCurrentPage(1);
    };
  
    const handleCheckboxChange = (id, key) => {
      if (selectedinvoices.includes(id)) {
        setSelectedinvoices(selectedinvoices.filter((contractId) => contractId !== id));
        setSelectedContractKeys(selectedContractKeys.filter((contractKey) => contractKey !== key));
      } else {
        setSelectedinvoices([...selectedinvoices, id]);
        setSelectedContractKeys([...selectedContractKeys, key]);
      }
    };
    
    useEffect(() => {
      if (token) {
        fetchSpreadsheets();
      }
    }, [fetchSpreadsheets, token]);
    
  
    const handleUploadSuccess = () => {
      fetchSpreadsheets();
      toggleModal();
    };
  
    const handleCloseModal = () => {
      toggleModal();
      fetchSpreadsheets();
    };
  
    const handlePageChange = (event) => {
      const newPage = Number(event.target.value);
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
  
    const toggleActionMenu = (index) => {
      setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
    };
  
    const toggleFilterModal = () => {
      setIsFilterModalOpen((prev) => !prev);
    };
  
    const handleViewMore = (contract) => {
      console.log("contract" ,contract);
      navigate("/Invoices/Invoice_Details", {
          state: { invoiceKey: contract.key },
      });
  };

  
  
  const enableEdit = (invoice) => {
    if (invoice) {
      setIsActionMenuOpen(null);
      setEditContractId(invoice.id);
      setEditedContractData({
        ...invoice,
        details: { ...invoice.details },
      });
    } else {
      setEditContractId(null);
      setEditedContractData({});
    }
  };
  
    
  
  const handleEditChange = (field, value) => {
    if (field === "excel_name") {
      setEditedContractData((prev) => ({
        ...prev,
        excel_name: value, // Ensure the new name is stored in state
      }));
    } else if (field.startsWith("details.")) {
      const key = field.split(".")[1];
      setEditedContractData((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          [key]: value,
        },
      }));
    } else {
      setEditedContractData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };
  
    
  
    const handleClickOutside = (event) => {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target)
      ) {
        setIsActionMenuOpen(null);
      }
      if (
        confirmDeleteVisible &&
        event.target.closest(".popup-overlay") === null &&
        !event.target.closest(".popup-box-delete")
      ) {
        setConfirmDeleteVisible(false);
      }
    };
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [confirmDeleteVisible, isActionMenuOpen]);
  
    const showPopup = (title, message) => {
      setPopupTitle(title);
      setPopupMessage(message);
      setPopupVisible(true);
    };
  
    const closePopup = () => {
      setPopupVisible(false);
    };
    const handleDelete = (spreadsheetId) => {
      if (!spreadsheetId) {
        showPopup("Error", "Invalid spreadsheet ID. Please try again.");
        return;
      }
    
      if (!token) {
        showPopup("Session Expired", "Session expired. Please log in again.");
        return;
      }
    
      setIsBulkDelete(false);
      setContractToDelete(spreadsheetId);
      setConfirmDeleteVisible(true);
    };
    
    
  
    const handleBulkDelete = () => {
      if (!token) {
        showPopup("Session Expired", "Session expired. Please log in again.");
        return;
      }
    
      if (!selectedinvoices || selectedinvoices.length === 0) {
        showPopup("Error", "Please select at least one spreadsheet to delete.");
        return;
      }
    
      setIsBulkDelete(true);
      setConfirmDeleteVisible(true);
    };
    
  
    const handleEditSave = async (excelId) => {
      if (!token) {
        showPopup("Session Expired", "Session expired. Please log in again.");
        return;
      }
    
      if (!editedContractData.excel_name || editedContractData.excel_name.trim() === "") {
        showPopup("Error", "Spreadsheet name cannot be empty.");
        return;
      }
    
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}api/v1/excel/rename-excel/${excelId}/`,
          { new_name: editedContractData.excel_name }, // Ensure correct key-value pair is sent
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
    
        if (response.status === 200) {
          const { excel_id, new_name } = response.data;
    
          // Update the table with the new name
          setSpreadsheets((prevInvoices) =>
            prevInvoices.map((invoice) =>
              invoice.id === excel_id ? { ...invoice, excel_name: new_name } : invoice
            )
          );
    
          setEditContractId(null);
          showPopup("Success", "Spreadsheet renamed successfully.");
        }
      } catch (error) {
        console.error("Error renaming spreadsheet:", error);
        showPopup("Error", "Failed to rename spreadsheet. Please try again.");
      }
    };
    
    
    
    
    
    const confirmDelete = async () => {
      if (!token) {
        showPopup("Error", "Session expired. Please log in again.");
        return;
      }
    
      if (isBulkDelete) {
        if (selectedinvoices.length === 0) {
          showPopup("Error", "No spreadsheets selected for bulk delete.");
          return;
        }
    
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/excel/excel-bulk-delete/`,
            { excel_ids: selectedinvoices }, // Pass selected invoice IDs in the request body
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
    
          if (response.status === 200) {
            const { deleted_contracts, failed_deletions } = response.data;
    
            // Remove successfully deleted items from the table
            setSpreadsheets((prevSpreadsheets) =>
              prevSpreadsheets.filter(
                (spreadsheet) => !deleted_contracts.includes(spreadsheet.id)
              )
            );
    
            setSelectedinvoices([]);
    
            if (failed_deletions.length > 0) {
              showPopup(
                "Partial Success",
                `Some spreadsheets could not be deleted: ${failed_deletions.join(", ")}`
              );
            } else {
              showPopup("Success", "Selected spreadsheets deleted successfully.");
            }
          }
        } catch (error) {
          console.error("Error deleting spreadsheets:", error);
          showPopup("Error", "Failed to delete spreadsheets. Please try again.");
        } finally {
          setConfirmDeleteVisible(false);
          setIsBulkDelete(false);
          fetchSpreadsheets(); // Refresh the table
        }
      } else {
        // Single delete logic remains unchanged
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}api/v1/excel/excels/${contractToDelete}/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
    
          setSpreadsheets((prevSpreadsheets) =>
            prevSpreadsheets.filter(
              (spreadsheet) => spreadsheet.id !== contractToDelete
            )
          );
    
          showPopup("Success", "Spreadsheet deleted successfully.");
        } catch (error) {
          console.error("Error deleting spreadsheet:", error);
          showPopup("Error", "Failed to delete spreadsheet. Please try again.");
        } finally {
          setConfirmDeleteVisible(false);
          setContractToDelete(null);
          fetchSpreadsheets();
        }
      }
    };
    
    
    
           useEffect(() => {
              const updateRowsPerPage = () => {
                const width = window.innerWidth;
            
                if (width >= 2400) {
                  setRowsPerPage(13); // Extra large screens
                } else if (width >= 1920 && width < 2400) {
                  setRowsPerPage(11);
                } else if (width >= 1536 && width < 1920) {
                  setRowsPerPage(9); 
                } else if (width >= 1440 && width < 1536) {
                  setRowsPerPage(8);
                } else if (width >= 1280 && width < 1440) {
                  setRowsPerPage(7); 
                } else if (width >= 1024 && width < 1280) {
                  setRowsPerPage(6); 
                } else if (width >= 768 && width < 1024) {
                  setRowsPerPage(5); // Tablets
                } else {
                  setRowsPerPage(4); // Mobile devices
                }
              };
            
              updateRowsPerPage(); // Set initial rows per page
              window.addEventListener("resize", updateRowsPerPage);
            
              return () => window.removeEventListener("resize", updateRowsPerPage);
            }, []);
    
    
    
  
    useEffect(() => {
      if (editContractId) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [editContractId]);
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    return (
        <>
          {loading && <Spinner />}
          <div className="full-container">
            <div className="">
              <Header name="Spreadsheet" icon={""} />
            </div>
    
            <div className="contract-table-container">
              <div className="contract-table-header">
                <div className="search-filter-container">
                <div
  className="search-box"
  style={{
    borderRadius: "20px",
    border: isSearchFocused
      ? "1px solid rgba(187, 66, 174, 0.36)"
      : "1px solid var(--black_stroke, rgba(0, 0, 0, 0.10))",
    background: "#FFF",
    boxShadow: isSearchFocused
      ? "0px 11px 3px 0px rgba(0, 0, 0, 0.00), 0px 7px 3px 0px rgba(0, 0, 0, 0.01), 0px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)"
      : "none",
    transition: "all 0.3s ease-in-out",
  }}
>
  <img src="search.svg" alt="" />
  <input
    type="text"
    placeholder="Search Spreadsheets Here"
    className="Input-space"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    onFocus={() => setIsSearchFocused(true)}
    onBlur={() => setIsSearchFocused(false)}
  />
</div>
                  <button
  className={`filter-button ${isFilterApplied ? "filter-applieds" : ""}`}
  onClick={toggleFilterModal}
>
  <img
    src={isFilterApplied ? "/Filter_CTA.png" : "/filter.svg"}
    alt="Filter Icon"
  />
</button>

                </div>
                {selectedinvoices.length > 0 ? (
                  <div className="button-cat">
                    {/* <button
                      onClick={handleExport}
                      disabled={exportInProgress || selectedinvoices.length === 0}
                    >
                      <img src="./export-icon-blue.svg" alt="Export Icon" />
                      {exportInProgress ? "Exporting..." : "Export"}
                    </button> */}
                    <button
                      onClick={handleBulkDelete}
                      disabled={selectedinvoices.length === 0}
                    >
                      <img src="./delete-icon-red.svg" alt="Delete Icon" />
                      Delete
                    </button>
    
                    <button onClick={handleCancel} className="cancel-bulk">
                      <img src="/cancel_icon.svg" alt="Cancel Icon" />
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="upload-container-invoice">
                    <button
    className="upload-button-spreadsheets bg-[#C95EBE] "
    onMouseEnter={() => {
      console.log("Mouse entered - Tooltip should appear");
      setIsTooltipVisible(true);
    }}
    onMouseLeave={() => {
      console.log("Mouse left - Tooltip should disappear");
      setIsTooltipVisible(false);
    }}
    onClick={() => fileInputRef.current.click()}
  >
       <img
                      className="upload-icon w-8 h-8"
                      src="/upload_icon_white.svg"
                      alt="Upload Icon"
                    />
                    <span className="text-white">
                    Upload  Spreadsheet
  </span>
  </button>

  {isTooltipVisible && (


<div 
  className={`upload-tooltip-invoice absolute top-[88px] left-[92%] transform -translate-x-1/2 
              w-[178px] h-[92.637px] flex-shrink-0 bg-contain bg-no-repeat 
              p-[27px] px-[20px] font-outfit text-[16px] font-normal leading-[8px] text-[#171717] 
              whitespace-nowrap z-[1000] flex opacity-${isTooltipVisible ? '100' : '0'} 
              ${isTooltipVisible ? 'visible' : 'invisible'} transition-opacity duration-200 ease-in-out`}
  style={{ backgroundImage: "url('/path-to-background.svg')" }}  
>
  <p className="text-[#171717] font-outfit text-[13px] font-normal leading-none w-[99px] h-[27px] flex-shrink-0">
    Supported formats:<br />Pdf, xlsx, csv, xls <br />Maximum Size : 25MB
  </p>
</div>




  )}
                
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".csv,.xlsx,.pdf"
                  />
                </div>
                  
               <input
               type="file"
               ref={fileInputRef}
               style={{ display: "none" }}
               onChange={handleFileChange}
               accept=".csv,.xlsx,.pdf"
             />
           </>
           )}
              </div>
    
              <div className="container">
                <table className="contract-table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedinvoices.length === spreadsheets.length  &&
                            spreadsheets.length  > 0
                          }
                        />
                      </th>
                      
                      <th>Spreadsheet Name</th>
                      <th>Size</th>
                      <th>Uploaded date</th>
                      <th>Actions</th>
                      {/* <th>Due Date</th>
                      <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody
    className={currentinvoices.length > rowsPerPage ? "scrollable" : ""}
    style={{
      overflowY: currentinvoices.length > rowsPerPage ? "auto" : "hidden",
      maxHeight: "400px",
    }}
  >
    {loading ? (
      <tr>
        <td colSpan="6" style={{ textAlign: "center" }}>
          <Spinner />
        </td>
      </tr>
    ) : spreadsheets.length > 0 ? (
      currentinvoices.map((contract, index) => (
        <tr
          key={index}
          ref={editContractId === contract.id ? editRowRef : null}
          style={{
            background: hoveredRow === index ? "#F8F3FF" : "transparent",
            boxShadow: hoveredRow === index
              ? "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
              : "none",
          }}
          onMouseEnter={() => setHoveredRow(index)}
          onMouseLeave={() => setHoveredRow(null)}
        >
          <td>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={selectedinvoices.includes(contract.id)}
              onChange={() => handleCheckboxChange(contract.id, contract.key)}
            />
          </td>

          {editContractId === contract.id ? (
  <>
    <td>
      <input
        type="text"
        value={editedContractData.excel_name || ""}
      onChange={(e) =>
        setEditedContractData({ ...editedContractData, excel_name: e.target.value })
      }
        className="edit-contract"
        style={{ width: "20.5rem" }}
        
      />
    </td>
    <td>{contract.document_size || "N/A"}</td>
    <td>{new Date(contract.uploaded_at).toLocaleString()}</td>
   
    <td className="action-icons-contract">
  <button
    onClick={() => handleEditSave(contract.id, contract.key)}
    className="icon-button-contract"
  >
    <img src="/save-icon-contracttable.svg" alt="Save" className="action-icon" />
  </button>
  <button
    onClick={() => setEditContractId(null)} // Cancel editing
    className="icon-button-contract"
  >
    <img src="/cancel-icon-contracttable.svg" alt="Cancel" className="action-icon" />
  </button>
</td>
  </>
) : (
  <>
    <td>
  <span
    style={{
      color: "#B793ED", 
      textDecoration: "underline", 
      cursor: "pointer",
    }}
    onClick={() =>
      navigate("/Spreadsheets/ExcelChat", {
        state: { excelKey: contract.key, excelUrl: contract.file }, // Pass both key and URL
      })
    }
    
  >
{contract.excel_name || "N/A"}  </span>
</td>

    <td>{contract.document_size || "N/A"}</td>
    <td>{new Date(contract.uploaded_at).toLocaleString()}</td>
    {/* <td>{contract.details?.["Total due amount"] || "N/A"}</td>
    <td>{contract.details?.["Due date"] || "N/A"}</td> */}
    <td>
                <img
                  src="./action.svg"
                  alt="Actions"
                  width="20"
                  height="20"
                  onClick={() => toggleActionMenu(index)}
                  style={{ cursor: "pointer" }}
                />
                {isActionMenuOpen === index && (
                  <td>
                  
                  {isActionMenuOpen === index && (
                    <div className="action-menu" ref={actionMenuRef}>
                      
                      {/* Edit Action */}
                      <div
                        className="action-item"
                        onMouseEnter={() => setHoveredAction("edit")}
                        onMouseLeave={() => setHoveredAction(null)}
                        onMouseDown={() => setClickedAction("edit")}
                        onMouseUp={() => setClickedAction(null)}
                        onClick={() => enableEdit(contract)}
                        style={{
                          color:
                            clickedAction === "edit"
                              ? "#C95EBE"
                              : hoveredAction === "edit"
                              ? "#C95EBE"
                              : "#000",
                          transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
                          borderRadius: "8px",
                          border:
                            clickedAction === "edit" || hoveredAction === "edit"
                              ? "none"
                              : "none",
                          background:
                            clickedAction === "edit" || hoveredAction === "edit"
                              ? "#FAF9F6"
                              : "transparent",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={
                            clickedAction === "edit"
                              ? "./edit-active-contract.svg"
                              : hoveredAction === "edit"
                              ? "./edit-active-contract.svg"
                              : "./edit.svg"
                          }
                          alt="Edit Icon"
                          className="action-icon"
                        />
                        <p>Edit</p>
                      </div>
                
                      {/* Delete Action */}
                      <div
                        className="action-item"
                        onMouseEnter={() => setHoveredAction("delete")}
                        onMouseLeave={() => setHoveredAction(null)}
                        onMouseDown={() => setClickedAction("delete")}
                        onMouseUp={() => setClickedAction(null)}
                        onClick={() => handleDelete(contract.id)}
                        style={{
                          color:
                            clickedAction === "delete"
                              ? "rgba(226, 25, 25, 0.75)"
                              : hoveredAction === "delete"
                              ? "rgba(226, 25, 25, 0.75)"
                              : "#000",
                          transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
                          borderRadius: "8px",
                          border:
                            clickedAction === "delete" || hoveredAction === "delete"
                              ? "none"
                              : "none",
                          background:
                            clickedAction === "delete" || hoveredAction === "delete"
                              ? "#FAF9F6"
                              : "transparent",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={
                            clickedAction === "delete"
                              ? "./delete-active-contract.svg"
                              : hoveredAction === "delete"
                              ? "./delete-active-contract.svg"
                              : "./delete.svg"
                          }
                          alt="Delete Icon"
                          className="action-icon"
                        />
                        <p>Delete</p>
                      </div>
                    </div>
                  )}
                </td>
                
                )}
              </td>
  </>
          )}
        </tr>
      ))
    ) : (
      <div className="no-contracts-icon">
        {filterApplied ? (
          <>
            <img src="/no-contracts.svg" alt="No invoicesss Found" />
            <p className="no-contracts-para">
              No invoices found for the selected filters.
            </p>
          </>
        ) : (
          <>
            <img src="/no-contracts.svg" alt="No invoices Available" />
            <p className="no-contracts-para">
              Looks like your table is empty!
            </p>
          </>
        )}
      </div>
    )}
  </tbody>

                </table>
                {spreadsheets.length > 0 && (
                  <div className="pagination">
                    <div className="pagination-dropdown">
                      <select
                        value={currentPage}
                        onChange={(e) => setCurrentPage(Number(e.target.value))}
                        className="page-selector"
                      >
                        {Array.from({ length: totalPages }, (_, index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="contentDown">of {totalPages} pages</p>
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      style={{
                        cursor: currentPage === 1 ? "not-allowed" : "pointer",
                      }}
                    >
                      <img
                        src="decrease.svg"
                        alt="Previous Page"
                        style={{ height: "12px", width: "12px" }}
                      />
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      style={{
                        cursor:
                          currentPage === totalPages ? "not-allowed" : "pointer",
                      }}
                    >
                      <img
                        src="increase.svg"
                        alt="Next Page"
                        style={{ height: "12px", width: "12px" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
    
            {popupVisible && (
              <div className="popup-overlay">
                <div className="popup-box">
                  <h2>{popupTitle}</h2>
                  <p>{popupMessage}</p>
                  <button onClick={closePopup} className="popup-close-button">
                    Close
                  </button>
                </div>
              </div>
            )}
    
            {confirmDeleteVisible && (
              <div className="popup-overlay">
                <div className="popup-box-delete">
                  <h2>Confirm Deletion</h2>
                  <p>
                    {isBulkDelete
                      ? "Are you sure you want to delete the selected invoices? This action cannot be undone."
                      : "Are you sure you want to delete this invoice? This action cannot be undone."}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={confirmDelete}
                      className="popup-close-button-confirm"
                    >
                      Yes, Delete
                    </button>
                    <button
                      onClick={() => setConfirmDeleteVisible(false)}
                      className="popup-close-button-delete"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <UploadModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onUploadSuccess={handleUploadSuccess}
              type={"Spreadsheets"}
              file={file}
            />
    
    <FilterModal
  isOpen={isFilterModalOpen}
  onClose={() => setIsFilterModalOpen(false)}
  token={token}
  setExcels={(filteredExcels) => {
    setSpreadsheets(filteredExcels); 
    setIsFilterApplied(true);  // ✅ Mark filter as applied
    setIsFilterModalOpen(false);
  }} // Updated to use Spreadsheets state
  isExcel={true}  // Indicates filtering Excel files
  fetchExcels={fetchSpreadsheets}
  setIsFilterApplied={setIsFilterApplied}
    // Fetch all Spreadsheets
/>


          </div>
        </>
      );
}

export default Spreadsheets_data