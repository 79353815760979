import React, { useState } from "react";
import "./explorePromptModal.css";

// 1. Data Structure for All Sections
const promptSections = [
  {
    name: "Featured",
    icon: "/featured-icon.svg",
    shortPrompts: ["Summarize Document", "Find Keyword", "Extract Key data"],
    fullPrompts: ["Summarize Document", "Find Keyword", "Extract Key data"],
  },
  {
    name: "Documents",
    icon: "/document-icon.svg",
    shortPrompts: ["Summarize Key Takeaways", "Highlight main ideas"],
    fullPrompts: ["Summarize Key Takeaways", "Highlight main ideas"],
  },
  {
    name: "Contracts",
    icon: "/contract-icon.svg",
    shortPrompts: [
      "Extract the contract's starting date",
      "Summarize Terms and Conditions",
      "Parties Involved",
      "Extract Payment Terms",
      "Find Obligations",
      "Summarize any confidentiality or non-disclosure clauses",
      "Total Contract Value",
      "Key Milestones",
      "Summarize penalities or consequences for breaches",
    ],
    fullPrompts: [
      "Extract the contract's starting date",
      "Summarize Terms and Conditions",
      "Parties Involved",
      "Extract Payment Terms",
      "Find Obligations",
      "Summarize any confidentiality or non-disclosure clauses",
      "Total Contract Value",
      "Key Milestones",
      "Summarize penalities or consequences for breaches",
    ],
  },
  {
    name: "Invoices",
    icon: "/invoices-icon.svg",
    shortPrompts: [
      "Extract Total Due Amount",
      "Identify the company name",
      "Payment due date",
      "Issuer of Invoice",
      "List all items,products, and their quantities",
      
    ],
    fullPrompts: [
      "Extract Total Due Amount",
      "Identify the company name",
      "Payment due date",
      "Issuer of Invoice",
      "List all items,products, and their quantities",
    ],
  },
  {
    name: "Spreadsheets",
    icon: "/spreadsheet-icon.svg",
    shortPrompts: [
      "Analyze trends in Data",
      "Sales breakdown by region",
      "Summarize Monthly Expense",
      "Calculate Average Sales for 3 Months",

    ],
    fullPrompts: [
      "Analyze trends in Data",
      "Sales breakdown by region",
      "Summarize Monthly Expense",
      "Calculate Average Sales for 3 Months",
    ],
  },
  {
    name: "Resumes",
    icon: "/resume-icon.svg",
    shortPrompts: [
      "Extract Key Skills",
      "Summarize work Experience",
      "Extract Education Details",
    ],
    fullPrompts: [
      "Extract Key Skills",
      "Summarize work Experience",
      "Extract Education Details",
      "Analyze Career Progression",
      "Identify Missing Details",
    ],
  },
];

/**
 * ExplorePromptModal Component
 *
 * @param {boolean}   isOpen          - Controls whether the modal is displayed
 * @param {function}  onClose         - Callback to close the modal
 * @param {function}  onSelectPrompt  - Callback when a prompt is selected (passed from parent)
 */
const ExplorePromptModal = ({ isOpen, onClose, onSelectPrompt }) => {
  // Tracks which section is currently expanded (null means no section expanded).
  const [selectedSection, setSelectedSection] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Close the modal
  const handleClose = () => {
    onClose();
  };

  /**
   * Handle selecting a prompt:
   * If the section name is "Featured", override the section to "Documents".
   */
  const handleSelectPrompt = (prompt, sectionName) => {
    const selectedSectionName = sectionName === "Featured" ? "Documents" : sectionName;
    onSelectPrompt(prompt, selectedSectionName);
    onClose();
  };

  // If the modal is closed, don't render anything
  if (!isOpen) return null;

  // Convert search query to lowercase once for consistent matching.
  const lowerSearchQuery = searchQuery.toLowerCase();

  const filteredSections = promptSections
    .map((section) => {
      // Determine if this section should be disabled
      const isDisabled = ["Resumes", "Spreadsheets"].includes(section.name);

      if (isDisabled) {
        return { ...section, disabled: true };
      }

      // Check if the section name contains the search query
      const isSectionNameMatch = section.name.toLowerCase().includes(lowerSearchQuery);

      // Filter prompts
      const filteredShortPrompts = section.shortPrompts.filter((prompt) =>
        prompt.toLowerCase().includes(lowerSearchQuery)
      );
      const filteredFullPrompts = section.fullPrompts.filter((prompt) =>
        prompt.toLowerCase().includes(lowerSearchQuery)
      );

      // If section name doesn't match and no prompts match, exclude the entire section
      if (
        !isSectionNameMatch &&
        filteredShortPrompts.length === 0 &&
        filteredFullPrompts.length === 0
      ) {
        return null;
      }

      // Otherwise, return the section with only matched prompts
      return {
        ...section,
        shortPrompts: filteredShortPrompts,
        fullPrompts: filteredFullPrompts,
      };
    })
    .filter((section) => section !== null);

  // Identify which section is expanded (if any) from the filtered list
  const expandedSection = filteredSections.find(
    (section) => section.name === selectedSection
  );

  return (
    <div className="prompt-modal-overlay-details">
      <div className="prompt-modal-content-details">
        {/* Close button */}
        <button
          className="close-button"
          onClick={handleClose}
          style={{ marginRight: "30px", fontSize: "22px" }}
        >
          x
        </button>

        {/* Heading */}
        <h1 className="text-center text-2xl font-bold mb-3">Prompt Library</h1>

        {/* Search box */}
        <div className="promptSearchBox text-center items-center">
          <img src="/search.svg" alt="search icon" />
          <input
            type="text"
            placeholder="Search prompts by task or document type..."
            className="prompt-input-space"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Expanded Section (shows all prompts for that section) */}
        {selectedSection && expandedSection ? (
          <div>
            {/* Icon + Section Name */}
            <div className="flex items-center mb-[20px] mx-[25px]">
              <img
                src={expandedSection.icon}
                alt={`${expandedSection.name} icon`}
              />
              <h2 className="text-lg font-semibold ml-[12px] text-[#C95EBE]">
                {expandedSection.name}
              </h2>
            </div>

            {/* Full Prompts for this section */}
            <div className="promptScrollSection">
              {expandedSection.fullPrompts.map((item, index) => (
                <div
                  key={index}
                  className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px] mb-[15px]"
                >
                  <button
                    onClick={() => handleSelectPrompt(item, expandedSection.name)}
                    className={
                      expandedSection.name === "Featured"
                        ? "flex linear-grad-btn h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                        : "flex gray-brdr h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                    }
                    style={{ background: "white" }}
                  >
                    {item}
                  </button>
                </div>
              ))}
            </div>

            {/* "Less" button to go back */}
            <div className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px] mt-5">
              <button
                onClick={() => setSelectedSection(null)}
                className="flex items-center gap-1 text-bold"
              >
                <img
                  src="/see-less-icon.svg"
                  alt="See less icon"
                  style={{ transform: "rotate(180deg)" }}
                />
                <span className="font-[Outfit] text-[14px] font-medium text-[#C95EBE]">
                  Less
                </span>
              </button>
            </div>
          </div>
        ) : (
          // MAIN SCREEN WITH FILTERED SECTIONS (only three prompts visible)
          <div className="promptScrollSection">
            {filteredSections.map((section) => (
              <div key={section.name} className="mb-[35px] mx-[25px]">
                {/* Icon + Section Name */}
                <div className="flex items-center mb-[20px]">
                  <img src={section.icon} alt={`${section.name} icon`} />
                  <span className="text-lg font-semibold ml-[12px] text-[#C95EBE]">
                    {section.name}
                    {section.disabled && (
                      <button
                        onClick={() => console.log("Button clicked!")}
                        className=" h-[20px] px-[6px] pt-[1px] pb-[3px] justify-center items-center rounded-[28px] border-[0.5px] border-[#C95EBE] text-center font-[Outfit] text-[12px] font-normal leading-normal tracking-[-0.48px] bg-gradient-to-r from-[#C95EBE] via-[#C95EBE] to-[#C95EBE] bg-clip-text text-transparent cursor-pointer ml-[4px] outline-none whitespace-nowrap overflow-hidden"
                      >
                        coming soon
                      </button>
                    )}
                  </span>
                </div>

                {/* Only show the first three prompts (if not disabled) */}
                {!section.disabled &&
                  section.shortPrompts.slice(0, 3).map((prompt, index) => (
                    <div
                      key={index}
                      className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px]"
                    >
                      <button
                        onClick={() => handleSelectPrompt(prompt, section.name)}
                        className={
                          section.name === "Featured"
                            ? "flex linear-grad-btn h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                            : "flex gray-brdr h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                        }
                        style={{ background: "white" }}
                      >
                        {prompt}
                      </button>
                    </div>
                  ))}

                {/* If section is disabled, show a message */}
                {section.disabled && (
                  <div className="text-gray-500 text-sm">
                    Prompts unavailable for this section.
                  </div>
                )}

                {/* "More" button to expand section (if not disabled) */}
                {!section.disabled && (
                  <div className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px]">
                    <button
                      onClick={() => setSelectedSection(section.name)}
                      className="flex items-center gap-1 text-bold"
                    >
                      <span className="font-[Outfit] text-[14px] font-medium text-[#C95EBE]">
                        More
                      </span>
                      <img src="/see-more-icon.svg" alt="See more icon" />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExplorePromptModal;
