import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./signin.css";
import logo from "../../Images/Logo.png";
import loginImage from "../../Images/loginImage.png";
import GoogleSignInButton from "../../components/GoogleSignInButton";
import errorEmailLogo from "../../Images/emailerror.png";
const Signin = () => {
  const [signInDetails, setSignInDetails] = useState({
    email: "",
    password: "",
  });
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // This effect checks if the email and password fields are filled, including autofill
  useEffect(() => {
    const allFieldsFilled =
      signInDetails.email.trim() !== "" && signInDetails.password.trim() !== "";

    setIsTermsAccepted(allFieldsFilled);
  }, [signInDetails.email, signInDetails.password]); // Run when these values change

  // const passwordRegex = {
  //   minLength: /(?=.{6,})/,
  //   hasUppercase: /(?=.*[A-Z])/,
  //   hasNumber: /(?=.*\d)/,
  // };

  // const [passwordValidation, setPasswordValidation] = useState({
  //   minLength: false,
  //   hasUppercase: false,
  //   hasNumber: false,
  // });

  useEffect(() => {
    // Dynamically add the Chatling script
    const chatScript = document.createElement("script");
    chatScript.async = true;
    chatScript.type = "text/javascript";
    chatScript.src = "https://chatling.ai/js/embed.js";
    chatScript.setAttribute("data-id", "2287369244");
    chatScript.id = "chatling-embed-script";

    // Add page-specific configuration
    const chatlingConfigScript = document.createElement("script");
    chatlingConfigScript.type = "text/javascript";
    chatlingConfigScript.innerHTML = `
      window.chtlConfig = { 
        chatbotId: "2287369244",
        page: "Signin" // Add a specific page identifier
      };
    `;

    document.body.appendChild(chatlingConfigScript);
    document.body.appendChild(chatScript);

    
    return () => {
      if (document.body.contains(chatlingConfigScript)) {
        document.body.removeChild(chatlingConfigScript);
      }
      if (document.body.contains(chatScript)) {
        document.body.removeChild(chatScript);
      }
    };
  }, []);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSignupDetailsChange = (event) => {
    const { name, value } = event.target;
  
    setSignInDetails((prevSignInDetails) => ({
      ...prevSignInDetails,
      [name]: value,
    }));
  
    // Validate email while typing
    if (name === "email") {
      if (!isValidEmail(value)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }
  
    if (name === "password" && passwordError) {
      setPasswordError("");
    }
  
    const allFieldsFilled = signInDetails.email !== "" && signInDetails.password !== "";
    setIsTermsAccepted(allFieldsFilled);
    setIsBtnDisabled(false);
  };
  

  const renderPasswordAsterisks = (password) => {
    return "*".repeat(password.length);
  };

  const navigate = useNavigate();
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  

  const handleSignin = async () => {
    // 1️⃣ Validate Email
    if (!isValidEmail(signInDetails.email)) {
      setEmailError("Please enter a valid email.");
      return; // Stop execution if email is invalid
    } else {
      setEmailError(""); // Clear any previous errors
    }
  
    // 2️⃣ Validate Password
    if (!signInDetails.password.trim()) {
      setPasswordError("Please enter your password.");
      return; // Stop execution if password is empty
    } else {
      setPasswordError(""); // Clear any previous errors
    }
  
    const isOtpRequired = localStorage.getItem("otp_verification_required");
  
    if (isOtpRequired) {
      toast.warn("Please verify your OTP before logging in.", {
        autoClose: 2000,
      });
      navigate("/otp", { replace: true });
      return;
    }
  
    setIsBtnDisabled(true);
    const payload = {
      email: signInDetails.email,
      password: signInDetails.password,
    };
  
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "api/v1/accounts/signin/",
        payload
      );
      console.log("response login page", response);
  
      if (response.data.message === "Sign-in successful.") {
        setFullName(response.data.full_name);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", signInDetails.email);
        localStorage.setItem("fullName", response.data.full_name);
  
        toast.success("Sign-in successful", { autoClose: 2000 });
  
        navigate("/uploadFile");
      } else if (response.data.message === "Invalid email.") {
        console.log("Invalid email detected.");
        setEmailError("Incorrect Email, Try again");
        setPasswordError(""); // Clear password error
      } else if (response.data.message === "Invalid password for the provided email.") {
        console.log("Incorrect password.");
        setPasswordError("Incorrect Password, Try again");
        setEmailError(""); // Clear email error
      } else if (response.data.message.includes("not verified")) {
        toast("Your account is not verified. Please verify your account before logging in.");
      }
    } catch (error) {
      console.error("Signin error:", error);
      toast.error("Signin failed. Please try again.");
    } finally {
      setLoading(false);
      setIsBtnDisabled(false);
    }
  };
  
  

  // verify signin button
  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

  // const handleNavigateToForgotPassword = () => {
  //   navigate('/forgot-password');
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setVerified(true);
      navigate("/uploadFile"); //navigate("/ai-copilot");
    }
    setIsBtnDisabled(false);
  }, []);

  useEffect(() => {
    // If "Remember me" was previously selected, pre-fill the email field
    const rememberedEmail = localStorage.getItem("email");
    if (rememberedEmail) {
      setSignInDetails((prevSignInDetails) => ({
        ...prevSignInDetails,
        email: rememberedEmail,
      }));
      setRememberMe(true); // Set the checkbox to true
    }
  }, []);
  // If verified is true, navigate to "/uploadFile"
  // useEffect(() => {
  //   if (verified) {
  //     navigate("/uploadFile");
  //   }
  // }, [verified, navigate]);
  return (
<div className="min-h-[100dvh] flex flex-col justify-center items-center bg-[#FEF9F7] px-4">
<div className="w-38 mb-5 flex justify-center">
        <img
          src="/docusensasign.svg"
          alt="docuSensa logo"
          className="w-full h-full object-contain"
        />
      </div>

      <section className=" flex justify-center items-center">
      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-6 mx-auto">
      <div>
            <h2 className="welcome-signin">Welcome Back</h2>
            <h3 className="credentials-signin">
              Enter your Credentials to Access your Account
            </h3>
          </div>
          <div>
            <div className="google-signin-button">
              <GoogleSignInButton
                setVerified={setVerified}
                loginMessage={loginMessage}
              />
              {/* <img
                src="/googleicon.svg"
                alt="Google icon"
                className="google-icon"
              />
              <span className="google-text">Continue with Google</span> */}
            </div>

            {/* <GoogleSignInButton 
        setVerified={setVerified}
        loginMessage={loginMessage} 
      /> */}
          </div>

          <div className="or-style my-6">
            <hr className=" flex-grow border-gray-300" />
            <span className="px-2 text-gray-500">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          <div>
            <label for="email" className="email-sigin">
              Email
            </label>
            <div className="Email-sigin-box relative flex items-center w-full max-w-sm">
            <img
                src="/email-signin.svg"
                alt="Email icon"
                className="emailsigin-icon w-5 h-5 absolute left-3" 
              />
              <div className="line-between-icon absolute left-12 top-1/2 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

              <input
                type="email"
                name="email"
                value={signInDetails.email}
                onChange={handleSignupDetailsChange}
                className="Email-sigin-placeholder border-none outline-none" // Padding added to make room for the icon
                placeholder={emailError || "Enter your Email"}
                required
              />
              {emailError && (
                <div
                  className="relative flex items-center"
                  onMouseEnter={() => setIsHovered(true)} // On hover, set state to true
                  onMouseLeave={() => setIsHovered(false)} // On mouse leave, set state to false
                >
                  <span className="w-[20px] h-[20px] mr-1 cursor-pointer">
                    <img src="/error.svg" alt="email error logo" />
                  </span>

                  {/* Tooltip will only show if hovered */}
                  {isHovered && (
                    <div className="popup-show-letter email-error-hover">
                      <p>{emailError}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div>
            <label htmlFor="password" className="password-sigin">
              Password
            </label>
            <div className="password-sigin-box flex items-center border w-full max-w-sm">
            <img
                src="/passkey.svg"
                alt="Password icon"
                className="emailsigin-icon w-5 h-5 ml-3"
              />
              <div className="line-between-icon  ml-3.5 mt-8 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={signInDetails.password}
                onChange={handleSignupDetailsChange}
                placeholder={passwordError || "Enter Your Password"}
                className=" password-sigin-placeholder border-none outline-none ml-1.5"
                required
              />
              {passwordError && (
                <div
                  className="relative flex items-center"
                  onMouseEnter={() => setIsHovered(true)} // On hover, set state to true
                  onMouseLeave={() => setIsHovered(false)} // On mouse leave, set state to false
                >
                  <span className="w-[20px] h-[20px] mr-3 cursor-pointer">
                    <img src="/error.svg" alt="email error logo" />
                  </span>

                  
                  {isHovered && (
                    <div className="popup-show-letter email-error-hover">
                      <p>{passwordError}</p>
                    </div>
                  )}
                </div>
              )}

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="button-eye-email text-gray-500 ml-8"
              >
                {showPassword ? (
                  <IoEyeOutline size={22} />
                ) : (
                  <IoEyeOffOutline size={22} />
                )}
              </button>
            </div>

            {/* {passwordError && (
              <p className="text-red-500 text-sm mt-0 flex items-center">
                <span className="w-[10px] h-[10px] mr-1">
                  <img src={errorEmailLogo} alt="password error logo" />
                </span>{" "}
                {passwordError}
              </p>
            )} */}
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center remember-me-checkbox">
              <input type="checkbox" className="w-[20px] h-[20px] mt-3.5" />
              <label className=" remember-sigin ml-2  text-[rgba(115,96,123,0.60)] ">
                Remember me
              </label>
            </div>
            <a
              href="#"
              className="text-[#BB42AE] mt-3 font-inter text-[13px] font-medium"
            >
              {" "}
              <Link
                to="/request-password-reset"
                className="forgot-pass-sigin mr-2 mt-4"
              >
                Forgot password
              </Link>
            </a>
          </div>

          <div className="send-button-sigin w-[355px] h-[62px] bg-[#8B55DD] rounded-[6px] flex justify-center items-center ">
          <button
  disabled={isBtnDisabled}
  onClick={handleSignin}
  type="submit"
  className={`signin-button ${isTermsAccepted ? "isTermAccepted" : "notAccepted"} ${isBtnDisabled ? "notAccepted" : "isTermAccepted"}`}
>
  {loading ? "Logging" : "Login"}
</button>

          </div>

         
        </div>
      </section>

      <p className="login-text-sigin mt-2">
        Don’t have an account?{" "}
        <Link to="/signup" className="login-click-singin">
          Signup Now
        </Link>
      </p>
    </div>
  );
};

export default Signin;
