import React, { useState, useEffect } from "react";
import axios from "axios";

const ContractSearch = ({ onDocumentSelect }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [documents, setDocuments] = useState([]);
    const [token, setToken] = useState(localStorage.getItem("token") || "");
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [contractKey, setContractKey] = useState(""); // Define contractKey state
    const [contractUrl, setContractUrl] = useState(""); // Define contractUrl state
    const [documentType, setdocumentType] = useState("");

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async (query = "") => {
        if (!token) {
            console.warn("No token provided. Please login.");
            return;
        }

        setLoading(true);
        setError(null);

        const url = query
            ? `${process.env.REACT_APP_API_URL}api/v1/copilot/all-docs/?search=${query}`
            : `${process.env.REACT_APP_API_URL}api/v1/copilot/all-docs/`;

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("response-allDocs", response.data);

            // Extract only the 'documents' array from the response
            const { documents = [] } = response.data;

            // Map the documents to include necessary fields
            const filteredDocuments = documents.map(doc => ({
                ...doc,
                url: doc.url,
                type: doc.document_type,
            }));

            setDocuments(filteredDocuments); // Set only the filtered documents
        } catch (error) {
            console.error("Error fetching contracts:", error);
            setDocuments([]);
            setError("Failed to fetch documents. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleDocumentSelect = (doc) => {
        setSelectedDocuments((prevSelected) => [...prevSelected, doc]);

        // Set the contractKey and contractUrl in the state
        setContractKey(doc.key);
        setContractUrl(doc.url);
        setdocumentType(doc.document_type)
        console.log("child doc url", doc.url);
        console.log("child doc document type", doc.document_type);

        if (onDocumentSelect) {
            onDocumentSelect(doc); // Pass the full document to the parent if needed
        }

        setSearchQuery("");
        setShowDropdown(false);
    };



    const handleRemoveDocument = (doc) => {
        setSelectedDocuments((prevSelected) =>
            prevSelected.filter((selectedDoc) => selectedDoc.key !== doc.key)
        );
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query) {
            setShowDropdown(true);
            fetchDocuments(query);
        } else {
            setShowDropdown(false);
            setDocuments([]);
        }
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setShowDropdown(false);
        }, 200);
    };

    const handleDropdownMouseDown = (e) => {
        e.preventDefault();
    };

    return (
        <div
            className="relative inline-flex items-center border border-gray-200 bg-[rgba(243,233,242,0.85)] w-[155px] h-[40px] font-outfit text-[14px]"
           
        >
            {selectedDocuments.length === 0 && (
                <>
                    <img src="searchAICP.svg" alt="Search Icon" className="ml-2" />
                    <input
                        type="text"
                        placeholder="Search Documents"
                        className="w-full h-full bg-transparent text-neutral-500 font-outfit text-[14px] outline-none border-none pl-1"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onFocus={() => setShowDropdown(true)}
                        onBlur={handleInputBlur}
                    />
                </>
            )}

            <div className="flex flex-wrap gap-2">
                {selectedDocuments.map((doc, index) => (
                    <div
                        key={index}
                        className="selected-doc-button flex items-center justify-between"
                        style={{
                            width: "244px", // Width consistent with the button
                            height: "29px", // Height consistent with the button
                            background: "rgba(254, 249, 247, 1)", // Background color
                            border: "1px solid rgba(0, 0, 0, 0.5)", // Border styling
                            borderRadius: "6px", // Rounded corners
                            marginTop: "16px", // Top margin
                            marginLeft: "16px", // Left margin
                            padding: "4px 12px", // Padding for inner content
                            fontSize: "14px", // Font size
                            fontWeight: "500", // Font weight
                            color: "#000", // Text color
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                        }}
                        role="option"
                    >
                        <span className="truncate">{doc.name}</span>
                        <button
                            onClick={() => handleRemoveDocument(doc)}
                            className="ml-2 text-black cursor-pointer"
                            style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                color: "black",
                            }}
                        >
                            ×
                        </button>

                    </div>
                ))}

            </div>

            {showDropdown && (
                <div
                    className="absolute bottom-full left-1/2 transform -translate-x-1/2 min-w-[300px] max-w-[600px] overflow-x-auto whitespace-nowrap border border-gray-300 bg-white z-50 flex items-center"
                    role="listbox"
                    onMouseDown={handleDropdownMouseDown}
                    style={{
                        padding: "8px",
                        gap: "8px",
                    }}
                >
                    {loading ? (
                        <div className="px-4 py-2 text-gray-500">Loading...</div>
                    ) : error ? (
                        <div className="px-4 py-2 text-red-500">{error}</div>
                    ) : documents.length > 0 ? (
                        documents
                            .filter((doc) =>
                                doc.name.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .map((doc) => (
                                <button
                                    key={doc.key}
                                    className="px-4 py-2 gap-4 border border-black/50 rounded-[6px] hover:bg-gray-100 cursor-pointer"
                                    style={{
                                        display: "inline-block",
                                        marginRight: "8px",
                                        padding: "4px 12px",
                                    }}
                                    onClick={() => handleDocumentSelect(doc)}
                                >
                                    {doc.name}
                                </button>
                            ))
                    ) : (
                        <div className="px-4 py-2 text-gray-500">No documents found</div>
                    )}
                </div>
            )}

        </div>
    );
};

export default ContractSearch;
