import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./contractDetails.css";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid } from 'date-fns';
const ContractDetails = ({ isOpen }) => {
  const [contractData, setContractData] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [popupState, setPopupState] = useState({});
  const [isKeyDatesOpen, setIsKeyDatesOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false);
  const [milestoneContent, setMilestoneContent] = useState("");
  const [editNote, setEditNode] = useState("");
  const [editingSection, setEditingSection] = useState(null);
  const [activePopup, setActivePopup] = useState(null);
  const [activeEditField, setActiveEditField] = useState(null);
  const editRef = useRef(null);

  const [isButtonVisible, setButtonVisible] = useState(false);

  const [edit, setEdit] = useState(false);
  const [editedData, setEditedData] = useState({});
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  const [openSections, setOpenSections] = useState({
    termsAndConditions: false,
    financialTerms: false,
    legalClauses: false,
  });
  const [openClauses, setOpenClauses] = useState({
    termination: false,
    confidentiality: false,
    governing: false,
  });
  const location = useLocation();
  const contractKey = location.state?.contractKey;
  const navigate = useNavigate();
  const executionDatePickerRef = useRef(null);
  const paymentDate1PickerRef = useRef(null);
  const expirationDatePickerRef = useRef(null);
  const renewalDatePickerRef = useRef(null);
  const paymentDate2PickerRef = useRef(null);

  const handleViewMoreClick = () => {
    setIsPopupOpen(true);
  };
  const isValidDate = (date) => {
    return !isNaN(new Date(date).getTime());
  };

  const datePickerRef = useRef(null);
  const [isMilestonePopupOpen, setIsMilestonePopupOpen] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState({});
  const handleMilestoneViewMore = (milestone, event) => {
    event.stopPropagation();
    const buttonRect = event.target.getBoundingClientRect();
    const popupHeight = 200;
    const popupWidth = 300;

    const moveUpBy = 20;

    const top =
      buttonRect.bottom + popupHeight > window.innerHeight
        ? buttonRect.top - popupHeight - moveUpBy
        : buttonRect.bottom + moveUpBy;

    const left = Math.min(window.innerWidth - popupWidth, buttonRect.left);

    setActivePopup({
      milestone,
      position: { top: top + window.scrollY, left: left + window.scrollX },
    });
  };

  const closeMilestonePopup = () => {
    setActivePopup(null);
  };
  const [activeCustomerCard, setActiveCustomerCard] = useState(null); // Track active editing card
  const [activeKeyDate, setActiveKeyDate] = useState(null); // Track active editing key date
  const [activeFinancialTerm, setActiveFinancialTerm] = useState(false);
  const [activeLegalClause, setActiveLegalClause] = useState(false);
  const [activeTermsAndConditions, setActiveTermsAndConditions] = useState(false);

  const handleEditClick = (key) => {
    if (key === "keyDates") {
      setActiveKeyDate(activeKeyDate === "keyDates" ? null : "keyDates");
    } else if (key === "FinancialTerms") { 
      setActiveFinancialTerm(!activeFinancialTerm);
    } else if (key === "LegalClauses") {
      setActiveLegalClause(!activeLegalClause);
    } else if (key === "TermsAndConditions") { 
      setActiveTermsAndConditions(!activeTermsAndConditions);
    } else {
      setActiveCustomerCard(key === activeCustomerCard ? null : key);
    }
  };
  

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const toggleClause = (clause) => {
    setOpenClauses((prevClauses) => ({
      ...prevClauses,
      [clause]: !prevClauses[clause],
    }));
  };

  useEffect(() => {
    const fetchContractDetails = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Session expired, please log in.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/contract/extract-contract-info/`,
          { contract_key: contractKey },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setContractData(response.data.contract_data);
          setEditedData(response.data.contract_data);
          setUserNote(response.data.contract_data?.note || "");
        }
      } catch (error) {
        console.log("Fetch error:", error);
        alert(
          error.response?.data.msg ||
            "An error occurred while fetching contract details."
        );
      }
    };
    if (contractKey) fetchContractDetails();
  }, [contractKey, edit]);

  const viewContract = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/contract/view-contract/${contractKey}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      console.log("API Response for view contract:", response.data);
      if (response.status === 200 && response.data.contract_url) {
        setContractUrl(response.data.contract_url);
        setIsPopupOpen(true);
      } else {
        alert(response.data.msg || "Failed to retrieve contract.");
      }
    } catch (error) {
      console.log("Error fetching contract URL:", error);
      alert(
        error.response?.data.msg ||
          "An unexpected error occurred while retrieving the contract."
      );
    }
  };
  
  const saveNote = async (note) => {
    if (!note.trim()) return; // Avoid saving empty notes
    const token = localStorage.getItem("token");
    if (!token) {
        alert("Session expired, please log in.");
        return;
    }

    console.log("Saving note:", note); // Debugging: Check what note is being sent

    setIsSaving(true);
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/contract/edit-contract-details/${contractKey}/`,
            { 
                edited_data: { dummyField: "keep this non-empty" }, // Ensure it’s not empty
                note: note.trim(),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        );

        console.log("API Response:", response.data); // Debugging: Check API response

        if (response.status === 200) {
            console.log("Note saved successfully");
        }
    } catch (error) {
        console.error("Error saving note:", error.response?.data || error.message);
        alert("An error occurred while saving the note.");
    } finally {
        setIsSaving(false);
    }
};



  const notesRef = useRef(null);
  const [userNote, setUserNote] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {

      if (notesRef.current && !notesRef.current.contains(event.target)) {
        if (isEditingNote) {
          saveNote(userNote); // Call API only if the user was editing
          setIsEditingNote(false); // Reset the flag
        }
      }
    
      if (editRef.current && !editRef.current.contains(event.target)) {
        setActiveCustomerCard(null); 
        setActiveKeyDate(null);
        setActiveLegalClause(false);// Reset editing mode
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [activeEditField, editedData,userNote, isEditingNote, activeLegalClause]);

  const handleInputChange = (field, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleDateChange = (field, date) => {
    if (date) {
      // Adjust date to remove timezone offset
      const localDate = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
      );
      const formattedDate = format(localDate, "yyyy-MM-dd"); // Ensure it's formatted as 'yyyy-MM-dd'
      handleInputChange(field, formattedDate);
    } else {
      handleInputChange(field, null); // Handle invalid or cleared date
    }
  };
  

  const handleEditContract = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/contract/edit-contract-details/${contractKey}/`,
        {
          edited_data: {
            ...editedData,
          },
          note: userNote,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditModalOpen(false);
        setContractData(response.data.data);
        setUserNote(response.data.data?.note || "");
      }
    } catch (error) {}
  };

  const handleEdit = () => {
    if (edit === true) {
      handleEditContract();
      setEdit(false);
    } else {
      setEdit(true);
      setIsMilestoneOpen(true);
    }
  };

  const handleSaveOnBlur = (field) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/contract/edit-contract-details/${contractKey}/`,
        {
          edited_data: { [field]: editedData[field] },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setContractData((prevData) => ({
            ...prevData,
            [field]: editedData[field],
          }));
          setActiveEditField(null);
          setEdit(false);
        }
      })
      .catch((error) => {
        console.error(
          `Error saving field "${field}":`,
          error.response?.data?.msg || error.message
        );
      });
  };

  const cardRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        if (edit) {
          handleSaveOnBlur("Client name");
          handleSaveOnBlur("Client Contact Person name");
          handleSaveOnBlur("Client address");
          handleSaveOnBlur("Client Contact Person telephone");
          handleSaveOnBlur("Client Contact Person email");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [edit, handleSaveOnBlur]);

  return (
    <div
      ref={editRef}
      className={`contract-details-container ${
        isOpen ? "navbar-open" : "navbar-closed"
      }`}
    >
      <div className="full_Container">
        <div className="header-container">
          <Header
            icon="/arrow-back.svg"
            name="Go to Contracts"
            askCoPiolt={true}
            contractKey={contractKey}
          />
        </div>
        <div className="contract-details">
          {contractData ? (
            <>
              <header className="header">
                <div className="header-content">
                  <div className="contract-info">
                    <img
                      src="/hicon.svg"
                      alt="icon"
                      className="contract-icon"
                    />
                    <div>
                      <h1 className="contract-title">
                        {contractData["Contract Title"] || "N/A"}
                      </h1>
                      <p className="contract-type">
                        Contract type:{" "}
                        <span>{contractData["Contract Type"] || "N/A"}</span>
                      </p>
                    </div>
                  </div>
                  <button
                    className="view-contract-button-contract"
                    onClick={viewContract}
                  >
                    <span className="button-icon">
                      <img src="/eye.svg" alt="" />
                    </span>{" "}
                    View Contract
                  </button>
                </div>
              </header>

              {contractUrl && isPopupOpen && (
                <div className="contract-modal-overlay" onClick={closePopup}>
                  <div
                    className="contract-modal-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      // className="contract-close-button"
                      onClick={closePopup}
                    >
                      {/* &times; */}
                    </button>
                    <iframe
                      src={contractUrl}
                      title="Contract Document"
                      className="contract-iframe"
                    ></iframe>
                  </div>
                </div>
              )}

              <h4 className="customer-header">
                <span role="img" aria-label="icon">
                  <img src="/custDetails.svg" alt="" />
                </span>{" "}
                Customer Details
              </h4>
              <section className="customer-details">
                <div className="customer-cards">
                  <div className="customer-card">
                    <div className="flex items-center relative w-full gap-2">
                      <span className="party-label">Customer Party A:</span>
                      <div
                        style={{
                          flexGrow: 1,
                          maxWidth: "calc(100% - 40px)",
                          wordWrap: "break-word",
                        }}
                      >
                        {activeCustomerCard === "customerA" ? (
                          <input
                            className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[5px] font-outfit ml-10"
                            type="text"
                            value={
                              editedData["Client name"] !== undefined
                                ? editedData["Client name"]
                                : contractData["Client name"] || ""
                            }
                            onChange={(e) =>
                              handleInputChange("Client name", e.target.value)
                            }
                            onBlur={() => {
                              handleSaveOnBlur("Client name");
                              setActiveCustomerCard(null);
                            }}
                            // onFocus={() => handleEditClick("Client name")}
                          />
                        ) : (
                          <strong
                            className="cust-details-customer-a"
                            style={{
                              display: "block",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                          >
                            {contractData["Client name"] || "N/A"}
                          </strong>
                        )}
                      </div>
                     
                      {!activeCustomerCard && (
      <img
        src="/edit-icon.svg"
        alt="Edit"
        className="edit-icon cursor-pointer w-[20px] h-[20px] absolute right-[10px] top-1/2 transform -translate-y-1/2"
        onClick={() => handleEditClick("customerA")}
      />
    )}
                    </div>
                    <div className="signatory">
                      <strong>Customer Signatory:</strong>{" "}
                      {activeCustomerCard === "customerA" ?  (
                        <input
                          className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[4px] font-outfit ml-0"
                          type="text"
                          value={
                            editedData["Client Contact Person name"] !==
                            undefined
                              ? editedData["Client Contact Person name"]
                              : contractData["Client Contact Person name"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Client Contact Person name",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Client Contact Person name");
                            setActiveCustomerCard(null); // Close edit mode on blur
                          }}
                        />
                      ) : (
                        <span className="cust-details-signatory">
                          {contractData["Client Contact Person name"] || "N/A"}
                        </span>
                      )}
                    </div>
                    <p className="address">
                      <strong >Address:</strong>{" "}
                      {activeCustomerCard === "customerA" ?  (
                        <input
                          className="w-[312px] h-[65px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[42px] font-outfit ml-20"
                          type="text"
                          value={
                            editedData["Client address"] !== undefined
                              ? editedData["Client address"]
                              : contractData["Client address"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Client address", e.target.value)
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Client address");
                            setActiveCustomerCard(null);
                          }}
                        />
                      ) : (
                        <span className="cust-details">
                          {contractData["Client address"] || "N/A"}
                        </span>
                      )}
                    </p>
                    <div className="contact-info">
                      
                      <strong>Phone Number:</strong>{" "}
                    
                      {activeCustomerCard === "customerA" ? (
                        <input
                          className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[2px] font-outfit ml-5"
                          type="text"
                          value={
                            editedData["Client Contact Person telephone"] !==
                            undefined
                              ? editedData["Client Contact Person telephone"]
                              : contractData[
                                  "Client Contact Person telephone"
                                ] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Client Contact Person telephone",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Client Contact Person telephone");
                            setActiveCustomerCard(null);
                          }}
                        />
                      ) : (
                        <span className="phone-data">
                          {contractData["Client Contact Person telephone"] ||
                            "N/A"}
                        </span>
                      )}
                    </div>
                    <div className="contact-info">
                    <strong>Email Address:</strong>{" "}
                    {activeCustomerCard === "customerA" ? (
                        <input
                          className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[2px] font-outfit ml-6"
                          type="text"
                          value={
                            editedData["Client Contact Person email"] !==
                            undefined
                              ? editedData["Client Contact Person email"]
                              : contractData["Client Contact Person email"] ||
                                ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Client Contact Person email",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Client Contact Person email");
                            setActiveCustomerCard(null);
                          }}
                        />
                      ) : (
                        <span className="email-data">
                          {contractData["Client Contact Person email"] || "N/A"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="customer-card">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        position: "relative",
                        width: "100%",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span
                        className="party-label"
                        style={{
                          fontWeight: "bold",
                          flexShrink: 0,
                        }}
                      >
                        Customer Party B:
                      </span>
                      <div
                        style={{
                          flexGrow: 1,
                          maxWidth: "calc(100% - 40px)",
                          wordWrap: "break-word",
                        }}
                      >
                       {activeCustomerCard === "customerB" ? (
                          <input
                            className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[5px] font-outfit ml-8"
                            type="text"
                            value={
                              editedData["Company Name"] !== undefined
                                ? editedData["Company Name"]
                                : contractData["Company Name"] || ""
                            }
                            onChange={(e) =>
                              handleInputChange("Company Name", e.target.value)
                            }
                            onBlur={() => {
                              handleSaveOnBlur("Company Name");
                              setActiveCustomerCard(null);
                            }}
                          />
                        ) : (
                          <strong
                            className="cust-details-customer"
                            style={{
                              display: "block",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                          >
                            {contractData["Company Name"] || "N/A"}{" "}
                          </strong>
                        )}
                      </div>
                     
                      {!activeCustomerCard && (
      <img
        src="/edit-icon.svg"
        alt="Edit"
        className="edit-icon cursor-pointer w-[20px] h-[20px] absolute right-[10px] top-1/2 transform -translate-y-1/2"
        onClick={() => handleEditClick("customerB")}
      />
    )}
                      
                    </div>

                    <p className="signatory">
                      <strong>Customer Signatory:</strong>{" "}
                      {activeCustomerCard === "customerB" ? (
                        <input
                          className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[4px] font-outfit ml-1.2"
                          type="text"
                          value={
                            editedData["Company Contact Person Name"] !==
                            undefined
                              ? editedData["Company Contact Person Name"]
                              : contractData["Company Contact Person Name"] ||
                                ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Company Contact Person Name",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Company Contact Person Name");
                            setActiveCustomerCard(null);
                          }}
                        />
                      ) : (
                        <span className="cust-details-signatory">
                          {contractData["Company Contact Person Name"] || "N/A"}
                        </span>
                      )}
                    </p>

                    <p className="address">
                      <strong>Address:</strong>{" "}
                      {activeCustomerCard === "customerB" ? (
                        <input
                          className="w-[312px] h-[65px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[42px] font-outfit  ml-20"
                          type="text"
                          value={
                            editedData["Company Address"] !== undefined
                              ? editedData["Company Address"]
                              : contractData["Company Address"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Company Address", e.target.value)
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Company Address");
                            setActiveCustomerCard(null);
                          }}
                        />
                      ) : (
                        <span className="cust-details">
                          {contractData["Company Address"] || "N/A"}
                        </span>
                      )}
                    </p>

                    <div className="contact-info">
                    <strong>Phone Number:</strong>{" "}
                    {activeCustomerCard === "customerB" ? (
                        <input
                          className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[2px] font-outfit ml-5"
                          type="text"
                          value={
                            editedData["Company telephone"] !== undefined
                              ? editedData["Company telephone"]
                              : contractData["Company telephone"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Company telephone",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Company telephone");
                            setActiveCustomerCard(null);
                          }}                        />
                      ) : (
                        <span className="phone-data">
                          {contractData["Company telephone"] || "N/A"}
                        </span>
                      )}
                    </div>

                    <div className="contact-info">
                    <strong>Email Address:</strong>{" "}
                    {activeCustomerCard === "customerB" ? (
                        <input
                          className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[2px] font-outfit ml-6"
                          type="text"
                          value={
                            editedData["Company email"] !== undefined
                              ? editedData["Company email"]
                              : contractData["Company email"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Company email", e.target.value)
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Company email");
                            setActiveCustomerCard(null);
                          }}                        />
                      ) : (
                        <span className="email-data">
                          {contractData["Company email"] || "N/A"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <section className="key-dates-wrapper">
                <div className="key-dates">
                  <div
                    className="key-dates-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={() => toggleSection("keyDates")}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img src="/key-dates.svg" alt="Key Dates Icon" />
                      <h3>Key Dates</h3>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
           {isKeyDatesOpen && !activeKeyDate && (
          <img
            src="/edit-icon.svg"
            alt="Edit"
            style={{
              cursor: "pointer",
              width: "22px",
              height: "22px",
            }}
            onClick={() => handleEditClick("keyDates")}
          />
        )}

                      <img
                        src={
                          isKeyDatesOpen ? "/up-arrow.svg" : "/down-arrow.svg"
                        }
                        alt="Toggle Dropdown"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                        }}
                        onClick={() => setIsKeyDatesOpen((prev) => !prev)}
                      />
                    </div>
                  </div>
                  <p className="text-[#73607B] font-outfit text-[14px] font-medium leading-normal ml-[30px]">
                    View your all Important Key dates.
                  </p>
                  {isKeyDatesOpen && (
                    <div className="date-details">
                      <p className="effective">
                        Effective date:{" "}
                        {activeKeyDate === "keyDates" ? (
                          <div
                            className="unique"
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              ref={datePickerRef}
                              selected={
                                editedData["Effective Date"] &&
                                !isNaN(new Date(editedData["Effective Date"]))
                                  ? new Date(editedData["Effective Date"])
                                  : contractData["Effective Date"] &&
                                    !isNaN(
                                      new Date(contractData["Effective Date"])
                                    )
                                  ? new Date(contractData["Effective Date"])
                                  : null
                              }
                              onChange={(date) => handleDateChange("Effective Date", date)}
                              dateFormat="yyyy-MM-dd"
                              className="unique-input"
                              placeholderText="yyyy-MM-dd"
                              onBlur={() => {
                                handleSaveOnBlur("Effective Date");
                                setActiveKeyDate(null); // Close edit mode
                              }}
                              popperPlacement="top-start"
                              popperClassName="custom-datepicker-popper"
                              withPortal={false}
                            />
                            <img
                              className="unique-calendar-icon"
                              src="/calendar.svg"
                              alt="Calendar"
                              onClick={() =>
                                datePickerRef.current.setOpen(true)
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </div>
                        ) : (
                          <span className="keyValue">
                            {contractData["Effective Date"] || "N/A"}
                          </span>
                        )}
                      </p>

                      <p className="effective">
                        Execution date:{" "}
                        {activeKeyDate === "keyDates" ? (
                          <div
                            className="unique"
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              ref={executionDatePickerRef}
                              selected={
                                editedData["Execution Date"] &&
                                !isNaN(new Date(editedData["Execution Date"]))
                                  ? new Date(editedData["Execution Date"])
                                  : contractData["Execution Date"] &&
                                    !isNaN(
                                      new Date(contractData["Execution Date"])
                                    )
                                  ? new Date(contractData["Execution Date"])
                                  : null
                              }
                              onChange={(date) => handleDateChange("Execution Date", date)}
                              dateFormat="yyyy-MM-dd"
                              className="unique-input"
                              placeholderText="yyyy-MM-dd"
                              onBlur={() => {
                                handleSaveOnBlur("Execution Date");
                                setActiveKeyDate(null);
                              }}
                              popperPlacement="top-start"
                              popperClassName="custom-datepicker-popper"
                              withPortal={false}
                            />
                            <img
                              className="unique-calendar-icon"
                              src="/calendar.svg"
                              alt="Calendar"
                              onClick={() =>
                                executionDatePickerRef.current.setOpen(true)
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </div>
                        ) : (
                          <span className="keyValue">
                            {contractData["Execution Date"] || "N/A"}
                          </span>
                        )}
                      </p>

                      <p className="effective">
                        Payment date 1:{" "}
                        {activeKeyDate === "keyDates" ? (
                          <div
                            className="unique"
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              ref={paymentDate1PickerRef}
                              selected={
                                editedData["Payment date 1"] &&
                                !isNaN(new Date(editedData["Payment date 1"]))
                                  ? new Date(editedData["Payment date 1"])
                                  : contractData["Payment date 1"] &&
                                    !isNaN(
                                      new Date(contractData["Payment date 1"])
                                    )
                                  ? new Date(contractData["Payment date 1"])
                                  : null
                              }
                              onChange={(date) => handleDateChange("Payment date 1", date)}
                              dateFormat="yyyy-MM-dd"
                              className="unique-input"
                              placeholderText="yyyy-MM-dd"
                              onBlur={() => {
                                handleSaveOnBlur("Renewal Date");
                                setActiveKeyDate(null);
                              }}
                              popperPlacement="top-start"
                              popperClassName="custom-datepicker-popper"
                              withPortal={false}
                            />
                            <img
                              className="unique-calendar-icon"
                              src="/calendar.svg"
                              alt="Calendar"
                              onClick={() =>
                                paymentDate1PickerRef.current.setOpen(true)
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </div>
                        ) : (
                          <span className="keyValue">
                            {contractData["Payment date 1"] || "N/A"}
                          </span>
                        )}
                      </p>

                      <p className="effective">
                        Expiration date:{" "}
                        {activeKeyDate === "keyDates" ? (
                          <div
                            className="unique"
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              ref={expirationDatePickerRef}
                              selected={
                                editedData["Expiration Date"] &&
                                !isNaN(new Date(editedData["Expiration Date"]))
                                  ? new Date(editedData["Expiration Date"])
                                  : contractData["Expiration Date"] &&
                                    !isNaN(
                                      new Date(contractData["Expiration Date"])
                                    )
                                  ? new Date(contractData["Expiration Date"])
                                  : null
                              }
                              onChange={(date) => handleDateChange("Expiration Date", date)}
                              dateFormat="yyyy-MM-dd"
                              className="unique-input"
                              placeholderText="yyyy-MM-dd"
                              onBlur={() => {
                                handleSaveOnBlur("Expiration Date");
                                setActiveKeyDate(null); // Exit edit mode
                              }}                              popperPlacement="top-start"
                              popperClassName="custom-datepicker-popper"
                              withPortal={false}
                            />
                            <img
                              className="unique-calendar-icon"
                              src="/calendar.svg"
                              alt="Calendar"
                              onClick={() =>
                                expirationDatePickerRef.current.setOpen(true)
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </div>
                        ) : (
                          <span className="keyValue">
                            {contractData["Expiration Date"] || "N/A"}
                          </span>
                        )}
                      </p>

                      <p className="effective">
                        Renewal date:{" "}
                        {activeKeyDate === "keyDates" ? (
                          <div
                            className="unique"
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              ref={renewalDatePickerRef}
                              selected={
                                editedData["Renewal Date"] &&
                                !isNaN(new Date(editedData["Renewal Date"]))
                                  ? new Date(editedData["Renewal Date"])
                                  : contractData["Renewal Date"] &&
                                    !isNaN(
                                      new Date(contractData["Renewal Date"])
                                    )
                                  ? new Date(contractData["Renewal Date"])
                                  : null
                              }
                              onChange={(date) => handleDateChange("Renewal Date", date)}
                              dateFormat="yyyy-MM-dd"
                              className="unique-input"
                              placeholderText="yyyy-MM-dd"
                              onBlur={() => {
                                handleSaveOnBlur("Renewal Date");
                                setActiveKeyDate(null); // Exit edit mode
                              }}
                              popperPlacement="top-start"
                              popperClassName="custom-datepicker-popper"
                              withPortal={false}
                            />
                            <img
                              className="unique-calendar-icon"
                              src="/calendar.svg"
                              alt="Calendar"
                              onClick={() =>
                                renewalDatePickerRef.current.setOpen(true)
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </div>
                        ) : (
                          <span className="keyValue">
                            {contractData["Renewal Date"] || "N/A"}
                          </span>
                        )}
                      </p>

                      <p className="effective">
                        Payment date 2:{" "}
                        {activeKeyDate === "keyDates" ? (
                          <div
                            className="unique"
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              ref={paymentDate2PickerRef}
                              selected={
                                editedData["Payment date 2"] &&
                                !isNaN(new Date(editedData["Payment date 2"]))
                                  ? new Date(editedData["Payment date 2"])
                                  : contractData["Payment date 2"] &&
                                    !isNaN(
                                      new Date(contractData["Payment date 2"])
                                    )
                                  ? new Date(contractData["Payment date 2"])
                                  : null
                              }
                              onChange={(date) => handleDateChange("Payment date 2", date)}
                              dateFormat="yyyy-MM-dd"
                              className="unique-input"
                              placeholderText="yyyy-MM-dd"
                              onBlur={() => {
                                handleSaveOnBlur("Payment date 2");
                                setActiveKeyDate(null);
                              }}                              popperPlacement="top-start"
                              popperClassName="custom-datepicker-popper"
                              withPortal={false}
                            />
                            <img
                              className="unique-calendar-icon"
                              src="/calendar.svg"
                              alt="Calendar"
                              onClick={() =>
                                paymentDate2PickerRef.current.setOpen(true)
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </div>
                        ) : (
                          <span className="keyValue">
                            {contractData["Payment date 2"] || "N/A"}
                          </span>
                        )}
                      </p>
                    </div>
                  )}

                  <div className="milestone-section">
                    <div
                      className="milestone-header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => toggleSection("milestones")}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <h4 className="text-[#73607B] font-outfit text-[15px] font-semibold leading-normal">
                          Milestone Dates
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <img
                          src={
                            isMilestoneOpen
                              ? "/up-arrow.svg"
                              : "/down-arrow.svg"
                          }
                          alt="Toggle Dropdown"
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                            marginRight: "990px",
                          }}
                          onClick={() => setIsMilestoneOpen((prev) => !prev)}
                        />
                      </div>
                    </div>

                    {isMilestoneOpen && (
                      <div className="milestone-cards">
                        {[1, 2, 3].map((num) => {
                          const dateKey = `Milestone Date ${num}`;
                          const descKey = `Milestone Date ${num} Description`;
                          if (contractData[dateKey]) {
                            return (
                              <div className="milestone-card" key={num}>
                                <div className="milestone-header">
                                  <div className="milestone-date">
                                    <span
                                      className="calendar-icon-contract"
                                      role="img"
                                      aria-label="calendar"
                                    >
                                      <img
                                        src="/calendarmilestone.svg"
                                        alt="Calendar Icon"
                                      />
                                    </span>
                                  </div>
                                  <strong className="Strong-mile-date">
                                    {contractData[dateKey] || "N/A"}
                                  </strong>
                                  <span
                                    className="view-more"
                                    onClick={(event) =>
                                      handleMilestoneViewMore(
                                        {
                                          date: contractData[dateKey],
                                          description:
                                            contractData[descKey] ||
                                            "No description available",
                                        },
                                        event
                                      )
                                    }
                                  >
                                    View More
                                  </span>
                                </div>
                                <p className="milestone-description">
                                  {contractData[descKey] ||
                                    "No description available"}
                                </p>
                              </div>
                            );
                          }
                          return null;
                        })}
                        {activePopup && (
                          <div
                            className="popup-attached"
                            style={{
                              position: "fixed",
                              top: "40%",
                              left: "58%",
                              zIndex: 1000,
                              backgroundColor: "white",
                              padding: "17px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="popup-header">
                              <button className="milestone-button">
                                Milestone Date
                              </button>
                              <img
                                src="/mileStone-icon.svg"
                                alt=""
                                className="mileStone-icon"
                              />
                              <h3 className="popup-date">
                                {activePopup.milestone.date}
                              </h3>
                              <button
                                className="popup-close-contract"
                                onClick={closeMilestonePopup}
                              >
                                <img src="/cross-button.svg" alt="cross" />
                              </button>
                            </div>
                            <div className="popup-body">
                              <p className="popup-description">
                                {activePopup.milestone.description}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </section>

              <section className="terms-and-conditions-section">
  <div className="accordion-item">
    <div
      className="accordion-header"
      onClick={(e) => {
        if (!activeTermsAndConditions) {
          toggleSection("termsAndConditions");
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src="/term.svg" alt="" className="icon" />
        <p className="Terms-condition-title">Terms and Conditions</p>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        {openSections.termsAndConditions && !activeTermsAndConditions && (
          <img
            src="/edit-icon.svg"
            alt="Edit"
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              marginRight: "2px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick("TermsAndConditions");
            }}
          />
        )}
        <img
          src={
            openSections.termsAndConditions ? "/up-arrow.svg" : "/down-arrow.svg"
          }
          alt={openSections.termsAndConditions ? "Collapse" : "Expand"}
          style={{ cursor: "pointer", width: "20px", height: "20px" }}
        />
      </div>
    </div>

    {openSections.termsAndConditions && (
  <div className="accordion-content">
    <div className="terms-and-conditions-content">

      {/* Contract Duration */}
      <div className="contract-duration">
        <p className="label-terms">Contract Duration</p>
        <div className="contract-duration-container">

          {/* Contract From Date */}
          <div className="contract-date">
            <span className="date-label">From</span>
            {activeTermsAndConditions ? (
              <div className="datepicker-wrapper">
                <DatePicker
                  ref={fromDateRef}
                  selected={
                    editedData["Contract effective date"]
                      ? new Date(editedData["Contract effective date"])
                      : contractData["Contract effective date"]
                      ? new Date(contractData["Contract effective date"])
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange("Contract effective date", date)
                  }
                  dateFormat="yyyy-MM-dd"
                  className="date-input"
                  placeholderText="yyyy-MM-dd"
                  onBlur={() => {
                    handleSaveOnBlur("Contract effective date");
                    setActiveTermsAndConditions(false);
                  }}
                  popperPlacement="top-start"
                />
                <img
                  src="/calendar.svg"
                  alt="Calendar"
                  className="calendar-icon"
                  onClick={() => {
                    if (fromDateRef.current) {
                      fromDateRef.current.setOpen(true);
                    }
                  }}
                />
              </div>
            ) : (
              <span className="date-value">
                {contractData["Contract effective date"] || "N/A"}
              </span>
            )}
          </div>

          {/* Contract To Date */}
          <div className="contract-date">
            <span className="date-label">To</span>
            {activeTermsAndConditions ? (
              <div className="datepicker-wrapper">
                <DatePicker
                  ref={toDateRef}
                  selected={
                    editedData["Contract duration to date"]
                      ? new Date(editedData["Contract duration to date"])
                      : contractData["Contract duration to date"]
                      ? new Date(contractData["Contract duration to date"])
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange("Contract duration to date", date)
                  }
                  dateFormat="yyyy-MM-dd"
                  className="date-input"
                  placeholderText="yyyy-MM-dd"
                  onBlur={() => {
                    handleSaveOnBlur("Contract duration to date");
                    setActiveTermsAndConditions(false);
                  }}
                  popperPlacement="top-start"
                />
                <img
                  src="/calendar.svg"
                  alt="Calendar"
                  className="calendar-icon"
                  onClick={() => {
                    if (toDateRef.current) {
                      toDateRef.current.setOpen(true);
                    }
                  }}
                />
              </div>
            ) : (
              <span className="date-value">
                {contractData["Contract duration to date"] || "N/A"}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Payment Terms */}
      <div className="payment-terms">
        <p className="label-terms">Payment Terms</p>
        {activeTermsAndConditions ? (
          <textarea
            className="w-[946px] h-[104px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] p-[10px] ml-[35px] resize-none break-words font-outfit"
            value={
              editedData["Payment terms"] ?? contractData["Payment terms"] ?? ""
            }
            onChange={(e) =>
              handleInputChange("Payment terms", e.target.value)
            }
            onBlur={() => {
              handleSaveOnBlur("Payment terms");
              setActiveTermsAndConditions(false);
            }}
          />
        ) : (
          <p className="value">{contractData["Payment terms"] || "N/A"}</p>
        )}
      </div>

      <div style={{ borderTop: "1px solid #ccc", margin: "20px 0", width: "100%" }}></div>

      {/* Termination Clause */}
      <div 
  className="clause-header-contract flex items-center justify-between w-full cursor-pointer" 
  onClick={() => toggleClause("termination")}
>
  <p className="termination-title font-medium text-lg">Termination Clause</p>
  <img
    src={openClauses.termination ? "/up-arrow.svg" : "/down-arrow.svg"}
    alt={openClauses.termination ? "Collapse" : "Expand"}
    className="toggle-icon w-6 h-6"
  />
</div>

      {openClauses.termination && (
        <div className="clause-content">
          {activeTermsAndConditions ? (
            <textarea
              className="w-[946px] h-[104px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] p-[10px] ml-[35px] resize-none break-words whitespace-pre-wrap font-outfit"
              value={
                editedData["Termination clause"] ??
                contractData["Termination clause"] ??
                ""
              }
              onChange={(e) =>
                handleInputChange("Termination clause", e.target.value)
              }
              onBlur={() => {
                handleSaveOnBlur("Termination clause");
                setActiveTermsAndConditions(false);
              }}
            />
          ) : (
            <p className="termination-clause-desc">
              {contractData["Termination clause"] || "N/A"}
            </p>
          )}
        </div>
      )}
      <div style={{ borderTop: "1px solid #ccc", margin: "20px 0", width: "100%" }}></div>

      <div 
  className="clause-header flex items-center justify-between w-full cursor-pointer"
  onClick={() => toggleClause("confidentiality")}
>
  <p className="font-medium text-lg">Confidentiality Clause</p>
  <img
    src={openClauses.confidentiality ? "/up-arrow.svg" : "/down-arrow.svg"}
    alt={openClauses.confidentiality ? "Collapse" : "Expand"}
    className="toggle-icon w-6 h-6"
  />
</div>

      {openClauses.confidentiality && (
        <div className="clause-content">
          {activeTermsAndConditions ? (
            <textarea
              className="w-[946px] h-[104px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] p-[10px] resize-none break-words whitespace-pre-wrap ml-[35px] font-outfit"
              value={
                editedData["Confidentiality clause"] ??
                contractData["Confidentiality clause"] ??
                ""
              }
              onChange={(e) =>
                handleInputChange("Confidentiality clause", e.target.value)
              }
              onBlur={() => {
                handleSaveOnBlur("Confidentiality clause");
                setActiveTermsAndConditions(false);
              }}
            />
          ) : (
            <p className="termination-clause-desc">
              {contractData["Confidentiality clause"] || "N/A"}
            </p>
          )}
        </div>
      )}
      <div style={{ borderTop: "1px solid #ccc", margin: "20px 0", width: "100%" }}></div>

      {/* Governing Law */}
      <div 
  className="clause-header flex items-center justify-between w-full cursor-pointer"
  onClick={() => toggleClause("governing")}
>
  <p className="font-medium text-lg">Governing Law</p>
  <img
    src={openClauses.governing ? "/up-arrow.svg" : "/down-arrow.svg"}
    alt={openClauses.governing ? "Collapse" : "Expand"}
    className="toggle-icon w-6 h-6"
  />
</div>

      {openClauses.governing && (
        <div className="clause-content">
          {activeTermsAndConditions ? (
            <textarea
              className="w-[946px] h-[104px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] p-[10px] resize-none break-words whitespace-pre-wrap ml-[35px] font-outfit"
              value={
                editedData["Governing law"] ?? contractData["Governing law"] ?? ""
              }
              onChange={(e) =>
                handleInputChange("Governing law", e.target.value)
              }
              onBlur={() => {
                handleSaveOnBlur("Governing law");
                setActiveTermsAndConditions(false);
              }}
            />
          ) : (
            <p className="termination-clause-desc">
              {contractData["Governing law"] || "N/A"}
            </p>
          )}
        </div>
      )}
    </div>
  </div>
)}

  </div>
</section>


              <section className="financial-terms-section">
                <div className="accordion-item">
                  <div
                    className="accordion-header"
                    onClick={() => toggleSection("financialTerms")}
                  >
                    <p className="financial">
                      <span>
                        <img src="/financial.svg" alt="Financial Icon" />
                      </span>{" "}
                      Financial Terms
                    </p>
                    {openSections.financialTerms && !activeFinancialTerm && (
                      <img
                        src="/edit-icon.svg"
                        alt="Edit"
                        className="edit-icon"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                          marginRight: "12px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick("FinancialTerms"); // Only this section toggles edit mode
                        }}
                      />
                    )}
                    <div className="header-icons">
                      <img
                        src={
                          openSections.financialTerms
                            ? "/up-arrow.svg"
                            : "/down-arrow.svg"
                        }
                        alt={
                          openSections.financialTerms ? "Collapse" : "Expand"
                        }
                        className="toggle-icon-fin"
                      />
                    </div>
                  </div>
                  {openSections.financialTerms && (
                    <div className="accordion-content">
                     {activeFinancialTerm ? ( 
                        <textarea
                          className="w-full h-[150px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium p-[10px] resize-none whitespace-pre-wrap break-words font-outfit"
                          value={
                            editedData["Financial terms"] ||
                            contractData["Financial terms"] ||
                            ""
                          }
                          onChange={(e) =>
                            setEditedData((prevData) => ({
                              ...prevData,
                              "Financial terms": e.target.value,
                            }))
                          }
                          onBlur={() => {
                            handleSaveOnBlur("Financial terms");
                            setActiveFinancialTerm(false); // Exit edit mode after saving
                          }}
                                        />
                      ) : contractData["Financial terms"] ? (
                        <table className="financial-terms-table">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contractData["Financial terms"]
                              .split("\n")
                              .map((line, index) => {
                                const [description, value] = line.split(":");
                                return (
                                  <tr key={index}>
                                    <td>{description.trim()}</td>
                                    <td>{value ? value.trim() : "N/A"}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <p>No Financial Terms available.</p>
                      )}
                    </div>
                  )}
                </div>
              </section>

              <section className="legal-clauses-section">
  <div className="accordion-item">
    <div
      className="accordion-header"
      onClick={(e) => {
        if (!activeLegalClause) {
          toggleSection("legalClauses");
        }
      }}
    >
      <p className="financial">
        <span>
          <img src="/legal.svg" alt="Legal Icon" />
        </span>{" "}
        Legal Clauses
      </p>
      {openSections.legalClauses && !activeLegalClause && (
        <img
          src="/edit-icon.svg"
          alt="Edit"
          className="edit-icon"
          style={{
            cursor: "pointer",
            width: "20px",
            height: "20px",
            marginRight: "8px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleEditClick("LegalClauses"); // Enables edit mode only for Legal Clauses
          }}
        />
      )}
      <div className="header-icons">
        <img
          src={openSections.legalClauses ? "/up-arrow.svg" : "/down-arrow.svg"}
          alt={openSections.legalClauses ? "Collapse" : "Expand"}
          className="toggle-icon-legal"
        />
      </div>
    </div>

    {openSections.legalClauses && (
      <div className="accordion-content legal-clauses-container">
        {activeLegalClause ? (
          <textarea
            className="w-full h-[150px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium p-[10px] resize-none whitespace-pre-wrap break-words font-outfit"
            value={editedData["Legal clauses"] ?? contractData["Legal clauses"] ?? ""} // FIX: Ensuring value is not null
            onChange={(e) =>
              setEditedData((prevData) => ({
                ...prevData,
                "Legal clauses": e.target.value,
              }))
            }
            onBlur={(e) => {
              handleSaveOnBlur("Legal clauses");
              setActiveLegalClause(false); // Close edit mode when clicking outside
            }}
            onClick={(e) => e.stopPropagation()}
          />
        ) : contractData["Legal clauses"] ? (
          <div className="legal-clauses-list">
            {contractData["Legal clauses"]
              .split("\n")
              .map((clause, index) => (
                <p key={index} className="legal-clause-item">
                  {clause.trim()}
                </p>
              ))}
          </div>
        ) : (
          <p>No Legal Clauses available.</p>
        )}
      </div>
    )}
  </div>
</section>


              <section className="notes-section">
    <div className="notes-item" ref={notesRef}>
      <div className="notes-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <p className="notes" style={{ margin: 0 }}>
          <span>
            <img src="/notes.svg" alt="" />
          </span>{" "}
          Notes
        </p>
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <textarea
          placeholder="Enter your comments here..."
          className="notes-input custom-placeholder-invoice"
          style={{
            width: "95%",
            marginTop: "10px",
            padding: "10px",
            fontSize: "14px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            position: "relative",
          }}
          value={userNote}
          onChange={(e) => setUserNote(e.target.value)}
          onFocus={() => setIsEditingNote(true)} // Set editing flag when focused
        />
      </div>
      {isSaving && <p style={{ fontSize: "12px", color: "green", marginTop: "5px" }}></p>}
    </div>
  </section>
            </>
          ) : (
            <p>Loading contract details...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
