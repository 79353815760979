import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    fetchExcelChat,
    resetExcelChatState,
    fetchExcelSummary,
    updatePartialAnswer,
  } from "../../Features/excelChatSlice";
import { Header } from "../../components/Header/Header";
import axios from "axios";
import { format } from "date-fns";
import SpreadsheetViewer from "./SpreadsheetViewer";
import CustomBarChart from "./CustomBarChart";
function ExcelChat() {
  const location = useLocation();
  const { excelKey, excelUrl } = location.state || {};
  console.log("hgdhsdg",excelKey);
  console.log("haaha",excelUrl);
  
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const status = useSelector((state) => state.chat.status);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const textareaRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  const partialAnswer = useSelector(
    (state) => state.excelChat?.partialAnswer || ""
  );
  const barChart = useSelector((state) => state.excelChat.barChart) || [];
  console.log("jaaaa",barChart);

  const finalAnswer = useSelector(
    (state) => state.excelChat?.finalAnswer || ""
  );

  const partialSummary = useSelector(
    (state) => state.excelChat?.partialSummary || ""
  );
  const finalSummary = useSelector(
    (state) => state.excelChat?.finalSummary || ""
  );
  const summaryStatus = useSelector((state) => state.excelChat.summaryStatus);
console.log("smmrryryy",summaryStatus)




  useEffect(() => {
    if (excelKey) {
      dispatch(fetchExcelSummary({ excelKey }));
    }
  }, [excelKey, dispatch]);

  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const [showEarlierMessagesPopup, setShowEarlierMessagesPopup] =
    useState(false);



  useEffect(() => {
    if (partialSummary) {
      setChatHistory((prev) => {
        const lastMessage = prev[0];
        if (
          lastMessage?.sender === "bot" &&
          lastMessage.text.startsWith("Summary")
        ) {
          lastMessage.text = partialSummary;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialSummary
          )
        ) {
          return [
            { id: Date.now(), sender: "bot", text: partialSummary },

          ];
        }
        return prev;
      });
    }
  }, [partialSummary]);

  useEffect(() => {
    if (finalSummary) {
      console.log("✅ Final Summary Received in Component:", finalSummary);
      setChatHistory((prev) => {
        const lastMessage = prev[0];
        if (lastMessage?.sender === "bot") {
          lastMessage.text = finalSummary;
          return [...prev];
        }
        return [{ id: Date.now(), sender: "bot", text: finalSummary }, ...prev];
      });
      setIsSummaryCompleted(true);
    }
  }, [finalSummary]);

  useEffect(() => {
    if (excelKey) {
      console.log("Dispatching fetchExcelSummary for excelKey:", excelKey);
      dispatch(fetchExcelSummary({ excelKey }));
    }
  }, [excelKey, dispatch]);
  

  const formattedTimestamp = useSelector((state) => state.excelChat.formattedTimestamp);
  const userInitials = useSelector((state) => state.excelChat.userInitials || "N/A");

  const error = useSelector((state) => state.excelChat?.error);
  const generatedQuestions = useSelector((state) => state.excelChat.generatedQuestions);

  const { botMessageId, runId } = useSelector(
    (state) => state.excelChat
  );
  console.log("vidya",botMessageId);
  console.log("aydiv",runId);

  useEffect(() => {
    console.log("Generated Questions:", generatedQuestions);
  }, [generatedQuestions]);

  useEffect(() => {
    return () => {
      dispatch(resetExcelChatState());
    };
  }, [dispatch]);


  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);

  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
    return `<p>${formattedText}</p>`;
  }, []);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [clientMessage, setClientMessage] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);

  const navigate = useNavigate();


  const handleSend = (messageToSend) => {
    if (isAwaitingResponse || isSummaryLoading) return;
    const message = messageToSend || clientMessage;
    if (message.trim() && excelKey) {
      const userMessage = { id: Date.now(), sender: "user", text: message };
      setChatHistory((prev) => [
        ...prev,
        { ...userMessage, timestamp: new Date().toISOString() },
      ]);

      setChatHistory((prev) => [
        ...prev,
        {
          id: Date.now(),
          sender: "bot",
          text: "",
          final: false,
          timestamp: new Date().toISOString(),
        },
      ]);
      setIsThinking(true);
      setIsAwaitingResponse(true);
      dispatch(fetchExcelChat({ excelKey, clientMessage: message }));
      setClientMessage("");
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  useEffect(() => {
    if (status === "loading" && !partialAnswer) {
      setIsThinking(true);
    } else if (partialAnswer) {
      setIsThinking(false);
    }
  }, [status, partialAnswer]);

  useEffect(() => {
    if (status === "succeeded" || status === "failed") {
      setIsThinking(false);
    }
  }, [status]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = textAreaHeight;
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight;
      }
    };
  }, [textAreaHeight]);


  useEffect(() => {
    const fetchSummary = async () => {
      if (excelKey) {
        setIsSummaryLoading(true);
        try {
          setIsSummaryCompleted(false);
          setChatHistory((prev) => {
            if (
              !prev.some(
                (msg) => msg.sender === "bot" && msg.text === "Summarizing..."
              )
            ) {
              return [
                { id: Date.now(), sender: "bot", text: "Summarizing..." },
                ...prev,
              ];
            }
            return prev;
          });
  
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/v1/excel/summary-and-questions/?excel_key=${excelKey}`,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          const { excel_details, chat_history } = response.data;
          
          const {
            summary,
            example_questions,
            excel_url,
            run_id,
            bot_message_id,
          } = excel_details;
  
          if (summary) {
            setChatHistory((prev) => {
              const updatedHistory = prev
                .filter(
                  (msg) =>
                    msg.sender !== "bot" ||
                    (msg.text !== "Summarizing..." &&
                      msg.text !==
                        "Failed to fetch summary. Please try again later.")
                )
                .map((msg) =>
                  msg.sender === "bot" && msg.text === "Summarizing..."
                    ? { ...msg, text: summary }
                    : msg
                );
  
              // Ensure no duplicate summaries
              if (
                updatedHistory.some(
                  (msg) => msg.sender === "bot" && msg.text === summary
                )
              ) {
                return updatedHistory;
              }
  
              return [
                { id: Date.now(), sender: "bot", text: summary },
                ...updatedHistory,
              ];
            });
          }
  
          if (chat_history) {
            console.log("📜 Full chat history received from API:", chat_history);
          
            setChatHistory((prev) => {
              const existingMessageIds = new Set(prev.map((msg) => msg.id));
          
              const newMessages = chat_history
                .filter((item) => !existingMessageIds.has(item.message_id))
                .map((item) => ({
                  id: item.message_id, // Ensure correct message ID is used
                  sender: item.is_client ? "user" : "bot",
                  text: item.message,
                  timestamp: item.timestamp,
                  runId: item.run_id || null, // Store runId per message
                  messageId: item.message_id, // Ensure correct message ID is stored
                }));
          
              console.log("🆕 New messages being added to chatHistory:", newMessages);
              return [...prev, ...newMessages];
            });
          }
          
  
          setIsSummaryCompleted(true);
  
          if (example_questions) {
            const questions = example_questions.split(";").map((q) => q.trim());
            streamDummyMessages(questions);
          }
        } catch (error) {
          console.error("Error fetching summary and questions:", error);
          setChatHistory((prev) => {
            const hasValidData = prev.some(
              (msg) =>
                msg.sender === "bot" &&
                msg.text !== "Failed to fetch summary. Please try again later."
            );
  
            if (!hasValidData) {
              return [
                ...prev,
                {
                  id: Date.now(),
                  sender: "bot",
                  text: "Failed to fetch summary. Please try again later.",
                },
              ];
            }
  
            return prev;
          });
        } finally {
          setIsSummaryLoading(false);
          setIsAwaitingResponse(false);
        }
      }
    };
  
    fetchSummary();
  }, [excelKey]);
  

  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index);
      setClientMessage(dummyMessage.trim());
    }
  };

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() &&
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };

  const streamDummyMessages = (questions) => {
    const maxToShow = 2;
    const limitedQuestions = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(maxToShow).fill(""));
    limitedQuestions.forEach((question, index) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[index] = question.slice(0, charIndex + 1);
          return updatedMessages;
        });
        charIndex += 1;
        if (charIndex === question.length) clearInterval(interval);
      }, 10);
    });
  };

  const streamText = (text, callback, delay = 10) => {
    let charIndex = 0;
    const interval = setInterval(() => {
      const chunk = text.slice(0, charIndex + 1);
      callback(chunk);
      charIndex += 1;
      if (charIndex >= text.length) {
        clearInterval(interval);
      }
    }, delay);
  };

  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialAnswer
          )
        ) {
          return [
            ...prev,
            { sender: "bot", text: partialAnswer, final: false },
          ];
        }
        return prev;
      });
    }
  }, [partialAnswer]);
  useEffect(() => {
    if (finalAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
  
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = finalAnswer;
          lastMessage.final = true;
          lastMessage.runId = runId || "unknown-run-id"; // Ensure runId is set
          lastMessage.messageId = botMessageId || "unknown-message-id"; // Ensure messageId is set
  
          console.log("Updated last message with botMessageId and runId:", lastMessage);
          return [...prev];
        } else if (!prev.some((msg) => msg.sender === "bot" && msg.text === finalAnswer)) {
          const newBotMessage = {
            id: botMessageId || `generated-${Date.now()}`, // Ensure unique ID
            sender: "bot",
            text: finalAnswer,
            final: true,
            runId: runId || "unknown-run-id", // Ensure fallback for missing runId
            messageId: botMessageId || "unknown-message-id", // Ensure fallback for missing messageId
          };
  
          console.log("New bot message added:", newBotMessage);
          return [...prev, newBotMessage];
        }
  
        return prev;
      });
  
      setIsAwaitingResponse(false);
      setIsThinking(false);
      if (generatedQuestions) {
        const questions = generatedQuestions.split(";").map((q) => q.trim());
        streamDummyMessages(questions.slice(0, 2));
      }
      setShowDummyMessages(true);
    }
  }, [finalAnswer,generatedQuestions, runId, botMessageId]);
  

  const scrollToBottom = () => {
    if (chatContainerRef.current && !isUserScrolling) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, typedDummyMessages]);



  const [isScrolling, setIsScrolling] = useState(false);
  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isAtBottom = scrollHeight - clientHeight - scrollTop < 20;

      if (!isAtBottom) {
        setIsUserScrolling(true);
      } else {
        setIsUserScrolling(false);
      }

      if (scrollTop > 50) {
        setShowEarlierMessagesPopup(true);

        if (scrollHeight - clientHeight - scrollTop < 20) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      } else {
        setShowEarlierMessagesPopup(false);
        setIsScrolling(false);
      }

    }
  };

  let scrollTimeout;
  const handleScrollWithTimeout = () => {
    handleScroll();
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsUserScrolling(false), 1000);
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScrollWithTimeout);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScrollWithTimeout);
      }
      clearTimeout(scrollTimeout);
    };
  }, []);

  const submitFeedback = async (messageId, runId, feedbackType, userFeedback = "") => {
    console.log("Preparing to submit feedback...");
    console.log("Message ID:", messageId);
    console.log("Run ID:", runId);
    console.log("Feedback Type:", feedbackType);
    console.log("User Feedback:", userFeedback);
  
    if (!messageId || !runId || !feedbackType) {
      console.error("Missing required data for feedback submission.");
      return;
    }
  
    const payload = {
      message_id: messageId,
      run_id: runId,
      feedback_type: feedbackType,
      user_feedback: userFeedback.trim(),
    };
  
    console.log("🚀 Final Payload Sent to API:", payload);
  
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/contract/feedback/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("✅ API Response:", response.data);
  
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("🎉 Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("❌ Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("🚨 Error submitting feedback:", error);
    }
  };
  
  

  const handleThumbsUpClick = (messageId) => {
    console.log("🔍 Checking chat history before thumbs up click...");
  
    // Retrieve the clicked message
    const clickedMessage = chatHistory.find((msg) => msg.id === messageId);
  
    if (!clickedMessage) {
      console.error("❌ Clicked message not found in chat history.");
      return;
    }
  
    
  
    console.log("✅ Clicked Message Found:", clickedMessage);
  
    // Correctly extract bot message ID and run ID
    const correctBotMessageId = clickedMessage.messageId || clickedMessage.id;
    const correctRunId = clickedMessage.runId || "unknown-run-id";
  
    if (!correctBotMessageId || !correctRunId) {
      console.error("⚠️ Run ID or Message ID is missing for this message:", clickedMessage);
      return;
    }
  
    console.log("📌 Using Message ID:", correctBotMessageId);
    console.log("📌 Using Run ID:", correctRunId);
  
    // Update UI state for thumbs up
    setThumbsUpClicked((prev) => ({
      ...prev,
      [correctBotMessageId]: true, 
    }));
  
    setThumbsDownClicked((prev) => ({
      ...prev,
      [correctBotMessageId]: false,
    }));
  
    setPopupVisible(true);
  
    console.log("📤 Submitting thumbs-up feedback...");
    submitFeedback(correctBotMessageId, correctRunId, "up");
  };
  



  







const handleThumbsDownClick = (messageId) => {
  console.log("🔍 Checking chat history before thumbs down click...");
  console.log(chatHistory); // Full history before click

  // Find the correct message from chat history
  const clickedMessage = chatHistory.find((msg) => msg.id === messageId);

  if (!clickedMessage) {
    console.error("❌ Clicked message not found in chat history.");
    return;
  }

  console.log("✅ Clicked Message Found:", clickedMessage);

  // Ensure botMessageId and runId are retrieved correctly
  const correctBotMessageId = clickedMessage.messageId || clickedMessage.id;
  const correctRunId = clickedMessage.runId;

  if (!correctBotMessageId || !correctRunId) {
    console.error("⚠️ Run ID or Message ID is missing for this message:", clickedMessage);
    return;
  }

  console.log("📌 Using Message ID:", correctBotMessageId);
  console.log("📌 Using Run ID:", correctRunId);

  // Set thumbs-down state
  setThumbsDownClicked((prev) => ({
    ...prev,
    [messageId]: true,
  }));

  setThumbsUpClicked((prev) => ({
    ...prev,
    [messageId]: false,
  }));

  // Open thumbs-down feedback modal
  setShowThumbsDownPopup(true);

  // Store correct messageId and runId for feedback submission
  setMessageIdToFeedback(correctBotMessageId);
  setRunIdToFeedback(correctRunId);
};



  useEffect(() => {
    if (status === "succeeded") {
      const newBotMessage = {
        id: Date.now(),
        sender: "bot",
        text: finalAnswer,
      };
      setChatHistory((prev) => [...prev, newBotMessage]);
    }
  }, [status, finalAnswer]);

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => {
      const updatedState = {
        ...prev,
        [id]: checked,
      };
      console.log("Updated Checkbox State:", updatedState);
      return updatedState;
    });
  };
  const handleTextareaChange = (e) => {
    setFeedbackMessage(e.target.value);
  };
  const handleGoToInvoices = () => {
    navigate("/Invoices");
  };

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
        selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
        selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
        selectedFeedback.push("Not following instructions.");
    }

    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n").trim();
    console.log("Combined Feedback:", combinedFeedback);

    if (!messageIdToFeedback || !runIdToFeedback) {
        console.error("Missing messageId or runId for thumbs down feedback.");
        return;
    }

    try {
        await submitFeedback(messageIdToFeedback, runIdToFeedback, "down", combinedFeedback);

        // Reset feedback form
        setSelectedCheckboxes({
            factuallyIncorrect: false,
            refused: false,
            notFollowingInstructions: false,
        });
        setFeedbackMessage("");
        setShowThumbsDownPopup(false);
    } catch (error) {
        console.error("Error submitting thumbs-down feedback:", error);
    }
};


  const handleClosePopup = () => {
    setPopupVisible(false);
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

//   useEffect(() => {
//     return () => {
//       dispatch(resetInvoiceChatState());
//     };
//   }, [dispatch]);

  useEffect(() => {
    if (generatedQuestions) {
      const questions = generatedQuestions.split(";").map((q) => q.trim());
      streamDummyMessages(questions);
    }
  }, [generatedQuestions]);

  useEffect(() => {
    if (
      chatHistory.length > 0 &&
      chatHistory[chatHistory.length - 1]?.sender === "bot"
    ) {
      const newMessageId = chatHistory[chatHistory.length - 1]?.id;
      setThumbsUpClicked((prev) => ({
        ...prev,
        [newMessageId]: false,
      }));
      setThumbsDownClicked((prev) => ({
        ...prev,
        [newMessageId]: false,
      }));
    }
  }, [chatHistory]);


  const groupMessagesByDate = (messages) => {
    // Sort messages by timestamp in ascending order
    const sortedMessages = [...messages].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );

    const groupedMessages = {};

    sortedMessages.forEach((message) => {
      let label;

      try {
        const messageDate = message.timestamp
          ? new Date(message.timestamp)
          : null;

        if (!messageDate || isNaN(messageDate.getTime())) {
          label = formattedTimestamp || "Summary";
        } else {
          const today = new Date();
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);

          if (messageDate.toDateString() === today.toDateString()) {
            label = "Today";
          } else if (messageDate.toDateString() === yesterday.toDateString()) {
            label = "Yesterday";
          } else {
            label = format(messageDate, "dd MMM yyyy");
          }
        }
      } catch (error) {
        console.error("Error processing message timestamp:", message, error);
        label = "Invalid Date";
      }

      if (!groupedMessages[label]) {
        groupedMessages[label] = [];
      }
      groupedMessages[label].push(message);
    });

    return groupedMessages;
  };

  const groupedChatHistory = groupMessagesByDate(chatHistory);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (clientMessage.trim() !== "") {
      setShowEarlierMessagesPopup(false);
    }
  }, [clientMessage]);

  return (
    <div className="full-container">
      <div className="pl-[-10] -ml-16">
        <Header
          icon="/arrow-back.svg"
          name="Go to Spreadsheets"
          onGoToInvoices={() => navigate("/Spreadsheet")}
        />
      </div>
      <div className="content-container">
        <div className="left-side">
          {showEarlierMessagesPopup && (
            <div
              className="fixed transform -translate-x-1/2 inline-flex items-center justify-center gap-1.5 px-4 py-1 text-sm font-outfit font-normal text-primary-500 border border-black/10 rounded-full bg-gradient-to-r from-[#F5F4F3] to-[#FCDFF9] shadow-custom "
              style={{
                top: `${55}px`,
                left: `${438}px`,
                color: "#C95EBE",
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "normal",
                opacity: isScrolling ? "0.5" : "3",
              }}
            >
              <span>See earlier messages</span>
            </div>
          )}
          <div className="message-container " ref={chatContainerRef}>
            {Object.entries(groupedChatHistory)
              .sort(([dateA], [dateB]) => {
                if (dateA === "Today") return 1;
                if (dateB === "Today") return -1;
                return new Date(dateA) - new Date(dateB);
              })
              .map(([dateLabel, messages]) => (
                <div key={dateLabel}>
                  {/* Date Label */}
                  <div className="flex items-center justify-center my-4">
                    <div className="h-px w-[269px] bg-black/10"></div>
                    <div className="flex items-center justify-center gap-1 px-4 py-1.5 mx-3 rounded-full border border-black/10 shadow-md text-neutral-600 text-sm font-normal">
                      {dateLabel}
                    </div>
                    <div className="h-px w-[269px] bg-black/10"></div>
                  </div>

                  {/* Messages for the Date */}
                  {messages.map((message, index) => {
    // Check if this is the last bot message of the latest conversation
    const isLatestBotMessage =
      message.sender === "bot" &&
      index === messages.length - 1 && // Ensures it's the last message in the group
      dateLabel === "Today" && // Ensures it's today's latest response
      !isAwaitingResponse; // Hides chart when waiting for a response

    return (
      <div
        key={message.id || index}
        className={`mb-0 ${
          message.sender === "user"
            ? "w-full flex justify-end"
            : "bot-message-container w-full flex items-start"
        }`}
      >
        {/* Bot Messages */}
        {message.sender === "bot" && (
          <div className="bot-icon-container flex-shrink-0">
            <img
              src="/bot-icon.svg"
              alt="Bot Icon"
              className="bot-icon h-6 w-6 rounded-full mr-2"
            />
          </div>
        )}

        {message.sender === "bot" ? (
          
          <div className="flex flex-col">
            {(isThinking && index === chatHistory.length - 1) ||
            message.text === "" ? (
              <div className="thinking-indicator mb-7">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            ) : (
              <span
                className="bot-message-content"
                dangerouslySetInnerHTML={{
                  __html: formatText(message.text),
                }}
              />
            )}



{isLatestBotMessage && barChart && typeof barChart === "string" && barChart.trim() !== "" && (
  <div className="chart-container">
    {/* <h3>Bar Chart</h3> */}
    <img src={`data:image/png;base64,${barChart}`} alt="Bar Chart" />
  </div>
)}

{message.sender === "bot" && message.text && !isThinking && (isSummaryCompleted || finalAnswer) && (
                            <div className="reactions flex gap-2 text-gray-500 mt-2">
                              <span
                                className="reaction-icon cursor-pointer"
                                
                                onClick={() => {
                                  console.log("Thumbs up clicked for message:", message);
                                  console.log("Message ID:", message.id, "Run ID:", message.runId);
                                  handleThumbsUpClick(message.id, message.runId);
                                }}                                 >
                                <img
                                  src={
                                    thumbsUpClicked[message.id]
                                      ? "/thums-up-filled.svg"
                                      : "/thums-up.svg"
                                  }
                                  alt="Thumbs Up"
                                />
                                <span className="reaction-tooltip">
                                  Thumbs Up
                                </span>
                              </span>
                              <span
                                className="reaction-icon cursor-pointer"
                                onClick={() => {
                                  console.log("Thumbs down clicked for message:", message);
                                  console.log("Message ID:", message.id, "Run ID:", message.runId);
                                  handleThumbsDownClick(message.id, message.runId);
                                }}                                >
                                <img
                                  src={
                                    thumbsDownClicked[message.id]
                                      ? "/thums-down-filled.svg"
                                      : "/thums-down.svg"
                                  }
                                  alt="Thumbs Down"
                                />
                                <span className="reaction-tooltip">
                                  Thumbs Down
                                </span>
                              </span>
                              <span
                                className="reaction-icon cursor-pointer"
                                onClick={() =>
                                  handleCopy(message.id, message.text)
                                }
                              >
                                <img
                                  src={
                                    copiedMessageId === message.id
                                      ? "/copy-clicked.svg"
                                      : "/copy.svg"
                                  }
                                  alt="Copy"
                                />
                                <span className="reaction-tooltip">Copy</span>
                              </span>
                            </div>
                          )}

          </div>
        ) : (
          <div className="w-full flex justify-end items-start gap-4">
            <div className="user-message-bubble">{message.text}</div>
            <div className="user-icon-container flex-shrink-0 self-end">
              <div className="user-icon w-7 h-7 flex items-center justify-center rounded-full bg-white text-[#C95EBE] border border-[rgba(0,0,0,0.10)] text-[13px] font-outfit font-medium leading-none mr-3">
                {userInitials}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  })}

                </div>
              ))}

            {error && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}

            <div className="dummy-container flex flex-col gap-2 mr-20 mb-4">
              {showDummyMessages &&
                typedDummyMessages.map((question, index) => (
                  <div
                    key={index}
                    className={`dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center cursor-pointer ${clickedIndex === index ? "opacity-50" : "opacity-100"
                      }`}
                    onClick={() =>
                      handleDummyMessageClick(question.trim(), index)
                    }
                  >
                    <span className="icon mr-2">
                      <img src="/star-dot.svg" alt="Star Icon" />
                    </span>
                    <p className="text-sm">{question.trim()}</p>
                  </div>
                ))}
            </div>
          </div>

          <div className="custom-input-wrapper"
            style={{
              marginLeft: "-35px",
              marginTop: "-35px",
              backgroundColor: "#FEF9F7",
            }}>
            <div className="input-container-holder">

            <textarea
  placeholder={
    !isSummaryCompleted
      ? "Waiting for summary..."
      : isSummaryLoading
      ? "Summarizing..."
      : isAwaitingResponse
      ? "Copilot is responding..."
      : "Ask me anything"
  }
  className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
  style={{
    paddingTop: "3px",
    marginTop: "5px",
    lineHeight: "20px",
  }}
  value={clientMessage}
  onChange={(e) => {
    if (isSummaryCompleted) { // Allow typing only after summary is ready
      setClientMessage(e.target.value);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${Math.min(
          e.target.scrollHeight,
          88
        )}px`;
      }
    }
  }}
  onKeyDown={(e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (
        isSummaryCompleted &&  // Ensure summary is completed before sending
        !isAwaitingResponse &&
        !isSummaryLoading &&
        clientMessage.trim()
      ) {
        handleSend(clientMessage.trim());
        setClientMessage("");
      }
    }
  }}
  ref={textareaRef}
  disabled={!isSummaryCompleted} // Disable input field until summary is ready
/>





<button
  className="send-button-holder"
  onClick={() => {
    setTextAreaHeight("15px");
    handleSend();
  }}
  disabled={!isSummaryCompleted || isAwaitingResponse || isSummaryLoading || !clientMessage.trim()} // Ensure summary is completed before allowing send
>
  <img
    src={
      isSummaryLoading || isAwaitingResponse
        ? "/loading-send-button.svg"
        : "/send.svg"
    }
    alt="Send"
  />
</button>

            </div>
          </div>

        </div>
        <div className="right-side">
        <SpreadsheetViewer fileUrl={excelUrl} />

        </div>
      </div>
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${isSubmitDisabled()
                      ? "disabled-button-thumsdown"
                      : "enabled-button-thumsdown"
                    }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExcelChat;