import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid'; // Importing the UUID function
// Example icons: update these imports if needed
import arrowForward2 from "../../Images/arrow_forward-2.svg";
import ChatHistory from "./ChatHistory.jsx";

// Your custom CSS
import "../AI_Copilot/PromptChat.css";
import ThinkingIndicator from "../../components/Thinking-Animation.jsx";
const PromptChat = ({
  documentKey = "",
  documentUrl = "",
  documentType = "",
  selectedDocumentName = "",
  selectedDoc = "",
  finalResponse = "",
  generatedQuestions = [],
  chatId,
  initialMessages = [],    // The array of message objects passed from AIcopilot
  onMessagesUpdated = () => { },
  isMenuOpen,
  /** The text from parent you want automatically sent as a user message on mount **/
  chatInput,
  onNewChat,
  action,
  SummarizeDocKey,
  SummarizeDocUrl,
  SummarizeDocType,
  isNewSession,
  token,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("getting document key",SummarizeDocKey);
  // console.log("Chat id from parent ",chatId);

  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);

  // =============== STATE ===============

  // -- (A) Restore chatHistory from localStorage, or start with []
  const [chatHistory, setChatHistory] = useState([]);
  // map them into your local message shape:
  useEffect(() => {
    if (Array.isArray(initialMessages) && initialMessages.length > 0) {
      const mapped = initialMessages.map((msg) => ({
        id: msg.message_id,
        sender: msg.is_client ? "user" : "bot",
        text: msg.message,
        run_id:msg.run_id,
        final: true,
      }));
      setChatHistory(mapped);
    } else {
      setChatHistory([]);
    }
  }, []); // Run only once on mount


  /// states to track selection of object and values...
  // 1) State for dynamic dropdown
  const [showObjects, setShowObjects] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [filteredObjects, setFilteredObjects] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [showAtDropdown, setShowAtDropdown] = useState(false);
  const [filteredAtOptions, setFilteredAtOptions] = useState([]);
  const [PromptChatInput, setPromptChatInput] = useState("");

  // 2) Object-to-values mapping
  const objectOptions = useMemo(() => ({
    Documents: documents.map(doc => ({
      id: doc.id,
      name: doc.name,
      url: doc.url,
      type: "document"
    })),
    Contracts: contracts.map(contract => ({
      id: contract.id,
      name: contract.name,
      url: contract.url,
      type: "contract"
    })),
    Invoices: invoices.map(invoice => ({
      id: invoice.id,
      name: invoice.name,
      url: invoice.url,
      type: "invoice"
    })),
    Spreadsheets: documents.filter(doc => doc.name.endsWith(".xlsx") || doc.name.endsWith(".csv"))
      .map(doc => ({
        id: doc.id,
        name: doc.name,
        url: doc.url,
        type: "spreadsheet"
      })),

  }), [documents, contracts, invoices, documents]); // Ensure it updates when API data changes

  useEffect(() => {
    if (showObjects) {
      // Once objectOptions updates, update the dropdown items.
      setFilteredObjects(Object.keys(objectOptions));
    }
  }, [objectOptions, showObjects]);


  const objectIcons = {
    Documents: <img src="/ai-object-document-icon.svg" alt="Document Icon" />,
    Contracts: <img src="/ai-object-contract-icon.svg" alt="contracts Icon" />,
    Invoices: <img src="/ai-object-invoice-icon.svg" alt="Invoice Icon" />,
    Spreadsheets: <img src="/ai-object-spread-icon.svg" alt="spreadsheet Icon" />
  };

  const fetchAllDocuments = async () => {
    if (!token) {
      console.warn("⚠️ No token provided. Please login.");
      return;
    }

    // Prevent duplicate API calls
    // if (documents.length || contracts.length || invoices.length) {
    //   console.log("⚠️ Skipping API call. Documents already fetched.");
    //   return;
    // }

    console.log("📢 Fetching documents from API...");

    const url = `${process.env.REACT_APP_API_URL}api/v1/copilot/all-docs/`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("📌 Full API Response:", response.data.all_documents);

      const { contracts = [], invoices = [], documents = [], spreadsheet = [] } =
        response.data.all_documents || {};

      console.log("✅ Fetched Contracts:", contracts);
      console.log("✅ Fetched Invoices:", invoices);
      console.log("✅ Fetched Other Documents:", documents);

      // Correctly set state
      setContracts(contracts.map((doc) => ({ id: doc.id, name: doc.name, url: doc.url, type: "contract" })));
      setInvoices(invoices.map((doc) => ({ id: doc.id, name: doc.name, url: doc.url, type: "invoice" })));
      setDocuments(documents.map((doc) => ({ id: doc.id, name: doc.name, url: doc.url, type: "document" })));
      // setSpreadsheet(spreadsheet.map((doc) => ({ id: doc.id, name: doc.name, url: doc.url, type: "spreadsheet" })));

    } catch (error) {
      console.error("❌ Error fetching documents:", error);
    }
  };

  const handleAtSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();

    const allDocs = [
      ...documents,
      ...contracts,
      ...invoices,
      // ...spreadsheets
    ].map(doc => ({
      ...doc,
      display: `${doc.name} (${doc.type})`, // Adds document type as context
      id: doc.id || doc.key || uuidv4(),
    }));

    const filtered = allDocs.filter(doc =>
      doc.name.toLowerCase().includes(searchValue)
    );

    setFilteredAtOptions(filtered);
  };

  const handleValuesSearch = (e) => {
    const query = e.target.value.toLowerCase();
    // Determine the full list based on the selected object.
    let fullList = [];
    if (selectedObject === "Documents") {
      fullList = documents;
    } else if (selectedObject === "Contracts") {
      fullList = contracts;
    } else if (selectedObject === "Invoices") {
      fullList = invoices;
    } else if (selectedObject === "Spreadsheets") {
      // fullList = spreadsheet;
    }
    if (query.trim() === "") {
      // If search query is empty, reset to the full list.
      setFilteredValues(fullList);
    } else {
      // Filter the full list based on the query.
      const filtered = fullList.filter((item) =>
        item.name.toLowerCase().includes(query)
      );
      setFilteredValues(filtered);
    }
  };

  const handlePromptChatChange = (e) => {
    const value = e.target.value;
    setPromptChatInput(value);
    setClientMessage(value);
  
    // When input is empty, clear dropdowns and the selected object.
    if (value.trim() === "") {
      setShowAtDropdown(false);
      setSelectedObject(null); // Clear selected object so that all documents appear later.
    }
  
    // Trigger fetch when user types "/" (forward slash) and show top-level objects dropdown.
    if (value.endsWith("/")) {
      console.log("✅ Forward slash detected. Fetching documents...");
      fetchAllDocuments(); // Call API only when "/" is typed.
      setShowObjects(true);
      setFilteredObjects(Object.keys(objectOptions)); // Show top-level objects.
    } else {
      setShowObjects(false);
    }
  
    // Trigger "@" dropdown when value ends with "@".
    if (value.endsWith("@")) {
      console.log("✅ At symbol detected. Showing documents dropdown...");
      fetchAllDocuments();
      let options = [];
      if (selectedObject) {
        // Show values for the selected object only.
        if (selectedObject === "Contracts") {
          options = contracts.map(doc => ({ ...doc, display: doc.name }));
        } else if (selectedObject === "Invoices") {
          options = invoices.map(doc => ({ ...doc, display: doc.name }));
        } else if (selectedObject === "Spreadsheets") {
          options = documents
            .filter(doc => doc.name.endsWith(".xlsx") || doc.name.endsWith(".csv"))
            .map(doc => ({ ...doc, display: doc.name }));
        }
      } else {
        // Combine all document types
        options = [
          ...documents,
          ...contracts,
          ...invoices,
        ].map(doc => ({ ...doc, display: `${doc.name}` }));
      }
      setFilteredAtOptions(options);
      setShowAtDropdown(true);
    } else {
      // Hide the "@" dropdown if the user types further.
      setShowAtDropdown(false);
    }
  };
  


  const getSingular = (obj) => {
    if (obj === "Contracts") return "Contract";
    if (obj === "Invoices") return "Invoice";
    if (obj === "Spreadsheets") return "Spreadsheet";
    return obj;
  };


  const handleObjectSelection = (obj) => {
    // Remove the trailing "/" from the input and append the object name with " : "
    setPromptChatInput((prev) => prev.slice(0, -1) + obj + " : ");
    setShowObjects(false);
    setSelectedObject(obj);

    setTimeout(() => {
      let values = [];
      if (obj === "Documents") {
        values = [...documents];
      } else if (obj === "Contracts") {
        values = [...contracts];
      } else if (obj === "Invoices") {
        values = [...invoices];
      } else if (obj === "Spreadsheets") {
        // values = [...spreadsheet];
      }
      console.log(`📢 Delayed Selected Object: ${obj}, Values Assigned:`, values);
      setFilteredValues(values);
      setShowValues(true);
    }, 100);
  };
  

  // Updated: when selecting a value, simply append the value text to the input.
  const handleValueSelection = (val) => {
    console.log("✅ Selected Value:", val);

    // Check if `val` is an object or string
    if (typeof val === "object") {
      // Usually val = { id, name, url, type, ... }
      setPromptChatInput((prev) => prev + (val.name || "Unknown") + " ");
    } else {
      // val is just a string from the fallback array
      setPromptChatInput((prev) => prev + val + " ");
    }

    setShowValues(false);
    setSelectedObject(null);
  };

  const handleAtSelection = (doc) => {
    console.log("✅ Selected document from @ dropdown:", doc);

    // Remove the trailing "@" and add the selected document's display name.
    setPromptChatInput(prev => prev.slice(0, -1) + doc.display + " ");

    setShowAtDropdown(false);
  };

  useEffect(() => {
    if (token) {
      fetchAllDocuments();
    }
  }, [token]);

   const dropdownValuesRef = useRef(null);
  
    useEffect(() => {
      // Hide dropdown when clicking outside
      const handleClickOutside = (event) => {
        if (dropdownValuesRef.current && !dropdownValuesRef.current.contains(event.target)) {
          setShowValues(false);
        }
      };
  
      // Hide dropdown when Backspace is pressed
      const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
          setShowValues(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);


  const executeSummarize = () => {
    if (!isNewSession) return;
    console.log("2", isNewSession);

    sentRef.current += 1;
    console.log("useEffect triggered", { chatInput, action, sentRef: sentRef.current });

    // if (sentRef.current ) return;
    // If the action is specifically "summarize"
    if (sentRef.current === 1 && action === "summarize") {
      sentRef.current = true;
      handleSummarize();
    }
  };

  const executeSendPrompt = () => {
    if (!isNewSession) return;
    console.log("2", isNewSession);

    sentRef.current += 1;
    console.log("useEffect triggered", { chatInput, action, sentRef: sentRef.current });

    if ((sentRef.current === 1 && chatInput?.trim())) {
      // console.log("ansewrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");

      // sentRef.current = true;
      handleSendPrompt(chatInput?.trim());
    }
  }

  /// fetching chat history for reload
  const handleChatItemClick = async (chatId) => {
    console.log("Chat title clicked, chatId:", chatId);
    const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}api/v1/copilot/get-chat-sessions/${chatId}/`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("Fetched chat session data:", data);
      const mappedChatHistory = data.chat_history.map((msg) => ({
        id: msg.message_id, // Unique id for React key
        sender: msg.is_client ? "user" : "bot",
        text: msg.message,
        run_id: msg.run_id,
        // Include any additional fields if needed (like timestamp, document_title, etc.)
      }));
      console.log("mappedChatHistory",mappedChatHistory);
      

      // Update state with the mapped chat history
      setChatHistory(mappedChatHistory);
      console.log("Reloaded chat_history", mappedChatHistory);


    } catch (error) {
      console.error("Error fetching chat session:", error);
    }
  };

  useEffect(() => {
    console.log("action", action);
    console.log("isNewSession", isNewSession);

    if (action === "summarize" && isNewSession) {
      executeSummarize();
      console.log("summarizeeeeee");

    } else if (chatInput?.trim() && isNewSession) {
      executeSendPrompt();
      console.log("sendprompttttttt");
    }
    else if (chatId && isNewSession) {
      handleChatItemClick(chatId);
      console.log("elseeeeeee");
    }
  }, [chatId, action, chatInput, isNewSession]);

  // useEffect(() => {
  //   processChatAction();
  // }, [chatInput, action, isNewSession]);



  // Helper to push new messages into local + parent
  // const setChatHistory = (updater) => {

  //   setChatHistory((prev) => {
  //     console.log("andarrrrrrrrrr");

  //     const updated =
  //       typeof updater === "function" ? updater(prev) : updater || prev;

  //     // Only call onMessagesUpdated if the updated state is different from the previous state
  //     onMessagesUpdated(updated); 

  // console.log("updated",updated);

  //     return updated;
  //   });
  // };



  // -- (B) Restore chatResponse (finalResponse) from localStorage, or empty
  // const [chatResponse, setChatResponse] = useState(() => {
  //   try {
  //     return localStorage.getItem("promptChat_finalResponse") || "";
  //   } catch {
  //     return "";
  //   }
  // });
  // Update localStorage whenever chatResponse changes
  // useEffect(() => {
  //   localStorage.setItem("promptChat_finalResponse", chatResponse);
  // }, [chatResponse]);

  // // Update chatResponse whenever parent’s finalResponse changes
  // useEffect(() => {
  //   if (finalResponse) {
  //     setChatResponse(finalResponse);
  //   }
  // }, [finalResponse]);

  // -- (C) Restore generatedPromptQuestions from localStorage
  const [generatedPromptQuestions, setGeneratedPromptQuestions] = useState([]);
  // => {
  //   try {
  //     const stored = localStorage.getItem("promptChat_questions");
  //     return stored ? JSON.parse(stored) : [];
  //   } catch {
  //     return [];
  //   }
  // });
  // // Persist to localStorage
  // useEffect(() => {
  //   localStorage.setItem(
  //     "promptChat_questions",
  //     JSON.stringify(generatedPromptQuestions)
  //   );
  // }, [generatedPromptQuestions]);

  // -- (D) Restore clientMessage from localStorage
  const [clientMessage, setClientMessage] = useState();
  //  => {
  //   try {
  //     return localStorage.getItem("promptChat_clientMessage") || "";
  //   } catch {
  //     return "";
  //   }
  // });
  // // Persist to localStorage
  // useEffect(() => {
  //   localStorage.setItem("promptChat_clientMessage", clientMessage);
  // }, [clientMessage]);

  // Additional states if you need them
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [feedbackId, setFeedbackId] = useState("");
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  // Redux or other selectors
  const error = useSelector((state) => state.pdfChat?.error);


  // If parent used react-router state to pass data
  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl, docType } = location.state || {};

  // internal key for doc
  const [pdfKey, setPdfKey] = useState(dynamicPdfKey || "");
  const [pdfUrl, setPdfUrl] = useState("");

  const [clickedIndex, setClickedIndex] = useState(null);

  // If you want a "local" chatId instead of the parent's
  // (E) Restore from localStorage or use parent's
  // const [localChatId, setLocalChatId] = useState(() => {
  //   try {
  //     return localStorage.getItem("promptChat_chatId") || chatId || null;
  //   } catch {
  //     return chatId || null;
  //   }
  // });
  // console.log("localChatId",localChatId);

  // Whenever localChatId changes, persist it
  // useEffect(() => {
  //   if (localChatId) {
  //     localStorage.setItem("promptChat_chatId", localChatId);
  //   } else {
  //     localStorage.removeItem("promptChat_chatId");
  //   }
  // }, [localChatId]);

  // =============== EFFECTS ===============

  // If we have a dynamic PDF URL
  useEffect(() => {
    if (dynamicPdfUrl) {
      setPdfUrl(dynamicPdfUrl);
    }
  }, [dynamicPdfUrl]);

  // Auto-scroll to bottom on chatHistory update
  // useEffect(() => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTo({
  //       top: chatContainerRef.current.scrollHeight,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [chatHistory]);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    // If user is within 50px of the bottom, keep auto-scroll on; otherwise disable it
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 50;
    setAutoScrollEnabled(isNearBottom);
  };

  useEffect(() => {
    if (!chatContainerRef.current) return;
    const container = chatContainerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (autoScrollEnabled && chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory, autoScrollEnabled]);



  // If parent passes new generatedQuestions, store them
  const streamDummyMessages = useCallback((questions) => {
    const maxToShow = 2;
    const limited = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(limited.length).fill(""));

    limited.forEach((question, idx) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updated = [...prev];
          updated[idx] = question.slice(0, charIndex + 1);
          return updated;
        });
        charIndex++;
        if (charIndex >= question.length) clearInterval(interval);
      }, 30);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(generatedQuestions) && generatedQuestions.length > 0) {
      streamDummyMessages(generatedQuestions);
      // Also store them in state so they remain after reload
      setGeneratedPromptQuestions(generatedQuestions);
    }
  }, [generatedQuestions, streamDummyMessages]);

  useEffect(() => {
    if (generatedPromptQuestions.length > 0) {
      streamDummyMessages(generatedPromptQuestions);
    }
  }, [generatedPromptQuestions, streamDummyMessages]);


  const hasTriggered = useRef(false);
  const sentRef = useRef(0);




  // Reset clickedIndex when new questions are generated
  useEffect(() => {
    setClickedIndex(null);
  }, [generatedPromptQuestions]);

  // =============== NEW CHAT (OPTIONAL) ===============
  const [newChatId, setNewChatId] = useState(null);

  // const initiateNewChat = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}api/v1/copilot/new-chat/`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Token ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.data.chat_id) {
  //       setNewChatId(response.data.chat_id);
  //       toast.success("New Chat Started");
  //       setLocalChatId(response.data.chat_id);
  //     } else {
  //       throw new Error("Chat ID not received");
  //     }
  //   } catch (error) {
  //     console.error("Error initiating chat:", error);
  //     toast.error("Failed to start a new chat. Please try again.");
  //   }
  // };
  // console.log("actioonnnnnn",action);
  // console.log(SummarizeDocKey,"keyyyy");

  // =============== MAIN SEND PROMPT (WS) ===============
  const handleSendPrompt = (promptValue) => {
    console.log("handleSendPrompt invoked with:", promptValue);

    const generateUniqueId = () =>
      `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

    // 1) Add user message
    const userMsgId = generateUniqueId();
    const cleanedDocumentName = selectedDoc
      ? selectedDocumentName.replace(/\.pdf$/i, "")
      : ""; const userMessage = {
        id: userMsgId,
        sender: "user",
        text: selectedDoc
          ? `${promptValue} : ${cleanedDocumentName}`  // If document is selected, include its name.
          : promptValue, final: true,
      };
    console.log("userMessage", userMessage);
    console.log("selectedDocumentName", selectedDocumentName);


    // 2) Placeholder bot message
    const botMsgId = generateUniqueId();
    const botPlaceholder = {
      id: botMsgId,
      sender: "bot",
      text: "",
      final: false,
    };
    setChatHistory((prev) => [...prev, userMessage, botPlaceholder]);
    console.log("botPlaceholder", botPlaceholder);
    console.log("chatHistory", chatHistory);


    let finalReceived = false;

    setIsLoadingResponse(true);
    setGeneratedPromptQuestions([]);
    setAutoScrollEnabled(true);

    // Decide WS URL & payload
    let wsUrl;
    let payload;

    if (documentKey) {
      wsUrl = `${process.env.REACT_APP_WS_URL}/ws/prompt-library-answer/${chatId}/?token=${localStorage.getItem("token")}`;
      payload = { document_key: documentKey, prompt: promptValue };
    } else {
      wsUrl = `${process.env.REACT_APP_WS_URL}/ws/qdrant-copilot-chat/${chatId}/?token=${localStorage.getItem("token")}`;
      payload = { client_message: promptValue };
    }

    try {
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        ws.send(JSON.stringify(payload));
        // console.log("payload",payload);

      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          // Append streaming chunks to the bot placeholder
          if (data.type === "progress" && data.chunk) {
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + data.chunk }
                  : msg
              )
            );
          }

          // Handle final chunk
          if (data.type === "final") {
            finalReceived = true;
            const finalChunk = data.chunk || "";
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + finalChunk, final: true }
                  : msg
              )
            );
            console.log(finalChunk);

            setIsLoadingResponse(false);

            if (data.data && data.data.run_id) {
             const runId = data.data.run_id ;
              setFeedbackId(runId);
              console.log(feedbackId);
              
              console.log("Setting feedbackId:", runId); // Logs the correct value
            }
           
            

            // If we have generated questions
            if (data.data && data.data.generated_questions) {
              const questionsArray = data.data.generated_questions
                .split(";")
                .map((q) => q.trim())
                .filter(Boolean)
                .slice(0, 2);
              setGeneratedPromptQuestions(questionsArray);
            }

            ws.close();
          }
        } catch (err) {
          console.error("Error parsing WS data:", err);
        }
      };

      ws.onerror = (err) => {
        console.error("WebSocket error:", err);
        // toast.error("WebSocket error occurred. Please try again.");
        setIsLoadingResponse(false);

        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) => {
              if (msg.id === botMsgId && !msg.final) {
                return {
                  ...msg,
                  text:
                    "I'm sorry, I'm having trouble contacting the server right now. Please try again later.",
                  final: true,
                };
              }
              return msg;
            })
          );
        }
      };

      ws.onclose = () => {
        setIsLoadingResponse(false);

        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) => {
              if (msg.id === botMsgId && !msg.final) {
                return {
                  ...msg,
                  text:
                    "I'm sorry, I couldn't complete your request. The connection was closed or timed out.",
                  final: true,
                };
              }
              return msg;
            })
          );
        }
      };
    } catch (error) {
      console.error("WebSocket init error:", error);
      toast.error("Failed to connect to WebSocket. Please try again.");
      setIsLoadingResponse(false);

      // Fallback bot message if WebSocket fails
      setChatHistory((prev) =>
        prev.map((msg) => {
          if (msg.id === botMsgId && !msg.final) {
            return {
              ...msg,
              text:
                "I'm sorry, I'm having trouble connecting at the moment. Please try again later.",
              final: true,
            };
          }
          return msg;
        })
      );
    }
  };

  useEffect(() => {
    console.log("Updated feedbackId:", feedbackId);
  }, [feedbackId]);
  
  const handleSummarize = () => {
    console.log("summarizing api called", SummarizeDocKey);

    // Optional: Add a user message here if you want the UI to show "Summarize the document"
    // e.g.:
    // const generateUniqueId = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    // const userMsgId = generateUniqueId();
    // const userMessage = { id: userMsgId, sender: "user", text: "Summarize the document", final: true };
    // setChatHistory((prev) => [...prev, userMessage]);

    // 1) Placeholder bot message
    const generateUniqueId = () =>
      `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    const botMsgId = generateUniqueId();
    const botPlaceholder = {
      id: botMsgId,
      sender: "bot",
      text: "",
      final: false,
    };
    setChatHistory((prev) => [...prev, botPlaceholder]);

    let finalReceived = false;

    setIsLoadingResponse(true);
    setGeneratedPromptQuestions([]);
    setAutoScrollEnabled(true);

    // Summarize WS URL & payload
    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/pdf-summary-and-chats/${SummarizeDocKey}/?token=${localStorage.getItem("token")}`;
    const payload = {
      pdfKey: SummarizeDocKey,
      client_message: "summarize the document",
    };

    try {
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        ws.send(JSON.stringify(payload));
      };

      ws.onmessage = (event) => {
        try {
          // Some summary endpoints send data prefixed with "data:"
          const rawData = event.data.startsWith("data:")
            ? event.data.slice(5).trim()
            : event.data;
          const data = JSON.parse(rawData);

          if (data.type === "progress" && data.chunk) {
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + data.chunk }
                  : msg
              )
            );
          }

          if (data.type === "final" || data.pdf_details) {
            finalReceived = true;
            const finalChunk = data.chunk || "";
            setChatHistory((prev) =>
              prev.map((msg) => {
                if (msg.id === botMsgId && !msg.final) {
                  return { ...msg, text: msg.text + finalChunk, final: true };
                }
                return msg;
              })
            );
            setIsLoadingResponse(false);

            if (data.pdf_details && data.pdf_details.run_id) {
              const runId = data.pdf_details.run_id ;
               setFeedbackId(runId);
               console.log(feedbackId);
               
               console.log("Setting feedbackId:", runId); // Logs the correct value
             }

            // If we have generated questions
            if (data.pdf_details && data.pdf_details.example_questions) {
              const questionsArray = data.pdf_details.example_questions
                .split(";")
                .map((q) => q.trim())
                .filter(Boolean)
                .slice(0, 2);
              setGeneratedPromptQuestions(questionsArray);
            }
            ws.close();
          }
        } catch (err) {
          console.error("Error parsing WS data:", err);
        }
      };

      ws.onerror = (err) => {
        console.error("WebSocket error:", err);
        toast.error("WebSocket error occurred. Please try again.");
        setIsLoadingResponse(false);

        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) => {
              if (msg.id === botMsgId && !msg.final) {
                return {
                  ...msg,
                  text:
                    "I'm sorry, I'm having trouble contacting the server right now. Please try again later.",
                  final: true,
                };
              }
              return msg;
            })
          );
        }
      };

      ws.onclose = () => {
        setIsLoadingResponse(false);

        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) => {
              if (msg.id === botMsgId && !msg.final) {
                return {
                  ...msg,
                  text:
                    "I'm sorry, I couldn't complete your request. The connection was closed or timed out.",
                  final: true,
                };
              }
              return msg;
            })
          );
        }
      };
    } catch (error) {
      console.error("WebSocket init error:", error);
      toast.error("Failed to connect to WebSocket. Please try again.");
      setIsLoadingResponse(false);

      // Fallback message if WebSocket fails
      setChatHistory((prev) =>
        prev.map((msg) => {
          if (msg.id === botMsgId && !msg.final) {
            return {
              ...msg,
              text:
                "I'm sorry, I'm having trouble connecting at the moment. Please try again later.",
              final: true,
            };
          }
          return msg;
        })
      );
    }
  };



  // =============== NORMAL CHAT (WS) ===============
  const handleUserQuery = (userQuery) => {
    const generateUniqueId = () =>
      `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

    let finalReceived = false;

    // 1) User message
    const userMsgId = generateUniqueId();
    const userMessage = {
      id: userMsgId,
      sender: "user",
      text: userQuery,
      final: true,
    };

    // 2) Bot placeholder
    const botMsgId = generateUniqueId();
    const botPlaceholder = {
      id: botMsgId,
      sender: "bot",
      text: "",
      final: false,
    };
    setChatHistory((prev) => [...prev, userMessage, botPlaceholder]);
    // console.log("chatHistory",chatHistory);

    setIsLoadingResponse(true);
    setGeneratedPromptQuestions([]);
    setAutoScrollEnabled(true);

    try {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_URL}/ws/qdrant-copilot-chat/${chatId}/?token=${localStorage.getItem("token")}`
      );

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            client_message: userQuery,
            chat_id: chatId,
          })
        );
      };
      console.log("userQuery", userQuery);

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          // Progress chunks: append to the bot placeholder
          if (data.type === "progress" && data.chunk) {
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + data.chunk }
                  : msg
              )
            );
          }

          // Final chunk: mark final
          if (data.type === "final") {
            finalReceived = true;
            const finalChunk = data.chunk || "";
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + finalChunk, final: true }
                  : msg
              )
            );
            console.log("finalchunk", finalChunk);

            setIsLoadingResponse(false);
            if (data.data && data.data.run_id) {
              const runId = data.data.run_id ;
               setFeedbackId(runId);
               console.log(feedbackId);
               
               console.log("Setting feedbackId:", runId); // Logs the correct value
             }
            

            // If we have generated questions
            if (data.data && data.data.generated_questions) {
              const questionsArray = data.data.generated_questions
                .split(";")
                .map((q) => q.trim())
                .filter(Boolean)
                .slice(0, 2);
              setGeneratedPromptQuestions(questionsArray);
            }
            ws.close();
          }
        } catch (err) {
          console.error("Error parsing WS data:", err);
        }
      };

      ws.onerror = (err) => {
        console.error("WebSocket error:", err);
        toast.error("WebSocket error occurred. Please try again.");
        setIsLoadingResponse(false);

        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) =>
              msg.id === botMsgId && !msg.final
                ? {
                  ...msg,
                  text:
                    "I'm sorry, I'm having trouble contacting the server right now. Please try again later.",
                  final: true,
                }
                : msg
            )
          );
        }
      };
      console.log("chatHistory", chatHistory);

      ws.onclose = () => {
        setIsLoadingResponse(false);

        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) =>
              msg.id === botMsgId && !msg.final
                ? {
                  ...msg,
                  text:
                    "I'm sorry, I couldn't complete your request. The connection was closed or timed out.",
                  final: true,
                }
                : msg
            )
          );
        }
      };
    } catch (error) {
      console.error("WebSocket init error:", error);
      toast.error("Failed to connect to WebSocket. Please try again.");
      setIsLoadingResponse(false);

      setChatHistory((prev) =>
        prev.map((msg) =>
          msg.id === botMsgId && !msg.final
            ? {
              ...msg,
              text:
                "I'm sorry, I'm having trouble connecting at the moment. Please try again later.",
              final: true,
            }
            : msg
        )
      );
    }
  };

  // console.log("chatHistory",chatHistory);



  // =============== SEND TEXTAREA MESSAGE ===============
  const handleSend = () => {
    if (!PromptChatInput.trim()) {
      toast.error("Please enter a prompt!");
      return;
    }
    handleUserQuery(PromptChatInput.trim());
    // Clear the states once sent.
    setPromptChatInput("");
    setClientMessage("");
    setTypedDummyMessages([]); // Clear recommended messages
  };

  // =============== FORMAT TEXT (OPTIONAL) ===============
  const formatText = useCallback((input) => {
    if (typeof input !== "string" || !input.trim()) return "";
    return input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
  }, []);

  // =============== VIEW DETAILS / CHAT WITH DOC ===============
  // const handleViewDetails = (documentKey, documentType) => {
  //   if (!documentKey || !SummarizeDocKey) {
  //     toast.error("File key is not available.");
  //     return;
  //   }
  //   if (documentType === "contract") {
  //     navigate("/Contract/contract-details", {
  //       state: { contractKey: documentKey },
  //     });
  //   } else if (documentType === "invoice") {
  //     navigate("/Invoices/Invoice_Details", {
  //       state: { invoiceKey: documentKey },
  //     });
  //   } else {
  //     console.warn("Unsupported document type:", documentType);
  //   }
  // };

  // const handleChatWithDoc = () => {
  //   if (!documentKey || !documentUrl) {
  //     toast.error("Document information is missing. Please try again.");
  //     return;
  //   }
  //   if (documentType === "contract") {
  //     // Ensure that contractKey is defined and correct
  //     navigate(`/Contract/contractChat?ckey=${documentKey}`);
  //   } else if (documentType === "invoice") {
  //     navigate(
  //       `/Invoices/Invoice_chat?invoiceKey=${documentKey}&invoiceUrl=${encodeURIComponent(
  //         documentUrl
  //       )}`
  //     );
  //   } else {
  //     navigate("/chat", { state: { pdfUrl: documentUrl, pdfKey: documentKey } });
  //   }
  // };


  const handleViewDetails = (documentKey, documentType) => {
    // Use documentKey and documentType if available; otherwise fallback to summarization values
    const keyToUse = documentKey || SummarizeDocKey;
    const typeToUse = documentType || SummarizeDocType;

    if (!keyToUse) {
      toast.error("File key is not available.");
      return;
    }
    console.log("keysssss", keyToUse, typeToUse);

    if (typeToUse === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey: keyToUse },
      });
    } else if (typeToUse === "invoice") {
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: keyToUse },
      });
    } else {
      console.warn("Unsupported document type:", typeToUse);
    }
  };

  const handleChatWithDoc = () => {
    // Use documentKey and documentUrl if available; otherwise fallback to summarization values
    const keyToUse = documentKey || SummarizeDocKey;
    const urlToUse = documentUrl || SummarizeDocUrl;
    const typeToUse = documentType || SummarizeDocType;

    if (!keyToUse || !urlToUse) {
      toast.error("Document information is missing. Please try again.");
      return;
    }

    if (typeToUse === "contract") {
      navigate(`/Contract/contractChat?ckey=${keyToUse}`);
    } else if (typeToUse === "invoice") {
      navigate(
        `/Invoices/Invoice_chat?invoiceKey=${keyToUse}&invoiceUrl=${encodeURIComponent(
          urlToUse
        )}`
      );
    } else {
      navigate("/chat", { state: { pdfUrl: urlToUse, pdfKey: keyToUse } });
    }
  };



  // =============== THUMBS UP/DOWN & FEEDBACK ===============
  const handleThumbsUpClick = (messageId) => {
    const newThumbsUpValue = !thumbsUpClicked[messageId];
    setThumbsUpClicked((prev) => ({ ...prev, [messageId]: newThumbsUpValue }));
    setThumbsDownClicked((prev) => ({ ...prev, [messageId]: false }));
    if (newThumbsUpValue) {
      // Instead of updating state and then calling submitFeedback,
      // call it immediately with the values you need.
      submitFeedback(messageId, feedbackId, "up");
    }
  };

  const handleThumbsDownClick = (messageId) => {
    const newThumbsDownValue = !thumbsDownClicked[messageId];
    setThumbsDownClicked((prev) => ({ ...prev, [messageId]: newThumbsDownValue }));
    setThumbsUpClicked((prev) => ({ ...prev, [messageId]: false }));
    if (newThumbsDownValue) {
      setMessageIdToFeedback(messageId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };
  
  const submitFeedback = async (msgId, runId, feedbackType) => {
    // If runId is not provided, try to get it from the chat message in chatHistory.
    let effectiveRunId = runId;
    if (!effectiveRunId) {
      const message = chatHistory.find((msg) => msg.id === msgId);
      if (message && message.run_id) {
        effectiveRunId = message.run_id;
      }
    }
    if (!msgId || !effectiveRunId || !feedbackType) {
      console.error("Missing data for feedback submission.", msgId, effectiveRunId, feedbackType);
      return;
    }
    const token = localStorage.getItem("token");
    const payload = {
      message_id: msgId,
      run_id: effectiveRunId,
      feedback_type: feedbackType,
    };
    console.log("Submitting feedback with payload:", payload);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/contract/feedback/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Feedback response:", response.data);
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  
  
 

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  // const submitFeedback = async () => {
  //   console.log(messageIdToFeedback, feedbackId, selectedFeedbackType);
  //   try {
  //     if (!messageIdToFeedback || !feedbackId || !selectedFeedbackType) {
  //       console.error("Missing data for feedback submission.");
  //       return;
  //     }

  //     const token = localStorage.getItem("token");
  //     const payload = {
  //       message_id: messageIdToFeedback,
  //       run_id: feedbackId,
  //       feedback_type: selectedFeedbackType,
  //     };
  //     console.log("Submitting feedback with payload:", payload);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}api/v1/contract/feedback/`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log("Feedback response:", response.data);
  //     if (response.data.msg === "Feedback recorded successfully.") {
  //       console.log("Feedback submitted successfully!");
  //     } else {
  //       console.error("Feedback submission failed:", response.data.msg);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting feedback:", error);
  //   }
  // };


  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");
    setFeedbackMessage(combinedFeedback);
  
    // Pass the proper parameters to submitFeedback:
    try {
      await submitFeedback(messageIdToFeedback, feedbackId, selectedFeedbackType);
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  

  const handleClosePopup = () => {
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  // =============== CHAT HISTORY SIDEBAR (OPTIONAL) ===============
  const [isOpen, setIsOpen] = useState(false);
  const toggleChatHistory = () => {
    setIsOpen(!isOpen);
  };

  // const handleSelectChat = (chatId, sessionHistory) => {
  //   console.log("Selected chat session:", chatId, sessionHistory);
  //   // Map API fields to your chat message format
  //   const mappedHistory = sessionHistory.map(item => ({
  //     id: item.message_id, // using API's message_id as our id
  //     sender: item.is_client ? "user" : "bot",
  //     text: item.message,
  //     final: true, // assuming the history messages are complete
  //   }));
  //   setChatHistory(mappedHistory);
  //   setLocalChatId(chatId);
  // };


  // handling see-more icon (dropdown)
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (messageId) => {
    setOpenDropdownId((prev) => (prev === messageId ? null : messageId));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // =============== RENDER ===============
  return (
    <div className="AI-CP-PL-full-container">
      <div className="AI-CP-PL-docChatContainer"
        style={{
          width: isMenuOpen ? "1200px" : "1294px",
        }}>
        {/* Left Section: Chat History (optional) 
        <div
          className="AI-CP-CopilotChatHistoryContainer flex gap-4"
          style={{
            left: isMenuOpen ? "280px" : "150px",
            position: "absolute",
            top: "86px",
          }}
        >
          {/* onNewChat triggers a brand new chat - clear local storage if you want */}
        {/* <div className="relative group">
            <button
              className="w-[44px] h-[36px] rounded-[6px] border border-[rgba(0,0,0,0.1)] bg-white p-[8px_12px] shadow flex items-center justify-center"
              onClick={() => {
                // Clear local storage so we start fresh
                localStorage.removeItem("promptChat_history");
                localStorage.removeItem("promptChat_questions");
                localStorage.removeItem("promptChat_clientMessage");
                localStorage.removeItem("promptChat_finalResponse");
                localStorage.removeItem("promptChat_chatId");

                setChatHistory([]);
                setGeneratedPromptQuestions([]);
                setClientMessage("");
                setChatResponse("");
                setLocalChatId(null);

                // Then call parent’s onNewChat logic
                if (onNewChat) onNewChat();
              }}
            >
              <img src="/newChat-icon.svg" alt="New Chat Icon" className="w-4 h-4" />
            </button>

            {/* Tooltip Below */}
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 bg-black text-white text-xs rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
          Start New Chat
          {/* Upward Arrow */}
          <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
        </div>
        {/* </div>  */}
        {/* Render ChatHistory and pass the callback */}
        {/* <ChatHistory
            isOpen={isOpen}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            onSelectChat={handleSelectChat}
          /> */}


        {/* <button className="chat-historyBtn" onClick={toggleChatHistory}>
            <span>Chat History</span>
            <img src={arrowForward2} alt="Forward arrow" />
          </button> */}

        {/* </div> */}

        {/* Main Chat Content */}
        <div className="AI-CP-PL-docChatContent">
          <div className="AI-CP-PL-message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => {
              const isBot = message.sender === "bot";
              const isFinal = message.final;
              const isFirstBotMessage =
                isBot && chatHistory.findIndex((msg) => msg.sender === "bot") === index;

              return (
                <div
                  key={`${message.id}-${index}`}
                  className={`mb-2 ${isBot
                    ? "Ai-CP-bot-message-container w-full flex items-start"
                    : "w-full flex justify-end"
                    }`}
                >
                  {/* BOT ICON
                  {isBot && (
                    <div className="PL-bot-icon-container flex-shrink-0 mr-2">
                      <img
                        src="/bot-icon.svg"
                        alt="Bot Icon"
                        className={` ${isFirstBotMessage && isLoadingResponse ? "PL-bot-icon-first h-6 w-6 rounded-full" : "PL-bot-icon h-6 w-6 rounded-full"}`}
                      />
                    </div>
                  )} */}

                  {/* MESSAGE BUBBLE */}
                  <div className="flex flex-col">
                    <div className="user-interactions flex">
                      {message.sender === "user" ? (
                        <span className="user-message-style">{message.text}</span>
                      ) : (
                        <>
                          {/* Show Thinking Indicator while bot is responding */}
                          {message.final === false && message.text === "" ? (
                            <div className="min-w-[300px] max-w-full p-4">
                              <ThinkingIndicator isThinking={isLoadingResponse} />
                            </div>
                          ) : (
                            <>
                              {/* Show BOT ICON only when thinking is complete */}
                              {isBot && (
                                <div className="PL-bot-icon-container flex-shrink-0 mr-2">
                                  <img
                                    src="/bot-icon.svg"
                                    alt="Bot Icon"
                                    className="PL-bot-icon h-6 w-6 rounded-full"
                                  />
                                </div>
                              )}
                              {/* Display the bot message */}
                              <span
                                className="AI-CP-PL-bot-message-content"
                                dangerouslySetInnerHTML={{ __html: formatText(message.text) }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className="user-interaction-buttons">
                      {/* Reaction Buttons if it's a final bot message */}
                      {isBot && ((index !== chatHistory.length - 1) || (!isLoadingResponse)) && (
                        <div className="AI-CP-reactionContainer">
                          <div className="Ai-CP-reactions flex gap-2 text-gray-500 mt-2">
                            {((documentKey || SummarizeDocKey) && !isFirstBotMessage) && (
                              <span
                                className="see-more-reaction-icon cursor-pointer"
                                ref={dropdownRef}
                              >
                                <img
                                  src="/more-icon.svg"
                                  alt="more icon"
                                  onClick={() => toggleDropdown(message.id)}
                                />
                                {openDropdownId === message.id && (
                                  <div className="dropdown-menu">
                                    {(documentType || SummarizeDocType) !== "normal_document" && (
                                      <button
                                        onClick={() => handleViewDetails(documentKey, documentType)}
                                      >
                                        <img src="/eye-icon.svg" alt="eye icon" />
                                        <span>View More</span>
                                      </button>
                                    )}
                                    <button onClick={handleChatWithDoc}>
                                      <img src="chatButton-icon.svg" alt="chat-button" />
                                      <span>Chat</span>
                                    </button>
                                  </div>
                                )}
                              </span>
                            )}

                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => handleThumbsUpClick(message.id)}
                            >
                              <img
                                src={
                                  thumbsUpClicked[message.id]
                                    ? "/thums-up-filled.svg"
                                    : "/thums-up.svg"
                                }
                                alt="Thumbs Up"
                              />
                            </span>
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => handleThumbsDownClick(message.id)}
                            >
                              <img
                                src={
                                  thumbsDownClicked[message.id]
                                    ? "/thums-down-filled.svg"
                                    : "/thums-down.svg"
                                }
                                alt="Thumbs Down"
                              />
                            </span>
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => handleCopy(message.id, message.text)}
                            >
                              <img
                                src={
                                  copiedMessageId === message.id ? "/copy-clicked.svg" : "/copy.svg"
                                }
                                alt="Copy"
                              />
                            </span>
                          </div>

                          {((documentKey || SummarizeDocKey) && isFirstBotMessage) && (
                            <div className="AI-CP-reaction-buttons mt-2">
                              {(documentType || SummarizeDocType) !== "normal_document" && (
                                <button
                                  className="w-[120px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[20px] py-[6px]"
                                  onClick={() => handleViewDetails(documentKey, documentType)}
                                >
                                  View More
                                </button>
                              )}
                              <button
                                className="w-[99px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[35px] py-[6px]"
                                onClick={handleChatWithDoc}
                              >
                                Chat
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              );
            })}

            {/* Error if any */}
            {error && (
              <div className="AI-CP-bot-message-container w-full flex items-start mb-4">
                <div className="AI-CP-bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>

          <div className="dummyContent">
            {/* Recommended Questions */}
            <div className="AI-CP-PL-dummy-container">
              {generatedPromptQuestions?.map((question, idx) => (
                <div
                  key={idx}
                  className={`AI-CP-PL-dummy-message-box border rounded-lg  cursor-pointer ${clickedIndex === idx ? "opacity-50" : "opacity-100"
                    }`}
                  onClick={() => {
                    setPromptChatInput(question.trim());
                    setClickedIndex(idx);
                  }}
                >
                  <span className="icon mr-2">
                    <img src="/dummy-prompt-icon.svg" alt="Prompt Icon" />
                  </span>
                  <p className="text-sm">{question.trim() || "..."}</p>
                </div>
              ))}
            </div>

            {/* Input Area */}
            <div className="AI-CP-PL-input-container-holder">
              <textarea
                placeholder={
                  isLoadingResponse
                    ? "Copilot is responding..."
                    : "How can I help? Type your Question or choose a prompt"
                }
                className="AI-CP-PL-input-field-holder"
                value={PromptChatInput}
                onChange={handlePromptChatChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (!isLoadingResponse && PromptChatInput.trim()) {
                      handleSend();
                    }
                  }
                }}
                ref={textareaRef}
              />
              {showObjects && (
                <div className="CP-AI-dropdown-wrapper">
                  <div className="CP-AI-dropdown-container">
                    {/* Your dropdown content */}
                    <h1 className="CP-AI-dropdown-heading">Document Types</h1>
                    <ul className="CP-AI-dropdown-menu CP-AI-dropdown-objects">
                      {["Documents","Contracts", "Invoices"].map((obj) => (
                        <li
                          key={obj}
                          className="CP-AI-dropdown-item"
                          onClick={() => handleObjectSelection(obj)}
                        >
                          {objectIcons[obj] && (
                            <span className="CP-AI-dropdown-icon">{objectIcons[obj]}</span>
                          )}
                          <span className="CP-AI-dropdown-text">{obj}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              {showValues && selectedObject && (
                <div className="CP-dropdown-wrapper-values" ref={dropdownValuesRef}>
                  <div className="CP-dropdown-container-values">
                    <h1 className="CP-dropdown-heading-values">
                      Select {getSingular(selectedObject)}
                    </h1>
                    <div className="CP-AI-values-SearchBox">
                      <img src="/search.svg" alt="search icon" />
                      <input
                        type="text"
                        placeholder="Search here"
                        className="CP-AI-values-SearchBox-input"
                        onChange={handleValuesSearch}
                      />
                    </div>
                    {/* New scrollable container wrapping the list */}
                    <div className="CP-dropdown-scrollable">
                      <ul className="CP-dropdown-menu-values">
                        {filteredValues.length > 0 ? (
                          filteredValues.map((val, index) => (
                            <li
                              key={val.id || index} // Ensure unique key
                              className="CP-dropdown-item-values"
                              onClick={() => handleValueSelection(val)}
                              title={val.name}
                            >
                              <img src="/ai-object-document-icon.svg" alt="documents icon" />
                              <span>{val.name}</span>
                            </li>
                          ))
                        ) : (
                          <li className="CP-dropdown-item-values no-data">
                            No values found
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {showAtDropdown && (
                <div className="CP-dropdown-wrapper-at">
                  <div className="CP-dropdown-container-at">
                    <h1 className="CP-dropdown-heading-at">Select Document</h1>
                    <div className="CP-dropdown-searchbox-at">
                      <img
                        src="/search.svg"
                        alt="search icon"
                        className="CP-dropdown-search-icon-at"
                      />
                      <input
                        type="text"
                        placeholder="Search here"
                        className="CP-dropdown-search-input-at"
                        onChange={handleAtSearch}
                      />
                    </div>
                    {/* New scrollable container */}
                    <div className="CP-dropdown-scrollable-at">
                      <ul className="CP-dropdown-menu-at">
                        {filteredAtOptions.length > 0 ? (
                          filteredAtOptions.map((doc) => (
                            <li
                              key={doc.id}
                              className="CP-dropdown-item-at"
                              onClick={() => handleAtSelection(doc)}
                              title={doc.display}
                            >
                              <img
                                src="/ai-object-document-icon.svg"
                                alt="document icon"
                                className="CP-dropdown-item-icon-at"
                              />
                              <span>{doc.display}</span>
                            </li>
                          ))
                        ) : (
                          <li className="CP-dropdown-item-at no-data">No documents found</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              <button
                className="AI-CP-PL-send-button-holder"
                onClick={() => {
                  if (!isLoadingResponse && PromptChatInput.trim()) {
                    handleSend();
                  }
                }}
                disabled={isLoadingResponse}
              >
                <img
                  src={isLoadingResponse ? "/loading-send-button2.svg" : "/send-copilot.svg"}
                  alt="Send"
                  className={isLoadingResponse ? "PL-send-icon-disabled" : "PL-send-icon"}
                  style={{ width: "56px", height: "56px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Thumbs Down Popup */}
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button className="close-button-thumsdown" onClick={handleClosePopup}>
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          factuallyIncorrect: e.target.checked,
                        }))
                      }
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.refused}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          refused: e.target.checked,
                        }))
                      }
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          notFollowingInstructions: e.target.checked,
                        }))
                      }
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${!feedbackMessage.trim() &&
                    !Object.values(selectedCheckboxes).some((x) => x)
                    ? "disabled-button-thumsdown"
                    : "enabled-button-thumsdown"
                    }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={
                    !feedbackMessage.trim() &&
                    !Object.values(selectedCheckboxes).some((x) => x)
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptChat;