import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const UploadYoutubeUrl = () => {
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // Add state for error handling
  const navigate = useNavigate();

  // Function to extract YouTube video ID from the URL
  const getVideoIdFromUrl = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  // Function to handle summary generation
  const handleGenerateSummary = async () => {
    const youtubeUrl = document.getElementById("large-input").value.trim(); // Get the YouTube URL from the input
    
    if (!youtubeUrl) {
      setError("Please enter a valid YouTube URL.");
      return;
    }

    const videoId = getVideoIdFromUrl(youtubeUrl);

    if (!videoId) {
      setError("Invalid YouTube URL or video ID not found");
      return;
    }

    try {
      setLoading(true);
      setError("");  // Clear any existing error

      // Make the GET request to fetch the summary
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          `api/v1/youtube/summary/?url=${youtubeUrl}`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );

      // Log for debugging
      console.log(response, "YouTube summary");

      // Check if the summary is available
      const summaryData = response.data.summary;
      if (!summaryData) {
        setError("No summary found for this video.");
        return;
      }

      // Update state with the received summary
      setSummary(summaryData);

      // Redirect to another route, passing the summary and videoId in the state
      navigate("/youtube-summary", {
        state: {
          summary: summaryData,
          videoId: videoId,
        },
      });
    } catch (error) {
      console.error("Error fetching summary:", error);
      setError("Error fetching summary. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="md:h-screen p-10 overflow-y-auto w-full bg-gray-900">
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full z-50 overflow-auto bg-black bg-opacity-70 flex justify-center items-center">
          <div className="loader w-16 h-16 border-t-4 border-b-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      )}

      <div className="w-10/12 m-auto pt-16">
        <h1 className="text-white text-[40px] ">
          Upload Youtube Video URL and get a video summary
        </h1>

        {/* Input Section */}
        <div className="my-6">
          <input
            type="text"
            id="large-input"
            placeholder="Please insert YouTube URL"
            className="block w-full p-10 border rounded-lg sm:text-md focus:border-purple-500 bg-gray-700 border-gray-600 placeholder-gray-400 placeholder:text-lg text-white"
            required
            disabled={loading} // Disable input during loading
          />
        </div>

        {/* Error Display */}
        {error && (
          <p className="text-red-500 text-lg mb-4">{error}</p> // Display error message
        )}

        {/* Button Section */}
        <div className="text-center">
          <button
            onClick={handleGenerateSummary}
            className="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-800 font-medium rounded-lg text-md px-10 py-2.5 text-center me-2 mb-2"
            disabled={loading} // Disable button during loading
          >
            {loading ? "Generating..." : "Generate YT Summary"}  {/* Dynamic Button Text */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadYoutubeUrl;
