import { motion } from "framer-motion";
import { FaMagic } from "react-icons/fa"; // Using a magic wand icon

const ThinkingIndicator = () => {
    // Define the final widths for each bar
    const bars = [
        { width: "160%", delay: 0 },   // Longest
        { width: "140%", delay: 0.2 },  // Medium
        { width: "130%", delay: 0.4 },  // Shortest
    ];

    return (
        <div className="flex flex-col items-start w-full max-w-[899px] mx-auto p-4">
            {/* Icon and text */}
            <div className="flex items-center text-purple-600 text-sm font-medium mb-3">
                <img src="/thinking-copilot-icon.svg" alt="thinking icon" />


                <h1
                    className=" px-2 py-1 rounded inline-block"
                    style={{
                        color: "#18101C",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px"
                    }}
                >
                    Preparing your results...
                </h1>
            </div>

            {/* Animated Bars */}
            <div className="flex flex-col space-y-3 w-full">
                {bars.map((bar, index) => (
                    <motion.div
                        key={index}
                        className="h-3 w-full "  // Increased height
                        style={{
                            borderRadius: "4px", // Fixed camelCase syntax
                            background: "linear-gradient(90deg, rgba(232, 142, 222, 0.52) 0%, #DBC5FC 100%)", // Fixed extra comma
                        }}

                        initial={{ width: "10%" }}
                        animate={{
                            width: bar.width,  // Expands once to full width
                            opacity: [1, 0.8, 1], // Subtle pulsing effect
                        }}
                        transition={{
                            width: { duration: 1.5, ease: "easeOut" }, // Expands once
                            opacity: { duration: 1.5, repeat: Infinity, ease: "easeInOut" }, // Blinks infinitely
                            delay: bar.delay, // Staggered effect
                        }}
                    />
                ))}
            </div>

            <p className="text-xs text-gray-500 mt-4 flex gap-1">
                <strong
                    style={{
                        color: "#18101C",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px"
                    }}
                >
                    Tip:
                </strong>
                <br />
                <span
                    style={{
                        color: "rgba(0, 0, 0, 0.60)",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px"
                    }}
                >
                    Type <span className="text-purple-500">/</span> to select a document type. Then, use <span className="text-purple-500">@</span> followed by keywords to find matching docs instantly!
                </span>
            </p>

        </div >
    );
};

export default ThinkingIndicator;
