import React, { useState, useRef, useEffect } from "react";
import "./filterModal.css";
import axios from "axios";

const FilterModal = ({ isOpen, onClose, token, setContracts,setExcels,isExcel = false, fetchExcels, setInvoices, isInvoice = false,  fetchContracts,fetchinvoices , setIsFilterApplied}) => {
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  const [filterType, setFilterType] = useState("");
  const [disableQuickSelect, setDisableQuickSelect] = useState(true); // Initially true
  const [disableDateRange, setDisableDateRange] = useState(true); // Initially true
  const [isApplyFilterEnabled, setIsApplyFilterEnabled] = useState(false); // Initially false
  const [isClearAllEnabled, setIsClearAllEnabled] = useState(false); // Initially false
  
  const [timeRange, setTimeRange] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  useEffect(() => {
    if (isOpen) {
      resetFilters();
    }
  }, [isOpen]);


  const resetFilters = () => {
    setFilterType("");
    setDisableQuickSelect(true); // Disable Quick Select
    setDisableDateRange(true); // Disable From-To
    setTimeRange("");
    setFromDate("");
    setToDate("");
    setIsApplyFilterEnabled(false); // Disable Apply Filter
    setIsClearAllEnabled(false); // Disable Clear All button
    if (fromDateRef.current) fromDateRef.current.value = ""; 
    if (toDateRef.current) toDateRef.current.value = "";
};


  const handleClearAll = () => {
    resetFilters();
    if (setIsFilterApplied) {
      setIsFilterApplied(false); // Reset filter applied state ✅
    }
    if (fetchContracts) {
      fetchContracts();
    }
  
    if (fetchinvoices) {
      fetchinvoices();
    }
    if (fetchExcels) {
      fetchExcels();  // Fetch all Excel files when clearing filters
    }
  
    onClose(); 
  };
  
  useEffect(() => {
    if (isOpen) {
      resetFilters();
      // if (isExcel) {
      //   setFilterType("uploaded_date");
      // }
    }
  }, [isOpen, isExcel]);

  const openDatePicker = (ref) => {
    if (ref.current) {
      if (ref.current.showPicker) {
        ref.current.showPicker();
      } else {
        console.log("Your browser does not support the showPicker API.");
      }
    }
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
    setDisableQuickSelect(false); // Enable Quick Select
    setDisableDateRange(false);   // Enable From-To Dates
    setIsClearAllEnabled(true); // Enable Clear All button
    setIsApplyFilterEnabled(false); // Apply Filter remains disabled until further input
};




const handleTimeRangeChange = (e) => {
  setTimeRange(e.target.value);
  setDisableDateRange(true); // Disable From-To when Quick Select is used
  setDisableQuickSelect(false);
  setFromDate("");
  setToDate("");
  setIsApplyFilterEnabled(!!filterType); // Apply Filter enabled only when filterType exists
};




const handleFromDateChange = (e) => {
  setFromDate(e.target.value);
  setDisableQuickSelect(true);
  setDisableDateRange(false);
  setTimeRange(""); 
  setIsApplyFilterEnabled(!!filterType && e.target.value && toDate); // Apply enabled only if both dates exist
};

const handleToDateChange = (e) => {
  setToDate(e.target.value);
  setDisableQuickSelect(true);
  setDisableDateRange(false);
  setTimeRange("");
  setIsApplyFilterEnabled(!!filterType && fromDate && e.target.value); // Apply enabled only if both dates exist
};




const applyFilter = async () => {
  if (!filterType) {
    console.log("Validation Error: Please select a filter type.");
    return;
  }

  if (!timeRange && (!fromDate || !toDate)) {
    console.log("Validation Error: Please provide a valid Quick Select option or From-To dates.");
    return;
  }

  if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
    console.log("Validation Error: From date cannot be later than To date.");
    return;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/invoice/filter-invoice/`, {
      params: {
        filter_type: filterType,
        time_range: disableDateRange ? timeRange : undefined,
        from_date: !disableDateRange ? fromDate : undefined,
        to_date: !disableDateRange ? toDate : undefined,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    const data = response.data.invoices;

    if (data && data.length > 0) {
      setInvoices(data);
    } else {
      setInvoices([]);
    }

    setIsFilterApplied(true); // Ensure filter is applied
    onClose();
  } catch (error) {
    console.error("Error applying filter:", error);
    setInvoices([]);
    onClose();
  }
};


  if (!isOpen) return null;


  

  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal">
        <button className="close-button-filter" onClick={onClose}>
          ×
        </button>
        <h2 className="filter-title">Filter By</h2>

        <div className="custom-select-wrapper">
          <select
            className="filter-select"
            value={filterType}
            onChange={handleFilterTypeChange}
            // disabled={isExcel}
          >
            <option value="" disabled hidden>
              Select a filter
            </option>
            {isInvoice ? (
      <>
        <option value="issued_on">Date Issued</option>
        <option value="due_date">Due Date</option>
      </>
  ) : isExcel ? (
    <>
      <option value="uploaded_date">Upload Date</option>
      {/* <option value="modified_date">Last Modified Date</option> */}

    </>
  ) : (
      <>
        <option value="renewal_date">Effective Date</option>
        <option value="expiration_date">Expiration Date</option>
      </>
    )}
  </select>
  { <img src="/down.svg" alt="dropdown icon" />}
  {/* !isExcel && */}
       </div>

       <h3 className={`quick-select-label ${disableQuickSelect ? "label-disabled" : ""}`}>
    Quick select
</h3>
        <div className="custom-select-wrapper1">
          <select
            className={`quick-select ${disableQuickSelect ? "disabled" : ""}`}
            value={timeRange}
            onChange={handleTimeRangeChange}
            disabled={disableQuickSelect} 
          >
            <option value="" disabled hidden>
              Select here
            </option>
            <option value="this_month">This month</option>
            <option value="next_three_months">Next 3 Months</option>
            <option value="this_year">This year</option>
          </select>
          <img 
    src="/down.svg" 
    alt="dropdown icon " 
    className={`icon-filter-drop ${disableQuickSelect ? "disabled" : ""}`} 
/>
        </div>

        <div className="date-picker-container">
          <div className="date-picker">
          <label className={disableDateRange ? "label-disabled" : ""}>From</label>
          <div
              className={`date-input-wrapper ${
                disableDateRange ? "disabled" : ""
              }`}
              onClick={() => openDatePicker(fromDateRef)}
            >
              <img src="/calendar.svg" alt="calendar" className="calendar-icon-filter" />
              <input
                type="text"
                ref={fromDateRef}
                className="date-input-filter"
                placeholder="--:--:----"
                value={fromDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={handleFromDateChange}
                disabled={disableDateRange} 
              />
            </div>
          </div>
          <div className="date-picker">
          <label className={disableDateRange ? "label-disabled" : ""}>To</label>
          <div
              className={`date-input-wrapper ${
                disableDateRange ? "disabled" : ""
              }`}
              onClick={() => openDatePicker(toDateRef)}
            >
              <img src="/calendar.svg" alt="calendar" className="calendar-icon-filter" />
              <input
                type="text"
                ref={toDateRef}
                className="date-input-filter"
                placeholder="--:--:----"
                value={toDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={handleToDateChange}
                disabled={disableDateRange}
              />
            </div>
          </div>
        </div>
        <div>
        <div class="button-container-filter">
        <button 
    className="clear-filter-button-filter" 
    onClick={handleClearAll} 
    disabled={!isClearAllEnabled} // Clear All is disabled until a filter is selected
>
    Clear All
</button>

<button 
    className="apply-filter-button-filter" 
    onClick={applyFilter} 
    disabled={!isApplyFilterEnabled} // Apply Filter is disabled until all required fields are filled
>
    Apply Filter
</button>


</div>

        </div>
      </div>
    </div>
  );
};

export default FilterModal;
