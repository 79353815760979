// SubscriptionPopup.jsx

import React from "react";
import { useNavigate } from "react-router-dom";

const SubscriptionPopup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleChoosePlan = () => {
    onClose(); 
    navigate("/test"); 
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative w-[560px] sm:w-[600px] bg-white rounded-xl p-2 text-center shadow-xl border border-[#D9D6E0]">
       
        <div className="absolute top-0 right-0 mt-0 mr-0 bg-[#EDE9FE] text-[#7C3AED] text-xs px-3 py-1 rounded-tr-[12px] bg-[rgba(139,85,221,0.16)]
 font-semibold">
          🎉 Enjoy a 14-day free trial
        </div>

        <h2 className="text-[20px] font-semibold leading-[19px] tracking-[0.2px] text-[#18101C] text-center font-[Outfit] self-stretch mt-6">
  Continue Using DocuSensa – Choose Your Plan
</h2>

        <p className=""  style={{
    width: "465px",
    color: "#3F3F46",
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0.14px",
    marginTop: "0.5rem",
    marginBottom: "1rem",
    paddingLeft: "6.2rem",
    
  }}>
          We’re excited to bring you even more powerful features! <br />
          To continue accessing DocuSensa, select a plan that best fits your needs.
        </p>

        {/* ✅ Features */}
        <div className="flex justify-center gap-4 text-sm text-[#4B5563] mb-2">
          <div className="flex items-center gap-1">
            <span className="text-green-600 text-lg">✅</span>
            Monthly & Yearly Plans Available
          </div>
          <div className="flex items-center gap-1">
            <span className="text-green-600 text-lg">✅</span>
            Enjoy More Premium Features
          </div>
        </div>

        {/* CTA Button */}
        <button
  onClick={handleChoosePlan}
  className="inline-flex justify-center items-center gap-[10px] px-[52px] py-[10px] rounded-[8px] bg-gradient-to-r from-[#6F52ED] to-[#845EC2] text-white text-[14px] font-outfit font-medium leading-[19px] tracking-[0.14px] hover:from-[#5B3DD9] hover:to-[#7247C2] transition"
>
  Choose a Plan
</button>

      </div>
    </div>
  );
};

export default SubscriptionPopup;
