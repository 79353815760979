import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios"; 
import { IoMdContacts } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import "./pricing.css";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import singleUser from "../../Images/single-user.png";
import multiUser from "../../Images/group.png";
import leftArrow from "../../Images/arrow_cool_down.jpg";
import notificaton from "../../Images/notifications.jpg";
import docologo from "../../Images/doculogo.svg";
import cardCheck from "../../Images/card-check.jpg";
import { Header } from "../../components/Header/Header";
const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState(null);

  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(false);
  



  const handleOpenPopup = (plan) => {
    setSelectedPlan(plan);
    setIsProcessingPayment(false); 
    const start = new Date();
  const end = new Date(start);

  if (plan.title === "Grow") {
    // Yearly plan
    end.setFullYear(end.getFullYear() + 1);
  } else {
    // Monthly plan
    end.setMonth(end.getMonth() + 1);
  }

  setUpgradeStartDate(start);
  setUpgradeEndDate(end);
    setIsPopupOpen(true); // Always show popup
    setSubscriptionId(null);
  };
  
  const [currencySymbol, setCurrencySymbol] = useState("$"); // Default USD
const [countryCode, setCountryCode] = useState("");

  



  const [hasCancelledOnce, setHasCancelledOnce] = useState(false);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const initializeRazorpay = async (subscriptionId) => {
    console.log("Initializing Razorpay with Subscription ID:", subscriptionId);
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded || !subscriptionId) return;
  
    const userName = localStorage.getItem("fullName") || "User";
    const userEmail = localStorage.getItem("email") || "user@example.com";
    const userContact = localStorage.getItem("user_contact") || "0000000000";
  
    const options = {
      key: "rzp_test_SbFHwwSZNNf7SE",
      subscription_id: subscriptionId,
      name: "DocuSensa",
      description: `${selectedPlan?.title} Plan Subscription`,
      image: "/DocuSensaLogo.png",
      callback_url: window.location.origin + "/ai-Copilot",
      prefill: {
        name: userName,
        email: userEmail,
        contact: userContact,
      },
      theme: { color: "#C95EBE" },
      handler: async function (response) {
        console.log("✅ Payment Success:", response);
  
        const token = localStorage.getItem("token");
        const prevSubType = subscriptionStatus?.subscription_type;
        const prevPlanType = subscriptionStatus?.plan_type;
        let retryCount = 0;
        setIsActivating(true);
        const pollSubscription = async () => {
          try {
            const result = await axios.get(
              `${process.env.REACT_APP_API_URL}api/v1/accounts/get-subscription-status/`,
              {
                headers: {
                  Authorization: `Token ${token}`,
                },
              }
            );
  
            const { subscription_type, plan_type,quota,message} = result.data;
            const isNowPaid = subscription_type === "paid_subs";
            const upgraded =
              (prevSubType === "trial" && plan_type === "yearly") ||
              (prevSubType === "trial" && plan_type === "monthly") ||
              (prevSubType === "paid_subs" && prevPlanType === "monthly" && plan_type === "yearly");
  
            console.log("🔁 Poll attempt:", retryCount + 1, { subscription_type, plan_type });
  
            const shouldNavigate =
            subscription_type === "trial" &&
            quota === "monthly" &&
            !plan_type.includes("paid_subs"); // Ensures it’s still a trial quota bump
      
          if (shouldNavigate || message) {
            console.log("✅ Condition met. Navigating to /ai-Copilot");
            setIsActivating(false);
            setShowGetStarted(true);
            return;
          }
  
            retryCount++;
            if (retryCount < 30) {
              setTimeout(pollSubscription, 4000); // 4 seconds
            } else {
              console.warn("⏱ Max retries reached. Manual refresh might be required.");
            }
          } catch (err) {
            console.error("❌ Polling error:", err);
            retryCount++;
            if (retryCount < 30) {
              setTimeout(pollSubscription, 4000);
            }
          }
        };
  
        setTimeout(() => {
          pollSubscription();
        }, 10000); // Give a buffer before polling starts
      },
      modal: {
        escape: false,
        ondismiss: function () {
          console.log("⚠️ User closed Razorpay.");
        },
      },
    };
  
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  

  const token = localStorage.getItem("token");
console.log("tkk",token);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedPlan(null);
    setIsProcessingPayment(false);
  };

  const cardInfo = [
    {
      offer: "Limited Time Offer",
      title: "Start",
      planDes: "Monthly Plan - Flexible & Affordable",
      originalPrice: 60.99,
      discountedPrice: 39.0,
      button: {
        text: "Start Free Trial",
      },
      user: {
        logo: <img src={singleUser} alt="user" />,
        text: "1 user",
      },
      purchaseText: "Start Free Trial",
      points: [
        {
          icon: "/features-icon-one-pay.svg",
          text: (
            <>
              <span>Document Upload Limit:</span>
              <br />
              Upto 70 documents per month
            </>
          ),
        },
        {
          icon: "/features-icon-two-pay.svg",
          text: "Daily Questions Limit: Unlimited",
        },
        {
          icon: "/features-icon-three-pay.svg",
          text: "Maximum File Size: 25 MB",
        },
        {
          icon: "/features-icon-four-pay.svg",
          text: "AI Co-Pilot with AI Search on Knowledgebase",
        },
        { icon: "/features-icon-five-pay.svg", text: "Chat with Excel: Yes" },
        {
          icon: "/features-icon-six-pay.svg",
          text: (
            <>
              <span>Multi-Document Chat: 
             </span>
              <br />
              Chat with up to 5 documents simultaneously
            </>
          ),
        },
        {
          icon: "/features-icon-eight-pay.svg",
          text: (
            <>
              <span>Advanced Data Extraction:
           
             </span>
              <br />
              OCR, contracts, and invoices
            </>
          ),
        },

        { icon: "/features-icon-nine-pay.svg", text: "AI RFP Writer" },
        {
          icon: "/features-icon-ten-pay.svg",
          text: "Custom Fields to Extract: Yes",
        },
        {
          icon: "/features-icon-eleven-pay.svg",
          text: "Support Level: Standard support included",
        },
        {
          icon: "/features-icon-twelve-pay.svg",
          text: (
            <>
              <span>Free Trial: 
            
           
             </span>
              <br />
              14 days (5 documents overall, 5 AI queries per day)
            </>
          ),
        },

        {
          icon: "/features-icon-seven-pay.svg",
          text: (
            <>
              <span className="coming-soon-text">Coming Soon:</span>
              <br />
              Includes Google Drive and OneDrive integrations
            </>
          ),
        }
        
      ],
    },
    {
      offer: "Recommended",
      title: "Grow",
      planDes: "Yearly Plan - Saves more",
      originalPrice: 299.99,
      discountedPrice: 199.0,
      button: {
        text: "Start Free Trial",
      },
      user: {
        logo: <img src={multiUser} alt="multi-user" />,
        text: "Multiple Users",
      },
      purchaseText: "Purchase Pro",
      points: [
        {
          icon: "/features-icon-one-pay.svg",
          text: (
            <>
              <span>Document Upload Limit:</span>
              <br />
              Unlimited Document uploads
            </>
          ),
        },
        {
          icon: "/features-icon-two-pay.svg",
          text: "Daily Questions Limit: Unlimited",
        },
        {
          icon: "/features-icon-three-pay.svg",
          text: "Maximum File Size: 50 MB",
        },
        {
          icon: "/features-icon-four-pay.svg",
          text: "AI Co-Pilot with AI Search on Knowledgebase",
        },
        { icon: "/features-icon-five-pay.svg", text: "Chat with Excel: Yes" },
        {
          icon: "/features-icon-six-pay.svg",
          text: (
            <>
              <span>Multi-Document Chat: 
             </span>
              <br />
              Chat with up to 5 documents simultaneously
            </>
          ),
        },
        {
          icon: "/features-icon-eight-pay.svg",
          text: (
            <>
              <span>Advanced Data Extraction:
           
             </span>
              <br />
              OCR, contracts, and invoices
            </>
          ),
        },
        { icon: "/features-icon-nine-pay.svg", text: "AI RFP Writer" },
        {
          icon: "/features-icon-ten-pay.svg",
          text: "Custom Fields to Extract: Yes",
        },
        {
          icon: "/features-icon-eleven-pay.svg",
          text: "Support Level: Standard support included",
        },
        {
          icon: "/features-icon-twelve-pay.svg",
          text: (
            <>
              <span>Free Trial: 
            
           
             </span>
              <br />
              14 days (5 documents overall, 5 AI queries per day)
            </>
          ),
        },

        {
          icon: "/features-icon-seven-pay.svg",
          text: (
            <>
              <span className="coming-soon-text">Coming Soon:</span>
              <br />
              Includes Google Drive and OneDrive integrations
            </>
          ),
        }
      ],
    },

    {
      offer: "Coming Soon",
      title: "Enterprise",
      planDes: "Ideal for Organisations",
      originalPrice: null,
      discountedPrice: "",
      button: {
        text: "Contact Sales",
      },
      user: {
        logo: <img src={multiUser} alt="multi-user" />,
        text: "Multiple Users",
      },
      purchaseText: "Contact Sales",
      points: [
        {
          icon: "/features-icon-one-pay.svg",
          text: (
            <>
              <span>Document Upload Limit:</span>
              <br />
              Unlimited Document uploads
            </>
          ),
        },
        {
          icon: "/features-icon-two-pay.svg",
          text: "Daily Questions Limit: Unlimited",
        },
        {
          icon: "/features-icon-three-pay.svg",
          text: "Maximum File Size: 50 MB",
        },
        {
          icon: "/features-icon-four-pay.svg",
          text: "AI Co-Pilot with AI Search on Knowledgebase",
        },
        // { icon: "/features-icon-seven-pay.svg", text: "Integrations with SFDC, Hubspot, Oracle  Net Suite, Quick Books, Pandadoc, Docusign" },
        { icon: "/features-icon-five-pay.svg", text: "Chat with Excel: Yes" },
        {
          icon: "/features-icon-six-pay.svg",
          text: (
            <>
              <span>Multi-Document Chat: 
             </span>
              <br />
              Chat with up to 5 documents simultaneously
            </>
          ),
        },
        { icon: "/features-icon-three-pay.svg", text: "SSO and SAML" },

        {
          icon: "/features-icon-eight-pay.svg",
          text: (
            <>
              <span>Advanced Data Extraction:
           
             </span>
              <br />
              OCR, contracts, and invoices
            </>
          ),
        },
        {
          icon: "/features-icon-thirteen-pay.svg",
          text: "Dedicated Customer Success Manager",
        },
        {
          icon: "/features-icon-two-pay.svg",
          text: "Create Custom Knowledgebase",
        },
        {
          icon: "/features-icon-seven-pay.svg",
          text: (
            <>
              Integrations with 
             
              <br />
              <span className="coming-soon-text"> SFDC, Hubspot, Oracle  Net Suite, Quick Books, Pandadoc, Docusign</span>
            </>
          ),
        }
      ],
    },
  ];

  const handlePaymentFrequencyChange = (frequency) => {
    setIsMonthly(frequency);
  };
  // const handlePaymentFrequencyChange = (isMonthly) => {
  //   setIsMonthly(isMonthly);
  // };
  const handleCardClick = (index) => {
    setSelectedCard(index); // Set the clicked card as the selected one
  };

  const getBackgroundColor = (title) => {
    switch (title) {
      case "Start":
        return "#ECE0FD"; // Background for Starter plan
      case "Grow":
        return "rgba(201, 94, 190, 0.20)"; // Background for Pro plan
      case "Enterprise":
        return "#ECE0FD"; // Background for Pro+ plan
      default:
        return "white"; // Default background
    }
  };
  const getTextColor = (title) => {
    switch (title) {
      case "Start":
        return "#8B55DD";
      case "Grow":
        return "#C95EBE";
      case "Enterprise":
        return "linear-gradient(90deg, #8B55DD 0%, #8B55DD 100%)"; // Background for Pro+ plan
      default:
        return "white";
    }
  };

  const getButtonColor = (title) => {
    switch (title) {
      case "Start":
        return "#8B55DD";
      case "Grow":
        return "#8B55DD";
      case "Enterprise":
        return "#C95EBE";
      default:
        return "black";
    }
  };

  const getButtonBorderColor = (title) => {
    switch (title) {
      case "Start":
        return "#8B55DD";
      case "Grow":
        return "#8B55DD";
      case "Enterprise":
        return "#C95EBE";
      default:
        return "black";
    }
  };
  // const handleNavigation = () => {
  //   if (cardInfo.title === "Starter") {
  //     navigate("/uploadFile");
  //   }
  // };
  const handleNavigation = () => {
    navigate("/ai-Copilot");
  };

  const getLink = () => {
    return isMonthly
      ? "https://rzp.io/rzp/docusensa-testing" // Monthly Razorpay link
      : "https://pages.razorpay.com/pl_PBk8xMY3wOv18X/view"; // Yearly Razorpay link
  };

  const [plans, setPlans] = useState([]);
  const [userIP, setUserIP] = useState("103.108.4.82");
  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/accounts/get-plans/`);
      if (response.status === 200) {
        const { ip_address, currency, country_code } = response.data;
        setUserIP(ip_address);
        setCountryCode(country_code);
  
        if (currency === "INR") {
          setCurrencySymbol("₹");
        } else {
          setCurrencySymbol("$");
        }
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };


  useEffect(() => {
    fetchPlans();
  }, []);
  
  const [upgradeStartDate, setUpgradeStartDate] = useState(null);
const [upgradeEndDate, setUpgradeEndDate] = useState(null);


const formatPopupDate = (dateObj) => {
  if (!dateObj) return "";
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}-${month}-${year}`;
};



  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          "api/v1/accounts/get-subscription-status/",
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setSubscriptionStatus(response.data);
        if (data?.has_cancelled_once) {
          setHasCancelledOnce(true);
        }

        
      const { quota, plan_type } = data;
      if (quota === "monthly" && plan_type === "trial") {
        console.log("🚀 User already has trial monthly plan. Navigating...");
        navigate("/ai-Copilot");
        return;
      }
    
        // Optional: log entire response
        console.log("Subscription Status Response:", response);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const data = error.response.data;
        if (data?.has_cancelled_once) {
          setHasCancelledOnce(true);
        }
      }
      console.error("Error fetching subscription status:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus(); // Fetch subscription status on load
  }, []);


  const formatPrice = (usd, inr) => {
    return currencySymbol === "₹" ? `₹${inr}` : `$${usd}`;
  };

  const handleSubscribe = async () => {
    if (!selectedPlan) {
      console.error("🚨 No plan selected");
      return;
    }
  
    const planType = selectedPlan.title === "Grow" ? "yearly" : "monthly";
    const token = localStorage.getItem("token");
  
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };
  
    if (hasCancelledOnce && (selectedPlan.title === "Start" || selectedPlan.title === "Grow")) {
      headers["X-Forwarded-For"] = userIP;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/create-subscription/`,
        { plan_type: planType },
        { headers }
      );
  
      const data = response.data;
      console.log("📦 create-subscription response:", data);
  
      setIsProcessingPayment(true);
  
      if (data.subscription_local_id) {
        // Case 1: Poll for final subscription_id
        const localId = data.subscription_local_id;
  
        const pollForSubscriptionId = async (retries = 0) => {
          try {
            const statusRes = await axios.get(
              `${process.env.REACT_APP_API_URL}api/v1/accounts/get-subscription-status/?local_id=${localId}`,
              { headers }
            );
  
            const subData = statusRes.data;
            if (subData.subscription_id) {
              setIsPopupOpen(false); 
              initializeRazorpay(subData.subscription_id);
            } else if (retries < 20) {
              setTimeout(() => pollForSubscriptionId(retries + 1), 3000);
            } else {
              alert("Subscription setup is taking longer than expected. Please try again later.");
            }
          } catch (err) {
            console.error("❌ Polling error:", err);
            if (retries < 20) {
              setTimeout(() => pollForSubscriptionId(retries + 1), 3000);
            } else {
              alert("Something went wrong. Please refresh and try again.");
            }
          }
        };
  
        pollForSubscriptionId();
      } else if (data.subscription_id) {
        // Case 2: Subscription already exists, proceed directly
        initializeRazorpay(data.subscription_id);
      } else {
        // Case 3: Unexpected error
        alert("Subscription initiation failed. Please try again.");
      }
    } catch (error) {
      console.error("🚨 Error during subscription initiation:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  
  

  // monthly razor pay :  "https://pages.razorpay.com/pl_PBheiGIKWBSbCt/view"
  // const handleButtonClick = (route) => {
  //   navigate(route);
  // };

  // const getMonthlyRazorPay = () => {
  //   window.location.href = "https://pages.razorpay.com/pl_PBheiGIKWBSbCt/view";
  // }

  // const getYearlyRazorPay = () => {
  //   window.location.href = "https://pages.razorpay.com/pl_PBk8xMY3wOv18X/view";
  // }

  // const handleButtonClick = (title) => {
  //   if (title === "Pro") {
  //     if (isMonthly) {
  //       getMonthlyRazorPay();  // Redirect to monthly Razorpay link for Pro
  //     } else {
  //       getYearlyRazorPay();  // Redirect to yearly Razorpay link for Pro
  //     }
  //   }
  // };
  // const handlePayment = async () => {
  //   const stripe = await stripePromise;
  //   const paymentFrequency = !isMonthly ? "Monthly" : "Yearly";
  //   // Call your backend to create a Checkout Session
  //   const response = await fetch(
  //     process.env.REACT_APP_BASE_URL + "api/v1/accounts/create_checkout/",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Token " + localStorage.getItem("token"),
  //       },
  //       body: JSON.stringify({
  //         subscription_plan: "Paid",
  //         payment_frequency: paymentFrequency,
  //       }),
  //     }
  //   );

  //   const session = await response.json();

  //   // Redirect to Stripe Checkout
  //   const result = await stripe.redirectToCheckout({
  //     sessionId: session.session_id,
  //   });

  //   if (result.error) {
  //     console.error(result.error.message);
  //     // Handle errors if needed
  //   }
  // };

  return (
    <div className="plan-page">
      <div className="custom-header-wrapper -ml-16">
        <Header name="" image={docologo} />
      </div>

      <div className="mid-section w-full h-full py-5">
        <h2 className="top-planheading">Choose the best Plan for You</h2>

        <p className="top-planheading-bref">
          Explore our tailored plans below and start managing your documents
          with ease. All plans come with a 14-day free trial
        </p>
        <div className="price-card-container w-full h-full flex md:flex-row flex-col items-center justify-center gap-6 pt-4 relative">
          {cardInfo.map((card, index) => (
            <div
              key={index}
              onClick={() => handleCardClick(index)}
              className={`card flex flex-col bg-white p-6 transition-all duration-300`}
              style={{
                width: "21.56rem",
                minHeight: "33.44rem",
                borderRadius: "1rem",
                border: "1px solid rgba(0, 0, 0, 0.10)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              {/* Offer Badge */}
              <div className="offers-pay-terms">
                <span className="offers-text-pay">{card.offer}</span>
              </div>

              {/* Title & Description */}
              <div className="text-center">
                <h3 className="card-header text-lg font-bold text-[#18101C]">
                  {card.title}
                </h3>
                <p className="plan-des">{card.planDes}</p>
                {/* <p className="card-sub-header text-base text-gray-600 mt-2">{card.subHeader}</p> */}
              </div>

              {/* Price */}
              {/* Pricing Section */}
              <div className="card-price text-center font-semibold text-[#18101C] text-2xl flex items-center justify-center gap-2">
                {card.originalPrice !== null ? (
                  <>
                    {/* Strikethrough Original Price */}
                    <span className="text-[#C95EBE] text-lg line-through pay-original-price">
  {formatPrice(card.originalPrice, card.title === "Grow" ? "25,675.30" : "5,219.96")}
</span>

<span className="text-black pay-discount-price">
  {formatPrice(card.discountedPrice, card.title === "Grow" ? "17,032.00" : "3,338.00")}
</span>
<sub className="pay-discount-price-des">
  {card.title === "Grow" ? "/year" : "/month"}
</sub>
                  </>
                ) : (
                  <span className="comming-sson-pay">/Coming Soon</span>
                )}
              </div>

              <div
                style={{
                  background:
                    "linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%)",
                  width: "299px",
                  height: "1px",
                  // margin: "16px auto",
                  marginLeft: "15px",
                }}
              ></div>

              <div className="flex flex-col gap-2 ml-4">
                {/* Heading with Icon */}
                <div className="flex items-center included-pay">
                  <img
                    src="/stars-pay.svg"
                    alt="star icon"
                    className="w-5 h-5 mr-2"
                  />
                  <h4 className="card-include ">What's Included</h4>
                </div>

                <div
                  className="points-container mt-0"
                  style={{
                    maxHeight: card.title === "Enterprise" ? "222px" : "240px", // 240px for first two, 222px for third
                    overflowY: "auto", // Ensure scrolling applies for all if needed
                  }}
                >
                  {card.points.map((point, index) => (
                    <div
                      key={index}
                      className="flex items-start text-gray-800 text-sm"
                    >
                      <img
                        src={point.icon}
                        alt="feature icon"
                        className="w-5 h-5 mr-3 mt-0"
                      />
                      <div className="des-features-pay">{point.text}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-6">
                <button
                  className="card-btn w-full py-3 rounded-lg font-semibold text-lg transition duration-300 flex items-center justify-center gap-2"
                  onClick={() => handleOpenPopup(card)}
                  style={{
                    color: getButtonColor(card.title),
                    border: `2px solid ${getButtonBorderColor(card.title)}`,
                  }}
                >
                  <span className="flex items-center">
                  {
  hasCancelledOnce && (card.title === "Start" || card.title === "Grow")
    ? "Proceed"
    : card.button.text
}


                    <img
                      src={
                        index === 3
                          ? "/call-new-icon.svg"
                          : "/pay-icon-trail.svg"
                      }
                      alt="icon"
                      className={`w-5 h-5 ${
                        index === 2 ? "ml-2 mt-1" : "ml-[-2px]"
                      }`}
                    />
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isPopupOpen && (
        <div className="custom-popup-overlay">
          <div className="popup-content">
            <span className="close-btn" onClick={handleClosePopup}>
              &times;
            </span>

            <h2 className="custom-popup-title">
              {selectedPlan === "Start"
                ? "Start Your 14-Day Free Trial Today!"
                : selectedPlan === "Grow"
                ? "Confirm Your Plan Upgrade"
                : "Get Started with Your Plan"}
            </h2>

            <p className="custom-popup-subtext">
              {selectedPlan === "Grow"
                ? "You're upgrading to the full version of your plan. Enjoy all premium features!"
                : "Review your selection and start enjoying premium features right away"}
            </p>

            {/* Plan Details */}
            <div className="popup-plan-details">
              <div className="popup-plan-header">
                <span className="popup-free">Free for 14-days</span>
                <span className="popup-free-monthly">Billed monthly</span>
              </div>
              <div>
                <div className="popup-plan-info">
                  <div className="popup-plan-column ml-4">
                    <p className="popup-title">Plan</p>
                    <p className="popup-value">{selectedPlan?.title} Plan</p>
                  </div>
                  <div className="popup-plan-column ml-12">
                    <p className="popup-title">Price</p>
                    <p className="popup-value">
  {selectedPlan?.title === "Grow"
    ? `${currencySymbol}${currencySymbol === "₹" ? "17,032.00" : "199.00"}`
    : `${currencySymbol}${currencySymbol === "₹" ? "3,338.00" : "39.00"}`}
  <sub>/month</sub>
</p>

                  </div>
                  <div className="popup-plan-column ml-4">
                    <p className="popup-title">Start Date</p>
                    <p className="popup-value">{formatPopupDate(upgradeStartDate)}</p>
                    </div>
                  <div className="popup-plan-column ml-12">
                    <p className="popup-title">End Date</p>
                    <p className="popup-value">{formatPopupDate(upgradeEndDate)}</p>
                    </div>
                </div>
                <p className="popup-extra-text">
                  Rest assured! $1 will be charged and refunded immediately for
                  verification.
                </p>
              </div>
            </div>

            {/* Terms and Proceed Button */}
            <p className="popup-terms">
              By clicking below, you agree to our{" "}
              <strong>Terms of Service</strong> and{" "}
              <strong>Privacy Policy</strong>.
            </p>
            <div
  className={`popup-button ${isProcessingPayment ? 'disabled-popup-button' : ''}`}
  onClick={!isProcessingPayment ? handleSubscribe : undefined}
>

            <p className="popup-button-payment">
  {isProcessingPayment
    ? "Please wait ⏳ Redirecting to Razorpay..."
    : "Start Free Trial – Proceed to Secure Payment"}
</p>

              <p>After 14 days, you will be billed with the card on the file</p>
            </div>

            <p className="popup-payment-note">
              You’ll be securely redirected to Razorpay to complete your card
              setup.
            </p>
          </div>
        </div>
      )}

      {/* ACTIVATION IN PROGRESS POPUP */}
      {isActivating && (
    <div className="activation-overlay">
       <img src="/leftside-celebration.svg" alt="celebration left"  style={{
    position: "absolute",
    top: "50%",
    left: "32rem",
    transform: "translateY(-50%)",
   
    zIndex: 1,
  }} />

{/* Right side image */}
<img src="/right-side-celebration.svg" alt="celebration right"  style={{
   position: "absolute",
   top: "50%",
   left: "49rem",
   transform: "translateY(-50%)",
  
   zIndex: 1,
  }} />
      <div className="activation-popup">
        <img src="/celebrate-icon.svg" alt="confetti" className="confetti-icon" />
       
        <h2>Welcome on board!</h2>
        <p>
          ✨ We’re setting up your premium features...
          <br />
          Hang tight, this will take just a moment!
        </p>
        <button disabled className="activating-btn">Activating your account...</button>
      </div>
    </div>
  )}

  {/* ACTIVATION COMPLETE POPUP showGetStarted && */}
  {showGetStarted && (
    <div className="activation-overlay">
       <img src="/leftside-celebration.svg" alt="celebration left"  style={{
    position: "absolute",
    top: "50%",
    left: "32rem",
    transform: "translateY(-50%)",
   
    zIndex: 1,
  }} />

{/* Right side image */}
<img src="/right-side-celebration.svg" alt="celebration right"  style={{
   position: "absolute",
   top: "50%",
   left: "49rem",
   transform: "translateY(-50%)",
  
   zIndex: 1,
  }} />
      <div className="activation-popup">
        <img src="/celebrate-icon.svg" alt="confetti" className="confetti-icon" />
        <h2>You're all set!</h2>
        <p>
          Ready to unlock the full potential of your documents?
          <br />
          Your 14-day free trial starts now!
        </p>
        <button
          className="activating-btn"
          onClick={() => {
            setShowGetStarted(false);
            window.location.href = "/ai-Copilot";
          }}
        >
          Get Started Now
        </button>
      </div>
    </div>
  )}



    </div>
  );
};

export default Pricing;
